import { useState, useEffect } from 'react'
import { Dropdown } from 'antd'
import { useSelector } from 'react-redux'
import moment from 'moment'
import { useNavigate } from 'react-router-dom'

// import { FiSearch } from "react-icons/fi";
import { BsThreeDots } from 'react-icons/bs'
import { FaPlus } from 'react-icons/fa'

import Layout from '../../layout/Layout'
import DataTable from '../../components/Table/DataTable'
import { privateAPI, attachToken } from '../../config/constants'
// import { VscSettings } from "react-icons/vsc";
import { NumberFormatter } from '../../functions/NumberFormatter'
import { ActionMenu, columns } from './utils/campainsUtil'

const Campaigns = () => {
  const [data, setData] = useState(null)
  const [getCampaigns, setgetCampaigns] = useState(null)
  const navigate = useNavigate()
  const selectedBrand = useSelector((state) => state?.brand?.selectedBrand)

  const getAllCampaigns = async () => {
    setgetCampaigns(null)
    try {
      attachToken()
      const res = await privateAPI.get(`/campaign/get-campaigns/${selectedBrand}`)
      if (res?.data) {
        // console.log('get-campaigns',res?.data)
        setgetCampaigns(res?.data)
      }
    } catch (error) {
      console.log('err', error)
    }
  }

  useEffect(() => {
    if (selectedBrand) {
      getAllCampaigns()
    }
  }, [selectedBrand])

  useEffect(() => {
    let tempArr = []
    getCampaigns?.map((i) => {
      tempArr.push({
        name: (
          <span className='link' onClick={() => navigate(`/campaign/${i?._id}`)}>
            {i?.name}
          </span>
        ),
        brand: i?.brand?.name,
        type: i?.campaignType,
        objective: i?.objective,
        status: (
          <p
            style={{
              textTransform: 'capitalize',
              display: 'flex',
              alignItems: 'center',
              gap: '0.5rem',
            }}
          >
            <span
              style={{
                display: 'flex',
                width: '8px',
                height: '8px',
                borderRadius: '50%',
                background: i?.status === 'completed' ? '#ef5a29' : 'green',
              }}
            />
            {i?.status}
          </p>
        ),
        createdOn: moment(i?.createdAt).format('MMM DD, YYYY'),
        date: `${moment(i?.startDate).format('MMM DD, YYYY')} - ${moment(i?.endDate).format(
          'MMM DD, YYYY',
        )}`,
        people: i?.influencers?.length,
        budget: `PKR${NumberFormatter(i?.budget, 1)}`,
        total: `PKR${NumberFormatter(i?.totalSpend, 1)}`,
        action: (
          <Dropdown
            overlay={ActionMenu(i, getAllCampaigns, navigate)}
            trigger={['click']}
            placement={'bottomRight'}
          >
            <BsThreeDots className='dot-icon' />
          </Dropdown>
        ),
      })
    })
    setData(tempArr)
  }, [getCampaigns])

  return (
    <Layout active={'campaigns'}>
      <div className='campaigns-container'>
        <h2 className='mainHeading'>Campaigns</h2>
        <p className='subHeading'>{getCampaigns?.length} Active Campaigns</p>

        {/* Filter Bar */}
        <div className='filter-bar'>
          <div></div>
          {/* <div className="left-side">
            <div className="filter-div">
              <VscSettings className="icon" />
              <span>Filter</span>
            </div>
            <Select
              defaultValue={25}
              style={{ minWidth: 100 }}
              onChange={perPageChange}
              className="select"
              size="large"
            >
              <Option value={25}>show 25</Option>
              <Option value={50}>show 50</Option>
              <Option value={75}>show 75</Option>
              <Option value={100}>show 100</Option>
            </Select>
            <Select
              defaultValue={"date"}
              style={{ minWidth: 100 }}
              onChange={onFilterChange1}
              className="select"
              size="large"
            >
              <Option value={"date"}>Date</Option>
              <Option value={"name"}>Name</Option>
              <Option value={"people"}>People</Option>
            </Select>
          </div> */}
          <div className='right-side'>
            {/* <Input
              placeholder="Search by name"
              prefix={<FiSearch className="search-icon" />}
              className="searchInput"
              size="small"
            /> */}
            <FaPlus className='add-icon' onClick={() => navigate('/create-campaign')} />
            {/* <FaRegSave className="add-icon" style={{ width: "50px" }} /> */}
          </div>
        </div>

        {/* Table */}
        <div className='main'>
          <DataTable
            columns={columns}
            data={data}
            loader={getCampaigns ? false : true}
            // width={1400}
          />
        </div>
      </div>
    </Layout>
  )
}

export default Campaigns
