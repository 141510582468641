import { useState, useEffect } from "react";

import Layout from "../layout/Layout";
import DataTable from "../components/Table/DataTable";
import { useNavigate } from "react-router-dom";

const ProgramAnalytics = () => {
  const navigate = useNavigate();
  const [data, setData] = useState(null);

  // const onfilter2Change = (value) => {
  //   console.log("filter", value);
  // };

  useEffect(() => {
    let tempArr = [];
    [1]?.map(() => {
      tempArr.push({
        profile: (
          <div
            className="profile"
            onClick={() => navigate(`/report/brand-sov`)}
          >
            <img
              src={"https://app.traackr.com/img/analytics/report-influence.png"}
              alt="profile"
            />
          </div>
        ),
        name: (
          <span
            className="link"
            onClick={() => navigate(`/report/brand-sov`)}
          >
            Brand Sov
          </span>
        ),
        time: "Jan 20, 2021 - Nov 16, 2022",
        owner: "Sheeran El Naggar",
        date: "Mar 26, 2022",
      });
    });
    setData(tempArr);
  }, []);

  const columns = [
    {
      title: " ",
      dataIndex: "profile",
      width: "50px",
    },
    {
      title: "Name",
      dataIndex: "name",
    },
    {
      title: "Time Frame",
      dataIndex: "time",
    },
    {
      title: "Owner",
      dataIndex: "owner",
    },
    {
      title: "Created Date",
      dataIndex: "date",
    },
  ];

  return (
    <Layout active={"reports"}>
      <div className="program-analytics-container">
        <h2 className="mainHeading">Program Analysis</h2>
        <p className="subHeading">1 reports</p>

        {/* Filter Bar */}
        {/* <div className="filter-bar">
          <div className="left-side">
            <Select
              defaultValue="recent"
              style={{ width: 100 }}
              onChange={onfilter2Change}
              size="large"
              className="select"
            >
              <Option value="recent">Recent</Option>
              <Option value="name">Name</Option>
              <Option value="owner">Owner</Option>
            </Select>
          </div>
          <div className="right-side">
            <AddNotes />
          </div>
        </div> */}
        <div className="main">
          <DataTable columns={columns} data={data} loader={false} />
        </div>
      </div>
    </Layout>
  );
};

export default ProgramAnalytics;
