import { notification } from "antd";
import { publicAPI } from "../../config/constants";

export const authLogin = (payload, navigate) => {
  return async () => {
    try {
      const res = await publicAPI.post(`/admin/login`, payload);
      if (res) {
        if (res.data) {
          localStorage.setItem("token", res.data.token);
          localStorage.setItem("user", JSON.stringify(res.data.user));
          localStorage.setItem("adminType", JSON.stringify(res.data.type));
          localStorage.setItem("token_Version", JSON.stringify(res.data.user.token_Version));
          notification.success({
            description: res.data.message,
            duration: 2,
          });
          navigate("/brand-buzz");
        }
      }
    } catch (err) {
      notification.error({
        description: err?.response?.data?.message || "Server Error",
        duration: 2,
      });
    }
  };
};

export const influencerAuthLogin = (payload, navigate) => {
  return async () => {
    try {
      const res = await publicAPI.post(`/influencer/login`, payload);
      if (res) {
        if (res.data) {
          localStorage.setItem("token", res.data.token);
          localStorage.setItem("user", JSON.stringify(res.data.user));
          localStorage.setItem("adminType", JSON.stringify(res.data.type));
          notification.success({
            description: res.data.message,
            duration: 2,
          });
          navigate(`/influencer/profile/${payload?.id}?tab=content`);
        }
      }
    } catch (err) {
      notification.error({
        description: err?.response?.data?.message || "Server Error",
        duration: 2,
      });
    }
  };
};
