import React, { useEffect, useState } from 'react'
import { Row, Col } from 'antd'
import { useNavigate, useParams } from 'react-router-dom'

import { MdArrowBack } from 'react-icons/md'

import { attachToken, privateAPI } from '../../../config/constants'
import BrandBuzzCard from '../../../components/Cards/BrandBuzzCard'
import InfinityLoader from '../../../components/InfinityLoader'
import Layout from '../../../layout/Layout'
// import { ExpireDateCheck } from '../../../functions/ExpireDateCheck'
// import OfflineProfileImage from '../../../assets/offlineProfile.png'

const CampaignInfluencerContent = () => {
  const { id, inf } = useParams()
  const navigate = useNavigate()
  const [content, setContent] = useState(null)

  const getCampaignContent = async () => {
    if (id && inf) {
      setContent(null)
      try {
        let payload = {
          influencer: inf,
        }
        attachToken()
        const res = await privateAPI.post(
          `/campaign/get-campaign-influencer-content/${id}`,
          payload,
        )
        if (res?.data) {
          // console.log('res', res?.data)
          setContent(res?.data)
        }
      } catch (error) {
        console.log('err', error)
      }
    }
  }

  useEffect(() => {
    getCampaignContent()
  }, [])

  return (
    <Layout active={'campagin'}>
      {content ? (
        <div className='campaign-content'>
          <MdArrowBack className='back-arrow' onClick={() => navigate(-1)} />
          <div className='filter-bar'>
            <div className='profile-div'>
              <img
                src={content?.inf?.arch_profile_picture_url || content?.inf?.profile_picture_url}
                // src={ExpireDateCheck(content?.inf?.dataRefreshed) === 10 ? content?.inf?.arch_profile_picture_url ? content?.inf?.arch_profile_picture_url : OfflineProfileImage : ExpireDateCheck(content?.inf?.dataRefreshed) === 2 ? content?.inf?.profile_picture_url : OfflineProfileImage}
                alt='profile'
              />
              <span>{content?.inf?.name}</span>
            </div>
          </div>
          <br />
          <Row gutter={[20, 20]}>
            {content?.content ? (
              content?.content?.length > 0 ? (
                content?.content?.map((post, index) => (
                  <Col key={index} xs={24} lg={12} xl={8} xxl={6}>
                    <BrandBuzzCard
                      followers={post?.media?.instagram?.followers_count}
                      media={post?.media}
                      name={post?.media?.instagram?.name}
                      profilePic={post?.media?.instagram?.profile_picture_url}
                      influencerId={post?.media?.instagram?.influencer}
                      inf={post?.media?.influencer}
                    />
                  </Col>
                ))
              ) : (
                <div className='no-found '>
                  <span>No Content Found.</span>
                </div>
              )
            ) : (
              <Col xs={24}>
                <InfinityLoader />
              </Col>
            )}
          </Row>
        </div>
      ) : (
        <InfinityLoader />
      )}
    </Layout>
  )
}

export default CampaignInfluencerContent
