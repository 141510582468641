import { Progress } from 'antd'

// import { MdKeyboardArrowDown } from 'react-icons/md'

function AudienceType({ data }) {
  return (
    <>
      {data && (
        <div className='progress_bar'>
          <div className='header'>
            <h3>Audience Type</h3>
            {/* <MdKeyboardArrowDown className='icon' /> */}
          </div>

          {data?.map((item, ind) => (
            <div key={ind}>
              <div className='parcentage_div'>
                <p>{item?.name || ''}</p>
                <p>{item?.value}%</p>
              </div>
              <Progress
                percent={item?.value || 0}
                strokeColor='rgb(239 90 41 / 72%)'
                showInfo={false}
              />
            </div>
          ))}
        </div>
      )}
    </>
  )
}

export default AudienceType
