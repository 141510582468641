import React from 'react'
import { Col, Row } from 'antd'

import { MdPeopleOutline } from 'react-icons/md'
import { CgFileDocument } from 'react-icons/cg'
import { BiMessageRounded, BiTrendingUp } from 'react-icons/bi'
import { BsPlay, BsWifi2 } from 'react-icons/bs'
import { AiOutlineEye } from 'react-icons/ai'

import { NumberFormatter } from '../../../functions/NumberFormatter'

function Summary({ visible }) {
  const {
    inf,
    posts,
    engagements,
    video_views,
    reach,
    impressions,
    estImpressions,
    potReach,
    engRate,
    vit,
  } = visible

  return (
    <div className='summary_container'>
      <Row className='heading' justify='space-between'>
        {inf?.enabled && (
          <Col className='col_div'>
            <div className='heading_icon'>
              <MdPeopleOutline size={20} />
              <h1>{Number(inf?.value)}</h1>
            </div>
            <p>INF&apos;S</p>
          </Col>
        )}
        {posts?.enabled && (
          <Col className='col_div'>
            <div className='heading_icon'>
              <CgFileDocument size={20} />
              <h1>{Number(posts?.value)}</h1>
            </div>
            <p>POSTS</p>
          </Col>
        )}
        {engagements?.enabled && (
          <Col className='col_div'>
            <div className='heading_icon'>
              <BiMessageRounded size={20} />
              <h1>{NumberFormatter(Number(engagements?.value), 1)}</h1>
            </div>
            <p>ENG&apos;S</p>
          </Col>
        )}
        {video_views?.enabled && (
          <Col className='col_div'>
            <div className='heading_icon'>
              <BsPlay size={20} />
              <h1>{NumberFormatter(Number(video_views?.value), 1)}</h1>
            </div>
            <p>VIDEO VIEWS</p>
          </Col>
        )}
        {reach?.enabled && (
          <Col className='col_div'>
            <div className='heading_icon'>
              <BsWifi2 size={20} />
              <h1>{NumberFormatter(Number(reach?.value), 1)}</h1>
            </div>
            <p>REACH</p>
          </Col>
        )}
        {impressions?.enabled && (
          <Col className='col_div'>
            <div className='heading_icon'>
              <AiOutlineEye size={20} />
              <h1>{NumberFormatter(Number(impressions?.value), 1)}</h1>
            </div>
            <p>IMPRESSIONS</p>
          </Col>
        )}
        {estImpressions?.enabled && (
          <Col className='col_div'>
            <div className='heading_icon'>
              <BsWifi2 size={20} />
              <h1>{NumberFormatter(Number(estImpressions?.value), 1)}</h1>
            </div>
            <p>EST IMPRESSIONS</p>
          </Col>
        )}
        {potReach?.enabled && (
          <Col className='col_div'>
            <div className='heading_icon'>
              <BsWifi2 size={20} />
              <h1>{NumberFormatter(Number(potReach?.value), 1)}</h1>
            </div>
            <p>POT&apos;L REACH</p>
          </Col>
        )}

        {engRate?.enabled && (
          <Col className='col_div'>
            <div className='heading_icon'>
              <BiTrendingUp size={20} />
              <h1>{engRate?.value || '0'}%</h1>
            </div>
            <p>ENG RATE</p>
          </Col>
        )}
        {vit?.enabled && (
          <Col className='col_div'>
            <div className='heading_icon'>
              <BiTrendingUp size={20} />
              <h1>{NumberFormatter(Number(vit?.value), 1)}</h1>
            </div>
            <p>VIT</p>
          </Col>
        )}
      </Row>
    </div>
  )
}

export default Summary

// xl={3} lg={3} md={6} sm={12} xs={12}
