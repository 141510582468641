import Layout from "../layout/Layout";
import { VscSettings } from "react-icons/vsc";

const StudioApplicants = () => {

  return (
    <Layout active={"studio-applicants"}>
      <div className="group-container">
        <h2 className="mainHeading">Studio Applicants</h2>
        <p className="subHeading">0 people want to join your studio</p>

        {/* Filter Bar */}
        <div className="filter-bar">
          <div className="left-side">
            <div className="filter-div">
              <VscSettings className="icon" />
              <span>Filter</span>
            </div>
          </div>
        </div>
        <div className="no-found">
          <span>No applicants found.</span>
        </div>
      </div>
    </Layout>
  );
};

export default StudioApplicants;
