import { useState, useEffect } from 'react'
import { Col, Row, Select, Table } from 'antd'

// import { FaRegSave } from 'react-icons/fa'
// import { VscSettings } from 'react-icons/vsc'
import { CiCircleInfo } from 'react-icons/ci'

import { attachToken, privateAPI } from '../../../config/constants'
import { NumberFormatter } from '../../../functions/NumberFormatter'
import InfinityLoader from '../../../components/InfinityLoader'
// import OfflineProfileImage from '../../../assets/offlineProfile.png'
// import { ExpireDateCheck } from '../../../functions/ExpireDateCheck'

const CampaignEfficiency = ({ id, activeTab }) => {
  const { Option } = Select
  const [perPage, setPerPage] = useState(10)
  const [data, setData] = useState(null)
  const [campaignData, setCampaignData] = useState(null)

  const getCampaignEfficiency = async () => {
    if (id) {
      try {
        attachToken()
        const res = await privateAPI.get(`/campaign/get-campaign-efficiency/${id}`)
        if (res?.data) {
          setCampaignData(res?.data)
          console.log('res', res?.data)
        }
      } catch (error) {
        console.log('err', error)
      }
    }
  }

  useEffect(() => {
    if (activeTab === 'efficiency') {
      getCampaignEfficiency()
    }
  }, [activeTab])

  useEffect(() => {
    let tempArr = []
    let tempCampaignData = campaignData?.influencers
    tempCampaignData?.sort((a, b) => {
      if (a?.influencer?.name?.toLowerCase() > b?.influencer?.name?.toLowerCase()) {
        return 1
      }
      if (a?.influencer?.name?.toLowerCase() < b?.influencer?.name?.toLowerCase()) {
        return -1
      }
      return 0
    })
    tempCampaignData?.map((inf, ind) => {
      tempArr.push({
        sr: ind,
        addAfter: inf?.addAfter,
        name: (
          <div className='profile-info'>
            <img
              src={
                inf?.influencer?.arch_profile_picture_url || inf?.influencer?.profile_picture_url
              }
              // src={ExpireDateCheck(inf?.influencer?.dataRefreshed) === 10 ? inf?.influencer?.arch_profile_picture_url ? inf?.influencer?.arch_profile_picture_url : OfflineProfileImage : ExpireDateCheck(inf?.influencer?.dataRefreshed) === 2 ? inf?.influencer?.profile_picture_url : OfflineProfileImage}
              alt='profile'
            />
            <span>{inf?.influencer?.name}</span>
          </div>
        ),
        spend: inf?.value ? `PKR${NumberFormatter(inf?.value, 2)}` : '--',
        cpp: inf?.cpp ? `PKR${NumberFormatter(inf?.cpp, 2)}` : '--',
        cpvid: inf?.cpvid ? `PKR${NumberFormatter(inf?.cpvid, 2)}` : '--',
        cpe: inf?.cpe ? `PKR${NumberFormatter(inf?.cpe, 2)}` : '--',
        cpv: inf?.cpv ? `PKR${NumberFormatter(inf?.cpv, 2)}` : '--',
        cpsv: '--',
      })
    })
    setData(tempArr)
  }, [campaignData])

  const columns = [
    // {
    //   title: 'SR',
    //   dataIndex: 'sr',
    //   width:'50px'
    // },
    {
      title: 'NAME',
      dataIndex: 'name',
    },
    {
      title: 'SPEND',
      dataIndex: 'spend',
    },
    {
      title: 'CPP',
      dataIndex: 'cpp',
    },
    {
      title: 'CPVID',
      dataIndex: 'cpvid',
    },
    {
      title: 'CPE',
      dataIndex: 'cpe',
    },
    {
      title: 'CPV',
      dataIndex: 'cpv',
    },
    {
      title: 'CPSV',
      dataIndex: 'cpsv',
    },
  ]

  //   const tableLoading = {
  //     spinning: loader,
  //     indicator: <LoadingOutlined />,
  //   };

  // console.log('campaignData',campaignData)
  return (
    <div className='campaign-efficiency'>
      {/* Filter Bar */}
      {/* <div className='filter-bar'>
        <div className='left-side'>
          <div className='filter-div'>
            <VscSettings className='icon' />
            <span>Filter</span>
          </div>
        </div>
        <div className='right-side'>
          <FaRegSave className='add-icon' style={{ width: '50px' }} />
        </div>
      </div>
      <br /> */}

      {campaignData ? (
        <>
          <Row gutter={[10, 5]}>
            <Col xs={24} sm={12} md={6} lg={4}>
              <div className='box'>
                <span>
                  {campaignData?.totalSpend
                    ? `PKR${NumberFormatter(campaignData?.totalSpend, 2)}`
                    : '--'}
                </span>
                <p>TOTAL SPEND</p>
              </div>
            </Col>
            <Col xs={24} sm={12} md={6} lg={4}>
              <div className='box'>
                <span>
                  {campaignData?.cpp ? `PKR${NumberFormatter(campaignData?.cpp, 2)}` : '--'}
                </span>
                <p>COST PER POST</p>
              </div>
            </Col>
            <Col xs={24} sm={12} md={6} lg={4}>
              <div className='box'>
                <span>
                  {campaignData?.cpvid ? `PKR${NumberFormatter(campaignData?.cpvid, 2)}` : '--'}
                </span>
                <p>COST PER VIDEO</p>
              </div>
            </Col>
            <Col xs={24} sm={12} md={6} lg={4}>
              <div className='box'>
                <span>
                  {campaignData?.cpe ? `PKR${NumberFormatter(campaignData?.cpe, 2)}` : '--'}
                </span>
                <p>COST PER ENGAGEMENT</p>
              </div>
            </Col>
            <Col xs={24} sm={12} md={6} lg={4}>
              <div className='box'>
                <span>
                  {campaignData?.cpv ? `PKR${NumberFormatter(campaignData?.cpv, 2)}` : '--'}
                </span>
                <p>COST PER VIDEO VIEW</p>
              </div>
            </Col>
            <Col xs={24} sm={12} md={6} lg={4}>
              <div className='box'>
                <span>
                  {campaignData?.cpsv ? `PKR${NumberFormatter(campaignData?.cpsv, 2)}` : '--'}
                </span>
                <p>COST PER STORY VIEW</p>
              </div>
            </Col>
          </Row>
          <br />

          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Select
              onChange={(e) => setPerPage(e)}
              className='select'
              size='large'
              value={perPage}
              style={{ minWidth: '120px' }}
            >
              <Option value={10}>Show 10</Option>
              <Option value={20}>Show 20</Option>
              <Option value={35}>Show 35</Option>
              <Option value={50}>Show 50</Option>
            </Select>
          </div>
          <br />
          {/* Table */}
          <Table
            className='data-table'
            columns={columns}
            dataSource={data}
            rowClassName={(row) => row?.addAfter && 'background-row'}
            pagination={{
              pageSize: perPage,
            }}
            summary={() => {
              return (
                <>
                  <Table.Summary.Row>
                    <Table.Summary.Cell index={0}>
                      <span style={{ color: 'var(--primary)', fontWeight: '600' }}>Total</span>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={1}>
                      <span>
                        {campaignData?.totalSpend
                          ? `PKR${NumberFormatter(campaignData?.totalSpend, 2)}`
                          : '--'}
                      </span>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={2}>
                      <span>
                        {campaignData?.cpp ? `PKR${NumberFormatter(campaignData?.cpp, 2)}` : '--'}
                      </span>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={3}>
                      <span>
                        {campaignData?.cpvid
                          ? `PKR${NumberFormatter(campaignData?.cpvid, 2)}`
                          : '--'}
                      </span>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={4}>
                      <span>
                        {campaignData?.cpe ? `PKR${NumberFormatter(campaignData?.cpe, 2)}` : '--'}
                      </span>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={5}>
                      <span>
                        {campaignData?.cpv ? `PKR${NumberFormatter(campaignData?.cpv, 2)}` : '--'}
                      </span>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={6}>
                      <span>
                        {campaignData?.cpsv ? `PKR${NumberFormatter(campaignData?.cpsv, 2)}` : '--'}
                      </span>
                    </Table.Summary.Cell>
                  </Table.Summary.Row>
                </>
              )
            }}
          />

          <br />

          <div
            style={{
              width: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              gap: '0.5rem',
              background: '#f6f6f8',
              padding: '10px',
              borderRadius: '25px',
            }}
          >
            <CiCircleInfo style={{ color: 'var(--primary)' }} />
            <p>influencers without any hashtags found in their last 25 posts are highlighted.</p>
          </div>
        </>
      ) : (
        <InfinityLoader />
      )}
      {/* Analytics Box */}
    </div>
  )
}

export default CampaignEfficiency
