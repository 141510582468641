import { useState, useEffect } from "react";
// import { Input } from "antd";

// import { FiSearch } from "react-icons/fi";

import Layout from "../../layout/Layout";
// import AddGroup from "../components/modal/AddGroup";
import { privateAPI } from "../../config/constants";
// import { VscSettings } from "react-icons/vsc";
import { useParams } from "react-router-dom";
import NetworkRowCard from "../../components/Cards/NetworkRowCard";
import InfinityLoader from "../../components/InfinityLoader";

const Group = () => {
  const [data, setData] = useState(null);
  const { id } = useParams();

  const getAllGroups = async () => {
    try {
      let payload = {
        id: id,
      };
      const res = await privateAPI.post(`/group/get-by-id`, payload);
      if (res?.data) {
        // console.log("res", res?.data);
        setData(res?.data);
      }
    } catch (error) {
      console.log("err", error);
    }
  };

  useEffect(() => {
    getAllGroups();
  }, []);

  return (
    <Layout active={"Groups"}>
      {data ? (
        <div className="group-container">
          <h2 className="mainHeading">{data?.name}</h2>
          <p className="subHeading">{data?.description}</p>

          {/* Filter Bar */}
          {/* <div className="filter-bar">
          <div className="left-side">
            <div className="filter-div">
              <VscSettings className="icon" />
              <span>Filter</span>
            </div>
            <Input
              placeholder="Search by name"
              prefix={<FiSearch className="search-icon" />}
              className="searchInput"
              size="small"
            />
          </div>
          <div className="right-side">
            <AddGroup getAllGroups={getAllGroups} />
          </div>
        </div> */}

          <br />
          {/* Influencers */}
          <div>
            {data?.influencers?.map((inf,ind) => (
              <NetworkRowCard data={inf} key={ind} />
            ))}
          </div>
        </div>
      ) : (
        <InfinityLoader height={"60vh"} />
      )}
    </Layout>
  );
};

export default Group;
