import { notification } from "antd";
import { privateAPI, publicAPI, attachToken } from "../../config/constants";
import * as type from "../types/generalTypes";
import {onLogout} from  "../../functions/RedirectingAuth"

export const getAllInfluencers = (payload) => {
  return async () => {
    try {
      attachToken();
      const res = await privateAPI.post(`/influencer/get-all`, payload);
      if (res?.data) {
        return res?.data;
      }
    } catch (err) {
      notification.error({
        description: err?.response?.data?.message || "Server Error",
        duration: 2,
      });
      onLogout();
    }
  };
};

export const getAllInfluencersCategory = (payload, setloading) => {
  return async () => {
    setloading(true)
    try {
      attachToken();
      const res = await privateAPI.post(`/influencer/get-all-influencers`, payload);
      if (res?.data) {
        setloading(false)
        return res?.data;
      }
    } catch (err) {
      setloading(false)
      notification.error({
        description: err?.response?.data?.message || "Server Error",
        duration: 2,
      });
      onLogout();
    }
  };
};

export const getAllInfluencerCateories = () => async (dispatch) => {
  try {
    attachToken()
    const res = await publicAPI.get(`/influencer/get-category`);
    if (res?.data) {
      dispatch({
        type: type.GET_ALL_INFLUENCER_CATEGORIES,
        payload: res?.data,
      });
    }
  } catch (err) {
    notification.error({
      description: err?.response?.data?.message || "Server Error",
      duration: 2,
    });
    onLogout();
  }
};

export const getAllInfluencersForCampaign = (payload) => {
  return async () => {
    try {
      attachToken();
      const res = await privateAPI.post(`/influencer/get-all-for-campaign`, payload);
      if (res?.data) {
        return res?.data?.data;
      }
    } catch (err) {
      notification.error({
        description: err?.response?.data?.message || "Server Error",
        duration: 2,
      });
      onLogout();
    }
  };
};
