import { useState } from 'react'
import {  Input, Modal } from 'antd'

import { forntendUrl } from '../../config/constants'

const InstaAuthLinkModal = ({id}) => {
  const [isModalVisible, setIsModalVisible] = useState(false)

  const showModal = () => {
    setIsModalVisible(true)
  }

  const handleCancel = () => {
    setIsModalVisible(false)
  }

  return (
    <>
      <span onClick={() => showModal()}>Get Instagram auth link</span>

      <Modal
        title={'Get Instagram authentication link'}
        visible={isModalVisible}
        footer={false}
        onCancel={handleCancel}
        width={'550px'}
      >
        <div className='insta-auth-link-container'>
          <p>
            This is a link to the influencer&apos;s personal authentication page. Please share this
            link with the influencer to invite them to authenticate their Instagram business account
            with Fireup.
          </p>
          <p className='label'>Authentication Link</p>
          <Input value={`${forntendUrl}/influencer/connect/${id}`} />
        </div>
      </Modal>
    </>
  )
}

export default InstaAuthLinkModal
