import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";

import Layout from "../../layout/Layout";
import { getAllBrands } from "../../redux";
import DataTable from "../../components/Table/DataTable";
import AddBrand from "../../components/modal/AddBrand";
import InfinityLoader from "../../components/InfinityLoader";

const Brands = () => {
  const [data, setData] = useState(null);
  const dispatch = useDispatch();
  const brands = useSelector((state) => state?.brand?.brands);

  useEffect(() => {
    let tempArr = [];
    brands?.map((i) => {
      tempArr.push({
        name: i?.name,
        desc: i?.description,
        createdOn: moment(i?.createdAt).format("MMMM DD, YYYY"),
      });
    });
    setData(tempArr);
  }, [brands]);

  useEffect(() => {
    dispatch(getAllBrands());
  }, []);

  const columns = [
    {
      title: "NAME",
      dataIndex: "name",
    },
    {
      title: "Description",
      dataIndex: "desc",
    },
    {
      title: "CREATED ON",
      dataIndex: "createdOn",
    },
  ];

  return (
    <Layout active={"brands"}>
      {brands ? (
        <div className="campaigns-container">
          <h2 className="mainHeading">Brands</h2>
          <p className="subHeading">{brands?.length} Total</p>

          {/* Filter Bar */}
          <div className="filter-bar">
            <div></div>

            <div className="right-side">
              <AddBrand />
            </div>
          </div>

          {/* Table */}
          <div className="main">
            <DataTable columns={columns} data={data} loader={false} />
          </div>
        </div>
      ) : (
        <InfinityLoader height={"50vh"} />
      )}
    </Layout>
  );
};

export default Brands;
