import React from 'react'
import { Layout } from 'antd'

import Logo from '../../assets/mainlogo.png'

const PublicVerticalLayout = ({children}) => {
  const { Header, Content } = Layout

  return (
    <div className='pub-v-layout'>
      <Layout>
        <Header className='pub-header'>
          <div className='left'>
            <img src={Logo} alt='logo' />
          </div>
        </Header>
        <Content className='public-main-content'>{children}</Content>
      </Layout>
    </div>
  )
}

export default PublicVerticalLayout
