import { useState, useEffect, useMemo } from 'react'
import { Tabs } from 'antd'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import moment from 'moment'

import { MdOutlineDateRange } from 'react-icons/md'

// components
import PublicLayout from '../../layout/PublicLayout/PublicLayout'
// import CampaignProfile from '../ManualCampaign/components/CampaignProfile'
// import CampaignCelebrities from '../ManualCampaign/components/CampaignCelebrities'
// import CampaignTikTokers from '../ManualCampaign/components/CampaignTikTokers'
// import CampaignContentCreators from '../ManualCampaign/components/CampaignContentCreators'
// import CampaignBloggers from '../ManualCampaign/components/CampaignBloggers'
// import CampaignPublications from '../ManualCampaign/components/CampaignPublications'
// import CampaignTwitterArmy from '../ManualCampaign/components/CampaignTwitterArmy'
// import CampaignSeeders from '../ManualCampaign/components/CampaignSeeders'
// import CampaignArticles from '../ManualCampaign/components/CampaignArticles'
// import CampaignKPI from '../ManualCampaign/components/CampaignKPI'
// import CampaignCost from '../ManualCampaign/components/CampaignCost'
import { attachToken } from '../../config/constants'
// import InfinityLoader from '../../components/InfinityLoader'
// import { SettingsMenu } from './utils/campaignUtil'

const useQuery = (search) => {
  return useMemo(() => new URLSearchParams(search), [search])
}

const PublicCampaignDetails = () => {
  const navigate = useNavigate()
  // const { TabPane } = Tabs
  const { id } = useParams()
  const { search } = useLocation()
  const query = useQuery(search)
  const [campaignData, setCampaignData] = useState(null)
  const [activeTab, setActiveTab] = useState('profiles')

  useEffect(() => {
    if (query.get('tab')) {
      setActiveTab(query.get('tab'))
    }
  }, [activeTab])

  useEffect(() => {
    getCampaign()
  }, [id])

  const onTabsChange = (key) => {
    const params = new URLSearchParams()
    if (key) {
      params.append('tab', key)
    } else {
      params.delete('tab')
    }
    navigate({ search: params.toString() })
  }

  const getCampaign = async () => {
    if (id) {
      try {
        attachToken()
        // const res = await publicAPI.get(`/manual-campaign/get-campaigns-details/${id}`)
        let res;
        if (res?.data) {
          setCampaignData(res?.data)
        }
      } catch (error) {
        console.log('err', error)
      }
    }
  }

  return (
    <PublicLayout>
      <div>
        <div className='header'>
          <div className='left'>
            <h2>{campaignData?.campaign?.name || '--'}</h2>
            <p className='subHeading'>
              CPD <MdOutlineDateRange className='icon' />
              {`${moment(campaignData?.campaign?.startDate).format('MMM DD')} - ${moment(
                campaignData?.campaign?.endDate,
              ).format('MMM DD')}`}
            </p>
          </div>
        </div>
        <Tabs
          defaultActiveKey={activeTab ? activeTab : 'profile'}
          onChange={(e) => onTabsChange(e)}
          className='tabs-container'
        >
          {/* {campaignData?.campaign?.tabs?.indexOf('Cost') !== -1 && (
            <TabPane tab='COST' key='cost'>
              <CampaignCost
                id={id}
                shared={true}
                influencersCost={campaignData?.campaign?.influencersCost}
                executionCost={campaignData?.campaign?.executionCost}
                netCost={campaignData?.campaign?.netCost}
                salesTax={campaignData?.campaign?.salesTax}
                totalNetCost={campaignData?.campaign?.totalNetCost}
                getCampaign={getCampaign}
              />
            </TabPane>
          )} */}
          {/* {campaignData?.campaign?.tabs?.indexOf('Costing & KPI') !== -1 && ( */}
          {/* <TabPane tab='COSTING' key='kpi'>
            <CampaignKPI id={id} title={campaignData?.campaign?.name} shared={true} />
          </TabPane> */}
          {/* )} */}
          {/* <TabPane tab='PROFILES' key='profiles'>
            <CampaignProfile id={id} shared={true} />
          </TabPane> */}
          {/* {campaignData?.campaign?.tabs?.indexOf('Celebrities') !== -1 && (
            <TabPane tab='CELEBRITIES' key='celebrities'>
              <CampaignCelebrities id={id} shared={true} />
            </TabPane>
          )} */}
          {/* {campaignData?.campaign?.tabs?.indexOf('Influencers') !== -1 && (
                <TabPane tab='INFLUENCERS' key='influencers'></TabPane>
              )} */}
          {/* {campaignData?.campaign?.tabs?.indexOf('Tiktokers') !== -1 && (
            <TabPane tab='TIKTOKERS' key='tiktokers'>
              <CampaignTikTokers id={id} shared={true} />
            </TabPane>
          )} */}
          {/* {campaignData?.campaign?.tabs?.indexOf('Micro Bloggers') !== -1 && (
            <TabPane tab='MICRO BLOGGERS' key='bloggers'>
              <CampaignBloggers id={id} shared={true} />
            </TabPane>
          )} */}
          {/* {campaignData?.campaign?.tabs?.indexOf('Content Creators') !== -1 && (
            <TabPane tab='CONTENT CREATORS' key='content'>
              <CampaignContentCreators id={id} shared={true} />
            </TabPane>
          )} */}
          {/* {campaignData?.campaign?.tabs?.indexOf('Publications') !== -1 && (
            <TabPane tab='PUBLICATIONS' key='publication'>
              <CampaignPublications id={id} shared={true} />
            </TabPane>
          )} */}

          {/* {campaignData?.campaign?.tabs?.indexOf('Twitter Army') !== -1 && (
            <TabPane tab='TWITTER ARMY' key='twitter'>
              <CampaignTwitterArmy id={id} shared={true} />
            </TabPane>
          )} */}
          {/* {campaignData?.campaign?.tabs?.indexOf('Pack of Seeders') !== -1 && (
            <TabPane tab='PEEK OF SEEDERS' key='seeders'>
              <CampaignSeeders id={id} shared={true} />
            </TabPane>
          )} */}
          {/* {campaignData?.campaign?.tabs?.indexOf('Articles') !== -1 && (
            <TabPane tab='ARTICLES' key='article'>
              <CampaignArticles id={id} shared={true} />
            </TabPane>
          )} */}
        </Tabs>
      </div>
    </PublicLayout>
  )
}

export default PublicCampaignDetails
