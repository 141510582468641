import { useState, useEffect, useMemo } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { Tabs, Dropdown } from 'antd'
import moment from 'moment'

import { MdOutlineDateRange } from 'react-icons/md'
import { AiOutlineSetting } from 'react-icons/ai'

import Layout from '../../layout/Layout'
import CampaignProfile from './components/CampaignProfile'
import CampaignContent from './components/CampaignContent'
import CampaignSocialPerformance from './components/CampaignSocialPerformance'
// import CampaignConversion from "../../components/Views/CampaignConversions";
import CampaignEfficiency from './components/CampaignEfficiency'
import { attachToken, privateAPI } from '../../config/constants'
import InfinityLoader from '../../components/InfinityLoader'
// import CampaignPayment from './components/CampaignPayment'
import { SettingsMenu } from './utils/campaignUtil'
import CampaignReport from './components/CampaignReport'

const useQuery = (search) => {
  return useMemo(() => new URLSearchParams(search), [search])
}

const Campaign = () => {
  const navigate = useNavigate()
  const { TabPane } = Tabs
  const { id } = useParams()
  const { search } = useLocation()
  const [campaignData, setCampaignData] = useState(null)
  const [activeTab, setActiveTab] = useState('profiles')
  const query = useQuery(search)

  const onTabsChange = (key) => {
    setActiveTab(key)
    const params = new URLSearchParams()
    if (key) {
      params.append('tab', key)
    } else {
      params.delete('tab')
    }
    navigate({ search: params.toString() })
  }

  const getCampaign = async () => {
    if (id) {
      try {
        attachToken()
        const res = await privateAPI.get(`/campaign/get-campaigns-details/${id}`)
        if (res?.data) {
          setCampaignData(res?.data)
        }
      } catch (error) {
        console.log('err', error)
      }
    }
  }

  useEffect(() => {
    if (query.get('tab')) {
      setActiveTab(query.get('tab'))
    }
  }, [activeTab])

  useEffect(() => {
    getCampaign()
  }, [id])

  return (
    <>
      <Layout active={'campagin'}>
        {campaignData ? (
          <div className='campaign-container'>
            <div className='header'>
              <div className='left'>
                <div style={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}>
                  <h2>{campaignData?.campaign?.name || '--'}</h2>
                  <span
                    style={{
                      width: '10px',
                      height: '10px',
                      borderRadius: '50%',
                      display: 'flex',
                      background: campaignData?.campaign?.status === 'completed' ? '#ef5a29' : 'green',
                    }}
                  />
                </div>
                <p className='subHeading'>
                  <MdOutlineDateRange className='icon' />
                  {`${moment(campaignData?.campaign?.startDate).format('MMM DD')} - ${moment(
                    campaignData?.campaign?.endDate,
                  ).format('MMM DD')}`}
                  <span style={{textTransform: 'capitalize', marginLeft: '1rem'}}>Status: {campaignData?.campaign?.status}</span>
                </p>
              </div>
              <div className='right'>
                <Dropdown
                  overlay={SettingsMenu(campaignData, navigate)}
                  trigger={['click']}
                  placement={'bottomLeft'}
                >
                  <AiOutlineSetting className='icon' />
                </Dropdown>

                {/* <AiOutlineSave className="icon" /> */}
                {/* <MdRefresh className="icon" /> */}
              </div>
            </div>
            <Tabs
              defaultActiveKey={activeTab ? activeTab : 'profile'}
              onChange={(e) => onTabsChange(e)}
              className='tabs-container'
            >
              <TabPane tab='PROFILES' key='profiles'>
                <CampaignProfile id={id} />
              </TabPane>
              <TabPane tab='CONTENT' key='content'>
                <CampaignContent
                  id={id}
                  campaignDate={{
                    startOf: campaignData?.campaign?.startDate,
                    endOf: campaignData?.campaign?.endDate,
                  }}
                />
              </TabPane>
              <TabPane tab='SOCIAL PERFORMANCE' key='social-performance'>
                <CampaignSocialPerformance
                  id={id}
                  campaignName={campaignData?.campaign?.name}
                  campaignDate={{
                    startOf: campaignData?.campaign?.startDate,
                    endOf: campaignData?.campaign?.endDate,
                  }}
                  ActiveTab={activeTab}
                />
              </TabPane>
              {/* <TabPane tab="CONVERSIONS" key="conversions">
            <CampaignConversion />
          </TabPane> */}
              <TabPane tab='EFFICIENCY' key='efficiency'>
                <CampaignEfficiency id={id} activeTab={activeTab} />
              </TabPane>
              {/* <TabPane tab='PAYMENTS' key='payments'>
                <CampaignPayment id={id} activeTab={activeTab} />
              </TabPane> */}
              <TabPane tab='REPORT' key='report'>
                <CampaignReport id={id} />
              </TabPane>
            </Tabs>
          </div>
        ) : (
          <InfinityLoader />
        )}
      </Layout>
    </>
  )
}

export default Campaign
