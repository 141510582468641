import { useState, useEffect } from 'react'
import { Modal, Table } from 'antd'

const CampaignInfluencersEmailCheck = ({ isModalVisible, setIsModalVisible, campInfData }) => {
  const [data, setData] = useState()
  const handleCancel = () => setIsModalVisible(false)

  useEffect(() => {
    let budgetNull = []
    let budgetNotNull = []
    let budgetNotExist = []
    if (campInfData?.data) {
      let tempArr = campInfData?.data
      tempArr?.map((item) => {
        if (item?.deliverables?.length > 0) {
          item?.deliverables?.map((del) => {
            if (del?.contentFee == 0) {
              budgetNull.push({ ...item, contentType: del?.postType, contentFee: del?.contentFee })
            } else {
              if (!item?.influencer?.email || item?.influencer?.email == '') {
                budgetNotNull.push({
                  ...item,
                  contentType: del?.postType,
                  contentFee: del?.contentFee,
                })
              }
            }
          })
        } else if (item?.deliverables?.length == 0) {
          if (!item?.influencer?.email || item?.influencer?.email == '') {
            budgetNotExist.push(item)
          }
        }
      })

      let resultArr = [...budgetNotExist, ...budgetNull, ...budgetNotNull]
      setData(resultArr)
    }
  }, [campInfData?.data])

  const columns = [
    {
      title: 'Name',
      dataIndex: ['influencer', 'name'],
      key: 'name',
    },
    {
      title: 'Email',
      dataIndex: ['influencer', 'email'],
      key: 'email',
      render: (email) => (
        <>
          {email && email !== '' ? email : <span className='not-available-tag'>Not Available</span>}
        </>
      ),
    },
    {
      title: 'Content Type',
      dataIndex: ['contentType'],
      key: 'type',
    },
    {
      title: 'Content Fee',
      dataIndex: ['contentFee'],
      key: 'budget',
    },
  ]

  return (
    <>
      <Modal
        title='Assign Budget / Email'
        open={isModalVisible}
        footer={false}
        onCancel={handleCancel}
        width={'800px'}
      >
        <Table
          className='data-table'
          columns={columns}
          dataSource={data}
          scroll={{ x: 'auto' }}
          //   rowSelection={{
          //     selectedRowKeys: selectedInfluencer,
          //     onChange: (selected) => setSelectedInfluencer(selected),
          //   }}
        />
      </Modal>
    </>
  )
}

export default CampaignInfluencersEmailCheck
