import { Progress } from 'antd'
import React from 'react'

const getTopStatus = (data) => {
  const values = data?.map((e) => e?.percent)
  const max = Math.max(...values)
  const index = values.indexOf(max)
  const topStatus = data?.[index]
  return topStatus?.name || {}
}

function FamilyStatus({ data }) {
  // console.log('data', data)
  return (
    <div className='family_div'>
      <h3>Family Status</h3>
      <div className='mostly'>
        <h4>Mostly</h4>
        <h2>{getTopStatus(data)}</h2>
      </div>
      {data?.map((item, ind) => (
        <div key={ind} className='parent_div'>
          <div>
            <p>{item?.name || ''}</p>
            <div>
              <p>{item?.value}</p>
              <p>{String(item?.percent)?.replace('-','<')}%</p>
            </div>
          </div>
          <Progress
            percent={item?.percent || 0}
            strokeColor='rgb(239 90 41 / 72%)'
            showInfo={false}
          />
        </div>
      ))}
    </div>
  )
}

export default FamilyStatus
