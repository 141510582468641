import { notification } from "antd";
import { attachToken, privateAPI } from "../../config/constants";
import * as type from "../types/generalTypes";
import {onLogout} from  "../../functions/RedirectingAuth"
export const getAllBrands = () => async (dispatch) => {
  try {
    attachToken();
    const res = await privateAPI.get(`/brand/get-all`);
    // const res = await publicAPI.get(`/brand/get-all`);
    // console.log('all brands res',res)
    if (res?.data) {
      dispatch({
        type: type.GET_ALL_BRANDS,
        payload: res?.data,
      });
    }
  } catch (err) {
    notification.error({
      description: err?.response?.data?.message || "Server Error",
      duration: 2,
    });
    onLogout();
  }
};

export const SelectBrand = (payload) => async (dispatch) => {
  try {
    if (payload) {
      dispatch({
        type: type.SELECT_BRAND,
        payload: payload,
      });
    }
  } catch (err) {
    notification.error({
      description: err?.response?.data?.message || "Server Error",
      duration: 2,
    });
    onLogout();
  }
};
