import React, { useState } from 'react'
import { Col, Row, Tooltip } from 'antd'
import { useNavigate } from 'react-router-dom'
import moment from 'moment'

// React-icons Imports
import { AiOutlineInstagram, AiOutlineLike, AiOutlineRise } from 'react-icons/ai'
import { FaRegBookmark, FaRegComment, FaRegEye } from 'react-icons/fa'
import { BsFillPlayFill, BsPlay } from 'react-icons/bs'
// import { FiUsers } from 'react-icons/fi'
import { RiUserStarLine } from 'react-icons/ri'
import { LuRadioTower } from 'react-icons/lu'
import { PiArrowClockwiseFill, PiArrowCounterClockwiseFill, PiSignOutDuotone } from 'react-icons/pi'

// Local Imports
import PostViewModal from '../modal/PostViewModal'
import { NumberFormatter } from '../../functions/NumberFormatter'
import { getPotentialReach, calculateTotalLikes } from '../../functions/CalculateTotalLikes'
// import { ExpireDateCheck } from '../../functions/ExpireDateCheck'
// import OfflineProfileImage from '../../assets/offlineProfile.png'
// import NotAvailableImage from '../../assets/not-available.jpg'
import NotAvailableImage from '../../assets/not-available-2.png'
import { HashtagText } from '../../functions/HashtagText'
import { GetReach } from '../../functions/GetReach'

const BrandBuzzCard = ({ media, name, followers, influencerId, inf, disableLink }) => {
  const [isModalVisible, setIsModalVisible] = useState(false)
  const navigate = useNavigate()

  const potentialReach = getPotentialReach(media);
  const totalLikes = calculateTotalLikes(potentialReach);
  return (
    <div className='bb-card'>
      <PostViewModal
        isModalVisible={isModalVisible}
        setIsModalVisible={setIsModalVisible}
        media={media}
        name={name}
        // profilePic={
        //   ExpireDateCheck(inf?.dataRefreshed) === 10
        //     ? inf?.arch_profile_picture_url
        //       ? inf?.arch_profile_picture_url
        //       : OfflineProfileImage
        //     : ExpireDateCheck(inf?.dataRefreshed) === 2
        //     ? inf?.profile_picture_url
        //     : OfflineProfileImage
        // }
        profilePic={inf?.arch_profile_picture_url || inf?.profile_picture_url}
        followers={followers}
        influencerId={influencerId}
        disableLink={disableLink}
      />
      <div className='header'>
        <div className='profile'>
          <img
            alt='profile'
            className='img'
            style={{ cursor: disableLink == true ? 'auto' : 'pointer' }}
            onClick={() =>
              disableLink !== true && navigate(`/influencer/${influencerId}?tab=content`)
            }
            src={inf?.arch_profile_picture_url || inf?.profile_picture_url}
            // src={
            //   ExpireDateCheck(inf?.dataRefreshed) === 10
            //     ? inf?.arch_profile_picture_url
            //       ? inf?.arch_profile_picture_url
            //       : OfflineProfileImage
            //     : ExpireDateCheck(inf?.dataRefreshed) === 2
            //     ? inf?.profile_picture_url
            //     : OfflineProfileImage
            // }
          />
          {media?.permalink ? (
            <Tooltip placement='bottom' title={`${media?.permalink}`} className='tooltip'>
              <AiOutlineInstagram
                className='icon'
                onClick={() => {
                  if (media?.permalink) {
                    window.open(media?.permalink)
                  }
                }}
              />
            </Tooltip>
          ) : (
            <AiOutlineInstagram
              className='icon'
              onClick={() => {
                if (media?.permalink) {
                  window.open(media?.permalink)
                }
              }}
            />
          )}
        </div>
        <div className='info'>
          <p
            className='name'
            style={{ cursor: disableLink == true ? 'auto' : 'pointer' }}
            onClick={() =>
              disableLink !== true && navigate(`/influencer/${influencerId}?tab=content`)
            }
          >
            {name}
          </p>
          <p className='desc' style={{ cursor: disableLink == true ? 'auto' : 'pointer' }}>
            @{media?.username} , <span>{moment(media?.timestamp).format('DD MMM')}</span>
          </p>
        </div>
      </div>
      {media?.media_type === 'VIDEO' ||
      (media?.media_type === 'STORY' && media?.story_type === 'VIDEO') ? (
        <div className='media' onClick={() => setIsModalVisible(true)}>
          {media?.media_url ? (
            <>
              <BsFillPlayFill className='icon' />
              <video width={'100%'} height='250'>
                <source src={media?.media_url} type='video/mp4' />
              </video>
            </>
          ) : (
            <img src={NotAvailableImage} alt='influncerImage' className='image' />
          )}
        </div>
      ) : (
        <div className='media' onClick={() => setIsModalVisible(true)}>
          <div className='more'></div>
          {media?.media_url ? (
            <img src={media?.media_url} alt='influncerImage' className='image' />
          ) : (
            <img src={NotAvailableImage} alt='influncerImage' className='image' />
          )}
        </div>
      )}
      <p className='detail'>
        {media?.caption?.length > 130
          ? HashtagText(media?.caption?.slice(0, 130), '...')
          : HashtagText(media?.caption, '')}
      </p>
      <Row className='engage-container' gutter={[20, 10]}>
        {media?.like_count ? (
          <Col className='engage-div'>
            <Tooltip placement='topLeft' title={`Likes`} className='tooltip'>
              <AiOutlineLike className='icon' />
              <span>{NumberFormatter(media?.like_count, 1)}</span>
            </Tooltip>
          </Col>
        ) : null}
        {media?.comments_count ? (
          <Col className='engage-div'>
            <Tooltip placement='topLeft' title={`Comments`} className='tooltip'>
              <FaRegComment className='icon' />
              <span>{NumberFormatter(media?.comments_count, 1)}</span>
            </Tooltip>
          </Col>
        ) : null}

        {media?.media_type === 'STORY' && media?.story_replies ? (
          <Col className='engage-div'>
            <Tooltip placement='topLeft' title={`Replies`} className='tooltip'>
              <FaRegComment className='icon' />
              <span>{NumberFormatter(media?.story_replies, 1)}</span>
            </Tooltip>
          </Col>
        ) : null}

        {media?.media_type === 'STORY' && media?.story_tap_forward ? (
          <Col className='engage-div'>
            <Tooltip placement='topLeft' title={`Taps Forward`} className='tooltip'>
              <PiArrowClockwiseFill className='icon' />
              <span>{NumberFormatter(media?.story_tap_forward, 1)}</span>
            </Tooltip>
          </Col>
        ) : null}

        {media?.media_type === 'STORY' && media?.story_tap_backward ? (
          <Col className='engage-div'>
            <Tooltip placement='topLeft' title={`Taps Backward`} className='tooltip'>
              <PiArrowCounterClockwiseFill className='icon' />
              <span>{NumberFormatter(media?.story_tap_backward, 1)}</span>
            </Tooltip>
          </Col>
        ) : null}

        {media?.media_type === 'STORY' && media?.story_exits ? (
          <Col className='engage-div'>
            <Tooltip placement='topLeft' title={`Exits`} className='tooltip'>
              <PiSignOutDuotone className='icon' />
              <span>{NumberFormatter(media?.story_exits, 1)}</span>
            </Tooltip>
          </Col>
        ) : null}

        {media?.media_type !== 'IMAGE' && media?.media_type !== 'CAROUSEL_ALBUM' ? (
          media?.video_views === 0 || media?.video_views ? (
            <Col className='engage-div'>
              <Tooltip placement='topLeft' title={`Video Plays`} className='tooltip'>
                <BsPlay className='icon' />
                <span>{NumberFormatter(media?.video_views, 1)}</span>
              </Tooltip>
            </Col>
          ) : null
        ) : null}
        {media?.saved === 0 || media?.saved ? (
          <Col className='engage-div'>
            <Tooltip placement='topLeft' title={`Saves`} className='tooltip'>
              <FaRegBookmark className='icon' />
              <span>{NumberFormatter(media?.saved, 1)}</span>
            </Tooltip>
          </Col>
        ) : null}
        {media?.impression === 0 || media?.impression ? (
          <Col className='engage-div'>
            <Tooltip placement='topLeft' title={`Impressions`} className='tooltip'>
              <FaRegEye className='icon' />
              <span>{NumberFormatter(media?.impression, 1)}</span>
            </Tooltip>
          </Col>
        ) : null}
        {media?.reach === 0 || media?.reach ? (
          <Col className='engage-div'>
            <Tooltip placement='topLeft' title={`Reach`} className='tooltip'>
              {/* <FiUsers className='icon' /> */}
              <LuRadioTower className='icon' />
              <span>{NumberFormatter(media?.reach, 1)}</span>
            </Tooltip>
          </Col>
        ) : GetReach({
            type: media?.media_type,
            engRate: media?.engagement
              ? ((media?.engagement / followers) * 100).toFixed(2)
              : media?.local_engagememt,
            videoViews: media?.video_views || null,
            followers: followers,
          }) !== 0 ? (
          <Col className='engage-div'>
            <Tooltip placement='topLeft' title={`Reach`} className='tooltip'>
              {/* <FiUsers className='icon' /> */}
              <LuRadioTower className='icon' />
              <span>
                {NumberFormatter(
                  GetReach({
                    type: media?.media_type,
                    engRate: media?.engagement
                      ? ((media?.engagement / followers) * 100).toFixed(2)
                      : media?.local_engagememt,
                    videoViews: media?.video_views || null,
                    followers: followers,
                  }),
                  1,
                )}
              </span>
            </Tooltip>
          </Col>
        ) : null}
        {/* {GetReach({
          type: media?.media_type,
          engRate: media?.engagement
            ? ((media?.engagement / followers) * 100).toFixed(2)
            : media?.local_engagememt,
          videoViews: media?.video_views || null,
          followers: followers,
        }) !== 0 ? (
          <Col className='engage-div'>
            <Tooltip placement='topLeft' title={`Reach`} className='tooltip'>
              <FiUsers className='icon' />
              <span>
                {NumberFormatter(
                  GetReach({
                    type: media?.media_type,
                    engRate: media?.engagement
                      ? ((media?.engagement / followers) * 100).toFixed(2)
                      : media?.local_engagememt,
                    videoViews: media?.video_views || null,
                    followers: followers,
                  }),
                  1,
                )}
              </span>
            </Tooltip>
          </Col>
        ) : null} */}

        {media?.engagement ? (
          <>
            {media?.engagement === 0 || media?.engagement ? (
              <Col className='engage-div'>
                <Tooltip placement='topLeft' title={`Engagement Rate`} className='tooltip'>
                  <AiOutlineRise className='icon' />
                  <span>{((media?.engagement / followers) * 100).toFixed(2)}</span>
                </Tooltip>
              </Col>
            ) : null}
          </>
        ) : (
          <>
            {media?.local_engagememt === 0 || media?.local_engagememt ? (
              <Col className='engage-div'>
                <Tooltip placement='topLeft' title={`Engagememt Rate`} className='tooltip'>
                  <AiOutlineRise className='icon' />
                  <span>{media?.local_engagememt}</span>
                </Tooltip>
              </Col>
            ) : null}
          </>
        )}
        <Col className='engage-div'>
          <Tooltip placement='topLeft' title={`Potential Reach`} className='tooltip'>
            <RiUserStarLine className='icon' />
            <span>{NumberFormatter((totalLikes/6), 1)}</span>
          </Tooltip>
        </Col>
      </Row>
    </div>
  )
}

export default BrandBuzzCard
