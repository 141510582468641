import { useState, useEffect } from "react";
import { Modal, Button, Form, Input, Select } from "antd";
import { FaPlus } from "react-icons/fa";
import { attachToken, privateAPI } from "../../config/constants";

const AddNotes = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [influencers, setInfluencers] = useState(null);
  const [form] = Form.useForm();
  const { Option } = Select;

  const [loading, setLoading] = useState(false);

  const getInfluencers = async () => {
    try {
      attachToken();
      const res = await privateAPI.get(`/influencer/get-all-for-group`);
      if (res?.data) {
        // console.log("res", res);
        setInfluencers(res?.data);
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    getInfluencers();
  }, []);

  const showModal = () => {
    // setIsModalVisible(true);
  };

  const onFinish = async () => {
    setLoading(true);

    setLoading(false);
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <>
      <FaPlus className="add-icon" onClick={() => showModal()} />
      <Modal
        title={"Create Group"}
        visible={isModalVisible}
        footer={false}
        onCancel={handleCancel}
        width={"600px"}
      >
        <Form
          layout="vertical"
          form={form}
          // fields={
          //   data
          //     ? [
          //         {
          //           name: "title",
          //           value: data.title,
          //         },
          //         {
          //           name: "description",
          //           value: data.description,
          //         },
          //       ]
          //     : [
          //         {
          //           name: "description",
          //           value: "",
          //         },
          //       ]
          // }
          onFinish={onFinish}
        >
          <Form.Item
            label="Name"
            name={"name"}
            rules={[
              {
                required: true,
                message: "Name is Required",
              },
            ]}
            requiredMark={"optional"}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Description"
            name={"description"}
            rules={[
              {
                required: true,
                message: "Description is Required",
              },
            ]}
            requiredMark={"optional"}
          >
            <Input.TextArea
              rows={2}
              style={{ resize: "none" }}
              placeholder="Short description for this group"
            />
          </Form.Item>

          <Form.Item
            label="Influencer"
            name={"influencer"}
            rules={[
              {
                required: true,
                message: "Influencer is Required",
              },
            ]}
            requiredMark={"optional"}
          >
            <Select
              mode="multiple"
              style={{ width: "100%" }}
              onChange={(e) => console.log("e", e)}
              size="large"
            >
              {influencers?.map((inf,ind) => (
                <Option key={ind} value={inf.value}>{inf.label}</Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item>
            <Button htmlType="submit" loading={loading} type="primary">
              Create
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default AddNotes;
