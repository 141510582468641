import { Bar } from "@ant-design/plots";

const StackedBarChart = () => {
  const data = [
    {
      brand: "Maybelline",
      value: 1,
      type: "Story Content",
    },
    {
      brand: "l'oreal parls",
      value: 0.8,
      type: "Story Content",
    },
    {
      brand: "MAKE-UP",
      value: 0.5,
      type: "Story Content",
    },
    {
      brand: "medora",
      value: 0.3,
      type: "Story Content",
    },
    {
      brand: "swiss miss",
      value: 0,
      type: "Story Content",
    },
    {
      brand: "Maybelline",
      value: 8,
      type: "Feed Content",
    },
    {
      brand: "l'oreal parls",
      value: 6,
      type: "Feed Content",
    },
    {
      brand: "MAKE-UP",
      value: 4,
      type: "Feed Content",
    },
    {
      brand: "medora",
      value: 3,
      type: "Feed Content",
    },
    {
      brand: "swiss miss",
      value: 2,
      type: "Feed Content",
    },

    {
      brand: "Maybelline",
      value: 0.2,
      type: "Stream Content",
    },
    {
      brand: "l'oreal parls",
      value: 0.1,
      type: "Stream Content",
    },
    {
      brand: "MAKE-UP",
      value: 0,
      type: "Stream Content",
    },
    {
      brand: "medora",
      value: 0,
      type: "Stream Content",
    },
    {
      brand: "swiss miss",
      value: 0,
      type: "Stream Content",
    },
  ];
  const config = {
    data: data,
    width: "100%",
    isStack: true,
    xField: "value",
    yField: "brand",
    xAxis: false,
    yAxis: {
      display: false,
      line: false,
    },
    seriesField: "type",
    color: ["rgba(0, 0, 0, 0.842)", "#ef5b29f3", "#ef5b29bd"],
    legend: false,
    label: false,
  };
  return <Bar {...config} />;
};

export default StackedBarChart;
