import { Col, Dropdown, Menu, Row } from "antd";
import React from "react";
import {
  BiDotsHorizontalRounded,
  BiHomeAlt,
  BiIdCard,
  BiUserCircle,
} from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import AddGroup from "../modal/AddGroup";

const GroupsCard = ({ data, getAllGroupsData }) => {
  const navigate = useNavigate();

  const menu = (
    <Menu>
      <Menu.Item>
        <AddGroup edit={data} getAllGroupsData={getAllGroupsData} />
      </Menu.Item>
    </Menu>
  );

  return (
    <div
      className="groupsCard-container"
      // onClick={() => navigate(`/group/${data?._id}`)}
    >
      <Row gutter={[30, 30]}>
        <Col xs={20} onClick={() => navigate(`/group/${data?._id}`)}>
          <p className="desc">{data?.description}</p>
        </Col>
        <Col xs={4}>
          <Dropdown
            overlay={menu}
            trigger={["click"]}
            placement={"bottomRight"}
          >
            <BiDotsHorizontalRounded className="dots-icon" />
          </Dropdown>
        </Col>
        <Col xs={24} onClick={() => navigate(`/group/${data?._id}`)}>
          <p>--</p>
        </Col>
        <Col xs={8} onClick={() => navigate(`/group/${data?._id}`)}>
          <span className="home">
            <BiHomeAlt className="icon" /> --
          </span>
        </Col>
        <Col xs={8} onClick={() => navigate(`/group/${data?._id}`)}>
          <span className="influencers">
            <BiUserCircle className="icon" />
            {data?.influencers?.length} Influencer
          </span>
        </Col>
        <Col xs={8} onClick={() => navigate(`/group/${data?._id}`)}>
          <span className="name">
            <BiIdCard className="icon" />
            {data?.name}
          </span>
        </Col>
      </Row>
    </div>
  );
};

export default GroupsCard;
