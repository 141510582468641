import React, { useState } from "react";
import { Drawer } from "antd";
import { MenuOutlined } from "@ant-design/icons";
import { BiArrowBack } from "react-icons/bi";

import MainMenu from "./MainMenu";
import WhiteLogo from "../assets/mainlogo.png";


const MenuDrawer = ({ active }) => {
  const [visible, setVisible] = useState(false);

  const showDrawer = () => {
    setVisible(true);
  };

  const onClose = () => {
    setVisible(false);
  };

  return (
    <>
      <MenuOutlined onClick={showDrawer} className="mobile-menu-icon" />

      <Drawer
        className="mobile-menu-drawer"
        placement="left"
        closable={false}
        onClose={onClose}
        visible={visible}
      >
        <div>
          <div className="m-drawer">
            <div style={{ width: "100px" }}>
              <img src={WhiteLogo} alt="logo" className="logo" />
            </div>
            <BiArrowBack
              onClick={() => setVisible(false)}
              className="backArrow"
            />
          </div>
          <MainMenu active={active} setVisible={setVisible} visible={visible} />
        </div>
      </Drawer>
    </>
  );
};

export default MenuDrawer;
