export const columns = [
    {
      title: 'Platform',
      dataIndex: 'platform',
    },
    {
      title: 'Post Type',
      dataIndex: 'postType',
    },
    {
      title: 'How Many',
      dataIndex: 'howMany',
    },
    {
      title: 'Remove',
      dataIndex: 'action',
    },
  ]