import { useState } from "react";
import { Row, Select, Col } from "antd";

// import { TbDeviceFloppy } from "react-icons/tb";

import StackedBarChart from "../../Charts/StackedBarChart";
import BasicStackbar from "../../Charts/BasicStackbar";

function PostType() {
  const { Option } = Select;
  const [chart, setChart] = useState("stackedBarChart");
  const [chartSelector, setChartSelector] = useState(["activatedInfluencers"]);

  const chartChangeHandler = (value) => {
    setChart(value);
  };

  const multipleChangeHandler = (value) => {
    setChartSelector(value);
  };

  return (
    <div className="post-type-container">
      <div className="header">
        <p>Post Type Breakdown</p>
        <div className="select">
          <Select
            style={{ minWidth: "270px" }}
            mode="multiple"
            onChange={multipleChangeHandler}
            value={chartSelector}
          >
            <Option value="activatedInfluencers">Activated Influencers</Option>
            <Option value="posts">Posts</Option>
            <Option value="engagements">Engagements</Option>
          </Select>
          <Select
            onChange={chartChangeHandler}
            defaultValue="stackedBarChart"
            style={{ minWidth: "150px" }}
          >
            <Option value="stackedBarChart">Stacked Bar Chart</Option>
            <Option value="barChart">Bar Chart</Option>
          </Select>
        </div>
      </div>
      <Row className="stackBarChart_container" gutter={20}>
        {chartSelector.map((data,ind) => (
          <Col
          key={ind}
            xl={
              chartSelector?.length === 1
                ? 24
                : chartSelector?.length === 2
                ? 12
                : 8
            }
            lg={
              chartSelector?.length === 1
                ? 24
                : chartSelector?.length === 2
                ? 12
                : 8
            }
            md={24}
            sm={24}
            xs={24}
            
          >
            <p>ACTIVATED INFLUENCERS</p>
            {chart === "stackedBarChart" ? (
              <StackedBarChart />
            ) : (
              <BasicStackbar />
            )}
          </Col>
        ))}
      </Row>
      <div className="curcle_div">
        <div>
          <div className="story-content"></div>
          <p>Story Content</p>
        </div>
        <div>
          <div className="feed-content"></div>
          <p>Feed Content</p>
        </div>
        <div>
          <div className="stream-content"></div>
          <p>Stream Content</p>
        </div>
      </div>
    </div>
  );
}

export default PostType;
