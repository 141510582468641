import React, { useState } from 'react'
import { Row, Col, Select, Pagination, Form, notification } from 'antd'

import { privateAPI, attachToken } from '../../../../config/constants'
import InfinityLoader from '../../../../components/InfinityLoader'
import InfluencerCard from '../../../../components/Cards/InfluencerCard'

const GroupComp = ({
  state,
  apiLoading,
  setApiLoading,
  groups,
  groupInfluencers,
  setGroupInfluencers,
  overAllSelected,
  setOverAllSelected,
  overAllSelectedObj,
  setOverAllSelectedObj,
}) => {
  const { Option } = Select
  const [page, setPage] = useState(1)

  const getGroupInfluencers = async (id) => {
    try {
      setApiLoading(true)
      attachToken()
      const res = await privateAPI.post('/group/get-influencers', {
        groupId: id,
      })
      if (res?.data) {
        // let tempArr = []
        // res?.data?.influencers?.map((item) => {
        //   tempArr.push(item?._id)
        // })
        // setSelectedGroupInfluencers(tempArr)
        setApiLoading(false)
        setGroupInfluencers(res?.data)

        // if (selectedGroupInfluencerArray) {
        //   setSelectedGroupInfluencersArray(res?.data?.influencers)
        // }
      }
    } catch (err) {
      setApiLoading(false)
      notification.error({
        description: err?.response?.data?.message || 'Server Error',
        duration: 2,
      })
    }
  }

  return (
    <>
      <Col xs={24} md={24} lg={24}>
        <Form.Item
          label='Group'
          name={'group'}
          rules={[
            {
              required: false,
              message: '',
              // message: 'Group is Required',
            },
          ]}
          requiredMark={'optional'}
        >
          <Select
            style={{ width: '100%' }}
            onChange={(e) => {
              getGroupInfluencers(e)
            }}
            size='large'
            placeholder='Select Group'
          >
            {groups?.map((group, ind) => (
              <Option key={ind} value={group?._id}>
                {group?.name}
              </Option>
            ))}
          </Select>
        </Form.Item>
      </Col>

      <Col xs={24}>
        <div className='filter-bar'>
          <div className='left-side'></div>
          <div className='right-side' style={{ flexWrap: 'wrap' }}>
            {/* <Button
                  onClick={() => {
                    let tempArr = []
                    groupInfluencers?.influencers?.map((item) => tempArr.push(item?._id))
                    setOverAllSelected([...overAllSelected,...tempArr])
                    setSelectedGroupInfluencersArray(groupInfluencers?.influencers)
                  }}
                  // disabled={
                  //   selectedGroupInfluecers?.length === groupInfluencers?.influencers?.length
                  //     ? true
                  //     : false
                  // }
                >
                  Select All
                </Button> */}
            {/* <Button
                  // disabled={selectedGroupInfluecers?.length === 0 ? true : false}
                  // onClick={() => {
                  //   setSelectedGroupInfluencers([])
                  //   setSelectedGroupInfluencersArray([])
                  // }}
                >
                  Clear All
                </Button> */}
            <Pagination
              defaultCurrent={1}
              pageSize={20}
              showSizeChanger={false}
              total={groupInfluencers?.influencers?.length}
              onChange={(page) => setPage(page)}
            />
          </div>
        </div>
        <div
          style={{
            background: '#f1f1f1',
            padding: '10px',
            borderRadius: '5px',
            // maxHeight: '550px',
            // overflowY: 'auto',
          }}
        >
          <Row gutter={[20, 20]} style={{ minHeight: '200px' }}>
            {!apiLoading ? (
              groupInfluencers?.influencers?.slice((page - 1) * 20, 20 * page)?.map((inf, i) => (
                <Col xxl={4} xl={6} md={8} sm={12} xs={24} key={i}>
                  <InfluencerCard
                    data={{
                      id: inf?._id,
                      profileImage: inf?.arch_profile_picture_url || inf?.profile_picture_url,
                      name: inf?.name,
                      userName: inf?.instagram_username,
                    }}
                    // selectedInfluencers={selectedGroupInfluecers}
                    selectedInfluencers={overAllSelected}
                    // setSelectedInfluencers={setSelectedGroupInfluencers}
                    setSelectedInfluencers={setOverAllSelected}
                    object={inf}
                    // objectsArr={selectedGroupInfluencerArray}
                    objectsArr={overAllSelectedObj}
                    // setObjects={setSelectedGroupInfluencersArray}
                    setObjects={setOverAllSelectedObj}
                    state={state}
                  />
                </Col>
              ))
            ) : (
              <InfinityLoader />
            )}
          </Row>
        </div>
      </Col>
    </>
  )
}

export default GroupComp
