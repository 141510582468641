import { useState } from "react";
import { Tabs } from "antd";

import Layout from "../layout/Layout";
import Partners from "../components/Views/Partners";
import Brands from "../components/Views/Brands";
import Campaigns from "../components/Views/Campaigns";

const PaidPartners = () => {
  const { TabPane } = Tabs;
  const [subHeader, setSubHeader] = useState("Paid partners in your network.");

  const onTabsChange = (key) => {
    if (key === "partners") {
      setSubHeader("Paid partners in your network.");
    } else if (key === "brands") {
      setSubHeader("Brand spend in your account");
    } else {
      setSubHeader("Campaign spend in your account");
    }
  };

  return (
    <Layout active={"paid-partners"}>
      <div className="paid-partners-container">
        <h2 className="mainHeading">Paid Partnerships</h2>
        <p className="subHeading">{subHeader}</p>

        <Tabs
          defaultActiveKey="partners"
          onChange={onTabsChange}
          className="tabs-container"
        >
          <TabPane tab="PARTNERS" key="partners">
            <Partners />
          </TabPane>
          <TabPane tab="BRANDS" key="brands">
            <Brands />
          </TabPane>
          <TabPane tab="CAMPAIGNS" key="campaigns">
            <Campaigns />
          </TabPane>
        </Tabs>
      </div>
    </Layout>
  );
};

export default PaidPartners;
