import { useState } from "react";
import { Col, Row, Select } from "antd";

// import { TbDeviceFloppy } from "react-icons/tb";

import StackedBarChart from "../../Charts/PlatformStackedBarChart";
import BasicStackbar from "../../Charts/PlatformBasicBarChart";
import SocialMedia from "./PlatformSocialMedia";

function PlatformBreakdown() {
  const { Option } = Select;
  const [chart, setChart] = useState("StackedBarChart");
  const [chartFilter, setChartFilter] = useState([
    "ActivatedInfluencers",
    "Posts",
  ]);

  const chartChangeHandler = (value) => {
    setChart(value);
  };
  const multipleChangeHandler = (value) => {
    setChartFilter(value);
  };
  return (
    <div className="plateformBreakdown_container">
      <div className="header">
        <h3>Platform Breakdown</h3>
        <div className="select">
          <Select
            mode="multiple"
            value={chartFilter}
            style={{ minWidth: "270px" }}
            onChange={multipleChangeHandler}
          >
            <Option value="ActivatedInfluencers">Activated Influencers</Option>
            <Option value="Posts">Posts</Option>
            <Option value="Engagements">Engagements</Option>
          </Select>
          <Select
            defaultValue="StackedBarChart"
            style={{ minWidth: "200px" }}
            onChange={chartChangeHandler}
          >
            <Option value="StackedBarChart">Stacked Bar Chart</Option>
            <Option value="BasicBarChart">Basic Bar Chart</Option>
          </Select>
        </div>
      </div>
      <div className="stackedBarChart_container">
        <Row>
          {chartFilter.map((data,ind) => (
            <Col
            key={ind}
              xl={
                chartFilter?.length === 1
                  ? 24
                  : chartFilter?.length === 2
                  ? 12
                  : 8
              }
              lg={
                chartFilter?.length === 1
                  ? 24
                  : chartFilter?.length === 2
                  ? 12
                  : 8
              }
              md={24}
              sm={24}
              xs={24}
            >
              <p>{data}</p>
              {chart === "StackedBarChart" ? (
                <StackedBarChart />
              ) : (
                <BasicStackbar />
              )}
            </Col>
          ))}
        </Row>
      </div>
      <SocialMedia />
    </div>
  );
}

export default PlatformBreakdown;
