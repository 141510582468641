import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { Menu } from 'antd'

// React Icons
// import { TbLayoutDashboard } from "react-icons/tb";
import { BiUser } from 'react-icons/bi'
import { ImStack } from 'react-icons/im'
import { HiOutlineDocumentReport } from 'react-icons/hi'
// import { AiOutlineFileText } from "react-icons/ai";
// import { MdOutlineAnalytics } from "react-icons/md";
import { BsViewStacked } from 'react-icons/bs'

import { attachToken, privateAPI } from '../config/constants'

const MainMenu = ({ active }) => {
  const { SubMenu } = Menu
  const navigate = useNavigate()
  const [getCampaigns, setgetCampaigns] = useState(null)
  const selectedBrand = useSelector((state) => state?.brand?.selectedBrand)

  const getAllCampaigns = async () => {
    if (selectedBrand) {
      try {
        attachToken()
        const res = await privateAPI.get(`/campaign/get-campaigns/${selectedBrand}`)
        if (res?.data) {
          setgetCampaigns(res?.data?.slice(0, 3))
        }
      } catch (error) {
        console.log('err', error)
      }
    }
  }

  useEffect(() => {
    getAllCampaigns()
  }, [selectedBrand])

  return (
    <Menu
      theme='dark'
      mode={'inline'}
      defaultSelectedKeys={[active]}
      style={{
        background: 'var(--sidebar)',
        // minHeight: "83vh",
      }}
    >
      <SubMenu title='Network' key='Network' icon={<BiUser className='menu-icon' />}>
        <Menu.Item
          key='Network'
          className={'sidebar-menu'}
          onClick={() => {
            navigate('/')
            // visible && setVisible(false);
          }}
        >
          <a href='/' onClick={(e) => e.preventDefault()}>
            Network
          </a>
        </Menu.Item>
        <Menu.Item key='Groups' className={'sidebar-menu'} onClick={() => navigate('/groups')}>
          <a href='/groups' onClick={(e) => e.preventDefault()}>
            Groups
          </a>
        </Menu.Item>
        <Menu.Item key='brands' className={'sidebar-menu'} onClick={() => navigate('/brands')}>
          <a href='/brands' onClick={(e) => e.preventDefault()}>
            Brands
          </a>
        </Menu.Item>
        <Menu.Item
          key='bulkUpload'
          className={'sidebar-menu'}
          onClick={() => navigate('/bulk-upload-influencers')}
        >
          <a href='/bulk-upload-influencers' onClick={(e) => e.preventDefault()}>
            Bulk Upload
          </a>
        </Menu.Item>
        <Menu.Item
          key='influencerCategory'
          className={'sidebar-menu'}
          onClick={() => navigate('/influencer-categories')}
        >
          <a href='/influencer-categories' onClick={(e) => e.preventDefault()}>
          Categories/Affinities
          </a>
        </Menu.Item>
        {/* <Menu.Item
          key="paid-partners"
          className={"sidebar-menu"}
          onClick={() => navigate("/paid-partners")}
        >
          Paid Partnerships
        </Menu.Item>
        <Menu.Item
          key="studio-applicants"
          className={"sidebar-menu"}
          onClick={() => navigate("/studio-applicants")}
        >
          Studio Applicants
        </Menu.Item> */}
      </SubMenu>

      <Menu.Item
        key='brand-buzz'
        className={'sidebar-menu'}
        icon={<ImStack className='menu-icon' />}
        onClick={() => navigate('/brand-buzz')}
      >
        <a href='/brand-buzz' onClick={(e) => e.preventDefault()}>
          Brand Buzz
        </a>
      </Menu.Item>
      {/* <SubMenu
        title="Tasks"
        key="tasks"
        icon={<AiOutlineFileText className="menu-icon" />}
      >
        <Menu.Item
          key="tasks"
          className={"sidebar-menu"}
          onClick={() => navigate("/tasks")}
        >
          Tasks
        </Menu.Item>
        <Menu.Item
          key="notes"
          className={"sidebar-menu"}
          onClick={() => navigate("/notes")}
        >
          Notes
        </Menu.Item>
      </SubMenu> */}

      {/* <SubMenu
        title="Reports"
        key="reports"
        icon={<MdOutlineAnalytics className="menu-icon" />}
      >
        <Menu.Item
          key="reports"
          className={"sidebar-menu"}
          onClick={() => navigate("/reports")}
        >
          Program Analytics
        </Menu.Item>
      </SubMenu> */}

      <SubMenu title='Campaigns' key='campaigns' icon={<BsViewStacked className='menu-icon' />}>
        {getCampaigns?.map((camp, ind) => (
          <Menu.Item
            key={ind}
            className={'sidebar-menu'}
            onClick={() => navigate(`/campaign/${camp?._id}?tab=profiles`)}
          >
            <a href={`/campaign/${camp?._id}?tab=profiles`} onClick={(e) => e.preventDefault()}>
              {camp?.name}
            </a>
          </Menu.Item>
        ))}

        <Menu.Item
          key='campaigns'
          className={'sidebar-menu'}
          onClick={() => navigate('/campaigns')}
        >
          <a href='/campaigns' onClick={(e) => e.preventDefault()}>
            See All
          </a>
        </Menu.Item>
      </SubMenu>

      <Menu.Item
        key='reports'
        className={'sidebar-menu'}
        icon={<HiOutlineDocumentReport className='menu-icon' />}
        onClick={() => navigate('/all-reports')}
      >
        <a href='/all-reports' onClick={(e) => e.preventDefault()}>
          Reports
        </a>
      </Menu.Item>

      {/* <Menu.Item
        key='bulkUpload'
        className={'sidebar-menu'}
        icon={<HiOutlineDocumentReport className='menu-icon' />}
        onClick={() => navigate('/bulk-upload-influencers')}
      >
        <a href='/bulk-upload-influencers' onClick={(e) => e.preventDefault()}>
          Bulk Upload
        </a>
      </Menu.Item> */}

      {/* <Menu.Item
        key="manual-campaigns"
        className={"sidebar-menu"}
        icon={<BsViewStacked className="menu-icon" />}
        onClick={() => navigate("/manual-campaigns")}
      >
        Manual Campaigns
      </Menu.Item> */}

      {/* <Menu.Item
        key="moderators"
        className={"sidebar-menu"}
        icon={<MdOutlineAddModerator className="menu-icon" />}
        onClick={() => navigate("/moderators")}
      >
        Moderators
      </Menu.Item> */}
      {/* <SubMenu
        title="Search"
        key="search"
        icon={<BsSearch className="menu-icon" />}
      >
        <Menu.Item
          key="search"
          className={"sidebar-menu"}
          onClick={() => navigate("/search")}
        >
          Discovery
        </Menu.Item>
      </SubMenu> */}
    </Menu>
  )
}

export default MainMenu
