import { useState, useEffect } from 'react'
import { Select, Input, Pagination } from 'antd'

import { FiSearch } from 'react-icons/fi'
// import { BsThreeDots } from 'react-icons/bs'

import Layout from '../../layout/Layout'
import NetworkRowCard from '../../components/Cards/NetworkRowCard'
import InfinityLoader from '../../components/InfinityLoader'
// import AddInfluencer from '../../components/modal/AddInfluencer'
// import UploadinfluencerModal from '../../components/modal/UploadInfluencerModal'
import { getAllInfluencerCateories, getAllInfluencers } from '../../redux/actions/networkAction'
import { useDispatch, useSelector } from 'react-redux'

const Network = () => {
  const { Option } = Select
  const dispatch = useDispatch()
  const [influencers, setInfluencers] = useState(null)
  const [influencerOptions, setInfluencerOptions] = useState(null)
  const [steps, setSteps] = useState('all')
  const [perPage, setperPage] = useState(10)
  // const [pageSize, setPageSize] = useState(1)
  const [pageSize, setPageSize] = useState(1)
  const [orderBy, setOrderBy] = useState('audience')
  const [search, setSearch] = useState('')
  const [categoryFilter, setCategoryFilter] = useState('all')
  const influencerCategories = useSelector((state) => state?.network?.categories)

  const getInfluencers = async () => {
    let localPage = localStorage.getItem('networkPage')
    setInfluencers(null)
    let payload = {
      perPage: perPage,
      pageSize: search?.length > 0 ? 0 : localPage ? localPage - 1 : pageSize - 1,
      // pageSize: search?.length > 0 ? 0 : pageSize - 1,
      steps: steps,
      orderBy: orderBy,
      searchString: search,
      category: categoryFilter,
    }
    let res = await dispatch(getAllInfluencers(payload))
    setInfluencerOptions(res)
    setInfluencers(res)
  }

  useEffect(() => {
    let r = localStorage.getItem('networkPage')
    setPageSize(Number(r))
  }, [localStorage.getItem('networkPage')])
  

  useEffect(() => {
    getInfluencers()
  }, [steps, perPage, pageSize, orderBy, categoryFilter])

  useEffect(() => {
    dispatch(getAllInfluencerCateories())
    if(!localStorage.getItem('networkPage')){
      localStorage.setItem('networkPage', 1)
    }
  }, [])

  const onOrderByChange = (value) => {
    setOrderBy(value)
  }

  const onCategoryChange = (value) => {
    setCategoryFilter(value)
  }

  const perPageChange = (value) => {
    setperPage(value)
  }

  const onPagination = (current) => {
    window.scrollTo(0, 0)
    setPageSize(current)
    localStorage.setItem('networkPage', current)
  }

  const onStepChange = (val) => {
    setSteps(val)
    setPageSize(1)
    localStorage.setItem('networkPage', 1)
  }

  // const menu = (
  //   <Menu>
  //     <Menu.Item key={1}>
  //       <AddInfluencer text={'Upload Influencer'} />
  //     </Menu.Item>
  //     {/* <Menu.Item key={2}>
  //     <UploadinfluencerModal tab={'bulk'}/>
  //     </Menu.Item> */}
  //   </Menu>
  // )

  return (
    <Layout active={'Network'}>
      {!influencerOptions ? (
        <InfinityLoader height={'80vh'} />
      ) : (
        <div className='network-container'>
          <h2 className='mainHeading'>Network</h2>
          <p className='subHeading'>{influencerOptions?.all} people in your network</p>

          {/* Filter Bar */}
          <div className='filter-bar'>
            <div className='left-side'>
              <Select
                defaultValue='audience'
                onChange={onOrderByChange}
                size='large'
                className='select'
                style={{ minWidth: '120px' }}
              >
                <Option value='name'>Name</Option>
                <Option value='audience'>Audience</Option>
              </Select>
              <Select
                onChange={onCategoryChange}
                size='large'
                className='select'
                style={{ minWidth: '120px' }}
                placeholder='Select Category'
              >
                <Option key={'all'} value='all'>
                  All
                </Option>

                {influencerCategories?.map((cat) => (
                  <Option key={cat?._id} value={cat?._id}>
                    {cat?.name}
                  </Option>
                ))}
              </Select>
              <Select
                onChange={perPageChange}
                className='select'
                size='large'
                value={perPage}
                style={{ minWidth: '120px' }}
              >
                <Option value={10}>show 10</Option>
                <Option value={20}>show 20</Option>
                <Option value={35}>show 35</Option>
                <Option value={50}>show 50</Option>
              </Select>
              <Input
                placeholder='Search by name'
                prefix={<FiSearch className='search-icon' />}
                className='searchInput'
                size='small'
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    getInfluencers()
                  }
                }}
              />
            </div>
            <div style={{ display: 'flex', alignItems: 'center', columnGap: '1rem' }}>
              {/* <Dropdown overlay={menu} trigger={['click']} placement={'bottomRight'}>
                <BsThreeDots className='more-icon' />
              </Dropdown> */}
              {/* <AddInfluencer text={'Add Influencer'} btn={true} /> */}
            </div>
          </div>

          {/* Steps */}
          <div className='steps'>
            <div
              className={`${steps === 'all' && 'active'} filter-card__item`}
              onClick={() => onStepChange('all')}
            >
              <span className='filter-card__item-num'>{influencerOptions?.all}</span>
              <span className='filter-card__item-label'>ALL PEOPLE</span>
            </div>
            <div
              className={`${steps === 'nano' && 'active'} filter-card__item`}
              onClick={() => onStepChange('nano')}
            >
              <span className='filter-card__item-num'>{influencerOptions?.nano}</span>
              <span className='filter-card__item-label'>Nano (&amp;lt; 10k)</span>
            </div>
            <div
              className={`${steps === 'micro' && 'active'} filter-card__item`}
              onClick={() => onStepChange('micro')}
            >
              <span className='filter-card__item-num'>{influencerOptions?.micro}</span>
              <span className='filter-card__item-label'>MICRO (10k - 50K)</span>
            </div>
            <div
              className={`${steps === 'mid' && 'active'} filter-card__item`}
              onClick={() => onStepChange('mid')}
            >
              <span className='filter-card__item-num'>{influencerOptions?.mid}</span>
              <span className='filter-card__item-label'>MID (50k - 250K)</span>
            </div>
            <div
              className={`${steps === 'macro' && 'active'} filter-card__item`}
              onClick={() => onStepChange('macro')}
            >
              <span className='filter-card__item-num'>{influencerOptions?.macro}</span>
              <span className='filter-card__item-label'>MACRO (250k - 1M)</span>
            </div>
            <div
              className={`${steps === 'top' && 'active'} filter-card__item`}
              onClick={() => onStepChange('top')}
            >
              <span className='filter-card__item-num'>{influencerOptions?.top}</span>
              <span className='filter-card__item-label'>TOP (1M - 5M)</span>
            </div>
            <div
              className={`${steps === 'vip' && 'active'} filter-card__item11`}
              onClick={() => onStepChange('vip')}
            >
              <span className='filter-card__item-num'>{influencerOptions?.vip}</span>
              <span className='filter-card__item-label'>VIP (&gt; 5m)</span>
            </div>
          </div>

          <br />
          {influencers ? (
            <>
              <div>
                {influencers?.data?.map((inf, ind) => (
                  <NetworkRowCard data={inf} key={ind} infsViews={influencers?.infsAvgViews}/>
                ))}
              </div>
              <div className='pagination-div'>
                <Pagination
                  total={influencers.total}
                  onChange={onPagination}
                  current={pageSize}
                  hideOnSinglePage={true}
                  showSizeChanger={false}
                />
              </div>
            </>
          ) : (
            <InfinityLoader height={'80vh'} />
          )}
        </div>
      )}
    </Layout>
  )
}

export default Network
