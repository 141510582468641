import { Tabs } from "antd";

import Layout from "../layout/Layout";
import BrandSummary from "../components/Views/Reports/BrandSummary";
import ReportPerformance from "../components/Views/Reports/ReportPerformance";
import HeatMap from "../components/Views/Reports/HeatMap";
import PostType from "../components/Views/Reports/PostType";
import Leaderboard from "../components/Views/Reports/Leaderboard";
import PlatformBreakdown from "../components/Views/Reports/PlatformBreakdown";

const Report = () => {
  const { TabPane } = Tabs;

  const onTabsChange = () => {
    
  };

  return (
    <Layout active={"report"}>
      <div className="campaign-container">
        <div className="header">
          <div className="left">
            <h2>Brand Sov</h2>
            <p className="subHeading">
              12 Influencers | Last Refreshed AUg 26th
            </p>
          </div>
          {/* <div className="right">
            <AiOutlineSetting className="icon" />
            <AiOutlineSave className="icon" />
          </div> */}
        </div>
        <Tabs
          defaultActiveKey="profiles"
          onChange={onTabsChange}
          className="tabs-container"
        >
          <TabPane tab="SUMMARY" key="summary">
            <BrandSummary />
          </TabPane>
          <TabPane tab="LEADERBOARD" key="leaderboard">
            <Leaderboard />
          </TabPane>
          <TabPane tab="PERFORMANCE" key="performance">
            <ReportPerformance />
          </TabPane>
          {/* <TabPane tab="TIMELINE" key="timeline">
            <Timeline />
          </TabPane> */}
          <TabPane tab="PLATFORMS" key="platforms">
            <PlatformBreakdown />
          </TabPane>
          <TabPane tab="POST TYPES" key="posttypes">
            <PostType />
          </TabPane>
          <TabPane tab="HEATMAP" key="heatmap">
            <HeatMap />
          </TabPane>
        </Tabs>
      </div>
    </Layout>
  );
};

export default Report;
