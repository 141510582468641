import { useState, useEffect, useMemo } from 'react'
import { Col, Tabs, Row, Tooltip, Dropdown } from 'antd'
import { useNavigate, useParams, useLocation } from 'react-router-dom'

import { FaTiktok, FaInstagram, FaYoutube, FaRegFolder, FaRegEye } from 'react-icons/fa'
import { BsThreeDots, BsPlay } from 'react-icons/bs'
// import { TiArrowRepeat } from 'react-icons/ti'
import { LoadingOutlined } from '@ant-design/icons'
import { RiUserStarLine } from "react-icons/ri";
// import { MdOutlineLocationOn } from "react-icons/md";
import { AiOutlineCheckCircle, AiOutlineExclamationCircle } from 'react-icons/ai'

import Layout from '../../layout/Layout'
import { attachToken, privateAPI } from '../../config/constants'
import InfluencerInfo from './components/InfluencerInfo'
import InfluencerContent from './components/InfluencerContent'
import InfluencerCollaboration from './components/InfluencerCollaboration'
import FootPrint from './components/FootPrint'
import InfinityLoader from '../../components/InfinityLoader'
import { AudienceCalculator } from '../../functions/AudienceCalculation'
import { NumberFormatter } from '../../functions/NumberFormatter'
import { getPotentialReach, calculateTotalLikes } from '../../functions/CalculateTotalLikes'
import { menu } from './utils/influencerUtil'
// import OfflineProfileImage from '../../assets/offlineProfile.png'
// import { ExpireDateCheck } from '../../functions/ExpireDateCheck'
import InfluencerAudience from './components/InfluencerAudience'

const useQuery = (search) => {
  return useMemo(() => new URLSearchParams(search), [search])
}

const Influencer = () => {
  const navigate = useNavigate()
  const { TabPane } = Tabs
  const { id } = useParams()
  const { search } = useLocation()
  const [influencer, setInfluencer] = useState(null)
  const [activeTab, setActiveTab] = useState('content')
  const [pptDataLoading, setPptDataLoading] = useState(false)
  const query = useQuery(search)

  const onTabsChange = (key) => {
    const params = new URLSearchParams()
    if (key) {
      params.append('tab', key)
    } else {
      params.delete('tab')
    }
    navigate({ search: params.toString() })
  }

  const getInfluencer = async () => {
    attachToken()
    const res = await privateAPI.get(`/influencer/get/${id}`)
    setInfluencer(res?.data)
  }

  const getPPTData = async () => {
    const res = await privateAPI.post(`/influencer/get-ppt-data/${id}`)
    return res?.data
    // setPPTData(res?.data)
    // if ('caches' in window) {
    //   // Opening given cache and putting our data into it
    //   caches.open('url').then((cache) => {
    //     cache.put('http://localhost:3000', 'https://fireupstar-zhvu4963822m.s3.ap-southeast-1.amazonaws.com/16910253627218868.790518526459.com%26edm%3DAL-3X8kEAAAA%26oh%3D00_AfBcBaa_4uZt7xcygbA7c9VSsC9r_3Fizv778Mb27kunyg%26oe%3D64D05094.jpeg');
    //     alert('Data Added into cache!')
    //   });
    // }
  }

  useEffect(() => {
    if (query.get('tab')) {
      setActiveTab(query.get('tab'))
    }
  }, [activeTab])

  useEffect(() => {
    getInfluencer()
  }, [])
  const potentialReach = getPotentialReach(influencer?.influencer);
  const totalLikes = calculateTotalLikes(potentialReach);
  return (
    <Layout active={''}>
      {!influencer?.influencer ? (
        <InfinityLoader />
      ) : (
        <div className='influencer-container'>
          <Row className='network-row-card'>
            <Col xs={24} md={24} lg={6} xl={5} xxl={5} className='left-side'>
              <div className='image-div'>
                {/* <div className="options">
                <RiUserSettingsLine className="icon" />
                <BsThreeDots className="icon" />
                <BsArrowsAngleExpand className="icon" />
              </div> */}
                <img
                  className='profile'
                  src={
                    influencer?.influencer?.arch_profile_picture_url ||
                    influencer?.influencer?.profile_picture_url
                  }
                  // src={
                  //   ExpireDateCheck(influencer?.influencer?.dataRefreshed) === 10
                  //     ? influencer?.influencer?.arch_profile_picture_url
                  //       ? influencer?.influencer?.arch_profile_picture_url
                  //       : OfflineProfileImage
                  //     : ExpireDateCheck(influencer?.influencer?.dataRefreshed) === 2
                  //     ? influencer?.influencer?.profile_picture_url
                  //     : OfflineProfileImage
                  // }
                  alt='profile'
                />
                <div className='aligned-div'>
                  {influencer?.valuesMRS === 'Aligned' ? (
                    <Tooltip placement='right' title={`Aligned`} className='tooltip'>
                      <AiOutlineCheckCircle className='icon aligned' />
                    </Tooltip>
                  ) : influencer?.valuesMRS === 'Not Aligned' ? (
                    <Tooltip placement='right' title={`Not Aligned`} className='tooltip'>
                      <AiOutlineExclamationCircle className='icon notaligned' />
                    </Tooltip>
                  ) : null}
                </div>
              </div>
            </Col>
            <Col xs={24} md={24} lg={12} xl={13} xxl={15} className='middle'>
              <div className='info'>
                <div className='social'>
                  <FaTiktok className='icon disable' />
                  <FaYoutube className='icon disable' />
                  <Tooltip
                    placement='right'
                    title={`https://www.instagram.com/${influencer?.influencer?.instagram?.username}`}
                  >
                    <FaInstagram
                      className='icon'
                      onClick={() => {
                        window.open(
                          `https://www.instagram.com/${influencer?.influencer?.instagram?.username}`,
                          '_blank',
                        )
                      }}
                    />
                  </Tooltip>
                </div>
                <h2 className='name'>{influencer?.influencer?.name}</h2>
                <p className='desc'>{influencer?.influencer?.biography}</p>
                {/* <div className="address">
                  <MdOutlineLocationOn className="icon" />
                  <span>Karachi, Pakistan.</span>
                </div> */}
                <div className='categories'>
                  {influencer?.influencer?.category?.map((cat, ind) => (
                    <span key={ind}>{cat?.name}</span>
                  ))}
                </div>

                {influencer?.influencer?.campaigns?.length > 0 &&
                  influencer?.influencer?.campaigns?.slice(0, 2)?.map((camp, ind) => (
                    <div
                      key={ind}
                      className='campaign'
                      onClick={() => navigate(`/campaign/${camp?._id}`)}
                    >
                      <FaRegFolder className='icon' />
                      <span> {camp?.name} </span>
                    </div>
                  ))}
              </div>
              <h2 className='reach'>{AudienceCalculator(influencer?.influencer?.audience)}</h2>
            </Col>

            <Col
              xs={24}
              md={24}
              lg={6}
              xl={6}
              xxl={4}
              className='right-side'
              style={{ justifyContent: 'flex-start' }}
            >
              <div className='header'>
                {/* <RiUserSettingsLine className="icon" /> */}
                <Dropdown
                  overlay={menu(influencer?.influencer, getPPTData, setPptDataLoading)}
                  trigger={['click']}
                  placement={'bottomRight'}
                  disabled={pptDataLoading}
                >
                  {pptDataLoading == true ? (
                    <div className='icon'>
                      <LoadingOutlined
                        style={{
                          fontSize: 18,
                        }}
                        spin
                      />
                    </div>
                  ) : (
                    <BsThreeDots className='icon' />
                  )}
                </Dropdown>
              </div>
              <div className='list'>
                <FaRegEye className='icon' />
                <span>Audience</span>
                <span className='count'>
                  {NumberFormatter(influencer?.influencer?.audience, 2)}
                </span>
              </div>
              {/* {Number(influencer?.influencer?.reach) !== 0 && (
                <div className='list'>
                  <FaWifi className='icon' />
                  <span>Reach</span>
                  <span className='count'>{influencer?.influencer?.reach}</span>
                </div>
              )} */}
              {Number(influencer?.influencer?.reach) !== 0 && (
                <div className='list'>
                  <RiUserStarLine className='icon' />
                  <span>Potential Reach</span>
                  <span className='count'>
                  {NumberFormatter((totalLikes/6), 1)}
                  </span>
                  {/* <span className='count'>{influencer?.mCampaign?.avgReach}</span> */}
                </div>
              )}
              {/* {Number(influencer?.influencer?.resonance) !== 0 && (
                <div className='list'>
                  <TiArrowRepeat className='icon' />
                  <span>Resonance</span>
                  <span className='count'>{influencer?.influencer?.resonance}</span>
                </div>
              )} */}
              {Number(influencer?.infAvgViews) !== 0 && (
                <div className='list'>
                  <BsPlay className='icon' />
                  <span>Avg Views</span>
                  <span className='count'>{NumberFormatter(influencer?.infAvgViews, 1)}</span>
                </div>
              )}
            </Col>
          </Row>

          <Tabs
            defaultActiveKey={activeTab}
            onChange={onTabsChange}
            className='tabs-container'
            size='large'
          >
            <TabPane tab='INFO' key='info'>
              <InfluencerInfo id={id} getInfluencer={getInfluencer} data={influencer} />
            </TabPane>
            <TabPane tab='CONTENT' key='content'>
              <InfluencerContent id={id} />
            </TabPane>
            <TabPane tab='FOOTPRINT' key='footprint'>
              <FootPrint id={id} mCampaign={influencer?.mCampaign} />
            </TabPane>
            <TabPane tab='AUDIENCE' key='audience'>
              <InfluencerAudience id={id} activeTab={activeTab} />
            </TabPane>
            <TabPane tab='COLLABORATION' key='collaboration'>
              <InfluencerCollaboration id={id} />
            </TabPane>
            {/* <TabPane tab="CONNECTIONS" key="connections"></TabPane> */}
          </Tabs>
        </div>
      )}
    </Layout>
  )
}

export default Influencer
