import { useState, useEffect } from 'react'
import { Select } from 'antd'

import AssociationFilterChart from '../../Charts/AssociationFilterChart'
import { attachToken, privateAPI } from '../../../config/constants'
import moment from 'moment'

function Timeline({ id, reportDate, activeTab }) {
  const { Option } = Select
  const [data, setData] = useState([])
  const [graphFilter, setGraphFilter] = useState('Posts')
  const [loader, setLoader] = useState(true)

  const getReport = async () => {
    if (id) {
      let payload = {
        filter: graphFilter,
        dateRange: [moment(reportDate?.startOf), moment(reportDate?.endOf)],
      }
      try {
        attachToken()
        const res = await privateAPI.post(`/campaign/get-campaign-timeline-report/${id}`, payload)
        if (res?.data) {
          setData(res?.data?.data)
          setLoader(false)
        }
      } catch (error) {
        console.log('err', error)
      }
    }
  }

  useEffect(() => {
    setLoader(true)
    getReport()
  }, [id, graphFilter,activeTab])

  return (
    <div className='timelineChart_container'>
      <div className='header'>
        <p>Performance Over Time</p>
        <div>
          <Select
            style={{ minWidth: '150px' }}
            value={graphFilter}
            onChange={(e) => setGraphFilter(e)}
          >
            <Option value='Posts'>Posts</Option>
            <Option value='Influencers'>Influencers</Option>
            <Option value='Engagements'>Engagements</Option>
            <Option value='Reach'>Reach</Option>
            <Option value='Impressions'>Impressions</Option>
            <Option value='EngRate'>Eng. Rate</Option>
          </Select>
          {/* <Select defaultValue={'monthly'} onChange={(e) => onDateChange(e)}>
            <Option value='daily'>Daily</Option>
            <Option value='monthly'>Monthly</Option>
            <Option value='weekly'>Weekly</Option>
          </Select> */}
        </div>
      </div>
      <div>
        <AssociationFilterChart
          data={data}
          loader={loader}
        />
      </div>
    </div>
  )
}

export default Timeline
