import {
  Col,
  Collapse,
  Drawer,
  Form,
  Row,
  Tooltip,
  Input,
  Button,
  Select,
  notification,
  Menu,
} from "antd";
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { BsArrowsAngleExpand } from "react-icons/bs";
import {
  FaFacebook,
  FaInstagram,
  FaRegEye,
  FaTiktok,
  FaWifi,
  FaYoutube,
} from "react-icons/fa";
import {
  AiOutlineCopyright,
  AiOutlineDollarCircle,
  AiOutlineEdit,
} from "react-icons/ai";
import { TiArrowRepeat } from "react-icons/ti";
import { RiDeleteBin2Line } from "react-icons/ri";
import { MdKeyboardArrowDown, MdOutlineAssignment } from "react-icons/md";

import DataTable from "../Table/DataTable";
import { NumberFormatter } from "../../functions/NumberFormatter";
import { attachToken, privateAPI } from "../../config/constants";
// import OfflineProfileImage from "../../assets/offlineProfile.png";
// import { ExpireDateCheck } from "../../functions/ExpireDateCheck";

const CampaignInfluncerAssignDrawer = ({
  data,
  // campaignId,
  getAllCampaigns,
}) => {
  const navigate = useNavigate();
  const { Panel } = Collapse;
  const [form] = Form.useForm();
  const { Option } = Select;
  const [activePanel, setActivePanel] = useState(1)
  const [visible, setVisible] = useState(false);
  const [editVisible, setEditVisible] = useState(false);
  const [editDel, setEditDel] = useState(null);
  const [loading, setLoading] = useState(false);
  const [otherFeeArr, setOtherFeeArr] = useState([]);
  const [feeData, setFeeData] = useState(null);

  const randomCode = () => {
    return Math.random().toString(36).substr(2, 8).toUpperCase();
  };

  useEffect(() => {
    setOtherFeeArr(data?.otherFee);
  }, []);

  const showDrawer = () => {
    setVisible(true);
  };

  const onClose = () => {
    setVisible(false);
  };

  const onChange = (key) => {
    console.log(key);
    setActivePanel(key)
  };

  // console.log("data", data);

  const genExtra = () => <MdKeyboardArrowDown className="extra-icon" />;

  const onFinish = async (values) => {
    console.log('inside onfinish')
    setLoading(true);
    let payload = {
      ...values,
      contentFee:  typeof(values?.contentFee) == 'string' ? Number(values?.contentFee?.replaceAll(',','')) : values?.contentFee,
      deliverable: editDel,
      id: data._id,
    };
    try {
      attachToken();
      const res = await privateAPI.post(
        `/campaign/update-influencer-deliverable`,
        payload
      );
      if (res?.data) {
        notification.success({
          message: res?.data?.message,
          duration: 3,
        });
        // setVisible(false);
        setEditVisible(false);
        getAllCampaigns();
        setLoading(false);
      }
    } catch (err) {
      notification.error({
        description: err?.response?.data?.message,
        duration: 4,
      });
      // setLoading(false);
    }
  };

  const onOtherFeeFinish = (values) => {
    let payload = {
      ...values,
      id: randomCode(),
    };
    setOtherFeeArr([...otherFeeArr, payload]);
    form.resetFields();
  };

  const onEdit = (del) => {
    console.log('on edit clicked')
    setEditVisible(true);
    setEditDel(del);
  };

  useEffect(() => {
    let tempArr = [];
    otherFeeArr?.map((i) => {
      tempArr.push({
        type: i?.type,
        amount: i?.amount,
        remove: (
          <RiDeleteBin2Line
            className="remove-icon"
            onClick={() => {
              let indexFind = otherFeeArr.findIndex((e) => e.id === i.id);
              otherFeeArr.splice(indexFind, 1);
              setOtherFeeArr([...otherFeeArr]);
            }}
          />
        ),
      });
    });
    setFeeData(tempArr);
  }, [otherFeeArr]);

  const columns = [
    {
      title: "Type",
      dataIndex: "type",
    },
    {
      title: "Amount",
      dataIndex: "amount",
    },
    {
      title: "Remove",
      dataIndex: "remove",
    },
  ];

  const saveOtherFees = async () => {
    setLoading(true);
    let payload = {
      id: data?._id,
      otherFee: otherFeeArr,
    };
    try {
      attachToken();
      const res = await privateAPI.post(
        `/campaign/update-influencer-other-fee`,
        payload
      );
      if (res?.data) {
        notification.success({
          message: res?.data?.message,
          duration: 3,
        });
        // setVisible(false);
        getAllCampaigns();
        setLoading(false);
      }
    } catch (err) {
      notification.error({
        description: err?.response?.data?.message,
        duration: 4,
      });
      // setLoading(false);
    }
  };

  return (
    <>
      {/* <span onClick={() => showDrawer()}>Assign</span> */}
      <Menu.Item icon={<MdOutlineAssignment className='dropdown-menu-icon' />} onClick={() => showDrawer()}>
        Assign
      </Menu.Item>
      <Drawer
        placement={"right"}
        closable={false}
        onClose={onClose}
        visible={visible}
        className="influencerModal"
      >
        <div className="campaign-influencer-modal">
          <div className="header">
            <h3>{data?.influencer?.name}</h3>
            <div className="info">
              <BsArrowsAngleExpand
                className="icon"
                onClick={() =>
                  navigate(`/influencer/${data?.influencer?._id}`)
                }
              />
            </div>
          </div>

          <Row className="card">
            <Col xs={5} className="left-side">
              <img
              className='profile'
              src={data?.influencer?.arch_profile_picture_url || data?.influencer?.profile_picture_url}
              // src={ExpireDateCheck(data?.influencer?.dataRefreshed) === 10 ? data?.influencer?.arch_profile_picture_url ? data?.influencer?.arch_profile_picture_url : OfflineProfileImage : ExpireDateCheck(data?.influencer?.dataRefreshed) === 2 ? data?.influencer?.profile_picture_url : OfflineProfileImage}
            />
            </Col>
            <Col xs={12} className="middle">
              <div className="info">
                <div className="social">
                  <FaTiktok className="icon disable" />
                  <FaYoutube className="icon disable" />
                  <Tooltip
                    placement="right"
                    title={`https://www.instagram.com/${data?.influencer?.instagram?.username}`}
                  >
                    <FaInstagram className="icon insta" />
                  </Tooltip>
                </div>
                <h2 className="name">{data?.influencer?.name}</h2>
                <p className="desc">{data?.influencer?.biography}</p>
              </div>
            </Col>

            <Col xs={7} className="right-side">
              <div className="list">
                <FaRegEye className="icon" />
                <span>Audience</span>
                <span className="count">
                  {NumberFormatter(data?.influencer?.audience, 1)}
                </span>
              </div>
              <div className="list">
                <FaWifi className="icon" />
                <span>Reach</span>
                <span className="count">
                  {NumberFormatter(data?.influencer?.reach, 1)}
                </span>
              </div>
              <div className="list">
                <TiArrowRepeat className="icon" />
                <span>Resonance</span>
                <span className="count">
                  {NumberFormatter(data?.influencer?.resonance, 1)}
                </span>
              </div>
            </Col>
          </Row>

          <div className="collapse-container">
            <Collapse
              // defaultActiveKey={["1","3"]}
              activeKey={activePanel}
              onChange={onChange}
              accordion
              // expandIconPosition={expandIconPosition}
            >
              <Panel
                header="Deliverables"
                showArrow={false}
                key="1"
                extra={genExtra()}
              >
                <div className="diliverables-container">
                  {editVisible && (
                    <div className="edit-container">
                      <Form
                        layout="vertical"
                        form={form}
                        onFinish={onFinish}
                        fields={
                          editDel
                            ? [
                                {
                                  name: "platform",
                                  value: editDel?.platform,
                                },
                                {
                                  name: "postType",
                                  value: editDel?.postType,
                                },
                                {
                                  name: "howMany",
                                  value: editDel?.howMany,
                                },
                                {
                                  name: "rights",
                                  value: editDel?.rights,
                                },
                                {
                                  name: "contentFee",
                                  value: editDel?.contentFee,
                                },
                              ]
                            : null
                        }
                      >
                        <Row gutter={[20, 0]}>
                          <Col span={10}>
                            <Form.Item
                              label="Channel *"
                              name={"platform"}
                              rules={[
                                {
                                  required: true,
                                  message: "Channel is Required",
                                },
                              ]}
                              requiredMark={"optional"}
                            >
                              <Select placeholder="Select Platform">
                                <Option value={"Instagram"}>Instagram</Option>
                                <Option value={"Facebook"}>Facebook</Option>
                                <Option value={"Youtube"}>Youtube</Option>
                                <Option value={"Tiktok"}>Tiktok</Option>
                              </Select>
                            </Form.Item>
                          </Col>
                          <Col span={8}>
                            <Form.Item
                              label="Media *"
                              name={"postType"}
                              rules={[
                                {
                                  required: true,
                                  message: "Post Type is Required",
                                },
                              ]}
                              requiredMark={"optional"}
                            >
                              <Select placeholder="Select Post Type">
                                <Option value={"Video"}>Video</Option>
                                <Option value={"Post"}>Post</Option>
                                <Option value={"Message"}>Message</Option>
                                <Option value={"Text"}>Text</Option>
                                <Option value={"Story"}>Story</Option>
                              </Select>
                            </Form.Item>
                          </Col>
                          <Col span={6}>
                            <Form.Item
                              label="Rights *"
                              name={"rights"}
                              rules={[
                                {
                                  required: true,
                                  message: "Rights is Required",
                                },
                              ]}
                              requiredMark={"optional"}
                            >
                              <Select placeholder="Select Rights">
                                <Option value={"Yes"}>Yes</Option>
                                <Option value={"No"}>No</Option>
                              </Select>
                            </Form.Item>
                          </Col>
                          <Col span={12}>
                            <Form.Item
                              label="Content Fee *"
                              name={"contentFee"}
                              rules={[
                                {
                                  required: true,
                                  message: "Content Fee is Required",
                                },
                              ]}
                              requiredMark={"optional"}
                            >
                              <Input placeholder="PKR" />
                            </Form.Item>
                          </Col>
                          <Col span={12}>
                            <Form.Item
                              label="Numbers *"
                              name={"howMany"}
                              rules={[
                                {
                                  required: true,
                                  message: "How Many is Required",
                                },
                              ]}
                              requiredMark={"optional"}
                            >
                              <Input placeholder="No. of Media" />
                            </Form.Item>
                          </Col>
                          <Col span={5}>
                            <Form.Item>
                              <Button
                                htmlType="submit"
                                type="primary"
                                loading={loading}
                                // onClick={()=>console.log('update clicked')}
                              >
                                Update
                              </Button>
                            </Form.Item>
                          </Col>
                          <Col span={5}>
                            <Button
                              type="default"
                              size="large"
                              onClick={() => {
                                setEditVisible(false);
                                setEditDel(null);
                              }}
                            >
                              Cancel
                            </Button>
                          </Col>
                        </Row>
                      </Form>
                    </div>
                  )}

                  {data?.deliverables?.length > 0 &&
                    data?.deliverables?.map((del,ind) => (
                      <div className="item" key={ind}>
                        <div className="left">
                          {del?.platform === "Instagram" ? (
                            <FaInstagram className="main-icon" />
                          ) : del?.platform === "Facebook" ? (
                            <FaFacebook className="main-icon" />
                          ) : del?.platform === "Tiktok" ? (
                            <FaTiktok className="main-icon" />
                          ) : (
                            <FaYoutube className="main-icon" />
                          )}
                          <div className="info">
                            <div className="line">
                              <p>
                                {del?.platform} | {del?.postType}{" "}
                              </p>
                              <p>
                                {" "}
                                {del?.platform === "Instagram" ? (
                                  <FaInstagram className="icon" />
                                ) : del?.platform === "Facebook" ? (
                                  <FaFacebook className="icon" />
                                ) : del?.platform === "Tiktok" ? (
                                  <FaTiktok className="icon" />
                                ) : (
                                  <FaYoutube className="icon" />
                                )}{" "}
                                {del?.howMany}
                              </p>
                            </div>
                            <div className="line">
                              <p>
                                {" "}
                                <AiOutlineCopyright className="icon" />{" "}
                                {del?.rights}
                              </p>
                              <p>
                                {" "}
                                <AiOutlineDollarCircle className="icon" />
                                PKR {Number(del?.contentFee)?.toLocaleString()}
                              </p>
                            </div>
                          </div>
                        </div>
                        <AiOutlineEdit
                          className="edit"
                          onClick={() => onEdit(del)}
                        />
                      </div>
                    ))}
                </div>
              </Panel>
              {/* <Panel
                header="Products"
                showArrow={false}
                key="2"
                extra={genExtra()}
              ></Panel> */}
              <Panel
                header="Other fees"
                showArrow={false}
                key="3"
                extra={genExtra()}
              >
                <div className="other-fee-container">
                  {activePanel == 3 && <Form
                    layout="vertical"
                    form={form}
                    onFinish={onOtherFeeFinish}
                  >
                    <Row gutter={[10, 0]}>
                      <Col span={10}>
                        <Form.Item
                          label="Type"
                          name={"type"}
                          rules={[
                            {
                              required: true,
                              message: "Type is Required",
                            },
                          ]}
                          requiredMark={"optional"}
                        >
                          <Select placeholder="Select Options" size="large">
                            <Option value="agencyFee">Agency Fee</Option>
                            <Option value="exclusivity">Exclusivity</Option>
                            <Option value="otherCost">Other Cost</Option>
                            <Option value="usageRights">Usage Rights</Option>
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col span={10}>
                        <Form.Item
                          label="Amount"
                          name={"amount"}
                          rules={[
                            {
                              required: true,
                              message: "amount is Required",
                            },
                          ]}
                          requiredMark={"optional"}
                        >
                          <Input placeholder="PKR" />
                        </Form.Item>
                      </Col>
                      <Col span={2}>
                        <Form.Item label=" ">
                          <Button htmlType="submit" type="primary">
                            Add
                          </Button>
                        </Form.Item>
                      </Col>
                    </Row>
                  </Form>}
                  {otherFeeArr?.length > 0 && (
                    <DataTable
                      columns={columns}
                      data={feeData}
                      loader={false}
                    />
                  )}
                  <div className="action-buttons">
                    <Button type="default" onClick={() => setActivePanel(null)}>Cancel</Button>
                    <Button type="default" onClick={() => saveOtherFees()}>
                      Save
                    </Button>
                  </div>
                </div>
              </Panel>
              {/* <Panel
                header="Fee Calculator"
                showArrow={false}
                key="4"
                extra={genExtra()}
              ></Panel> */}
            </Collapse>
          </div>
        </div>
      </Drawer>
    </>
  );
};

export default CampaignInfluncerAssignDrawer;
