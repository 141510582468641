import { useState } from "react";
import { Form, Input, Button } from "antd";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import { authLogin } from "../../redux";
import Logo from "../../assets/fireup_white.png";
import MainLogo from "../../assets/mainlogo.png";

const SignIn = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const onFinish = async (values) => {
    setLoading(true);
    await dispatch(authLogin(values, navigate));
    setLoading(false);
  };

  if (localStorage.getItem("token")) {
    // navigate('/');
    navigate('/brand-buzz');
  } else {
    return (
      <>
        <div className="login-container">
          <div className="left-side">
            <img src={Logo} alt="logo" />
          </div>
          <div className="right-side">
            <img alt="logo" src={MainLogo} className="phone-logo" />

            <div className="main">
              <Form
                name="login"
                className="login-form"
                layout="vertical"
                onFinish={onFinish}
              >
                <h2 className="heading">Log In</h2>
                <Form.Item
                  name="email"
                  rules={[
                    {
                      type: "email",
                      message: "The entered email is not valid!",
                    },
                    {
                      required: true,
                      message: "Email is Required",
                    },
                  ]}
                  label="Email"
                  requiredMark="optional"
                >
                  <Input autoComplete="off" placeholder="Enter Email..." />
                </Form.Item>
                <Form.Item
                  name="password"
                  rules={[
                    {
                      required: true,
                      message: "Password is Required",
                    },
                  ]}
                  label="Password"
                  requiredMark="optional"
                >
                  {/* <Input.Password */}
                  <Input type="password" placeholder="Password..." />
                </Form.Item>

                <Form.Item>
                  <Button loading={loading} type="primary" htmlType="submit">
                    Login
                  </Button>
                </Form.Item>
              </Form>
            </div>
          </div>
        </div>
      </>
    );
  }
};

export default SignIn;
