import { notification } from "antd";
import { publicAPI } from "../../config/constants";
import {onLogout} from  "../../functions/RedirectingAuth"
export const getModerators = () => {
  return async () => {
    try {
      const res = await publicAPI.get(`/admin/get-all-moderator`);
      if (res.data) {
        return res.data.moderators;
      }
    } catch (err) {
      notification.error({
        description: err?.response?.data?.message || "Server Error",
        duration: 2,
      });
      onLogout();
    }
  };
};
