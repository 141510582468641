import React, { useState, useEffect } from "react";
import { Table } from "antd";

import { attachToken, privateAPI } from "../../../config/constants";
import moment from "moment";
import InfinityLoader from "../../../components/InfinityLoader";
import { useNavigate } from "react-router-dom";

const InfluencerCollaboration = ({ id }) => {
  const navigate = useNavigate();
  const [data, setData] = useState(null);
  const [collaborations, setCollaborations] = useState(null);

  const getInfluencerCollaborations = async () => {
    attachToken();
    const res = await privateAPI.post(`/influencer/collaborations/${id}`, {});
    if (res?.data) {
      setCollaborations(res?.data?.campaigns);
      // console.log("res", res?.data?.campaigns);
    }
  };

  useEffect(() => {
    getInfluencerCollaborations();
  }, []);

  useEffect(() => {
    let tempArr = [];
    collaborations?.map((i) => {
      tempArr.push({
        name: (
          <span
            className="link"
            onClick={() => navigate(`/campaign/${i?._id}`)}
          >
            {i?.name}
          </span>
        ),
        brand: i?.brand?.name,
        type: i?.campaignType,
        dates: `${moment(i?.startDate).format("MMM DD, YYYY")} - ${moment(
          i?.endDate
        ).format("MMM DD, YYYY")}`,
        posts: "--",
        cost: "--",
        cpp: "--",
        cpvid: "--",
        cpe: "--",
        cpv: "--",
        cpsv: "--",
        products: "--",
        contract: "--",
      });
    });
    setData(tempArr);
  }, [collaborations]);

  const columns = [
    {
      title: "NAME",
      dataIndex: "name",
      width: "200px",
    },
    {
      title: "BRAND",
      dataIndex: "brand",
    },
    {
      title: "TYPE",
      dataIndex: "type",
    },
    {
      title: "DATES",
      dataIndex: "dates",
    },
    {
      title: "POSTS",
      dataIndex: "posts",
    },
    {
      title: "COST",
      dataIndex: "cost",
    },
    {
      title: "CPP",
      dataIndex: "cpp",
    },
    {
      title: "CPVID",
      dataIndex: "cpvid",
    },
    {
      title: "CPE",
      dataIndex: "cpe",
    },
    {
      title: "CPV",
      dataIndex: "cpv",
    },
    {
      title: "CPSV",
      dataIndex: "cpsv",
    },
    {
      title: "PRODUCTS",
      dataIndex: "products",
    },
    {
      title: "CONTRACT",
      dataIndex: "contract",
    },
  ];

  //   const tableLoading = {
  //     spinning: loader,
  //     indicator: <LoadingOutlined />,
  //   };

  return (
    <>
      {!collaborations ? (
        <InfinityLoader />
      ) : (
        <div className="inf-collaboration">
          {/* FIlter */}
          <div className="filter-bar">
            <div className="left-side">
              <h2>Collaborations</h2>
            </div>
            <div className="right-side">
              {/* <MdOutlineSwapVerticalCircle
                className="add-icon"
                style={{ width: "50px" }}
              /> */}
            </div>
          </div>
          <br />

          {/* Table */}
          <Table
            className="data-table"
            columns={columns}
            dataSource={data}
            pagination
            scroll={{ x: "2000px" }}
            // loading={}
            summary={() => {
              // let totalBorrow = 0;
              // let totalRepayment = 0;
              // pageData.forEach(({ borrow, repayment }) => {
              //   totalBorrow += borrow;
              //   totalRepayment += repayment;
              // });
              return (
                <>
                  <Table.Summary.Row>
                    <Table.Summary.Cell index={0}>
                      <span
                        style={{ color: "var(--primary)", fontWeight: "600" }}
                      >
                        {collaborations?.length} Campaign
                      </span>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={1}>
                      <span>--</span>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={2}>
                      <span>--</span>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={3}>
                      <span>--</span>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={4}>
                      <span>--</span>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={5}>
                      <span>--</span>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={6}>
                      <span>--</span>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={7}>
                      <span>--</span>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={8}>
                      <span>--</span>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={9}>
                      <span>--</span>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={10}>
                      <span>--</span>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={11}>
                      <span>--</span>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={12}>
                      <span>--</span>
                    </Table.Summary.Cell>
                  </Table.Summary.Row>
                </>
              );
            }}
          />
        </div>
      )}
    </>
  );
};

export default InfluencerCollaboration;
