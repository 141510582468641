import React from "react";
import { Col, Row } from "antd";

import { TbDeviceFloppy } from "react-icons/tb";

import ExpandableTable from "./ExpandableTable";

function Leaderboard() {
  return (
    <div className="leaderboard-container">
      <Row>
        <Col xl={24} lg={24} md={24} sm={24} xs={24}>
          <div className="header">
            <p>Top Performers</p>
            <TbDeviceFloppy size={25} color={"#9e9fa3"} />
          </div>
          <ExpandableTable />
        </Col>
      </Row>
    </div>
  );
}

export default Leaderboard;
