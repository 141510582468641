import { useState } from "react";
import { Modal, Button, Form, Input, notification } from "antd";
import { FaPlus } from "react-icons/fa";
import { attachToken, privateAPI } from "../../config/constants";
import { useDispatch } from "react-redux";
import { getAllBrands } from "../../redux";

const AddBrand = ({ edit }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const merchant = JSON.parse(localStorage.getItem("user"));

  const showModal = () => {
    setIsModalVisible(true);
  };

  const onFinish = async (values) => {
    setLoading(true);
    let payload = {
      ...values,
      merchant: merchant?._id,
    };
    try {
      attachToken();
      const res = await privateAPI.post(`/brand/create`, payload);
      if (res?.data) {
        notification.success({
          message: res?.data?.message,
          duration: 3,
        });
        form.resetFields();
        dispatch(getAllBrands());
        setLoading(false);
        setIsModalVisible(false);
      }
    } catch (err) {
      notification.error({
        description: err?.response?.data?.message,
        duration: 4,
      });
      setLoading(false);
    }
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <>
      {edit ? (
        <span onClick={() => showModal()}>Edit</span>
      ) : (
        <FaPlus className="add-icon" onClick={() => showModal()} />
      )}
      <Modal
        title={"Create Group"}
        visible={isModalVisible}
        footer={false}
        onCancel={handleCancel}
        width={"600px"}
      >
        <Form
          layout="vertical"
          form={form}
          fields={
            edit
              ? [
                  {
                    name: "name",
                    value: edit?.name,
                  },
                  {
                    name: "description",
                    value: edit.description,
                  },
                ]
              : null
          }
          onFinish={onFinish}
        >
          <Form.Item
            label="Name"
            name={"name"}
            rules={[
              {
                required: true,
                message: "Name is Required",
              },
            ]}
            requiredMark={"optional"}
          >
            <Input placeholder="Creater Name" />
          </Form.Item>
          <Form.Item
            label="Description"
            name={"description"}
            rules={[
              {
                required: true,
                message: "Description is Required",
              },
            ]}
            requiredMark={"optional"}
          >
            <Input.TextArea
              rows={2}
              style={{ resize: "none" }}
              placeholder="Short description for this group"
            />
          </Form.Item>
          <Form.Item>
            <Button htmlType="submit" loading={loading} type="primary">
              {edit ? "Update" : "Create"}
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default AddBrand;
