import React from "react";
import { Col, Row } from "antd";

import { BiMessageRounded, BiTrendingUp } from "react-icons/bi";
import { BsPlay } from "react-icons/bs";
import { CgFileDocument } from "react-icons/cg";

import Influencers from "./Influencers";
import Summary from "./Summary";
import TopPerformer from "./TopPerformer";

function BrandSummary() {
  return (
    <div className="BrandSummary_Container">
      <Summary />
      <TopPerformer />
      <Row>
        <Col xl={6} lg={12} md={12} sm={24} xs={24}>
          <Influencers
            title="MOST ACTIVE INFLUENCERS"
            icon={<CgFileDocument className="icon" />}
          />
        </Col>
        <Col xl={6} lg={12} md={12} sm={24} xs={24}>
          <Influencers
            title="MOST ENGAGING INFLUENCERS"
            icon={<BiMessageRounded className="icon" />}
          />
        </Col>
        <Col xl={6} lg={12} md={12} sm={24} xs={24}>
          <Influencers
            title="HIGHEST ENG RATE"
            icon={<BiTrendingUp className="icon" />}
          />
        </Col>
        <Col xl={6} lg={12} md={12} sm={24} xs={24}>
          <Influencers
            title="MOST VIDEO VIEWS"
            icon={<BsPlay className="icon" />}
          />
        </Col>
      </Row>
    </div>
  );
}

export default BrandSummary;
