import { Menu } from 'antd'

import { AiOutlineSetting } from 'react-icons/ai'
import { RiDeleteBinLine } from 'react-icons/ri'

import { campaignDelete } from '../../../redux'

export const ActionMenu = (campaignData, getAllCampaigns, navigate) => (
  <Menu>
    <Menu.Item
      icon={<AiOutlineSetting className='dropdown-menu-icon' />}
      onClick={() => navigate('/edit-campaign', { state: campaignData })}
    >
      Edit Campaign
    </Menu.Item>
    <Menu.Item
      icon={<RiDeleteBinLine className='dropdown-menu-icon' />}
      onClick={() => {
        campaignDelete(campaignData?._id, getAllCampaigns)
      }}
    >
      Delete Campaign
    </Menu.Item>
  </Menu>
)

export const columns = [
  {
    title: 'NAME',
    dataIndex: 'name',
  },
  {
    title: 'BRAND',
    dataIndex: 'brand',
  },
  {
    title: 'TYPE',
    dataIndex: 'type',
  },
  {
    title: 'Status',
    dataIndex: 'status'
  },
  // {
  //   title: 'OBJECTIVE',
  //   dataIndex: 'objective',
  // },
  {
    title: 'CREATED ON',
    dataIndex: 'createdOn',
  },
  {
    title: 'DATES',
    dataIndex: 'date',
    width: '150px',
  },
  {
    title: 'PEOPLE',
    dataIndex: 'people',
  },
  {
    title: 'BUDGET',
    dataIndex: 'budget',
  },
  {
    title: 'TOTAL SPEND',
    dataIndex: 'total',
  },
  {
    title: 'ACTION',
    dataIndex: 'action',
  },
]
