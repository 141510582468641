export const GetReach = ({ type, videoViews, engRate, followers }) => {
    // console.log('type', type)
    // console.log('videoViews', videoViews)
    // console.log('engRate', engRate)
    // console.log('followers', followers)
    if (type === 'VIDEO') {
        let reach = 0;
        if (videoViews) {
            reach = videoViews / 100 * 85 + Number(engRate)
        } else {
            let views = Number(engRate) > 2 ?
                Number(engRate) > 3 ?
                    Number(engRate) > 4 ?
                        Number(engRate) > 5 ?
                            Number(followers / 100 * 70)
                            : Number(followers / 100 * 60)
                        : Number(followers / 100 * 50)
                    : Number(followers / 100 * 40)
                : Number(followers / 100 * 30)
            reach = views / 100 * 85 + Number(engRate)
        }
        return Number(reach)
    } else {
        // let reach = 0;
        let post = Number(engRate) > 2 ?
            Number(engRate) > 3 ?
                Number(engRate) > 4 ?
                    Number(engRate) > 5 ?
                        Number(followers / 100 * 70)
                        : Number(followers / 100 * 60)
                    : Number(followers / 100 * 50)
                : Number(followers / 100 * 40)
            : Number(followers / 100 * 30)
        let reach = post / 100 * 85 + Number(engRate)
        return Number(reach)
    }
}

