import React, { useState, useEffect } from "react";
import { Layout, Select } from "antd";
import { useLocation, useNavigate } from "react-router-dom";

import { RiMenuUnfoldLine, RiMenuFoldLine } from "react-icons/ri";
import { FiLogOut } from "react-icons/fi";

import Logo from "../assets/mainlogo.png";
import Profile from "../assets/profilepic.png";

import MainMenu from "./MainMenu";
import AddInfluencer from "../components/modal/AddInfluencer";
import { getAllBrands, SelectBrand } from "../redux";
import { useDispatch, useSelector } from "react-redux";

const VerticalLayout = ({ children, active }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation()
  const { Option } = Select;
  const [collapsed, setCollapsed] = useState(false);
  const { Header, Sider, Content } = Layout;
  const [windowDimenion, detectHW] = useState({
    winWidth: window.innerWidth,
  });
  const brands = useSelector((state) => state?.brand?.brands);
  const selectedBrand = useSelector((state) => state?.brand?.selectedBrand);
  
  const toggle = () => {
    setCollapsed(!collapsed);
  };

  useEffect(() => {
    if(brands?.length < 1){
      dispatch(getAllBrands());
    }
  }, [location.pathname]);

  const onLogout = () => {
    localStorage.clear();
    navigate("/login");
  };

  const detectSize = () => {
    detectHW({
      winWidth: window.innerWidth,
    });
  };

  useEffect(() => {
    if (windowDimenion.winWidth < 1030) {
      setCollapsed(true);
    }
  }, [windowDimenion]);

  useEffect(() => {
    window.addEventListener("resize", detectSize);

    // return () => {
      window.removeEventListener("resize", detectSize);
    // };
  }, [windowDimenion]);

  return (
    <div className="v-layout">
      <Layout>
        <Sider
          trigger={null}
          collapsible
          collapsed={collapsed}
          width={220}
          style={{
            overflow: "auto",
            height: "90vh",
            position: "fixed",
            background: "var(--sidebar)",
            boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
            borderRadius: "10px",
          }}
        >
          <div className="company-logo">
            <img src={Logo} alt="logo" />
          </div>
          {windowDimenion?.winWidth > 1030 && (
            <div className="collapse-div">
              {React.createElement(
                collapsed ? RiMenuUnfoldLine : RiMenuFoldLine,
                {
                  className: "trigger",
                  onClick: toggle,
                }
              )}
            </div>
          )}
          <MainMenu active={active} />
        </Sider>
        <Layout
          className="site-layout"
          style={{ marginLeft: collapsed ? 80 : 240 }}
        >
          <Header
            className="mainHeader"
            style={{ marginLeft: collapsed ? "20px" : "0" }}
          >
            <div>
            <div className="brand-select-container">
              <span>Brand</span> 
              <Select
                // style={{ minWidth: "150px" }}
                bordered={false}
                value={selectedBrand}
                className="brand-select-header"
                // value={selectedBrand || brands?.[0]?._id}
                onChange={(e) => {
                  dispatch(SelectBrand(e));
                }}
              >
                {brands?.map((brand,ind) => (
                  <Option key={ind} value={brand?._id}>{brand.name}</Option>
                ))}
              </Select>
            </div>
            </div>
            <div className="right">
              {/* {SearchShow ? (
              <Input
                size="small"
                placeholder="Search..."
                className="search-input"
                prefix={
                  <FiSearch
                    className="search icon"
                    onClick={() => setSearchShow(false)}
                  />
                }
              />
            ) : (
              <FiSearch
                className="search icon"
                onClick={() => setSearchShow(true)}
              />
            )}
            <AiOutlineSetting className="icon" />
            <AiOutlineQuestionCircle className="icon" /> */}
              {/* <AddInfluencer /> */}
              <AddInfluencer text={'Add Influencer'} btn={true} />
              <img
                onClick={() => navigate("/profile")}
                // src={Profile}
                src={JSON.parse(localStorage.getItem('user'))?.profile_picture || Profile}
                alt="profile"
                className="profile-pic"
              />
              <span onClick={() => navigate("/profile")} className="name">
                {JSON.parse(localStorage.getItem('user'))?.name || 'Admin'}
              </span>
              <FiLogOut className="logout icon" onClick={onLogout} />
            </div>
          </Header>
          <Content
            className="mainContent"
            style={{ marginLeft: collapsed ? "20px" : "0" }}
          >
            {children}
          </Content>
        </Layout>
      </Layout>
    </div>
  );
};

export default VerticalLayout;
