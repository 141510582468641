import React, { useEffect, useState } from 'react'
import { Select, Row, Col } from 'antd'
import moment from 'moment'

// import { FiSearch } from 'react-icons/fi'
// import { VscSettings } from 'react-icons/vsc'
import { attachToken, privateAPI } from '../../../config/constants'

import BrandBuzzCard from '../../../components/Cards/BrandBuzzCard'
import BrandBuzzSkeleton from '../../../components/Skeletons/BrandBuzzSkeleton'
import AddCustomCampaignContent from '../../../components/modal/AddCustomCampaignContent'

const CampaignContent = ({ id, campaignDate }) => {
  const { Option } = Select
  const [hashtags, setHashtags] = useState([])
  const [content, setContent] = useState(null)
  const [dateFilter, setDateFilter] = useState(null)
  const [filterChange, setFilterChange] = useState(null)
  const [influecersList, setInfluencersList] = useState([])
  const [adFilterState, setADFilterState] = useState('descending')

  // const onfilter2Change = (value) => {
  //   console.log('filter', value)
  // }

  const onDateChange = (value) => {
    setContent(null)
    if (value === 'campaignDate') {
      setDateFilter(campaignDate)
    } else if (value === 'today') {
      setDateFilter({
        startOf: moment()?.subtract('day', 2).format(),
        endOf: moment().add('day', 1).format(),
      })
    } else if (value === 'yesterday') {
      setDateFilter({
        startOf: moment()?.subtract('day', 2).format(),
        endOf: moment()?.subtract('day', 1).format(),
      })
    } else if (value === 'last7Days') {
      setDateFilter({ startOf: moment()?.subtract('day', 7).format(), endOf: moment().format() })
    } else if (value === 'last30Days') {
      setDateFilter({ startOf: moment()?.subtract('day', 30).format(), endOf: moment().format() })
    } else if (value === 'last60Days') {
      setDateFilter({ startOf: moment()?.subtract('day', 60).format(), endOf: moment().format() })
    } else if (value === 'last90Days') {
      setDateFilter({ startOf: moment()?.subtract('day', 90).format(), endOf: moment().format() })
    }
  }

  const onFilterChange = (value) => {
    setFilterChange(value)
  }

  const ADFilter = (value) => {
    // setContent(null)
    setADFilterState(value)
  }

  const getCampaignHashtags = async () => {
    setHashtags([])
    try {
      attachToken()
      const res = await privateAPI.post(`/campaign/getCampaignHashtags/${id}`)
      if (res?.data) {
        setHashtags(res?.data)
      }
    } catch (error) {
      console.log('err', error)
    }
  }

  const getCampaignContent = async () => {
    if (id && campaignDate) {
      setContent(null)
      try {
        let payload = {
          dateFilter: dateFilter || campaignDate,
          engagementFilter: filterChange,
          adFilter: adFilterState,
        }
        attachToken()
        const res = await privateAPI.post(`/campaign/get-campaign-content/${id}`, payload)
        if (res?.data) {
          // console.log('res', res?.data)
          setContent(res?.data)
        }
      } catch (error) {
        console.log('err', error)
      }
    }
  }

  const getInfluencersList = async () => {
    try {
      const res = await privateAPI.post('campaign/get-campaign-influencers-list', { id: id })
      if (res) {
        setInfluencersList(res?.data)
      }
    } catch (err) {
      console.log(err)
    }
  }

  useEffect(() => {
    getCampaignHashtags()
    getCampaignContent()
    getInfluencersList()
  }, [dateFilter, campaignDate, filterChange, adFilterState])

  return (
    <div className='campaign-content'>
      {/* Filter Bar */}
      <div className='filter-bar'>
        <div className='left-side'>
          {/* <Radio.Group defaultValue='a' buttonStyle='solid' size='large'>
            <Radio.Button value='a'>Campaign Posts</Radio.Button>
            <Radio.Button value='b'>All Posts</Radio.Button>
          </Radio.Group> */}
          {/* <div className='filter-div'>
            <VscSettings className='icon' />
            <span>Filter</span>
          </div> */}
          <Select placeholder='Date' style={{ minWidth: 130 }} onChange={onDateChange} size='large'>
            <Option value='campaignDate'>Campaign Date</Option>
            <Option value='today'>Today</Option>
            <Option value='yesterday'>Yesterday</Option>
            <Option value='last7Days'>Last 7 Days</Option>
            <Option value='last30Days'>Last 30 Days</Option>
            <Option value='last60Days'>Last 60 Days</Option>
            <Option value='last90Days'>Last 90 Days</Option>
          </Select>

          <Select
            placeholder='Sort'
            style={{ minWidth: 140 }}
            onChange={onFilterChange}
            size='large'
          >
            <Option value='comments'>Comments</Option>
            <Option value='likes'>Likes</Option>
            {/* <Option value='reach'>Reach</Option> */}
            <Option value='engrate'>Eng Rate</Option>
            <Option value='views'>Video Views</Option>
          </Select>

          <Select
            defaultValue={'descending'}
            placeholder='A-Z / Z-A'
            style={{ minWidth: 130 }}
            onChange={ADFilter}
            size='large'
          >
            <Option value='ascending'>Sort A-Z</Option>
            <Option value='descending'>Sort Z-A</Option>
          </Select>
          {/* <Select
            defaultValue='recent'
            style={{ minWidth: 140 }}
            onChange={onfilter2Change}
            size='large'
          >
            <Option value='recent'>Most Recent</Option>
            <Option value='liked'>Most Liked</Option>
          </Select> */}
        </div>
        <div className='right-side'>
          {/* <Input
            placeholder='Filter by Keyword'
            prefix={<FiSearch className='search-icon' />}
            className='searchInput'
            size='small'
          /> */}
          <AddCustomCampaignContent
            id={id}
            influecersList={influecersList}
            campaignDate={campaignDate}
            getCampaignContent={getCampaignContent}
          />
        </div>
      </div>

      {/* Trending Hashtags */}
      {hashtags?.length > 0 && (
        <div className='trending-hashtags'>
          <h2>Trending Hashtags</h2>
          <div className='main'>{hashtags?.map((hash, index) => [<p key={index}>{hash}</p>])}</div>
        </div>
      )}
      <br />
      {content ? (
        <Row gutter={[20, 20]}>
          {content?.length > 0 ? (
            content?.map((post, index) => (
              <Col key={index} xs={24} lg={12} xl={8} xxl={6}>
                <BrandBuzzCard
                  followers={post?.media?.instagram?.followers_count}
                  media={post?.media}
                  name={post?.media?.instagram?.name}
                  profilePic={post?.media?.instagram?.profile_picture_url}
                  influencerId={post?.media?.instagram?.influencer}
                  inf={post?.media?.influencer}
                />
              </Col>
            ))
          ) : (
            <div className='no-found '>
              <span>{hashtags?.length > 0 ? 'No Content Found.' : 'No Hashtags Found.'}</span>
            </div>
          )}
        </Row>
      ) : (
        <Row gutter={[20, 20]}>
          <Col xs={24} lg={12} xl={8} xxl={6}>
            <BrandBuzzSkeleton />
          </Col>
          <Col xs={24} lg={12} xl={8} xxl={6}>
            <BrandBuzzSkeleton />
          </Col>
          <Col xs={24} lg={12} xl={8} xxl={6}>
            <BrandBuzzSkeleton />
          </Col>
          <Col xs={24} lg={12} xl={8} xxl={6}>
            <BrandBuzzSkeleton />
          </Col>
        </Row>
      )}
    </div>
  )
}

export default CampaignContent
