import { notification } from "antd";
import { privateAPI } from "../../config/constants";
import {onLogout} from  "../../functions/RedirectingAuth"

export const getAllGroups = (payload) => {
  return async () => {
    try {
      const res = await privateAPI.get(`/group/all-groups/${payload.brand}`);
      if (res?.data) {
        return res?.data;
      }
    } catch (err) {
      notification.error({
        description: err?.response?.data?.message || "Server Error",
        duration: 2,
      });
      onLogout();
    }
  };
};
