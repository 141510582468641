import { useState, useEffect } from "react";
import { Col, DatePicker, Row, } from "antd";

import DataTable from "../Table/DataTable";
import { VscSettings } from "react-icons/vsc";

const Campaigns = () => {
  const { RangePicker } = DatePicker;
  const [data, setData] = useState(null);

  const onDateChange = () => {
    // console.log(date, dateString);
  };

  useEffect(() => {
    let tempArr = [];
    [1, 2, 3, 4, 5, 6]?.map(() => {
      tempArr.push({
        name: "Afaque Ahmed",
        brands: "L'Oreal",
        type: "Content Creation",
        spend: "PKR64M",
        cpp: "PKR64M",
        cpvid: "PKR64M",
        cpe: "PKR 417k",
        cpv: "PKR 224k",
        cpsv: "--",
      });
    });
    setData(tempArr);
  }, []);

  const columns = [
    {
      title: "NAME",
      dataIndex: "name",
    },
    {
      title: "BRANDS",
      dataIndex: "brands",
    },
    {
      title: "TYPE",
      dataIndex: "type",
    },
    {
      title: "SPEND",
      dataIndex: "spend",
    },
    {
      title: "CPP",
      dataIndex: "cpp",
    },
    {
      title: "CPVID",
      dataIndex: "cpvid",
    },
    {
      title: "CPE",
      dataIndex: "cpe",
    },
    {
      title: "CPV",
      dataIndex: "cpv",
    },
    {
      title: "CPSV",
      dataIndex: "cpsv",
    },
  ];

  return (
    <div className="partner-container">
      {/* Filter Bar */}
      <div className="filter-bar">
        <div className="left-side">
          <div className="filter-div">
            <VscSettings className="icon" />
            <span>Filter</span>
          </div>
          <RangePicker
            className="datePicker"
            style={{ width: "220px" }}
            onChange={onDateChange}
          />
        </div>
      </div>

      {/* Analytic's Box */}
      <Row className="analtics-boxes" gutter={[5, 5]}>
        <Col xs={12} md={8} lg={4}>
          <div className="box">
            <span>244</span>
            <p>PARTNERS</p>
          </div>
        </Col>
        <Col xs={12} md={8} lg={4}>
          <div className="box">
            <span>PKR75M</span>
            <p>TOTAL SPEND</p>
          </div>
        </Col>
        <Col xs={12} md={8} lg={4}>
          <div className="box">
            <span>PKR80.7K</span>
            <p>COST PER POST</p>
          </div>
        </Col>
        <Col xs={12} md={8} lg={4}>
          <div className="box">
            <span>PKR101K</span>
            <p>COST PER VIDEO</p>
          </div>
        </Col>
        <Col xs={12} md={8} lg={4}>
          <div className="box">
            <span>PKR 38.92</span>
            <p>CPE</p>
          </div>
        </Col>
        <Col xs={12} md={8} lg={4}>
          <div className="box">
            <span>PKR 2.53</span>
            <p>CPV</p>
          </div>
        </Col>
      </Row>

      {/* Table */}
      <DataTable data={data} columns={columns} loader={false} />
    </div>
  );
};

export default Campaigns;
