import React from 'react'
import { Progress, Tooltip } from 'antd'
import { NumberFormatter } from '../../../functions/NumberFormatter'

function Expand({ valueShow, name, value }) {
  const tooltipText = (title, value, name) => (
    <div className='performer-tooltip'>
      <p className='keyword'>{title || '#'}</p>
      <div className='detail'>
        <p className='title'>
          {name} ({name === 'ENG RATE' ? `${value}%` : NumberFormatter(value, 1)})
        </p>
      </div>
    </div>
  )

  return (
    <div className='expand-container'>
      <div className='expand-header'>
      <h1>{valueShow || 0}</h1>
      <p className='title'>{name}</p>
      </div>
      <Progress
        strokeColor={'#81d2ef'}
        // height={1}
        percent={0}
        size={'small'}
        showInfo={false}
      />
      <Progress strokeColor={'#4b95e7'} percent={0} size={'small'} showInfo={false} />
      <Tooltip title={tooltipText('Instagram', value, name)} placement={'top'}>
        <div className='progress-div'>
        <Progress
        className='progress-bar'
          strokeColor={'#E7008A'}
          percent={value > 0 ? ((value / value) * 100).toFixed() : '0'}
          size={'small'}
          showInfo={false}
        />
        <p>{name === 'ENG RATE' ? `${value}%` : NumberFormatter(value, 1)}</p>
        </div>
      </Tooltip>
      <Progress strokeColor={'#f2c4ae'} percent={0} size={'small'} showInfo={false} />
      <Progress strokeColor={'#ffb996'} percent={0} size={'small'} showInfo={false} />
      <Progress strokeColor={'#93f4ef'} percent={0} size={'small'} showInfo={false} />
    </div>
  )
}

export default Expand
