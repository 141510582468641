// import { useEffect, useState } from "react";
import { Line } from '@ant-design/charts'
import theme from '../../theme.json'

const LineChart = ({ graphData, graphFilter, loader }) => {
  // console.log('graphData',graphData)
  const config = {
    data: graphData || [],
    smooth: true,
    padding: 'auto',
    xField: 'date',
    yField: 'value',
    color: theme['primary-color'],
    xAxis: {
      // type: 'timeCat',
      tickCount: 5,
    },
    meta: {
      value: {
        alias: graphFilter || 'posts',
      },
    },
  }

  return (
    <div style={{ height: '400px' , position:'relative' }}>
      <Line {...config} loading={loader} />
    </div>
  )
}

// loading={Arr.length > 0 ? false : true}
export default LineChart
