import { Routes, Route, BrowserRouter, Navigate } from 'react-router-dom'

import AddCampaigns from '../pages/AddCampaigns/AddCampaigns'
import BrandBuzz from '../pages/BrandBuzz/BrandBuzz'
import Campaign from '../pages/Campaign/Campaign'
import Campaigns from '../pages/Campaigns/Campaigns'
import Discovery from '../pages/Discovery'
import Group from '../pages/Group/Group'
import Groups from '../pages/Groups/Groups'
import Influencer from '../pages/Influencer/Influencer'
import Login from '../pages/Login/Login'
import Network from '../pages/Network/Network'
import Notes from '../pages/Notes'
import PaidPartners from '../pages/PaidPartners'
import Profile from '../pages/Profile'
import ProgramAnalytics from '../pages/ProgramAnalytics'
import StudioApplicants from '../pages/StudioApplicants'
import Tasks from '../pages/Tasks'
import Report from '../pages/Report'
import CampaignEmailConfirmation from '../pages/CampaignEmailConfirmation'
import Brands from '../pages/Brands/Brands'
import Moderators from '../pages/Moderators'
import PrivateWrapper from './PrivateRoutes'
import InfluencerConnect from '../pages/InfluencerConnect'
import CampaignInfluencerContent from '../pages/Campaign/components/CampaignInfluencerContent'
import CreateCampReport from '../pages/Campaign/components/CreateReport'
import CampReport from '../pages/Campaign/components/Report'
import PublicCampaignDetails from '../pages/PublicPages/PublicCampaignDetails'
import PublicInfluencer from '../pages/PublicPages/PublicInfluencer'
import AllReports from '../pages/Reports/AllReports'
import PublicReport from '../pages/PublicPages/PublicReport'
import BulkUpload from '../pages/BulkUpload/BulkUpload'
import InfluencerCategories from '../pages/InfluencerCategories/InfluencerCategories'
import AddInfluencerInCategory from '../pages/InfluencerCategories/AddInfluencerInCategory'
import ViewCategoryInfluencers from '../pages/InfluencerCategories/ViewCategoryInfluencers'

const Routers = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route element={<PrivateWrapper />}>
          <Route path='/' element={<Network />} />
          <Route path='*' element={<Navigate to='/' />} />
          <Route path='/groups' element={<Groups />} />
          <Route path='/influencer-categories' element={<InfluencerCategories />} />
          <Route path='/category/:id' element={<AddInfluencerInCategory />} />
          <Route path='/view-category-influencers/:id' element={<ViewCategoryInfluencers />} />
          <Route path='/studio-applicants' element={<StudioApplicants />} />
          <Route path='/paid-partners' element={<PaidPartners />} />
          <Route path='/brand-buzz' element={<BrandBuzz />} />
          <Route path='/tasks' element={<Tasks />} />
          <Route path='/notes' element={<Notes />} />
          <Route path='/reports' element={<ProgramAnalytics />} />
          <Route path='/report/:id' element={<Report />} />
          <Route path='/campaigns' element={<Campaigns />} />
          <Route path='/create-campaign' element={<AddCampaigns />} />
          <Route path='/edit-campaign' element={<AddCampaigns />} />
          <Route path='/campaign/:id' element={<Campaign />} />
          <Route path='/search' element={<Discovery />} />
          <Route path='/influencer/:id' element={<Influencer />} />
          <Route path='/profile' element={<Profile />} />
          <Route path='/group/:id' element={<Group />} />
          <Route path='/brands' element={<Brands />} />
          <Route path='/moderators' element={<Moderators />} />
          <Route path='/campaign/:id/:inf' element={<CampaignInfluencerContent />} />
          <Route path='/campaign/:id/create-report' element={<CreateCampReport />} />
          <Route path='/campaign/:id/edit-report' element={<CreateCampReport />} />
          <Route path='/campaign/report/:id' element={<CampReport />} />
          <Route path='/all-reports' element={<AllReports />} />
          <Route path='/bulk-upload-influencers' element={<BulkUpload />} />
        </Route>

        <Route path='/campaign/confirmation/:id/:ad' element={<CampaignEmailConfirmation />} />
        <Route path='/login' element={<Login />} />
        <Route path='/influencer/connect/:id' element={<InfluencerConnect />} />
        <Route path='/public-campaign-details/:id' element={<PublicCampaignDetails />} />
        <Route path='/public-influencer/:id' element={<PublicInfluencer />} />
        <Route path='/public-campaign-report-summary/:id' element={<PublicReport />} />
      </Routes>
    </BrowserRouter>
  )
}

export default Routers
