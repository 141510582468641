export const SelectAll = (checked,setSelected, selected,data) => {
    if(checked){
        let newArr = []
        data?.map((e,ind) => newArr.push(ind))
        if(newArr.length === data?.length){
            setSelected(newArr)
        }
    }else{
        setSelected([])
    }    
}