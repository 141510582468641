import Axios from 'axios'

// export const base_url = "https://backend.fireupstar.com";
export const base_url = "http://52.220.221.192:5000/";

// export const forntendUrl = "https://admin.fireupstar.com";
export const forntendUrl = "http://localhost:3000";

export const publicAPI = Axios.create({ baseURL: base_url })

export const privateAPI = Axios.create({ baseURL: base_url })

export const attachToken = async () => {
  const jwt = localStorage.getItem('token')
  const TokenVersion = localStorage.getItem('token_Version')
  privateAPI.defaults.headers.common.Authorization = `Bearer ${jwt}`
  privateAPI.defaults.headers.common['Token_Version'] = TokenVersion;
}
