import { RingProgress } from '@ant-design/plots';
import { useEffect, useState } from 'react';

const RingChart = ({percent}) => {
    const[windowDimenion, detectHW] = useState(
        window.innerWidth,
    )

    const detectSize = () => {
        detectHW(
            window.innerWidth,
        )
    }

    useEffect(() => {
        window.addEventListener('resize', detectSize)

        return () => {
            window.removeEventListener('resize', detectSize)
        }
    }, [windowDimenion])
    const config =
    {
        height: windowDimenion > 1024 ? 150 : 250,
        
        autoFit: false,
        percent: percent || 0,
        color: ['#ef5a29', '#E8EDF3'],
    };
    return <RingProgress {...config} />;
};
export default RingChart;