import React, { useState, useEffect } from 'react'
import {
  Avatar,
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  notification,
  Row,
  Select,
  Steps,
  Switch,
  Table,
  Upload,
} from 'antd'
import ImgCrop from 'antd-img-crop'

import { UploadOutlined } from '@ant-design/icons'
import { MdDelete } from 'react-icons/md'

import Layout from '../../../layout/Layout'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import moment from 'moment'
import { attachToken, privateAPI } from '../../../config/constants'
import { NumberFormatter } from '../../../functions/NumberFormatter'

const CreateCampReport = () => {
  const { id } = useParams()
  const [form] = Form.useForm()
  const navigate = useNavigate()
  const { state } = useLocation()
  const [stepCount, setStepCount] = useState(0)
  const [campaignData, setCampaignData] = useState(null)
  const [selectedInfluencer, setSelectedInfluencer] = useState([])
  const [loading, setloading] = useState(false)
  const [agencyName, setAgencyName] = useState(null)
  const [fileState, setFileState] = useState(null)
  const formDataPayload = new FormData()
  const [formData, setFormData] = useState({
    name: '',
    startDate: '',
    endDate: '',
    keywords: '',
    engagements: true,
    video_views: true,
    // impressions: false,
    reach: false,
    estImpressions: false,
    // cpm: false,
    // vit: false,
    campaignInfluencers: [],
    agencyName: '',
    logo: null,
  })

  const getCampaign = async () => {
    if (id) {
      try {
        attachToken()
        const res = await privateAPI.get(`/campaign/get-campaigns-details/${id}`)
        if (res?.data) {
          setCampaignData(res?.data)
          if(!state){
            setSelectedInfluencer(() => res?.data?.campaign?.influencers?.map((inf) => inf?._id))
          }
        }
      } catch (error) {
        console.log('err', error)
      }
    }
  }

  useEffect(() => {
    getCampaign()
    
    if (state) {
      setFileState(state?.agencyLogo)
      setSelectedInfluencer(state?.influencers)
    }
  }, [id])

  const items = [
    {
      title: 'REPORT INFO',
    },
    {
      title: 'KEYWORDS',
    },
    {
      title: 'INFLUENCERS',
    },
    {
      title: 'OPTIONS',
    },
    {
      title: 'CLOSURE',
    },
  ]

  const columns = [
    {
      key: 'name',
      title: 'Name',
      dataIndex: 'name',
      render: (name, rowData) => (
        <>
          <Avatar src={rowData?.arch_profile_picture_url} style={{ marginRight: '10px' }} />
          {name}
        </>
      ),
    },
    {
      key: 'email',
      title: 'Email',
      dataIndex: 'email',
    },
    {
      key: 'audience',
      title: 'Audience',
      dataIndex: 'audience',
      render: (aud) => <>{NumberFormatter(aud, 1)}</>,
    },
  ]

  const onFormInfoFinish = async (values) => {
    setFormData({
      ...formData,
      name: values?.name || '',
      startDate: moment(values?.startDate).format() || moment().format(),
      endDate: moment(values?.endDate).format() || moment().format(),
    })

    setStepCount(1)
  }
  const onFormKeywordsFinish = async (values) => {
    setFormData({
      ...formData,
      keywords: values?.keywords || '',
    })
    formDataPayload.append('keywords', values?.keywords || '')
    setStepCount(2)
  }
  const onFormInfFinish = async () => {
    setFormData({
      ...formData,
      campaignInfluencers: [...selectedInfluencer],
    })
    // formDataPayload.append('campaignInfluencers', [...selectedInfluencer])
    setStepCount(3)
  }
  const onOptFinish = (values) => {
    setFormData({
      ...formData,
      engagements: values?.engagements || true,
      video_views: values?.video_views || true,
      // impressions: values?.impressions || false,
      reach: values?.reach || false,
      estImpressions: values?.estImpressions || false,
      // cpm: values?.cpm || false,
      // vit: values?.vit || false,
      cost_per_reach: values?.cost_per_reach,
      cpe_with_views: values?.cpe_with_views,
      cpe_without_views: values?.cpe_without_views,
      eng_with_views: values?.eng_with_views,
      eng_without_views: values?.eng_without_views,
      engagement_rate: values?.engagement_rate,
      inf_audience: values?.inf_audience,
      inf_engagement: values?.inf_engagement,
      inf_engagement_rate: values?.inf_engagement_rate,
      inf_posts: values?.inf_posts,
      potential_reach: values?.potential_reach,
      social_comments: values?.social_comments,
      social_engagement_rate: values?.social_engagement_rate,
      social_likes: values?.social_likes,
      social_reach: values?.social_reach,
      social_video_views: values?.social_video_views,
    })

    setStepCount(4)
  }

  const onFinish = async (values) => {
    // console.log('values', values)
    setFormData({
      ...formData,
      agencyName: values?.agencyName || null,
      logo: values?.logo,
    })

    // setFormData({
    //   ...formData,
    //   engagements: values?.engagements || true,
    //   video_views: values?.video_views || true,
    //   // impressions: values?.impressions || false,
    //   reach: values?.reach || false,
    //   estImpressions: values?.estImpressions || false,
    //   // cpm: values?.cpm || false,
    //   // vit: values?.vit || false,
    // })

    formDataPayload.append('name', formData?.name || '')
    formDataPayload.append('startDate', moment(formData?.startDate).format() || moment().format())
    formDataPayload.append('endDate', moment(formData?.endDate).format() || moment().format())
    formDataPayload.append('agencyName', values?.agencyName || null)
    formDataPayload.append('pictures', fileState)
    formDataPayload.append('oldLogo', state?.agencyLogo)
    formDataPayload.append('id', id)
    formDataPayload.append('keywords', JSON.stringify(formData?.keywords))
    formDataPayload.append('reportId', state?._id || null)
    formDataPayload.append('engagements', values?.engagements || true)
    formDataPayload.append('video_views', values?.video_views || true)
    formDataPayload.append('reach', values?.reach || false)
    formDataPayload.append('estImpressions', values?.estImpressions || false)
    formDataPayload.append('cost_per_reach', formData?.cost_per_reach)
    formDataPayload.append('cpe_with_views', formData?.cpe_with_views)
    formDataPayload.append('cpe_without_views', formData?.cpe_without_views)
    formDataPayload.append('eng_with_views', formData?.eng_with_views)
    formDataPayload.append('eng_without_views', formData?.eng_without_views)
    formDataPayload.append('engagement_rate', formData?.engagement_rate)
    formDataPayload.append('inf_audience', formData?.inf_audience)
    formDataPayload.append('inf_engagement', formData?.inf_engagement)
    formDataPayload.append('inf_engagement_rate', formData?.inf_engagement_rate)
    formDataPayload.append('inf_posts', formData?.inf_posts)
    formDataPayload.append('potential_reach', formData?.potential_reach)
    formDataPayload.append('social_comments', formData?.social_comments)
    formDataPayload.append('social_engagement_rate', formData?.social_engagement_rate)
    formDataPayload.append('social_likes', formData?.social_likes)
    formDataPayload.append('social_reach', formData?.social_reach)
    formDataPayload.append('social_video_views', formData?.social_video_views)
    formDataPayload.append('campaignInfluencers', JSON.stringify(formData?.campaignInfluencers))

    try {
      setloading(true)
      // let payload = {
      //   ...formData,
      //   agencyName: values?.agencyName || null,
      //   logo: values?.logo,
      //   engagements: values?.engagements || true,
      //   video_views: values?.video_views || true,
      //   // impressions: values?.impressions || false,
      //   reach: values?.reach || false,
      //   estImpressions: values?.estImpressions || false,
      //   // cpm: values?.cpm || false,
      //   // vit: values?.vit || false,
      //   id,
      //   reportId: state?._id || null,
      //   cost_per_reach: values?.cost_per_reach,
      //   cpe_with_views: values?.cpe_with_views,
      //   cpe_without_views: values?.cpe_without_views,
      //   eng_with_views: values?.eng_with_views,
      //   eng_without_views: values?.eng_without_views,
      //   engagement_rate: values?.engagement_rate,
      //   inf_audience: values?.inf_audience,
      //   inf_engagement: values?.inf_engagement,
      //   inf_engagement_rate: values?.inf_engagement_rate,
      //   inf_posts: values?.inf_posts,
      //   potential_reach: values?.potential_reach,
      //   social_comments: values?.social_comments,
      //   social_engagement_rate: values?.social_engagement_rate,
      //   social_likes: values?.social_likes,
      //   social_reach: values?.social_reach,
      //   social_video_views: values?.social_video_views,
      // }
      // console.log('payload', payload)
      // console.log('formDataPayload', formDataPayload)
      attachToken()
      // let url = state ? `/campaign/edit-report` : `/upload/create-report`
      let url = `/upload/${state ? 'edit' : 'create'}-report`

      // const res = await privateAPI.post(url, payload)
      const res = await privateAPI.post(url, formDataPayload)
      if (res?.data) {
        notification.success({
          message: res?.data?.message || '',
          duration: 3,
        })
        setloading(false)
        navigate(`/campaign/${id}?tab=report`)
      }
      setloading(false)
    } catch (error) {
      setloading(false)
      notification.error({
        message: error?.message,
        duration: 3,
      })
      console.log('err', error)
    }
  }

  const normFile = (e) => {
    console.log('Upload event:', e)
    if (Array.isArray(e)) {
      return e
    }
    return e?.fileList
  }

  const handleChange = (file) => {
    if (file) {
      file.status = 'success'
      if (file.size > 1024 * 1024 * 2) {
        notification.error({
          message: 'Image Size Error',
          description: 'Image size must be below 2mb',
          duration: 4,
        })
      } else {
        setFileState(file)
      }
    }
  }

  const props = {
    name: 'file',
    beforeUpload: () => {},
    customRequest: () => {},
    multiple: false,
    showUploadList: false,
    // onChange(info) {
    //   console.log('image on change --->',info)
    //   const { originFileObj } = info.file
    //   setFileState(originFileObj)
    // },
    // onDrop(e) {
    //   form.setFieldValue('content', e?.dataTransfer?.files?.[0])
    //   setFileState(e?.dataTransfer?.files?.[0])
    // },
  }

  return (
    <Layout active={'campagin'}>
      <div className='create-report-container'>
        <div className='main'>
          <div className='steps-div'>
            <Steps current={stepCount} labelPlacement='vertical' items={items} />
          </div>
          <div className='steps-header'>
            <h2>Step {stepCount + 1}</h2>
            <h1>
              {stepCount === 0
                ? 'Report Info'
                : stepCount === 1
                ? 'Define Keyword Sets'
                : stepCount === 2
                ? 'Define Influencer Segment'
                : stepCount === 3
                ? 'Manage Report Options'
                : 'Closure Slide'}
            </h1>
            <p>
              {stepCount === 0
                ? 'Name your report and choose the desired timeframe.'
                : stepCount === 1
                ? 'Define one or more keywords to track mentions of your brand, competitors, hashtags and more.'
                : stepCount === 2
                ? 'Select Influencers for report.'
                : // ? 'Click customize to refine the influencer segment for your report.'
                  'Choose up to 7 metrics to include in your report. Generate a shareable link to provide view-only access to this report.'}
            </p>
          </div>
          <br />
          {stepCount === 0 ? (
            <Form
              layout='vertical'
              form={form}
              onFinish={onFormInfoFinish}
              fields={
                state
                  ? [
                      {
                        name: 'name',
                        value: state?.name || '',
                      },
                      {
                        name: 'startDate',
                        value: moment(state?.startDate),
                      },
                      {
                        name: 'endDate',
                        value: moment(state?.endDate),
                      },
                    ]
                  : [
                      {
                        name: 'startDate',
                        value:
                          formData?.startDate == ''
                            ? moment(campaignData?.campaign?.startDate)
                            : moment(formData?.startDate),
                      },
                      {
                        name: 'endDate',
                        value:
                          formData?.endDate == ''
                            ? moment(campaignData?.campaign?.endDate)
                            : moment(formData?.endDate),
                      },
                    ]
              }
            >
              <Row gutter={[20, 0]}>
                <Col xs={24}>
                  <Form.Item
                    label='Report Name'
                    name={'name'}
                    rules={[
                      {
                        required: true,
                        message: 'Name is Required',
                      },
                    ]}
                    requiredMark={'optional'}
                  >
                    <Input placeholder='Name your report' />
                  </Form.Item>
                </Col>
                <Col xs={24} md={12}>
                  <Form.Item
                    label='Start Date'
                    name={'startDate'}
                    rules={[
                      {
                        required: true,
                        message: 'Start Date is Required',
                      },
                    ]}
                    requiredMark={'optional'}
                  >
                    <DatePicker
                      onChange={() => {
                        //  console.log("date", date)
                      }}
                      style={{ width: '100%', borderRadius: '0' }}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} md={12}>
                  <Form.Item
                    label='End Date'
                    name={'endDate'}
                    rules={[
                      {
                        required: true,
                        message: 'End Date is Required',
                      },
                    ]}
                    requiredMark={'optional'}
                  >
                    <DatePicker
                      onChange={() => {
                        //  console.log("date", date)
                      }}
                      style={{ width: '100%', borderRadius: '0' }}
                    />
                  </Form.Item>
                </Col>
                <Col xs={0} md={12}></Col>
                <Col xs={24} md={12} className='button-div'>
                  <Button
                    type='text'
                    size='middle'
                    style={{ padding: '0 30px' }}
                    onClick={() => navigate(-1)}
                  >
                    Cancel
                  </Button>
                  <Button
                    htmlType='submit'
                    type='default'
                    size='middle'
                    style={{ padding: '0 30px' }}
                  >
                    Next
                  </Button>
                </Col>
              </Row>
            </Form>
          ) : stepCount === 1 ? (
            <Form
              layout='vertical'
              form={form}
              onFinish={onFormKeywordsFinish}
              fields={
                state
                  ? [
                      {
                        name: 'keywords',
                        value: state?.keywords?.join(' ') || '',
                      },
                    ]
                  : [
                      {
                        name: 'keywords',
                        value:
                          formData?.keywords == ''
                            ? campaignData?.campaign?.hashTags
                            : formData?.keywords,
                      },
                    ]
              }
            >
              <Row gutter={[20, 0]}>
                <Col xs={24}>
                  <Form.Item
                    label='Keyword Sets'
                    name={'keywords'}
                    rules={[
                      {
                        required: true,
                        message: 'Keyword Sets is Required',
                      },
                    ]}
                    requiredMark={'optional'}
                  >
                    {/* <Input.TextArea rows={4} /> */}
                    <Select
                      mode='tags'
                      className='multipleSelect'
                      style={{
                        width: '100%',
                      }}
                      placeholder='Enter Campaign hashrags'
                      onChange={() => {
                        // console.log("e", e)
                      }}
                    />
                  </Form.Item>
                </Col>
                <Col xs={0} md={12}></Col>
                <Col xs={24} md={12} className='button-div'>
                  <Button
                    type='text'
                    size='middle'
                    style={{ padding: '0 30px' }}
                    onClick={() => navigate(-1)}
                  >
                    Cancel
                  </Button>
                  <Button
                    type='dashed'
                    size='middle'
                    style={{ padding: '0 30px' }}
                    onClick={() => setStepCount(0)}
                  >
                    Previous
                  </Button>
                  <Button
                    htmlType='submit'
                    type='default'
                    size='middle'
                    style={{ padding: '0 30px' }}
                  >
                    Next
                  </Button>
                </Col>
              </Row>
            </Form>
          ) : stepCount === 2 ? (
            <Form layout='vertical' form={form} onFinish={onFormInfFinish}>
              <Row gutter={[20, 0]}>
                <Col xs={24}>
                  <h2 className='influencer-selected'>
                    {/* {campaignData?.campaign?.influencers?.length} Influencers selected */}
                    {selectedInfluencer?.length} Influencers selected
                  </h2>
                  <Table
                    columns={columns}
                    dataSource={campaignData?.campaign?.influencers?.map((data) => {
                      return {
                        key: data?._id,
                        ...data,
                      }
                    })}
                    rowClassName='onHover'
                    loader={false}
                    pagination
                    rowSelection={{
                      selectedRowKeys: selectedInfluencer,
                      onChange: (selectedRowKey) => {
                        setSelectedInfluencer(selectedRowKey)
                      },
                    }}
                  />
                </Col>
                <Col xs={0} md={12}></Col>
                <Col xs={24} md={12} className='button-div'>
                  <Button
                    type='text'
                    size='middle'
                    style={{ padding: '0 30px' }}
                    onClick={() => navigate(-1)}
                  >
                    Cancel
                  </Button>
                  <Button
                    type='dashed'
                    size='middle'
                    style={{ padding: '0 30px' }}
                    onClick={() => setStepCount(1)}
                  >
                    Previous
                  </Button>
                  <Button
                    htmlType='submit'
                    type='default'
                    size='middle'
                    style={{ padding: '0 30px' }}
                  >
                    Next
                  </Button>
                </Col>
              </Row>
            </Form>
          ) : stepCount === 3 ? (
            <Form
              layout='vertical'
              labelAlign='left'
              form={form}
              onFinish={onOptFinish}
              fields={[
                {
                  name: 'engagements',
                  value: state ? state?.engagements : formData?.engagements || true,
                },
                {
                  name: 'engagement_rate',
                  value: state ? state?.engagement_rate : formData?.engagement_rate || true,
                },
                {
                  name: 'video_views',
                  value: state ? state?.video_views : formData?.video_views || true,
                },
                {
                  name: 'reach',
                  value: state ? state?.reach : formData?.reach || true,
                },
                {
                  name: 'potential_reach',
                  value: state ? state?.potential_reach : formData?.potential_reach || true,
                },
                // {
                //   name: 'impressions',
                //   value: state ? state?.impressions : formData?.impressions || false,
                // },
                {
                  name: 'estImpressions',
                  value: state ? state?.estImpressions : formData?.estImpressions || false,
                },
                {
                  name: 'inf_audience',
                  value: state ? state?.inf_audience : formData?.inf_audience || true,
                },
                {
                  name: 'inf_engagement_rate',
                  value: state ? state?.inf_engagement_rate : formData?.inf_engagement_rate || true,
                },
                {
                  name: 'inf_engagement',
                  value: state ? state?.inf_engagement : formData?.inf_engagement || true,
                },
                {
                  name: 'inf_posts',
                  value: state ? state?.inf_posts : formData?.inf_posts || true,
                },
                {
                  name: 'social_comments',
                  value: state ? state?.social_comments : formData?.social_comments || true,
                },
                {
                  name: 'social_likes',
                  value: state ? state?.social_likes : formData?.social_likes || true,
                },
                {
                  name: 'social_video_views',
                  value: state ? state?.social_video_views : formData?.social_video_views || true,
                },
                {
                  name: 'social_reach',
                  value: state ? state?.social_reach : formData?.social_reach || true,
                },
                {
                  name: 'social_engagement_rate',
                  value: state
                    ? state?.social_engagement_rate
                    : formData?.social_engagement_rate || true,
                },
                {
                  name: 'eng_without_views',
                  value: state ? state?.eng_without_views : formData?.eng_without_views || true,
                },
                {
                  name: 'eng_with_views',
                  value: state ? state?.eng_with_views : formData?.eng_with_views || true,
                },
                {
                  name: 'cpe_without_views',
                  value: state ? state?.cpe_without_views : formData?.cpe_without_views || true,
                },
                {
                  name: 'cpe_with_views',
                  value: state ? state?.cpe_with_views : formData?.cpe_with_views || true,
                },
                {
                  name: 'cost_per_reach',
                  value: state ? state?.cost_per_reach : formData?.cost_per_reach || true,
                },
                // {
                //   name: 'vit',
                //   value: state ? state?.vit : formData?.vit || false,
                // },
                // {
                //   name: 'cpm',
                //   value: state ? state?.cpm : formData?.cpm || false,
                // },
              ]}
            >
              <Row gutter={[20, 10]}>
                <Col xs={24} md={12} className='switch-style'>
                  <Form.Item name={'engagements'} valuePropName='checked'>
                    <Switch checkedChildren='On' unCheckedChildren='Off' />
                  </Form.Item>
                  <span>Engagements</span>
                </Col>
                <Col xs={24} md={12} className='switch-style'>
                  <Form.Item name={'engagement_rate'} valuePropName='checked'>
                    <Switch checkedChildren='On' unCheckedChildren='Off' />
                  </Form.Item>
                  <span>Engagement_Rate</span>
                </Col>
                <Col xs={24} md={12} className='switch-style'>
                  <Form.Item name={'video_views'} valuePropName='checked'>
                    <Switch checkedChildren='On' unCheckedChildren='Off' />
                  </Form.Item>
                  <span>Video Views</span>
                </Col>
                <Col xs={24} md={12} className='switch-style'>
                  <Form.Item name={'reach'} valuePropName='checked'>
                    <Switch checkedChildren='On' unCheckedChildren='Off' />
                  </Form.Item>
                  <span>Reach</span>
                </Col>
                <Col xs={24} md={12} className='switch-style'>
                  <Form.Item name={'potential_reach'} valuePropName='checked'>
                    <Switch checkedChildren='On' unCheckedChildren='Off' />
                  </Form.Item>
                  <span>Potential_Reach</span>
                </Col>
                {/* <Col xs={24} md={12} className='switch-style'>
                  <Form.Item name={'impressions'} valuePropName='checked'>
                    <Switch checkedChildren='On' unCheckedChildren='Off' />
                  </Form.Item>
                  <span>Impressions</span>
                </Col> */}
                <Col xs={24} md={12} className='switch-style'>
                  <Form.Item name={'estImpressions'} valuePropName='checked'>
                    <Switch checkedChildren='On' unCheckedChildren='Off' />
                  </Form.Item>
                  <span>Est Impressions</span>
                </Col>
                {/* <Col xs={24} md={12} className='switch-style'>
                  <Form.Item name={'vit'} valuePropName='checked'>
                    <Switch checkedChildren='On' unCheckedChildren='Off' />
                  </Form.Item>
                  <span>Brand Vitality Score (VIT)</span>
                </Col> */}
                {/* <Col xs={24} className='switch-style'>
                  <Form.Item name={'cpm'} valuePropName='checked'>
                    <Switch checkedChildren='On' unCheckedChildren='Off' />
                  </Form.Item>
                  <span>Cost per Metric</span>
                </Col> */}

                <Col xs={24}>
                  <p style={{ fontSize: '18px' }}>Influencers</p>
                </Col>
                <Col xs={24} md={12} className='switch-style'>
                  <Form.Item name={'inf_audience'} valuePropName='checked'>
                    <Switch checkedChildren='On' unCheckedChildren='Off' />
                  </Form.Item>
                  <span>Audience</span>
                </Col>
                <Col xs={24} md={12} className='switch-style'>
                  <Form.Item name={'inf_engagement_rate'} valuePropName='checked'>
                    <Switch checkedChildren='On' unCheckedChildren='Off' />
                  </Form.Item>
                  <span>Engagement Rate</span>
                </Col>
                <Col xs={24} md={12} className='switch-style'>
                  <Form.Item name={'inf_engagement'} valuePropName='checked'>
                    <Switch checkedChildren='On' unCheckedChildren='Off' />
                  </Form.Item>
                  <span>Engagement</span>
                </Col>
                <Col xs={24} md={12} className='switch-style'>
                  <Form.Item name={'inf_posts'} valuePropName='checked'>
                    <Switch checkedChildren='On' unCheckedChildren='Off' />
                  </Form.Item>
                  <span>Posts</span>
                </Col>

                <Col xs={24}>
                  <p style={{ fontSize: '18px' }}>Social Performance</p>
                </Col>
                <Col xs={24} md={12} className='switch-style'>
                  <Form.Item name={'social_comments'} valuePropName='checked'>
                    <Switch checkedChildren='On' unCheckedChildren='Off' />
                  </Form.Item>
                  <span>Comments</span>
                </Col>
                <Col xs={24} md={12} className='switch-style'>
                  <Form.Item name={'social_likes'} valuePropName='checked'>
                    <Switch checkedChildren='On' unCheckedChildren='Off' />
                  </Form.Item>
                  <span>Likes</span>
                </Col>
                <Col xs={24} md={12} className='switch-style'>
                  <Form.Item name={'social_video_views'} valuePropName='checked'>
                    <Switch checkedChildren='On' unCheckedChildren='Off' />
                  </Form.Item>
                  <span>Video Views</span>
                </Col>
                <Col xs={24} md={12} className='switch-style'>
                  <Form.Item name={'social_reach'} valuePropName='checked'>
                    <Switch checkedChildren='On' unCheckedChildren='Off' />
                  </Form.Item>
                  <span>Reach</span>
                </Col>
                <Col xs={24} md={12} className='switch-style'>
                  <Form.Item name={'social_engagement_rate'} valuePropName='checked'>
                    <Switch checkedChildren='On' unCheckedChildren='Off' />
                  </Form.Item>
                  <span>Engagement Rate</span>
                </Col>

                <Col xs={24}>
                  <p style={{ fontSize: '18px' }}>Efficiency</p>
                </Col>
                <Col xs={24} md={12} className='switch-style'>
                  <Form.Item name={'eng_without_views'} valuePropName='checked'>
                    <Switch checkedChildren='On' unCheckedChildren='Off' />
                  </Form.Item>
                  <span>Eng W/O Views</span>
                </Col>
                <Col xs={24} md={12} className='switch-style'>
                  <Form.Item name={'eng_with_views'} valuePropName='checked'>
                    <Switch checkedChildren='On' unCheckedChildren='Off' />
                  </Form.Item>
                  <span>Eng W/ Views</span>
                </Col>
                <Col xs={24} md={12} className='switch-style'>
                  <Form.Item name={'cpe_without_views'} valuePropName='checked'>
                    <Switch checkedChildren='On' unCheckedChildren='Off' />
                  </Form.Item>
                  <span>CPE W/ Views</span>
                </Col>
                <Col xs={24} md={12} className='switch-style'>
                  <Form.Item name={'cpe_with_views'} valuePropName='checked'>
                    <Switch checkedChildren='On' unCheckedChildren='Off' />
                  </Form.Item>
                  <span>CPE W/0 Views</span>
                </Col>
                <Col xs={24} md={12} className='switch-style'>
                  <Form.Item name={'cost_per_reach'} valuePropName='checked'>
                    <Switch checkedChildren='On' unCheckedChildren='Off' />
                  </Form.Item>
                  <span>Cost Per Reach</span>
                </Col>

                <Col xs={0} md={12}></Col>
                <Col xs={24} md={12} className='button-div'>
                  <Button
                    type='text'
                    size='middle'
                    style={{ padding: '0 30px' }}
                    onClick={() => navigate(-1)}
                  >
                    Cancel
                  </Button>
                  <Button
                    type='dashed'
                    size='middle'
                    style={{ padding: '0 30px' }}
                    onClick={() => setStepCount(2)}
                  >
                    Previous
                  </Button>
                  <Button
                    htmlType='submit'
                    type='default'
                    size='middle'
                    style={{ padding: '0 30px' }}
                    loading={loading}
                  >
                    Next
                  </Button>
                </Col>
              </Row>
            </Form>
          ) : (
            <Form
              layout='vertical'
              form={form}
              onFinish={onFinish}
              fields={[
                {
                  name: 'agencyName',
                  value: state ? state && !agencyName ? state?.agencyName : agencyName : agencyName,
                  // value: agencyName 
                },
              ]}
            >
              <Row gutter={[20, 0]}>
                <Col xs={24}>
                  <Form.Item
                    label='Agency Name'
                    name={'agencyName'}
                    rules={[
                      {
                        required: true,
                        message: 'Agency Name is Required',
                      },
                    ]}
                    requiredMark={'optional'}
                  >
                    <Input placeholder='Enter Agency Name' onChange={(e) => setAgencyName(e?.target?.value)}/>
                  </Form.Item>
                </Col>
                <Col xs={24} md={12}>
                  <Form.Item
                    label='Logo'
                    name={'logo'}
                    valuePropName='fileList'
                    getValueFromEvent={normFile}
                    // rules={[
                    //   {
                    //     required: true,
                    //     message: 'Start Date is Required',
                    //   },
                    // ]}
                    requiredMark={'optional'}
                  >
                    {fileState ? (
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'space-between',
                          // border: '1px solid var(--primary)',
                          padding: '5px',
                          // borderRadius: '10px'
                        }}
                      >
                        <img
                          src={
                            state && typeof fileState === 'string'
                              ? fileState
                              : URL.createObjectURL(fileState)
                          }
                          style={{ width: '100px' }}
                        />
                        {/* <Avatar
                          src={URL.createObjectURL(fileState)}
                          style={{ width: '60px', height: '60px' }}
                        /> */}
                        <MdDelete
                          style={{
                            color: 'var(--primary)',
                            width: '20px',
                            height: '20px',
                            cursor: 'pointer',
                          }}
                          onClick={() => setFileState(null)}
                        />
                      </div>
                    ) : (
                      // <Upload {...props}>
                      //   <Button icon={<UploadOutlined />}>Click to upload</Button>
                      // </Upload>

                      <ImgCrop
                        aspect={2 / 2}
                        // modalOk={() => {console.lo}}
                        // onModalOk={(e) => console.log("e", e)}
                        onModalOk={(e) => handleChange(e)}
                      >
                        <Upload {...props}>
                          <Button icon={<UploadOutlined />}>Click to upload</Button>
                        </Upload>
                      </ImgCrop>
                    )}
                  </Form.Item>
                </Col>

                <Col xs={24} className='button-div'>
                  <Button
                    type='text'
                    size='middle'
                    style={{ padding: '0 30px' }}
                    onClick={() => navigate(-1)}
                  >
                    Cancel
                  </Button>
                  <Button
                    type='dashed'
                    size='middle'
                    style={{ padding: '0 30px' }}
                    onClick={() => setStepCount(3)}
                  >
                    Previous
                  </Button>
                  <Button
                    htmlType='submit'
                    type='default'
                    size='middle'
                    style={{ padding: '0 30px' }}
                    loading={loading}
                  >
                    Next
                  </Button>
                </Col>
              </Row>
            </Form>
          )}
        </div>
      </div>
    </Layout>
  )
}

export default CreateCampReport
