import { useState } from "react";
import { Modal, Button, Form, Input, notification } from "antd";
// import { FaPlus } from "react-icons/fa";
import { attachToken, privateAPI } from "../../config/constants";
// import { IoAddCircleOutline } from "react-icons/io5";

const CampaginInfluencerAdd = ({ id, getAllCampaigns , button }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [form] = Form.useForm();

  const [loading, setLoading] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const onFinish = async (values) => {
    setLoading(true);
    // Influencer handle and Url Query
    let handle = values?.influencerHandle;
    let handleArray = handle?.split("");
    let handleSlashSplit = handle?.split("/");
    let influencerhandle = "";

    // Influencer handle and Url set
    if (handleArray[0] === "@") {
      influencerhandle = handleArray.slice(1, handleArray.length).join("");
    } else if (handleSlashSplit?.length > 2) {
      if (handleSlashSplit[handleSlashSplit?.length - 1] === "") {
        influencerhandle = handleSlashSplit[handleSlashSplit?.length - 2];
      } else {
        influencerhandle = handleSlashSplit[handleSlashSplit?.length - 1];
      }
    } else {
      influencerhandle = handle;
    }

    let payload = {
      influencerHandle: influencerhandle,
      campaignId: id,
    };
    try {
      attachToken();
      const res = await privateAPI.post(`/campaign/add-influencer`, payload);
      notification.success({
        description: res.data.message,
        duration: 2,
      });
      setLoading(false);
      getAllCampaigns();
      form.resetFields()
      setIsModalVisible(false);
    } catch (err) {
      notification.error({
        description: err?.response?.data?.message,
        duration: 4,
      });
      setLoading(false);
    }
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <>
    {
      button ? <Button onClick={() => showModal()}>Add Influencer</Button> : <span onClick={() => showModal()}>Add Influencer</span>
    }
      <Modal
        title={"Add Influencer"}
        visible={isModalVisible}
        footer={false}
        onCancel={handleCancel}
        width={"600px"}
      >
        <Form layout="vertical" form={form} onFinish={onFinish}>
          <Form.Item
            label="URL/Handle"
            name={"influencerHandle"}
            rules={[
              {
                required: true,
                message: "@handle is Required",
              },
            ]}
            requiredMark={"optional"}
          >
            <Input placeholder="Enter a URL of any Instagram or an @handle" />
          </Form.Item>
          <Form.Item>
            <Button htmlType="submit" loading={loading} type="primary">
              Add
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default CampaginInfluencerAdd;
