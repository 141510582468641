import { Pie } from '@ant-design/plots'
import { NumberFormatter } from '../../functions/NumberFormatter'

const DonutChart = ({ data, totalAudience, top }) => {
  const config = {
    appendPadding: 10,
    data: data ? data : [],
    height: 220,
    angleField: 'percent',
    colorField: 'name',
    color: ['#ef5a29', '#fcd145', 'rgb(59, 59, 59)'],
    radius: 1,
    innerRadius: 0.7,
    label: false,
    legend: false,
    interactions: [
      {
        type: 'element-selected',
      },
      //   {
      //     type: "element-active",
      //   },
    ],
    statistic: {
      title: false,
      content: {
        style: {
          fontSize: 16,
          whiteSpace: 'pre-wrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        },
        content: `${top?.name} ${top?.percent}%`,
      },
    },
    tooltip: {
      customContent: (title, data) => {
        return (
          <>
            <div
              style={{
                color: 'rgb(89, 89, 89)',
                backgroundColor: 'white',
                borderRadius: '6px',
                padding: '10px 6px',
              }}
            >
              <p
                style={{
                  color: '#444e5a',
                  fontSize: '14px',
                  fontWeight: 600,
                  lineHeight: '20px',
                  marginBottom: '6px',
                  gap: '6px',
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <div
                  style={{
                    background: data?.[0]?.color,
                    width: '8px',
                    height: '8px',
                    borderRadius: '50%',
                  }}
                ></div>
                {data?.[0]?.name}
              </p>

              <p
                style={{
                  fontSize: '13px',
                  fontWeight: 600,
                  lineHeight: '20px',
                  marginBottom: '4px',
                  color: '#7E84A3',
                }}
              >
                {NumberFormatter((data?.[0]?.data?.percent / 100) * totalAudience, 1)} People
              </p>
              <p
                style={{
                  fontSize: '13px',
                  fontWeight: 600,
                  lineHeight: '20px',
                  color: '#7E84A3',
                }}
              >
                {data?.[0]?.data?.percent}%
              </p>
            </div>
          </>
        )
      },
    },
  }
  return <Pie {...config} />
}

export default DonutChart
