// import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Layout } from "antd";

import { FiLogOut } from "react-icons/fi";
// import { IoAddCircleOutline } from "react-icons/io5";
// import { AiOutlineQuestionCircle, AiOutlineSetting } from "react-icons/ai";
// import Profile from "../assets/profilepic.png";

import MenuDrawer from "./MenuDrawer";
import AddInfluencer from "../components/modal/AddInfluencer";

// const { Content } = Layout;

const MobileLayout = ({ active, children }) => {
  const navigate = useNavigate();
  const { Header, Content } = Layout;
  // const [SearchShow, setSearchShow] = useState(false);

  const onLogout = () => {
    localStorage.clear();
    navigate("/login");
  };

  return (
    <Layout className="m-layout">
      <div className="main">
        <Header className="mainHeader mobileHeader">
          <MenuDrawer active={active} />
          <div className="right">
            {/* {SearchShow ? (
              <Input
                size="small"
                placeholder="Search..."
                className="search-input"
                prefix={
                  <FiSearch
                    className="search icon"
                    onClick={() => setSearchShow(false)}
                  />
                }
              />
            ) : (
              <FiSearch
                className="search icon"
                onClick={() => setSearchShow(true)}
              />
            )}
            <AiOutlineSetting className="icon" />
            <AiOutlineQuestionCircle className="icon" /> */}
            <AddInfluencer />
            {/* <img src={Profile} alt="profile" className="profile-pic" />
            <span className="name">Afaque Ahmed</span> */}
            <FiLogOut className="logout icon" onClick={onLogout} />
          </div>
        </Header>
      </div>
      <Content className="m-children">{children}</Content>
    </Layout>
  );
};

export default MobileLayout;
