import React, { useEffect, useState } from 'react'
import { Col, Row, Skeleton, Table, Avatar, Tabs, Select } from 'antd'
import { useNavigate } from 'react-router-dom'

import { CgFileDocument } from 'react-icons/cg'
import { BiMessageRounded, BiTrendingUp } from 'react-icons/bi'
import { BsPlay } from 'react-icons/bs'

// import InfinityLoader from '../../../components/InfinityLoader'
import { publicAPI } from '../../../../config/constants'
// import { ExpireDateCheck } from '../../../../functions/ExpireDateCheck'
import { NumberFormatter } from '../../../../functions/NumberFormatter'
import { getPotentialReach, calculateTotalLikes } from '../../../../functions/CalculateTotalLikes'
import { DeliverablesLength } from '../../utils/campaignUtil'
import Summary from '../../../../components/Views/Reports/Summary'
import BrandBuzzCard from '../../../../components/Cards/BrandBuzzCard'
import Influencers from '../../../../components/Views/Reports/Influencers'
import InfluencerSkeleton from '../../../../components/Skeletons/InfluencerSkeleton'
import BrandBuzzSkeleton from '../../../../components/Skeletons/BrandBuzzSkeleton'
// import OfflineProfileImage from '../../../../assets/offlineProfile.png'
// import TopPerformer from '../../../../components/Views/Reports/TopPerformer'

const ReportSummary = ({ id, activeTab, filterContentState, setFilterContentState }) => {
  const navigate = useNavigate()
  const { TabPane } = Tabs
  const { Option } = Select
  const [campaignOptionData, setCampaignOptionData] = useState(null)
  const [campaignContentData, setCampaignContentData] = useState(null)
  const [campaignTopData, setCampaignTopData] = useState(null)
  const [campaignReportInfluencers, setCampaignReportInfluencers] = useState([])
  const [activeTabState, setActiveTabState] = useState('influncers')
  const [data, setData] = useState([])
  // const [campaignTopPerformerData, setCampaignTopPerformerData] = useState(null)
  let multiProfilesLikeCount = 0
  campaignReportInfluencers?.influencers?.forEach((profile) => {
    const potentialReach = getPotentialReach(profile.influencer)
    const totalLikes = calculateTotalLikes(potentialReach)
    multiProfilesLikeCount += totalLikes
  })

  const getCampaignSummaryOptionReport = async () => {
    if (id) {
      try {
        // attachToken()
        const res = await publicAPI.post(`/campaign/get-campaign-summary-option-report/${id}`)
        if (res?.data) {
          setCampaignOptionData(res?.data)
        }
      } catch (error) {
        console.log('err', error)
      }
    }
  }
  const getCampaignSummaryContentReport = async () => {
    if (id) {
      try {
        // attachToken()
        const res = await publicAPI.post(`/campaign/get-campaign-summary-content-report/${id}`, {
          filter: filterContentState,
        })
        if (res?.data) {
          setCampaignContentData(res?.data)
        }
      } catch (error) {
        console.log('err', error)
      }
    }
  }

  const getCampaignSummaryTopInfluencerReport = async () => {
    if (id) {
      try {
        // attachToken()
        const res = await publicAPI.post(`/campaign/get-campaign-summary-topInf-report/${id}`)
        if (res?.data) {
          setCampaignTopData(res?.data)
        }
      } catch (error) {
        console.log('err', error)
      }
    }
  }

  const getCampaignReportInfluencers = async () => {
    if (id) {
      try {
        // attachToken()
        const res = await publicAPI.get(`/campaign/get-campaign-report-influencers/${id}`)
        if (res?.data) {
          setCampaignReportInfluencers(res?.data)
        }
      } catch (err) {
        console.log('err', err)
      }
    }
  }
  // const getCampaignSummaryTopPerformersReport = async () => {
  //   if (id) {
  //     try {
  //       attachToken()
  //       const res = await privateAPI.post(
  //         `/campaign/get-campaign-summary-top-performer-report/${id}`,
  //       )
  //       if (res?.data) {
  //         setCampaignTopPerformerData(res?.data)
  //       }
  //     } catch (error) {
  //       console.log('err', error)
  //     }
  //   }
  // }

  let campaignInfColumns = [
    {
      title: 'NAME',
      dataIndex: 'name',
    },
  ]

  if (campaignReportInfluencers?.audienceEnabled === true) {
    campaignInfColumns.push({
      title: 'AUDIENCE',
      dataIndex: 'audience',
    })
  }

  if (campaignReportInfluencers?.engRateEnabled === true) {
    campaignInfColumns.push({
      title: 'ENG RATE',
      dataIndex: 'rate',
    })
  }

  if (campaignReportInfluencers?.postsEnabled === true) {
    campaignInfColumns.push({
      title: 'POSTS',
      dataIndex: 'posts',
    })
  }

  if (campaignReportInfluencers?.engEnabled === true) {
    campaignInfColumns.push({
      title: 'ENGAGEMENTS',
      dataIndex: 'engagements',
    })
  }

  campaignInfColumns.push({
    title: 'VIDEO VIEWS',
    dataIndex: 'videoViews',
  })

  campaignInfColumns.push({
    title: 'REACH',
    dataIndex: 'reach',
  })

  useEffect(() => {
    let tempArr = []
    const campaignReprtInfsTemp = campaignReportInfluencers?.influencers
    campaignReprtInfsTemp?.sort((a, b) => {
      if (a?.influencer?.name?.toLowerCase() > b?.influencer?.name?.toLowerCase()) {
        return 1
      }
      if (a?.influencer?.name?.toLowerCase() < b?.influencer?.name?.toLowerCase()) {
        return -1
      }
      return 0
    })
    campaignReprtInfsTemp?.map((i, index) => {
      tempArr.push({
        key: index,
        campaignId: i?._id,
        deliverables: i?.deliverables,
        name: (
          <div
            style={{
              zIndex: '10',
              display: 'flex',
              alignItems: 'center',
              gap: '8px',
              cursor: 'pointer',
            }}
            onClick={() => navigate(`/influencer/${i?.influencer?._id}`)}
          >
            <Avatar
              src={i?.influencer?.arch_profile_picture_url || i?.influencer?.profile_picture_url}
              // src={
              //   ExpireDateCheck(i?.influencer?.dataRefreshed) === 10
              //     ? i?.influencer?.arch_profile_picture_url
              //       ? i?.influencer?.arch_profile_picture_url
              //       : OfflineProfileImage
              //     : ExpireDateCheck(i?.influencer?.dataRefreshed) === 2
              //     ? i?.influencer?.profile_picture_url
              //     : OfflineProfileImage
              // }
              alt='profile'
            />
            <span className='link'>{i?.influencer?.name}</span>
          </div>
        ),
        audience: NumberFormatter(i?.influencer?.audience, 1),
        rate: i?.influencer?.instagram?.engagememtRate
          ? `${Number(i?.influencer?.instagram?.engagememtRate)?.toFixed(2)}%`
          : '--',
        posts: `${i?.posts}/${DeliverablesLength(i?.deliverables)}`,
        value: `PKR${NumberFormatter(i?.value, 1)}`,
        engagements: (
          <div style={{ display: 'flex', alignItems: 'center', gap: '6px' }}>
            <span>{i?.engagement ? NumberFormatter(i?.engagement, 1) : '0'}</span>
          </div>
        ),
        videoViews: (
          <div style={{ display: 'flex', alignItems: 'center', gap: '6px' }}>
            <span>{i?.videoView ? NumberFormatter(i?.videoView, 1) : '0'}</span>
          </div>
        ),
        reach: (
          <div style={{ display: 'flex', alignItems: 'center', gap: '6px' }}>
            <span>{i?.reach ? NumberFormatter(i?.reach, 1) : '0'}</span>
          </div>
        ),
      })
    })
    setData(tempArr)
  }, [campaignReportInfluencers])

  useEffect(() => {
    getCampaignSummaryOptionReport()
    // getCampaignSummaryContentReport()
    getCampaignSummaryTopInfluencerReport()
    getCampaignReportInfluencers()
    // getCampaignSummaryTopPerformersReport()
  }, [activeTab, id])

  const onTabsChange = (e) => {
    setActiveTabState(e)
    console.log(e)
    if (e == 'posts') {
      getCampaignSummaryContentReport()
    }
  }

  useEffect(() => {
    getCampaignSummaryContentReport()
  }, [filterContentState])

  const filterContentChange = (value) => {
    setFilterContentState(value)
  }

  return (
    <div>
      {campaignOptionData ? (
        <Summary
          visible={{
            inf: {
              enabled: true,
              value: campaignOptionData?.influencers?.length,
              // value: campaignOptionData?.activeInfluencers,
            },
            posts: {
              enabled: true,
              value: campaignOptionData?.posts,
            },
            engagements: {
              enabled: campaignOptionData?.engagements?.enabled,
              value: campaignOptionData?.engagements?.value,
            },
            video_views: {
              enabled: campaignOptionData?.video_views?.enabled,
              value: campaignOptionData?.video_views?.value,
            },
            reach: {
              enabled: campaignOptionData?.reach?.enabled,
              value: campaignOptionData?.reach?.value,
            },
            impressions: {
              enabled: campaignOptionData?.impressions?.enabled,
              value: campaignOptionData?.impressions?.value,
            },
            estImpressions: {
              enabled: campaignOptionData?.estImpressions?.enabled,
              value: campaignOptionData?.estImpressions?.value,
            },
            potReach: {
              enabled: campaignOptionData?.potReach?.enabled,
              value: multiProfilesLikeCount,
            },
            engRate: {
              enabled: true,
              value: campaignOptionData?.engRate?.value.toFixed(2),
            },
            // vit:true,
          }}
        />
      ) : (
        <div style={{ backgroundColor: 'white', borderRadius: '8px', padding: '20px 20px' }}>
          <Skeleton active paragraph={{ rows: 1 }} />
        </div>
      )}
      <br />

      {campaignTopData ? (
        <Row gutter={[20, 20]}>
          <Col xl={6} lg={12} md={12} sm={24} xs={24}>
            <Influencers
              title='MOST ACTIVE INFLUENCERS'
              icon={<CgFileDocument className='icon' />}
              data={campaignTopData?.TopActiveInfluencers}
              value={'posts'}
            />
          </Col>
          <Col xl={6} lg={12} md={12} sm={24} xs={24}>
            <Influencers
              title='MOST ENGAGING INFLUENCERS'
              icon={<BiMessageRounded className='icon' />}
              data={campaignTopData?.TopEngagingInfluencers}
              value={'engagement'}
            />
          </Col>
          <Col xl={6} lg={12} md={12} sm={24} xs={24}>
            <Influencers
              title='HIGHEST ENG RATE'
              icon={<BiTrendingUp className='icon' />}
              data={campaignTopData?.TopEngRateInfluencers}
              value={'engRate'}
            />
          </Col>
          <Col xl={6} lg={12} md={12} sm={24} xs={24}>
            <Influencers
              title='MOST VIDEO VIEWS'
              icon={<BsPlay className='icon' />}
              data={campaignTopData?.TopVideoViewsInfluencers}
              value={'videoView'}
            />
          </Col>
        </Row>
      ) : (
        <Row gutter={[20, 20]}>
          <Col xl={6} lg={12} md={12} sm={24} xs={24}>
            <InfluencerSkeleton />
          </Col>
          <Col xl={6} lg={12} md={12} sm={24} xs={24}>
            <InfluencerSkeleton />
          </Col>
          <Col xl={6} lg={12} md={12} sm={24} xs={24}>
            <InfluencerSkeleton />
          </Col>
          <Col xl={6} lg={12} md={12} sm={24} xs={24}>
            <InfluencerSkeleton />
          </Col>
        </Row>
      )}

      {/* {campaignTopPerformerData ? (
        <TopPerformer data={campaignTopPerformerData} />
      ) : (
        <div
          style={{
            backgroundColor: 'white',
            borderRadius: '8px',
            padding: '20px 20px',
            marginTop: '1rem',
          }}
        >
          <Skeleton active paragraph={{ rows: 4 }} />
        </div>
      )} */}
      {/* <br /> */}

      {/* <div style={{ marginTop: '1rem' }}>
        <Table
          className='data-table'
          columns={campaignInfColumns}
          dataSource={data}
          scroll={{ x: 'auto' }}
          // rowSelection={{
          //   selectedRowKeys: selectedInfluencer,
          //   onChange: (selected) => setSelectedInfluencer(selected),
          // }}
          pagination={{
            pageSize: 10,
          }}
        />
      </div> */}

      <Tabs
        defaultActiveKey={activeTabState}
        onChange={onTabsChange}
        className='tabs-container'
        size='large'
      >
        <TabPane tab='INFLUENCERS' key='influencers'>
          <Table
            className='data-table'
            columns={campaignInfColumns}
            dataSource={data}
            scroll={{ x: 'auto' }}
            pagination={{
              pageSize: 10,
            }}
          />
        </TabPane>
        <TabPane tab='POSTS' key='posts'>
          {campaignContentData ? (
            <Row gutter={[20, 20]}>
              <Col xs={24}>
                <div className='filter-bar'>
                  <div className='left-side'>
                    <span>Top Posts</span>
                    <Select
                      placeholder='Limit Report Content'
                      style={{ minWidth: 130 }}
                      onChange={filterContentChange}
                      size='large'
                    >
                      <Option value='all'>All</Option>
                      <Option value='videoViews'>Top 5 Posts (Video Views)</Option>
                      <Option value='engagement'>Top 5 Posts (Engagement)</Option>
                    </Select>
                  </div>
                </div>
                {/* <br /> */}
              </Col>
              {campaignContentData?.length > 0 ? (
                campaignContentData?.map((post, index) => (
                  <Col key={index} xs={24} lg={12} xl={8} xxl={6}>
                    <BrandBuzzCard
                      followers={post?.media?.instagram?.followers_count}
                      media={post?.media}
                      name={post?.media?.instagram?.name}
                      profilePic={post?.media?.instagram?.profile_picture_url}
                      influencerId={post?.media?.instagram?.influencer}
                      inf={post?.media?.influencer}
                    />
                  </Col>
                ))
              ) : (
                <div className='no-found '>
                  <span>No Content Found.</span>
                </div>
              )}
            </Row>
          ) : (
            <Row gutter={[20, 20]}>
              <Col xs={24} lg={12} xl={8} xxl={6}>
                <BrandBuzzSkeleton />
              </Col>
              <Col xs={24} lg={12} xl={8} xxl={6}>
                <BrandBuzzSkeleton />
              </Col>
              <Col xs={24} lg={12} xl={8} xxl={6}>
                <BrandBuzzSkeleton />
              </Col>
              <Col xs={24} lg={12} xl={8} xxl={6}>
                <BrandBuzzSkeleton />
              </Col>
            </Row>
          )}
        </TabPane>
      </Tabs>
    </div>
  )
}

export default ReportSummary
