import React from 'react'
import { Progress, Tooltip } from 'antd'
import { NumberFormatter } from '../../../functions/NumberFormatter'

function PerformersActivated({ title, data, value }) {

  const tooltipText = (keyword, title,value, total) => (
    <div className='performer-tooltip'>
      <p className='keyword'>{keyword || '#'}</p>
      <div className='detail'>
        <p className='title'>{title}</p>
        <p className='title'>{value ? NumberFormatter(value,2) : '0'}</p>
        <p className='title'>{total === 0 ? '' : `| ${Number(((value/total) * 100).toFixed(0))}%`} </p>
      </div>
    </div>
  )

  return (
    <div className='performersActivated_container'>
      <h3>{title}</h3>
      {data?.map((i, ind) => (
        <div key={ind} className='info'>
          <p className='info_name'>{i?.keyword}</p>
          <div className='progress-div'>
            <Tooltip title={tooltipText(i?.keyword, title,i?.[value]?.value,i?.[value]?.total)} placement={'right'}>
              <div
                className='progress-bar'
                style={{
                  width:
                    i?.[value]?.total === 0
                      ? '10%'
                      : Number(((i?.[value]?.value / i?.[value]?.total) * 100).toFixed(0)) > 10
                      ? `${Number(((i?.[value]?.value / i?.[value]?.total) * 100).toFixed(0))}%`
                      : '15%',
                }}
              >
                <Progress percent={'100'} strokeColor={'#ef5b29bd'} showInfo={false} />
              </div>
            </Tooltip>
            <p className='count'>
              {i?.[value]?.value ? NumberFormatter(i?.[value]?.value, 1) : '0'}
              {value === 'engRate' ? '%' : ''}
            </p>
          </div>
        </div>
      ))}
    </div>
  )
}

export default PerformersActivated
