import { useEffect, useState } from "react";

import {
  MdKeyboardArrowRight,
  MdOutlineKeyboardArrowLeft,
} from "react-icons/md";

import { FaUserAlt } from "react-icons/fa";

import { NumberFormatter } from "../../../functions/NumberFormatter";
import { Countries } from "../../Shared/Countries";
import InfinityLoader from "../../InfinityLoader";

function Country({ data }) {
  const [dataShow, setDataShow] = useState([]);
  const [totalAudience, setTotalAudience] = useState(0);
  const [firstIndex, setFirstIndex] = useState(0);
  const [lastIndex, setLastIndex] = useState(5);
  const [nextdisbaled, setNextdisbaled] = useState(false);

  
  const getArrangedData = () => {
    let ArrShow = [];
    data?.sort((a, b) => a.percent - b.percent).reverse();
    data?.slice(firstIndex, lastIndex).map((country) => {
      let ind;
      if(country?.country){
        ind = Countries.findIndex((cou) => cou?.code === country?.country);
      }else{
        ind = Countries.findIndex((cou) => cou?.name === country?.name);
      }
      if(ind !== -1){
        ArrShow.push({
          name: Countries?.[ind]?.name,
          flag: Countries?.[ind]?.image,
          users: country?.users || null,
          percent: country?.percent || null,
          value: country?.value || null,
        });
      }
      
    });
    setDataShow(ArrShow);
    let totalUsers = 0;
    data?.map((users) => {
      totalUsers += users.users;
    });
    setTotalAudience(totalUsers);
  };

  useEffect(() => {
    getArrangedData();
  }, [data, lastIndex, firstIndex]);

  return (
    <div className="country-div">
      {dataShow ? (
        <>
          <div className="header">
            <h3>Country</h3>
            <div>
              <MdOutlineKeyboardArrowLeft
                className={`${firstIndex === 0 ? "disable" : "enable"} icon`}
                onClick={() => {
                  if (firstIndex > 0) {
                    setFirstIndex(firstIndex - 5);
                    setLastIndex(lastIndex - 5);
                    setNextdisbaled(false);
                  }
                }}
              />
              <MdKeyboardArrowRight
                className={`${nextdisbaled ? "disable" : "enable"} icon`}
                onClick={() => {
                  if (data?.length > lastIndex) {
                    setFirstIndex(firstIndex + 5);
                    setLastIndex(lastIndex + 5);
                  } else {
                    setNextdisbaled(true);
                  }
                }}
              />
            </div>
          </div>

          {dataShow?.map((country,ind) => (
            <div key={ind} className="flag-div">
              <div>
                <img src={country?.flag} alt="pak" />
                <p className="name">{country?.name}</p>
              </div>
              <div className="values">
                {country?.users ? <p className="users">
                  {NumberFormatter(country?.users)}{" "}
                  <FaUserAlt className="user-icon" />{" "}
                </p> : <p className="users">{country?.value}</p>}
                
                {country?.users ?<p>{`${((country?.users / totalAudience) * 100).toFixed(
                  2
                )}%`}</p> : <p>{String(country?.percent)?.replace('-','<')}%</p> }
              </div>
            </div>
          ))}
        </>
      ) : (
        <InfinityLoader height={"400px"} />
      )}
    </div>
  );
}

export default Country;
