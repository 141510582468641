import { useState } from 'react'
import { Modal, Button, Form, Input, Select, notification } from 'antd'
import { useSelector } from 'react-redux'

import { FaPlus } from 'react-icons/fa'
import { FiUsers } from 'react-icons/fi'
import { AiOutlineRise } from 'react-icons/ai'

// import { getAllInfluencersForCampaign } from '../../redux'
import { attachToken, privateAPI } from '../../config/constants'
// import { ExpireDateCheck } from '../../functions/ExpireDateCheck'
// import OfflineProfileImage from '../../assets/offlineProfile.png'
import { NumberFormatter } from '../../functions/NumberFormatter'

const AddGroup = ({ getAllGroupsData, edit, influencers }) => {
  const { Option } = Select
  // const dispatch = useDispatch()
  const [form] = Form.useForm()
  const [isModalVisible, setIsModalVisible] = useState(false)
  // const [influencers, setInfluencers] = useState(null)
  const selectedBrand = useSelector((state) => state?.brand?.selectedBrand)
  const [selectInfluencers, setSelectInfluencers] = useState(null)

  const [loading, setLoading] = useState(false)

  // const getInfluencers = async () => {
  //   try {
  //     let payload = {
  //       searchString: '',
  //     }
  //     attachToken()
  //     const res = await dispatch(getAllInfluencersForCampaign(payload))
  //     if (res) {
  //       setInfluencers(res)
  //     }
  //   } catch (error) {
  //     console.log('error', error)
  //   }
  // }

  // useEffect(() => {
  //   getInfluencers()
  // }, [])

  const showModal = () => {
    setIsModalVisible(true)
  }

  const onFinish = async (values) => {
    setLoading(true)
    let payload = {
      name: values?.name,
      description: values?.description,
      influencers: JSON.stringify(selectInfluencers || []),
      brand: selectedBrand,
      id: edit ? edit?._id : '',
    }
    try {
      attachToken()
      const res = await privateAPI.post(`/group/${edit ? 'update' : 'create'}`, payload)
      if (res?.data) {
        notification.success({
          message: res?.data?.message,
          duration: 3,
        })
        form.resetFields()
        getAllGroupsData()
        setLoading(false)
        setIsModalVisible(false)
      }
    } catch (err) {
      notification.error({
        description: err?.response?.data?.message,
        duration: 4,
      })
      setLoading(false)
    }
  }

  const handleCancel = () => {
    setIsModalVisible(false)
  }

  return (
    <>
      {edit ? (
        <span onClick={() => showModal()}>Edit</span>
      ) : (
        <FaPlus className='add-icon' onClick={() => showModal()} />
      )}
      <Modal
        title={'Create Group'}
        visible={isModalVisible}
        footer={false}
        onCancel={handleCancel}
        width={'600px'}
      >
        <Form
          layout='vertical'
          form={form}
          fields={
            edit
              ? [
                  {
                    name: 'name',
                    value: edit?.name,
                  },
                  {
                    name: 'description',
                    value: edit.description,
                  },
                  {
                    name: 'influencers',
                    value: edit.influencers,
                  },
                ]
              : null
          }
          onFinish={onFinish}
        >
          <Form.Item
            label='Name'
            name={'name'}
            rules={[
              {
                required: true,
                message: 'Name is Required',
              },
            ]}
            requiredMark={'optional'}
          >
            <Input placeholder='Creater Name' />
          </Form.Item>
          <Form.Item
            label='Description'
            name={'description'}
            rules={[
              {
                required: true,
                message: 'Description is Required',
              },
            ]}
            requiredMark={'optional'}
          >
            <Input.TextArea
              rows={2}
              style={{ resize: 'none' }}
              placeholder='Short description for this group'
            />
          </Form.Item>

          <Form.Item
            label='Influencers'
            name={'influencers'}
            rules={[
              {
                required: true,
                message: 'Influencers is Required',
              },
            ]}
            requiredMark={'optional'}
          >
            <Select
              showSearch
              mode='multiple'
              optionFilterProp='children'
              style={{ width: '100%' }}
              onChange={(e) => {
                setSelectInfluencers(e)
              }}
              filterOption={(input, option) =>
                (option?.name ?? '').toLowerCase().includes(input.toLowerCase())
              }
              size='large'
              placeholder='Select Influencers'
            >
              {influencers?.map((inf, ind) => (
                <Option key={ind} value={inf?._id} name={inf?.name}>
                  <div className='option'>
                    <img
                      src={inf?.arch_profile_picture_url || inf?.profile_picture_url}
                      // src={
                      //   ExpireDateCheck(inf?.dataRefreshed) === 10
                      //     ? inf?.arch_profile_picture_url
                      //       ? inf?.arch_profile_picture_url
                      //       : OfflineProfileImage
                      //     : ExpireDateCheck(inf?.dataRefreshed) === 2
                      //     ? inf?.profile_picture_url
                      //     : OfflineProfileImage
                      // }
                      alt='profile_picture_url'
                      className='option-image'
                    />
                    <span>{inf?.name}</span>
                    <span style={{ display: 'flex', alignItems: 'center', columnGap: '2px' }}>
                      ({NumberFormatter(inf?.audience)}
                      <FiUsers />)
                    </span>
                    <span style={{ display: 'flex', alignItems: 'center', columnGap: '2px' }}>
                      ({inf?.instagram?.engagememtRate ? inf?.instagram?.engagememtRate : 0}%
                      <AiOutlineRise />)
                    </span>
                  </div>
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item>
            <Button htmlType='submit' loading={loading} type='primary'>
              {edit ? 'Update' : 'Create'}
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </>
  )
}

export default AddGroup
