import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Col, Row, Tooltip, Menu, Dropdown, Select } from 'antd'
import { useSelector } from 'react-redux'

import { FaTiktok, FaInstagram, FaYoutube, FaRegFolder, FaRegEye } from 'react-icons/fa'

import { AiOutlineCheckCircle, AiOutlineExclamationCircle, AiOutlineRise } from 'react-icons/ai'
import { BsArrowsAngleExpand, BsThreeDots, BsPlay } from 'react-icons/bs'
// import { TiArrowRepeat } from 'react-icons/ti'
import { RiUserStarLine } from 'react-icons/ri'

import { privateAPI } from '../../config/constants'
import { NumberFormatter } from '../../functions/NumberFormatter'
import { getPotentialReach, calculateTotalLikes } from '../../functions/CalculateTotalLikes'
import { AudienceCalculator } from '../../functions/AudienceCalculation'
import InstaAuthLinkModal from '../modal/InstaAuthLinkModal'
// import OfflineProfileImage from '../../assets/offlineProfile.png'
// import { ExpireDateCheck } from '../../functions/ExpireDateCheck'

const NetworkRowCard = ({ data, infsViews }) => {
  const { Option } = Select
  const navigate = useNavigate()
  const influencerCategories = useSelector((state) => state?.network?.categories)
  const [infCategoriesState, setInfCategoriesState] = useState([])
  const [selectedCategories, setSelectedCategories] = useState([])


  let infsAvgViews = infsViews?.filter((item) => item?.id === data?._id)?.[0]?.avgViews

  const updateInfCategories = async (categories) => {
    try {
      const res = await privateAPI.post('/influencer/update-influencer-category', {
        id: data?._id,
        categories,
      })

      if (res) {
        setInfCategoriesState(res?.data?.data?.category)
      }
    } catch (err) {
      console.log(err)
    }
  }

  useEffect(() => {
    let catIds = []
    data?.category?.map((cat) => {
      catIds?.push(cat?._id)
    })
    setSelectedCategories(catIds)
    setInfCategoriesState(data?.category)
  }, [])

  const handleChangeCategories = (e) => {
    setSelectedCategories(e)

    updateInfCategories(e)
  }

  const menu = (
    <Menu>
      <Menu.Item>Email Send</Menu.Item>
      <Menu.Item>
        <InstaAuthLinkModal id={data?._id} />
      </Menu.Item>
    </Menu>
  )
  const potentialReach = getPotentialReach(data);
  const totalLikes = calculateTotalLikes(potentialReach);
  return (
    <div>
      <Row className='network-row-card'>
        <Col xs={24} md={7} lg={6} xl={5} xxl={4} className='left-side'>
          <div className='image-div'>
            <div className='options'>
              {/* <RiUserSettingsLine className="icon" /> */}
              <Dropdown overlay={menu} trigger={['click']} placement={'bottomRight'}>
                <BsThreeDots className='icon' />
              </Dropdown>
              <BsArrowsAngleExpand
                className='icon'
                onClick={() => navigate(`/influencer/${data?._id}?tab=content`)}
              />
            </div>
            <img
              className='profile'
              // src={ExpireDateCheck(data?.dataRefreshed) === 10 ? data?.arch_profile_picture_url ? data?.arch_profile_picture_url : OfflineProfileImage : ExpireDateCheck(data?.dataRefreshed) === 2 ? data?.profile_picture_url : OfflineProfileImage}
              src={data?.arch_profile_picture_url || data?.profile_picture_url}
              onClick={() => navigate(`/influencer/${data?._id}?tab=content`)}
            />
            <div className='aligned-div'>
              {data?.valuesMRS === 'Aligned' ? (
                <Tooltip placement='right' title={`Aligned`} className='tooltip'>
                  <AiOutlineCheckCircle className='icon aligned' />
                </Tooltip>
              ) : data?.valuesMRS === 'Not Aligned' ? (
                <Tooltip placement='right' title={`Not Aligned`} className='tooltip'>
                  <AiOutlineExclamationCircle className='icon notaligned' />
                </Tooltip>
              ) : null}
            </div>
          </div>
        </Col>
        <Col xs={24} md={11} lg={12} xl={13} xxl={16} className='middle'>
          <div className='info'>
            <div className='social'>
              <FaTiktok className='icon disable' />
              <FaYoutube className='icon disable' />

              <Tooltip
                placement='right'
                title={`https://www.instagram.com/${data?.instagram?.username}`}
              >
                <FaInstagram
                  className='icon insta'
                  onClick={() => {
                    window.open(`https://www.instagram.com/${data?.instagram?.username}`, '_blank')
                  }}
                />
              </Tooltip>
            </div>
            <div style={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}>
              <h2 className='name'>{data?.name}</h2>
              <p style={{ fontSize: '13px', display: 'flex', alignItems: 'center' }}>
                (@{data?.instagram_username || data?.instagram?.username})
              </p>
            </div>
            <p className='desc'>{data?.biography}</p>
            {/* <div className="address">
              <MdOutlineLocationOn className="icon" />
              <span>Karachi, Pakistan.</span>
            </div> */}
            {/* {moment(data?.updatedAt).format("DD-MM hh:mm:ss a")} */}
            <div className='categories'>
              {/* {data?.category?.map((cat, ind) => (
                <span key={ind}>{cat?.name}</span>
              ))} */}
              {infCategoriesState?.map((cat, ind) => (
                <span key={ind}>{cat?.name}</span>
              ))}
            </div>
            {data?.campaigns?.length > 0 &&
              data?.campaigns?.slice(0, 2)?.map((camp, ind) => (
                <div
                  key={ind}
                  className='campaign'
                  onClick={() => navigate(`/campaign/${camp?._id}`)}
                >
                  <FaRegFolder className='icon' />
                  <span> {camp?.name} </span>
                </div>
              ))}
          </div>
          <h2 className='reach'>{AudienceCalculator(data?.audience)}</h2>
        </Col>

        <Col xs={24} md={6} lg={6} xl={6} xxl={4} className='right-side'>
          <div>
            <div className='list'>
              <FaRegEye className='icon' />
              <span>Audience</span>
              <span className='count'>{NumberFormatter(data?.audience, 2)}</span>
            </div>
            <div className='list'>
              <RiUserStarLine className='icon' />
              <span>Potential Reached</span>
              <span className='count'>{NumberFormatter((totalLikes/6), 1)}</span>
            </div>
            <div className='list'>
              <AiOutlineRise className='icon' />
              <span>Engagement Rate</span>
              {/* <span className='count'>{NumberFormatter(data?.instagram?.engagememtRate, 2)}</span> */}
              <span className='count'>{data?.instagram?.engagememtRate} %</span>
            </div>
            {/* <div className='list'>
              <FaWifi className='icon' />
              <span>Reach</span>
              <span className='count'>{NumberFormatter(data?.reach, 1)}%</span>
            </div> */}
            <div className='list'>
              <BsPlay className='icon' />
              <span>Avg Views</span>
              <span className='count'>{NumberFormatter(infsAvgViews, 1)}</span>
            </div>
            {/* <div className='list'>
              <TiArrowRepeat className='icon' />
              <span>Resonance</span>
              <span className='count'>{NumberFormatter(data?.resonance, 1)}%</span>
            </div> */}
          </div>

          <div style={{display: 'flex', flexDirection: 'column', width: '100%', rowGap: '0.2rem', marginBottom: '1rem'}}>
            <p style={{paddingLeft: '5px'}}>Categories</p>
            <Select
              mode='tags'
              style={{
                width: '100%',
                paddingRight: '2rem',
                // marginTop: '1rem',
              }}
              placeholder='Select Categories'
              maxTagCount={1}
              maxTagTextLength={12}
              value={selectedCategories}
              onChange={handleChangeCategories}
            >
              {influencerCategories?.map((cat) => (
                <Option key={cat?._id} value={cat?._id}>
                  {cat?.name}
                </Option>
              ))}
            </Select>
          </div>
        </Col>
      </Row>
    </div>
  )
}

export default NetworkRowCard
