import { combineReducers } from "redux";

import theme from "./reducers/themeReducer";
import network from "./reducers/networkReducer";
import brand from "./reducers/brandReducer";

const rootReducer = combineReducers({
  theme,
  network,
  brand,
});

export default rootReducer;
