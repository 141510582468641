import React, { useState } from 'react'
import { Row, Col, Pagination, Input, Form, Button, notification } from 'antd'

import { FiSearch } from 'react-icons/fi'

import { privateAPI } from '../../../../config/constants'
import InfinityLoader from '../../../../components/InfinityLoader'
import InfluencerCard from '../../../../components/Cards/InfluencerCard'

const MinMaxComp = ({
  state,
  apiLoading,
  setApiLoading,
  minFollower,
  setMinFollower,
  maxFollower,
  setMaxFollower,
  influncersByFollowers,
  setInfluncersByFollowers,
  overAllSelected,
  setOverAllSelected,
  overAllSelectedObj,
  setOverAllSelectedObj,
}) => {
  const [searchInfByFollowers, setSearchInfByFollowers] = useState('')
  const [page, setPage] = useState(1)

  const getInfluencers = async () => {
    setApiLoading(true)
    let payload = {
      min: Number(minFollower),
      max: Number(maxFollower),
      searchString: searchInfByFollowers,
    }
    if (minFollower !== 0 && maxFollower !== 0) {
      const res = await privateAPI.post(`/campaign/get-by-followers`, payload)
      if (res) {
        let idsArr = []
        res?.data?.map((item) => {
          idsArr?.push(item?._id)
        })
        setApiLoading(false)
        setInfluncersByFollowers(res?.data)
      }
    } else {
      setApiLoading(false)
      notification.error({
        message: 'Enter Min and Max Followers Field',
        duration: 3,
      })
    }
  }

  return (
    <>
      <Col xs={24} md={12} lg={8}>
        <Form.Item
          label='Minimun Follower'
          name={'min'}
          // rules={[
          //   {
          //     required: true,
          //     message: 'Minimun Follower is Required',
          //   },
          // ]}
          // requiredMark={'optional'}
        >
          <Input
            value={minFollower}
            onChange={(e) => setMinFollower(e.target.value)}
            type={'number'}
            placeholder='10k'
          />
        </Form.Item>
      </Col>

      <Col xs={24} md={12} lg={8}>
        <Form.Item
          label='Maximum Follower'
          name={'max'}
          // rules={[
          //   {
          //     required: true,
          //     message: 'Maximum Follower is Required',
          //   },
          // ]}
          // requiredMark={'optional'}
        >
          <Input
            value={maxFollower}
            onChange={(e) => setMaxFollower(e.target.value)}
            type={'number'}
            placeholder='50k'
          />
        </Form.Item>
      </Col>

      <Col xs={24} md={12} lg={8}>
        <Form.Item label=' '>
          <Row gutter={20}>
            <Col span={8}>
              <Button type='primary' style={{ height: '36px' }} onClick={() => getInfluencers()}>
                Search
              </Button>
            </Col>
            {/* <Col span={16}>
                          <Form.Item noStyle>
                            <Input
                              value={influncersByFollowers?.length}
                              onChange={() => {}}
                              disabled
                            />
                          </Form.Item>
                        </Col> */}
          </Row>
        </Form.Item>
      </Col>

      <Col xs={24}>
        <div className='filter-bar'>
          <div className='left-side'>
            <Input
              placeholder='Search by name'
              prefix={<FiSearch className='search-icon' />}
              className='searchInput'
              size='small'
              value={searchInfByFollowers}
              onChange={(e) => setSearchInfByFollowers(e.target.value)}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  e.preventDefault()
                  getInfluencers()
                  setPage(1)
                }
              }}
            />
          </div>
          <div className='right-side' style={{ flexWrap: 'wrap' }}>
            {/* <Button
                  onClick={() => {
                    let tempArr = []
                    influncersByFollowers?.map((item) => tempArr.push(item?._id))
                    setOverAllSelected([...overAllSelected,...tempArr])
                    setOverAllSelectedObj([...overAllSelectedObj,...influncersByFollowers])
                  }}
                  disabled={
                    selectedInfByFollowers?.length === influncersByFollowers?.length ? true : false
                  }
                >
                  Select All
                </Button> */}
            {/* <Button
                  disabled={selectedInfByFollowers?.length === 0 ? true : false}
                  // onClick={() => {
                  //   setSelectedInfByFollowers([])
                  //   setSelectedMinMaxInfluencers([])
                  // }}
                >
                  Clear All
                </Button> */}
            <Pagination
              defaultCurrent={1}
              pageSize={20}
              showSizeChanger={false}
              total={influncersByFollowers?.length}
              onChange={(page) => setPage(page)}
            />
          </div>
        </div>
        <div
          style={{
            background: '#f1f1f1',
            padding: '10px',
            borderRadius: '5px',
            // maxHeight: '550px',
            // overflowY: 'auto',
          }}
        >
          <Row gutter={[20, 20]} style={{ minHeight: '200px' }}>
            {!apiLoading ? (
              influncersByFollowers?.length > 0 &&
              influncersByFollowers?.slice((page - 1) * 20, 20 * page)?.map((inf, i) => (
                <Col xxl={4} xl={6} md={8} sm={12} xs={24} key={i}>
                  <InfluencerCard
                    data={{
                      id: inf?._id,
                      profileImage: inf?.arch_profile_picture_url || inf?.profile_picture_url,
                      name: inf?.name,
                      userName: inf?.instagram_username,
                    }}
                    // selectedInfluencers={selectedInfByFollowers}
                    selectedInfluencers={overAllSelected}
                    // setSelectedInfluencers={setSelectedInfByFollowers}
                    setSelectedInfluencers={setOverAllSelected}
                    object={inf}
                    // objectsArr={selectedMinMaxInfluencer}
                    objectsArr={overAllSelectedObj}
                    // setObjects={setSelectedMinMaxInfluencers}
                    setObjects={setOverAllSelectedObj}
                    state={state}
                  />
                </Col>
              ))
            ) : (
              <InfinityLoader />
            )}
          </Row>
        </div>
      </Col>
    </>
  )
}

export default MinMaxComp
