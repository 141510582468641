import { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { Table } from 'antd'

import { BsFileBarGraph, BsFileEarmarkRichtext, BsPlay } from 'react-icons/bs'
import { FaRegComment, FaRegEye } from 'react-icons/fa'
import { AiOutlineAreaChart, AiOutlineRise } from 'react-icons/ai'
import { BiWifi } from 'react-icons/bi'

import { NumberFormatter } from '../../functions/NumberFormatter'
// import OfflineProfileImage from '../../assets/offlineProfile.png'
// import { ExpireDateCheck } from '../../functions/ExpireDateCheck'
// import { GetReach } from '../../functions/GetReach'

const InfluencerTable = ({ campPerformance, filteredInfluencers, id, perPage }) => {
  const [data, setData] = useState(null)
  const navigate = useNavigate()
  
  useEffect(() => {
    let tempArr = []
    // let tempSPInfluencersData = campPerformance?.influencers
    let tempSPInfluencersData = [...filteredInfluencers]
    tempSPInfluencersData?.sort((a, b) => {
      if (a?.influencer?.name?.toLowerCase() > b?.influencer?.name?.toLowerCase()) {
        return 1
      }
      if (a?.influencer?.name?.toLowerCase() < b?.influencer?.name?.toLowerCase()) {
        return -1
      }
      return 0
    })
    tempSPInfluencersData?.map((inf) => {
      tempArr.push({
        addAfter: inf?.addAfter,
        name: (
          <div className='profile-info'>
            <img
              src={
                inf?.influencer?.arch_profile_picture_url || inf?.influencer?.profile_picture_url
              }
              // src={
              //   ExpireDateCheck(inf?.influencer?.dataRefreshed) === 10
              //     ? inf?.influencer?.arch_profile_picture_url
              //       ? inf?.influencer?.arch_profile_picture_url
              //       : OfflineProfileImage
              //     : ExpireDateCheck(inf?.influencer?.dataRefreshed) === 2
              //     ? inf?.influencer?.profile_picture_url
              //     : OfflineProfileImage
              // }
              alt='profile_picture_url'
            />
            <span>{inf?.influencer?.name || '--'}</span>
          </div>
        ),
        post: (
          <div className='influncer-table-div'>
            <BsFileEarmarkRichtext className='icon' />
            <span>{inf?.posts || '0'}</span>
          </div>
        ),
        engagements: (
          <div className='influncer-table-div'>
            <FaRegComment className='icon' />
            <span>{inf?.engagement ? NumberFormatter(inf?.engagement, 1) : '0'}</span>
          </div>
        ),
        videoViews: (
          <div className='influncer-table-div'>
            <BsPlay className='icon' />
            <span>{inf?.videoView ? NumberFormatter(inf?.videoView, 1) : '0'}</span>
          </div>
        ),
        reach: (
          <div className='influncer-table-div'>
            <AiOutlineAreaChart className='icon' />
            <span>{inf?.reach ? NumberFormatter(inf?.reach, 1) : '0'}</span>
            {/* <span>{ NumberFormatter(GetReach({
              type: inf?.media_type,
              engRate: ((inf?.engagement / inf?.influencer?.audience) * 100).toFixed(2),
              videoViews: inf?.videoView || null,
              followers: inf?.influencer?.audience
            }),1)}</span> */}
          </div>
        ),
        impressions: (
          <div className='influncer-table-div'>
            <FaRegEye className='icon' />
            <span>{inf?.impressions ? NumberFormatter(inf?.impressions, 1) : '0'}</span>
          </div>
        ),
        estImpressions: (
          <div className='influncer-table-div'>
            <BiWifi className='icon' />
            <span>{inf?.estImpressions ? NumberFormatter(inf?.estImpressions, 1) : '0'}</span>
          </div>
        ),
        potentialReach: (
          <div className='influncer-table-div'>
            <BiWifi className='icon' />
            <span>{inf?.estImpressions ? NumberFormatter(inf?.estImpressions, 1) : '0'}</span>
          </div>
        ),
        engagementRate: (
          <div className='influncer-table-div'>
            <AiOutlineRise className='icon' />
            <span>{(inf?.engRate && `${inf?.engRate?.toFixed(2)}%`) || '0%'}</span>
          </div>
        ),
        vit: (
          <div className='influncer-table-div'>
            <BsFileBarGraph className='icon' />
            <span>0</span>
          </div>
        ),
        influencer: inf?.influencer?._id,
      })
    })
    setData(tempArr)
  }, [campPerformance, filteredInfluencers])

  const columns = [
    {
      title: 'NAME',
      dataIndex: 'name',
    },
    {
      title: 'POSTS',
      dataIndex: 'post',
    },
    {
      title: 'ENGAGEMENTS',
      dataIndex: 'engagements',
    },
    {
      title: 'VIDEO VIEWS',
      dataIndex: 'videoViews',
    },
    {
      title: 'REACH',
      dataIndex: 'reach',
    },
    // {
    //   title: 'IMPRESSIONS',
    //   dataIndex: 'impressions',
    // },
    {
      title: 'EST. IMPRESSIONS',
      dataIndex: 'estImpressions',
    },
    {
      title: 'POTENTIAL REACH',
      dataIndex: 'potentialReach',
    },
    {
      title: 'ENGAGEMENT RATE',
      dataIndex: 'engagementRate',
    },
    // {
    //   title: 'VIT',
    //   dataIndex: 'vit',
    // },
  ]

  //   const tableLoading = {
  //     spinning: loader,
  //     indicator: <LoadingOutlined />,
  //   };

  return (
    <>
      <div className='table-div'>
        {/* Table */}
        <Table
          className='data-table'
          columns={columns}
          dataSource={data}
          // rowClassName='onHover'
          rowClassName={(row) => row?.addAfter ? 'background-row onHover' : 'onHover'}
          pagination={{
            pageSize: perPage || 10,
          }}
          scroll={{ x: '1600px' }}
          onRow={(record) => {
            return {
              onClick: () => {
                navigate(`/campaign/${id}/${record?.influencer}`)
              },
            }
          }}
          // loading={}
          summary={() => {
            // let totalBorrow = 0;
            // let totalRepayment = 0;
            // pageData.forEach(({ borrow, repayment }) => {
            //   totalBorrow += borrow;
            //   totalRepayment += repayment;
            // });
            return (
              <>
                <Table.Summary.Row>
                  <Table.Summary.Cell index={0}>
                    <span style={{ color: 'var(--primary)', fontWeight: '600' }}>Total</span>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={1}>
                    <div className='influncer-table-div'>
                      <BsFileEarmarkRichtext className='icon' />
                      <span>{campPerformance?.posts || '0'}</span>
                    </div>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={2}>
                    <div className='influncer-table-div'>
                      <FaRegComment className='icon' />
                      <span>
                        {campPerformance?.engagement > 0
                          ? NumberFormatter(campPerformance?.engagement, 1)
                          : '0'}
                      </span>
                    </div>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={4}>
                    <div className='influncer-table-div'>
                      <BsPlay className='icon' />
                      <span>
                        {' '}
                        {campPerformance?.totalVideoView > 0
                          ? NumberFormatter(campPerformance?.totalVideoView, 1)
                          : '0'}
                      </span>
                    </div>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={4}>
                    <div className='influncer-table-div'>
                      <AiOutlineAreaChart className='icon' />
                      <span>
                        {' '}
                        {campPerformance?.totalReach > 0
                          ? NumberFormatter(campPerformance?.totalReach, 1)
                          : '0'}
                      </span>
                    </div>
                  </Table.Summary.Cell>
                  {/* <Table.Summary.Cell index={5}>
                  <div className='influncer-table-div'>
                    <FaRegEye className='icon' />
                    <span>
                      {campPerformance?.totalImpressions > 0
                        ? NumberFormatter(campPerformance?.totalImpressions, 1)
                        : '0'}
                    </span>
                  </div>
                </Table.Summary.Cell> */}
                  <Table.Summary.Cell index={6}>
                    <div className='influncer-table-div'>
                      <BiWifi className='icon' />
                      <span>
                        {campPerformance?.estImpressions > 0
                          ? NumberFormatter(campPerformance?.estImpressions, 1)
                          : '0'}
                      </span>
                    </div>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={7}>
                    <div className='influncer-table-div'>
                      <BiWifi className='icon' />
                      <span>
                        {campPerformance?.estImpressions > 0
                          ? NumberFormatter(campPerformance?.estImpressions, 1)
                          : '0'}
                      </span>
                    </div>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={8}>
                    <div className='influncer-table-div'>
                      <AiOutlineRise className='icon' />
                      <span>
                        {(campPerformance?.engRate && `${campPerformance?.engRate?.toFixed(2)}%`) ||
                          '0%'}
                      </span>
                    </div>
                  </Table.Summary.Cell>
                  {/* <Table.Summary.Cell index={9}>
                  <div className='influncer-table-div'>
                    <BsFileBarGraph className='icon' />
                    <span>0</span>
                  </div>
                </Table.Summary.Cell> */}
                </Table.Summary.Row>
              </>
            )
          }}
        />
      </div>
    </>
  )
}

export default InfluencerTable
