import { notification } from "antd";
import { attachToken, privateAPI } from "../config/constants";

// Campaign Profile Table Columns
export const campaignProfileColumns = [
  {
    title: "NAME",
    dataIndex: "name",
  },
  {
    title: "AUDIENCE",
    dataIndex: "audience",
  },
  {
    title: "ENG RATE",
    dataIndex: "rate",
  },
  {
    title: "POSTS",
    dataIndex: "posts",
  },
  {
    title: "VALUE",
    dataIndex: "value",
  },
  {
    title: "EMAIL STATUS",
    dataIndex: "emailStatus",
  },
  {
    title: "AVAILABILITY",
    dataIndex: "availability",
  },
  {
    title: "STATUS",
    dataIndex: "status",
    width: "100px",
  },
  {
    title: "ACTION",
    dataIndex: "action",
  },
];


// Influencer Status Change
export const onStatusChange = async (value, id, getAllProfiles) => {
  let payload = {
    status: value,
    id: id,
  };
  try {
    attachToken();
    const res = await privateAPI.post(
      `/campaign/update-campaign-influencer-status`,
      payload
    );
    if (res?.data) {
      notification.success({
        message: res?.data?.message,
        duration: 3,
      });
      getAllProfiles();
    }
  } catch (error) {
    console.log("err", error);
  }
};

// Influencer Bulk Status Change
export const onManyStatusChanges = async (value, ids, getAllProfiles) => {
  let payload = {
    status: value,
    ids: ids,
  };
  try {
    attachToken();
    const res = await privateAPI.post(
      `/campaign/update-campaign-influencer-status-many`,
      payload
    );
    if (res?.data) {
      notification.success({
        message: res?.data?.message,
        duration: 3,
      });
      getAllProfiles();
    }
  } catch (error) {
    console.log("err", error);
  }
};
