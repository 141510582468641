import React, { useEffect, useState } from 'react'
import { Col, DatePicker, Tabs, Select, Row, Skeleton, Button, Input } from 'antd'
import { CSVLink } from 'react-csv'
import moment from 'moment'

import { CiCircleInfo } from 'react-icons/ci'
// import { DotChartOutlined } from '@ant-design/icons'
// import { FaRegSave } from 'react-icons/fa'

import BrandBuzzCard from '../../../components/Cards/BrandBuzzCard'
import LineChart from '../../../components/Charts/LineChart'
import InfluencerTable from '../../../components/Views/InfluencerTable'
import { attachToken, privateAPI } from '../../../config/constants'
import { NumberFormatter } from '../../../functions/NumberFormatter'
// import InfinityLoader from '../../../components/InfinityLoader'
import BrandBuzzSkeleton from '../../../components/Skeletons/BrandBuzzSkeleton'
import { GetReach } from '../../../functions/GetReach'
// import { AiOutlineLineChart } from 'react-icons/ai'
// import { CampSocialCSVData, CampSocialHeaders } from '../utils/campaignSocialUtil'

const CampaignSocialPerformance = ({ id, campaignDate, ActiveTab }) => {
  const { RangePicker } = DatePicker
  const { Option } = Select
  const { TabPane } = Tabs
  const [campPerformance, setCampPerformance] = useState(null)
  const [content, setContent] = useState(null)
  const [graphData, setGraphData] = useState([])
  const [graphFilter, setGraphFilter] = useState('Influencers')
  const [perPage, setPerPage] = useState(10)
  const [inluencerCSVData, setInfluencerCSVData] = useState([])
  const [postsCSVData, setPostsCSVData] = useState([])
  const [loader, setLoader] = useState(true)
  const [activeTab, setActiveTab] = useState('influncers')
  const [dateRange, setDateRange] = useState([
    moment(campaignDate?.startOf),
    moment(campaignDate?.endOf),
  ])
  const [filterChange, setFilterChange] = useState(null)
  const [adFilterState, setADFilterState] = useState('descending')
  const [searchInfluencers, setSearchInfluencers] = useState('')
  const [filteredInfluencers, setFilteredInfluencers] = useState([])

  const getCampaignPerformance = async () => {
    if (id) {
      let payload = {
        dateRange,
      }
      try {
        attachToken()
        const res = await privateAPI.post(`/campaign/get-campaign-performance/${id}`, payload)
        if (res?.data) {
          // console.log('res?.data', res?.data?.influencers)
          setCampPerformance(res?.data)
          setFilteredInfluencers(res?.data?.influencers)
          let tempCSVData = []
          res?.data?.influencers?.map((inf) => {
            tempCSVData.push({
              Name: inf?.influencer?.name || '--',
              Profile: `https://www.instagram.com/${inf?.influencer?.instagram_username}`,
              Followers: NumberFormatter(inf?.influencer?.audience, 1),
              Post: inf?.posts || '0',
              Engagements: inf?.engagement ? NumberFormatter(inf?.engagement, 1) : '0',
              'Video Views': inf?.videoView ? NumberFormatter(inf?.videoView, 1) : '0',
              Reach: inf?.reach ? NumberFormatter(inf?.reach, 1) : '0',
              // Impressions: inf?.impressions ? NumberFormatter(inf?.impressions, 1) : '0',
              'Est.Impressions': inf?.estImpressions
                ? NumberFormatter(inf?.estImpressions, 1)
                : '0',
              'Potential Reach': inf?.estImpressions
                ? NumberFormatter(inf?.estImpressions, 1)
                : '0',
              'Engagement Rate': (inf?.engRate && `${inf?.engRate?.toFixed(2)}%`) || '0%',
              // influencer: inf?.influencer?._id,
            })
          })
          if (tempCSVData.length > 0) {
            setInfluencerCSVData(tempCSVData)
          }
        }
      } catch (error) {
        console.log('err', error)
      }
    }
  }

  const getCampaignPerformanceGraph = async () => {
    if (id) {
      let payload = {
        filter: graphFilter,
        dateRange,
      }
      try {
        attachToken()
        const res = await privateAPI.post(`/campaign/get-campaign-performance-graph/${id}`, payload)
        if (res?.data) {
          setGraphData(res?.data?.data)
          setLoader(false)
        }
      } catch (error) {
        console.log('err', error)
      }
    }
  }

  useEffect(() => {
    if (ActiveTab === 'social-performance') {
      setLoader(true)
      getCampaignPerformance()
      getCampaignPerformanceGraph()
    }
  }, [ActiveTab])

  useEffect(() => {
    getCampaignContent()
  }, [filterChange, adFilterState])

  const onDateChange = (date, dateString) => {
    // console.log('campaignName',campaignName)
    if (date) {
      setDateRange([moment(dateString[0]), moment(dateString[1])])
      getCampaignPerformanceGraph()
    } else {
      setDateRange([moment(campaignDate?.startOf), moment(campaignDate?.endOf)])
      getCampaignPerformanceGraph()
    }
  }

  const getCampaignContent = async () => {
    if (id && campaignDate) {
      // setContent(null)
      try {
        let payload = {
          dateFilter: campaignDate,
          engagementFilter: filterChange,
          adFilter: adFilterState,
        }
        attachToken()
        const res = await privateAPI.post(`/campaign/get-campaign-content/${id}`, payload)
        if (res?.data) {
          let tempCSVData = []
          // console.log('posts res', res?.data)
          setContent(res?.data)
          res?.data?.map((post, index) => {
            tempCSVData.push({
              'S.No': index,
              Name: post?.media?.influencer?.name,
              'Profile Link': `https://www.instagram.com/${post?.media?.instagram?.username}/`,
              Followers: post?.media?.instagram?.followers_count,
              'Content Update': post?.media?.permalink,
              'Post Views': post?.media?.video_views || 0,
              Likes: post?.media?.like_count || 0,
              Comments: post?.media?.comments_count || 0,
              'Post Reach':
                post?.media?.reach ||
                GetReach({
                  type: post?.media?.media_type,
                  engRate: post?.media?.engagement
                    ? (
                        (post?.media?.engagement / post?.media?.instagram?.followers_count) *
                        100
                      ).toFixed(2)
                    : post?.media?.local_engagememt,
                  videoViews: post?.media?.video_views || null,
                  followers: post?.media?.instagram?.followers_count,
                }),
              // 'Post Impressions': '',
              'Engagement Rate': post?.media?.local_engagememt,
              'Followers Reach':
                Number(post?.media?.video_views || 0) /
                Number(post?.media?.instagram?.followers_count),
              'Followers Engagement':
                (Number(post?.media?.like_count || 0) * Number(post?.media?.comments_count || 0)) /
                post?.media?.instagram?.followers_count,
            })
          })
          if (tempCSVData.length > 0) {
            setPostsCSVData(tempCSVData)
          }
        }
      } catch (error) {
        console.log('err', error)
      }
    }
  }

  const onTabsChange = (e) => {
    setActiveTab(e)
    if (e === 'posts') {
      getCampaignContent()
    }
  }

  const onFilterChange = (value) => {
    setFilterChange(value)
  }

  const ADFilter = (value) => {
    // setContent(null)
    setADFilterState(value)
  }

  return (
    <div className='campaign-social-performance'>
      {/* Filter Bar */}

      {campPerformance ? (
        <div className='filter-bar'>
          <div className='left-side'>
            <RangePicker
              style={{ borderRadius: '4px', width: '240px' }}
              onChange={onDateChange}
              value={dateRange}
              disabledDate={(d) => {
                return (
                  d?.isBefore(moment(campaignDate?.startOf)) ||
                  d?.isAfter(moment(campaignDate?.endOf))
                )
              }}
            />
          </div>
          <div className='right-side'>
            {/* <CSVLink
            filename={`${campaignName}.csv`}
            data={CSVData}
            asyncOnClick={true}
            headers={CampSocialHeaders}
            onClick={(event, done) => {
              CampSocialCSVData(done, setCSVData, campPerformance)
            }}
          >
            <FaRegSave className='add-icon' />
          </CSVLink> */}
          </div>
        </div>
      ) : (
        <div
          style={{
            backgroundColor: 'white',
            borderRadius: '8px',
            padding: '20px 20px',
            margin: '1rem 0',
          }}
        >
          <Skeleton.Input active block size='small' />
        </div>
      )}

      {/* Analytic's Box */}
      {campPerformance ? (
        <div className='analtics-boxes'>
          <div className='scroll-box'>
            <div className='box'>
              <span>{campPerformance?.people || '--'}</span>
              <p>PEOPLE</p>
            </div>

            <div className='box'>
              <span>{campPerformance?.posts || '--'}</span>
              <p>POSTS</p>
            </div>

            <div className='box'>
              <span>
                {campPerformance?.engagement > 0
                  ? NumberFormatter(campPerformance?.engagement, 1)
                  : '--'}
              </span>
              <p>ENGAGEMENTS</p>
            </div>

            <div className='box'>
              <span>
                {campPerformance?.totalVideoView > 0
                  ? NumberFormatter(campPerformance?.totalVideoView, 1)
                  : '--'}
              </span>
              <p>VIDEO VIEWS</p>
            </div>

            <div className='box'>
              <span>
                {campPerformance?.totalReach > 0
                  ? NumberFormatter(campPerformance?.totalReach, 1)
                  : '--'}
              </span>
              <p>REACH</p>
            </div>

            {/* <div className='box'>
              <span>
                {campPerformance?.totalImpressions > 0
                  ? NumberFormatter(campPerformance?.totalImpressions, 1)
                  : '--'}
              </span>
              <p>IMPRESSIONS</p>
            </div> */}
            <div className='box'>
              <span>
                {campPerformance?.estImpressions > 0
                  ? NumberFormatter(campPerformance?.estImpressions, 1)
                  : '--'}
              </span>
              <p>EST. IMPRESSIONS</p>
            </div>
            <div className='box'>
              <span>
                {campPerformance?.potReach > 0
                  ? NumberFormatter(campPerformance?.potReach, 1)
                  : '--'}
              </span>
              <p>POT. REACH</p>
            </div>
            <div className='box'>
              <span>
                {(campPerformance?.engRate && `${campPerformance?.engRate?.toFixed(2)}%`) || '--'}
              </span>
              <p>ENG RATE</p>
            </div>
            {/* <div className='box'>
              <span>--</span>
              <p>VIT</p>
            </div> */}
          </div>
        </div>
      ) : (
        <div
          style={{
            backgroundColor: 'white',
            borderRadius: '8px',
            padding: '20px 20px',
            margin: '1rem 0',
          }}
        >
          <Skeleton active paragraph={{ rows: 3 }} />
        </div>
      )}

      {/* Line Chart */}
      <div className='chart-div'>
        <div className='header'>
          <h2>Performance by Date</h2>
          <div className='right'>
            <Select
              value={graphFilter}
              onChange={(e) => {
                setLoader(true)
                setGraphFilter(e)
                getCampaignPerformanceGraph()
              }}
              size='large'
            >
              <Option value={'Influencers'}>Influencers</Option>
              <Option value={'Posts'}>Posts</Option>
              {/* <Option value={'Reach'}>Reach</Option> */}
              {/* <Option value={'VideoViews'}>Video Views</Option> */}
              {/* <Option value={'Immpressions'}>Impressions</Option> */}
            </Select>
            {/* <Select defaultValue={'byDate'} onChange={() => console.log('s')} size='large'>
              <Option value={'byDate'}>By Date</Option>
              <Option value={'byPlatform'}>By Platform</Option>
              <Option value={'byPostType'}>By Post Type</Option>
            </Select> */}
            {/* <Select defaultValue={'daily'} onChange={() => console.log('s')} size='large'>
              <Option value={'daily'}>Daily</Option>
              <Option value={'weekly'}>Weekly</Option>
              <Option value={'monthly'}>Monthly</Option>
            </Select> */}
          </div>
        </div>
        <div className='chart'>
          <LineChart graphData={graphData} graphFilter={graphFilter} loader={loader} />
        </div>
      </div>

      <Tabs
        defaultActiveKey={activeTab}
        onChange={onTabsChange}
        className='tabs-container'
        size='large'
        tabBarExtraContent={
          activeTab === 'influncers' ? (
            <div style={{ display: 'flex', alignItems: 'center', columnGap: '1rem' }}>
              <Input
                className='social_performance_search'
                placeholder='Search by name'
                value={searchInfluencers}
                onChange={(e) => setSearchInfluencers(e?.target?.value)}
                onKeyDown={(e) => {
                  if (e?.key === 'Enter') {
                    let tempInfArr = campPerformance?.influencers
                    if (searchInfluencers?.length > 0) {
                      tempInfArr = tempInfArr?.filter(
                        (item) =>
                          item?.influencerName
                            .toLowerCase()
                            ?.includes(searchInfluencers.toLowerCase()) ||
                          item?.influencer?.instagram_username
                            ?.toLowerCase()
                            ?.includes(searchInfluencers.toLowerCase()),
                      )
                    }
                    setFilteredInfluencers(tempInfArr)
                  }
                }}
              />
              <CSVLink
                filename={`Social-Performance-Influencers-${moment().format('YYYY-MM-DD')}.csv`}
                data={inluencerCSVData}
                // className="primarybtn"
                style={{ width: 'max-content', color: 'white' }}
              >
                <Button className='export-csv-btn'>Export CSV</Button>
              </CSVLink>

              <Select
                onChange={(e) => setPerPage(e)}
                className='select'
                size='large'
                value={perPage}
                style={{ minWidth: '120px' }}
              >
                <Option value={10}>Show 10</Option>
                <Option value={20}>Show 20</Option>
                <Option value={35}>Show 35</Option>
                <Option value={50}>Show 50</Option>
              </Select>
            </div>
          ) : (
            <CSVLink
              filename={`Social-Performance-Influencers-${moment().format('YYYY-MM-DD')}.csv`}
              data={postsCSVData}
              // className="primarybtn"
              style={{ width: 'max-content', color: 'white' }}
            >
              <Button className='export-csv-btn'>Export CSV</Button>
            </CSVLink>
          )
        }
      >
        <TabPane tab='INFLUENCERS' key='influncers'>
          {/* {TabFilter} */}
          <InfluencerTable
            campPerformance={campPerformance}
            filteredInfluencers={filteredInfluencers}
            id={id}
            perPage={perPage}
          />
          <br />
          <div
            style={{
              width: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              gap: '0.5rem',
              background: '#f6f6f8',
              padding: '10px',
              borderRadius: '25px',
            }}
          >
            <CiCircleInfo style={{ color: 'var(--primary)' }} />
            <p>influencers without any hashtags found in their last 25 posts are highlighted.</p>
          </div>
        </TabPane>
        <TabPane tab='POSTS' key='posts'>
          {/* <br /> */}
          <div className='filter-bar'>
            <div className='left-side'>
              <Select
                placeholder='Sort'
                style={{ minWidth: 140 }}
                onChange={onFilterChange}
                size='large'
              >
                <Option value='comments'>Comments</Option>
                <Option value='likes'>Likes</Option>
                {/* <Option value='reach'>Reach</Option> */}
                <Option value='engrate'>Eng Rate</Option>
                <Option value='views'>Video Views</Option>
              </Select>

              <Select
                defaultValue={'descending'}
                placeholder='A-Z / Z-A'
                style={{ minWidth: 100 }}
                onChange={ADFilter}
                size='large'
              >
                <Option value='ascending'>Sort A-Z</Option>
                <Option value='descending'>Sort Z-A</Option>
              </Select>
            </div>
          </div>
          <br />
          {content ? (
            <Row gutter={[20, 20]}>
              {content?.length > 0 ? (
                content?.map((post, index) => (
                  <Col key={index} xs={24} lg={12} xl={8} xxl={6}>
                    <BrandBuzzCard
                      followers={post?.media?.instagram?.followers_count}
                      media={post?.media}
                      name={post?.media?.instagram?.name}
                      profilePic={post?.media?.instagram?.profile_picture_url}
                      influencerId={post?.media?.instagram?.influencer}
                      inf={post?.media?.influencer}
                    />
                  </Col>
                ))
              ) : (
                <div className='no-found '>
                  <span>No Content Found.</span>
                </div>
              )}
            </Row>
          ) : (
            <Row gutter={[20, 20]}>
              <Col xs={24} lg={12} xl={8} xxl={6}>
                <BrandBuzzSkeleton />
              </Col>
              <Col xs={24} lg={12} xl={8} xxl={6}>
                <BrandBuzzSkeleton />
              </Col>
              <Col xs={24} lg={12} xl={8} xxl={6}>
                <BrandBuzzSkeleton />
              </Col>
              <Col xs={24} lg={12} xl={8} xxl={6}>
                <BrandBuzzSkeleton />
              </Col>
            </Row>
          )}
        </TabPane>
      </Tabs>
    </div>
  )
}

export default CampaignSocialPerformance
