import { useEffect, useState } from 'react'
import { Progress } from 'antd'
import { MdKeyboardArrowRight, MdOutlineKeyboardArrowLeft } from 'react-icons/md'

import InfinityLoader from '../../InfinityLoader'

function LikesInterest({ data }) {
  const [dataShow, setDataShow] = useState([])
  const [firstIndex, setFirstIndex] = useState(0)
  const [lastIndex, setLastIndex] = useState(5)
  const [nextdisbaled, setNextdisbaled] = useState(false)

  const getArrangedData = () => {
    let ArrShow = []
    data?.sort((a, b) => a.percent - b.percent).reverse()
    data?.slice(firstIndex, lastIndex).map((occupation) => {
      ArrShow.push({
        name: occupation?.name,
        percent: occupation?.percent || null,
        value: occupation?.value || null,
      })
    })
    setDataShow(ArrShow)
  }

  useEffect(() => {
    getArrangedData()
  }, [data, lastIndex, firstIndex])

  return (
    <div className='likes_div'>
      {dataShow ? (
        <>
          <div className='header'>
            <div>
              <h3>Likes & Interest</h3>
            </div>
            <div>
              <MdOutlineKeyboardArrowLeft
                className={`${firstIndex === 0 ? 'disable' : 'enable'} icon`}
                onClick={() => {
                  if (firstIndex > 0) {
                    setFirstIndex(firstIndex - 5)
                    setLastIndex(lastIndex - 5)
                    setNextdisbaled(false)
                  }
                }}
              />
              <MdKeyboardArrowRight
                className={`${nextdisbaled ? 'disable' : 'enable'} icon`}
                onClick={() => {
                  if (data?.length > lastIndex) {
                    setFirstIndex(firstIndex + 5)
                    setLastIndex(lastIndex + 5)
                  } else {
                    setNextdisbaled(true)
                  }
                }}
              />
            </div>
          </div>
          {dataShow?.map((occupation, ind) => (
            <div key={ind}>
              <div className='progress_div'>
                <p className='name'>{occupation?.name}</p>
                <div className='values'>
                  <p className='users'>{occupation?.value}</p>
                  <p>{String(occupation?.percent)?.replace('-', '<')}%</p>
                </div>
              </div>
              <Progress
                percent={occupation?.percent || 0}
                strokeColor='rgb(239 90 41 / 72%)'
                showInfo={false}
              />
            </div>
          ))}
        </>
      ) : (
        <InfinityLoader height={'400px'} />
      )}
    </div>
  )
}

export default LikesInterest
