import React, { useState, useEffect, useMemo } from 'react'
import { Dropdown, Menu, Tabs, Spin } from 'antd'
import { useParams, useNavigate, useLocation } from 'react-router-dom'
import { publicAPI } from '../../config/constants'
import moment from 'moment'

// icons
import { AiOutlineFilePpt } from 'react-icons/ai'
import { LoadingOutlined } from '@ant-design/icons'
import { TbDownload } from "react-icons/tb";

// local imports
import PublicLayout from '../../layout/PublicLayout/PublicLayout'
import PublicReportSummary from '../Campaign/components/ReportComponent/PublicReportSummary'
import PublicTimeLine from '../../components/Views/Reports/PublicTimeLine'
import PublicReportHeatMap from '../Campaign/components/ReportComponent/PublicReportHeatMap'
import { generateReportPpt } from '../../functions/ExportReportPPT'
import { generatePpt } from '../../functions/ExportPPT'

const useQuery = (search) => {
  return useMemo(() => new URLSearchParams(search), [search])
}

const PublicReport = () => {
  const { id } = useParams()
  const navigate = useNavigate()
  const { search } = useLocation()
  const { TabPane } = Tabs
  const [reportData, setReportData] = useState()
  const [reportPPTLoading, setReportPPTLoading] = useState(false)
  const [pptInfLoading, setPptInfLoading] = useState(false)
  const [activeTab, setActiveTab] = useState('summary')
  const query = useQuery(search)

  useEffect(() => {
    getCampaignReportPublic()
  }, [id])

  useEffect(() => {
    if (query.get('tab')) {
      setActiveTab(query.get('tab'))
    }
  }, [activeTab])

  const getCampaignReportPublic = async () => {
    if (id) {
      try {
        const res = await publicAPI.get(`/campaign/get-campaign-report-public/${id}`)
        if (res?.data) {
          setReportData(res?.data)
        }
      } catch (error) {
        console.log('err', error)
      }
    }
  }

  const getCampaignReportPPT = async () => {
    if (id) {
      try {
        setReportPPTLoading(true)
        const res = await publicAPI.get(`/campaign/get-campaign-report-ppt/${id}/all`)
        if (res?.data) {
          generateReportPpt(res?.data, setReportPPTLoading)
        }
      } catch (error) {
        console.log('err', error)
        setReportPPTLoading(false)
      }
    }
  }

  const getReportInfluencersPPT = async () => {
    if (id) {
      try {
        setPptInfLoading(true)
        // attachToken()
        const res = await publicAPI.get(`/campaign/get-report-influencers-ppt-data/${id}`)
        if (res?.data) {
          generatePpt(res?.data?.data, setPptInfLoading)
        }
      } catch (error) {
        setPptInfLoading(false)
        console.log('err', error)
      }
    }
  }

  const onTabsChange = (key) => {
    const params = new URLSearchParams()
    if (key) {
      params.append('tab', key)
    } else {
      params.delete('tab')
    }
    navigate({ search: params.toString() })
  }

  const SettingsMenu = () => (
    <Menu>
      <Menu.Item
        icon={
          pptInfLoading == true ? (
            <Spin indicator={antIconSpin} style={{ marginRight: '8px' }} />
          ) : (
            <AiOutlineFilePpt className='dropdown-menu-icon' />
          )
        }
        onClick={() => getReportInfluencersPPT()}
        disabled={pptInfLoading}
      >
        Report Influencers PPT
      </Menu.Item>

      <Menu.Item
        icon={
          reportPPTLoading == true ? (
            <Spin indicator={antIconSpin} style={{ marginRight: '8px' }} />
          ) : (
            <AiOutlineFilePpt className='dropdown-menu-icon' />
          )
        }
        onClick={getCampaignReportPPT}
        disabled={reportPPTLoading}
      >
        Report PPT
      </Menu.Item>
    </Menu>
  )

  const antIconSpin = (
    <LoadingOutlined
      style={{
        fontSize: 18,
      }}
      spin
    />
  )

  return (
    <PublicLayout>
      <div className='campaign-container'>
        <div className='header'>
          <div className='left'>
            <h2>{reportData?.name || '--'}</h2>
            <p className='subHeading'>
              {reportData?.influencers?.length} Influencers | Last Refreshed{' '}
              {moment(reportData?.updatedAt).format('h:mm a')} PM
            </p>
          </div>
          <div className='right'>
            <Dropdown
              overlay={SettingsMenu(reportData)}
              trigger={['click']}
              placement={'bottomLeft'}
            >
              {reportPPTLoading == true || pptInfLoading == true ? (
                <Spin indicator={antIconSpin} className='icon' />
              ) : (
                // <AiOutlineSetting className='icon' />
                <TbDownload className='icon' />
              )}
            </Dropdown>

            {/* <AiOutlineSave className="icon" /> */}
            {/* <MdRefresh className="icon" /> */}
          </div>
        </div>

        <Tabs
          defaultActiveKey={activeTab ? activeTab : 'summary'}
          onChange={(e) => onTabsChange(e)}
          className='tabs-container'
        >
          <TabPane tab='SUMMARY' key='summary'>
            <PublicReportSummary id={id} activeTab={activeTab} />
          </TabPane>

          <TabPane tab='TIMELINE' key='timeline'>
            <PublicTimeLine
              id={id}
              activeTab={activeTab}
              reportDate={{
                startOf: reportData?.startDate,
                endOf: reportData?.endDate,
              }}
            />
          </TabPane>

          <TabPane tab='HEATMAP' key='heatmap'>
            <PublicReportHeatMap id={id} activeTab={activeTab} />
          </TabPane>
        </Tabs>
      </div>
    </PublicLayout>
  )
}

export default PublicReport
