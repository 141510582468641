import React, { useState } from 'react'
import { Row, Col, Input, Pagination } from 'antd'

import { FiSearch } from 'react-icons/fi'

import InfinityLoader from '../../../../components/InfinityLoader'
import InfluencerCard from '../../../../components/Cards/InfluencerCard'

const MultipleComp = ({
  state,
  multipleSearchInfs,
  setMultipleSearchInfs,
  influencersData,
  overAllSelected,
  setOverAllSelected,
  overAllSelectedObj,
  setOverAllSelectedObj,
  getAllInfluencersData,
  apiLoading,
}) => {
  const [page, setPage] = useState(1)
  return (
    <>
      <Col xs={24}>
        <div className='filter-bar'>
          <div className='left-side'>
            <Input
              placeholder='Search by name'
              prefix={<FiSearch className='search-icon' />}
              className='searchInput'
              size='small'
              value={multipleSearchInfs}
              onChange={(e) => setMultipleSearchInfs(e.target.value)}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  e.preventDefault()
                  getAllInfluencersData()
                  setPage(1)
                }
              }}
            />
          </div>
          <div className='right-side' style={{ flexWrap: 'wrap' }}>
            {/* <Button
                  onClick={() => {
                    let tempArr = []
                    influencersData?.map((item) => tempArr.push(item?._id))
                    setOverAllSelected([...overAllSelected,...tempArr])
                    setSelectedMultiInfluencers(influencersData)
                  }}
                  // disabled={selectInfluencers?.length === influencersData?.length ? true : false}
                >
                  Select All
                </Button> */}
            {/* <Button
                  // disabled={selectInfluencers?.length === 0 ? true : false}
                  // onClick={() => {
                  //   setSelectInfluencers([])
                  //   setSelectedMultiInfluencers([])
                  // }}
                >
                  Clear All
                </Button> */}
            <Pagination
              defaultCurrent={1}
              pageSize={20}
              showSizeChanger={false}
              total={influencersData?.length}
              onChange={(page) => setPage(page)}
            />
          </div>
        </div>

        <div
          style={{
            background: '#f1f1f1',
            padding: '10px',
            borderRadius: '5px',
            // maxHeight: '550px',
            // overflowY: 'auto',
          }}
        >
          <Row gutter={[20, 20]} style={{ minHeight: '200px' }}>
            {!apiLoading ? (
              influencersData?.length > 0 &&
              influencersData?.slice((page - 1) * 20, 20 * page)?.map((inf, i) => (
                <Col xxl={4} xl={6} md={8} sm={12} xs={24} key={i}>
                  <InfluencerCard
                    data={{
                      id: inf?._id,
                      profileImage: inf?.arch_profile_picture_url || inf?.profile_picture_url,
                      name: inf?.name,
                      userName: inf?.instagram_username,
                    }}
                    // selectedInfluencers={selectInfluencers}
                    selectedInfluencers={overAllSelected}
                    // setSelectedInfluencers={setSelectInfluencers}
                    setSelectedInfluencers={setOverAllSelected}
                    object={inf}
                    // objectsArr={selectedMultiInfluencers}
                    objectsArr={overAllSelectedObj}
                    // setObjects={setSelectedMultiInfluencers}
                    setObjects={setOverAllSelectedObj}
                    state={state}
                  />
                </Col>
              ))
            ) : (
              <InfinityLoader />
            )}
          </Row>
        </div>
      </Col>
    </>
  )
}

export default MultipleComp
