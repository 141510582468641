import React, { useState } from "react";
import { Col, Row, Select } from "antd";

import PerformersActivated from "./PerformersActivated";

function ReportPerformance() {
  const { Option } = Select;
  const [selctedBar, setSelctedBar] = useState([
    "activatedInfluencers",
    "posts",
    "engagements",
  ]);

  return (
    <div className="top_performer_Container">
      <div className="header">
        <h3>Top Performer</h3>
        <Select
          mode="multiple"
          style={{
            minWidth: "250px",
          }}
          value={selctedBar}
          onChange={(e) => {
            // if (selctedBar.length < 3) {
            // }
            setSelctedBar(e);
          }}
        >
          <Option value="activatedInfluencers">Activated Influencers</Option>
          <Option value="posts">Posts</Option>
          <Option value="engagements">Engagements</Option>
          {/* <Option value="reach">reach</Option> */}
        </Select>
      </div>
      <div>
        <Row gutter={[50, 20]}>
          {selctedBar?.map((per,ind) => (
            <Col
            key={ind}
              xl={
                selctedBar?.length === 3 ? 8 : selctedBar?.length === 2 ? 12 : 24
              }
              lg={8}
              md={24}
              xs={24}
            >
              <PerformersActivated
                title={
                  per === "activatedInfluencers" ? "ACTIVATED INFLUENCERS" : per
                }
              />
            </Col>
          ))}
        </Row>
      </div>
    </div>
  );
}

export default ReportPerformance;
