import React, { useState, useEffect, useRef } from 'react'
import { Table, Dropdown, Menu, notification } from 'antd'
import { useNavigate } from 'react-router-dom'
import moment from 'moment'
import { CSVLink } from 'react-csv'

// icons
import { LoadingOutlined } from '@ant-design/icons'
import { BsThreeDots } from 'react-icons/bs'
import { AiOutlineFilePpt, AiOutlineShareAlt } from 'react-icons/ai'
import { RiDeleteBinLine } from 'react-icons/ri'
import { GrDocumentCsv } from 'react-icons/gr'

// local imports
import { privateAPI, attachToken, publicAPI, forntendUrl } from '../../config/constants'
import { NumberFormatter } from '../../functions/NumberFormatter'
import { DeliverablesLength } from '../../pages/Campaign/utils/campaignUtil'
import Layout from '../../layout/Layout'
import { generateReportPpt } from '../../functions/ExportReportPPT'
import reportPng from '../../assets/report-influence.png'

const AllReports = () => {
  const navigate = useNavigate()
  const [allReports, setAllReports] = useState([])
  const [data, setData] = useState()
  const [reportPptLoading, setReportPptLoading] = useState(false)
  const [CSVData, setCSVData] = useState([])
  const csvRef = useRef()

  const getReports = async () => {
    try {
      attachToken()
      const res = await privateAPI.get('/reports/get-all-reports')

      if (res) {
        setAllReports(res?.data)
      }
    } catch (err) {
      console.log(err)
    }
  }

  const getCampaignReportPPT = async (reportID, chartData) => {
    if (reportID) {
      try {
        setReportPptLoading(true)
        // attachToken()
        const res = await publicAPI.get(`/campaign/get-campaign-report-ppt/${reportID}/all`)
        if (res?.data) {
          generateReportPpt(
            {
              ...res?.data,
              chart1: chartData?.chartData,
              interval: chartData?.interval,
              duration: chartData?.duration,
              chart2: [
                [chartData?.budget, chartData?.budget, chartData?.budget],
                [chartData?.totalViews, chartData?.totalEng, chartData?.totalReach],
              ],
            },
            setReportPptLoading,
          )
        }
      } catch (error) {
        console.log('err', error)
      }
    }
  }

  const getChartReportPPT = async (reportID) => {
    if (reportID) {
      try {
        // attachToken()
        const res = await publicAPI.post(`/campaign/chart-1-data`, { reportId: reportID })
        if (res?.data) {
          // setBarChartData(res?.data)
          getCampaignReportPPT(reportID, res?.data)
          // setReportPPTData(res?.data)
        }
      } catch (error) {
        console.log('err', error)
      }
    }
  }

  const deleteReport = async (id) => {
    try {
      attachToken()
      const res = await privateAPI.delete(`/campaign/report-delete/${id}`)
      if (res) {
        getReports()
        notification.success({
          message: 'Report Deleted',
          duration: 3,
        })
      }
    } catch (err) {
      console.log(err)
    }
  }

  const copyToClipBoard = (id) => {
    navigator.clipboard.writeText(`${forntendUrl}/public-campaign-report-summary/${id}`)
    notification.success({
      message: 'Copied To Clipboard',
      duration: 2,
    })
  }

  useEffect(() => {
    getReports()
  }, [])

  useEffect(() => {
    let tempArr = []
    let tempReports = allReports
    tempReports?.sort((a, b) => {
      if (a?.name?.toLowerCase() > b?.name?.toLowerCase()) {
        return 1
      }
      if (a?.name?.toLowerCase() < b?.name?.toLowerCase()) {
        return -1
      }
      return 0
    })
    tempReports?.map((i) => {
      tempArr.push({
        id: i?._id,
        profile: (
          <div>
            <img
              src={reportPng}
              alt='profile'
              style={{ width: '40px', height: '40px', borderRadius: '50%' }}
            />
          </div>
        ),
        name:
          (
            <span
              style={{ cursor: 'pointer' }}
              onClick={() => navigate(`/campaign/report/${i?._id}`)}
            >
              {i?.name}
            </span>
          ) || '--',
        campName: i?.campaign?.name,
        time: `${moment(i?.startDate).format('MMM DD, YYYY')} - ${moment(i?.endDate).format(
          'MMM DD, YYYY',
        )}`,
        owner: 'Admin',
        date: moment(i?.createdAt).format('MMM DD, YYYY'),
        action: (
          <>
            {reportPptLoading == true ? (
              <LoadingOutlined
                style={{
                  fontSize: 18,
                }}
                spin
              />
            ) : (
              <Dropdown
                overlay={reportMenu(i?._id)}
                trigger={['click']}
                placement={'bottomRight'}
                disabled={reportPptLoading}
              >
                <BsThreeDots className='dot-icon' />
              </Dropdown>
            )}
          </>
        ),
      })
    })
    setData(tempArr)
  }, [allReports])

  const columns = [
    {
      title: ' ',
      dataIndex: 'profile',
      width: '50px',
    },
    {
      title: 'Name',
      dataIndex: 'name',
    },
    {
      title: 'Campaign Name',
      dataIndex: 'campName',
    },
    {
      title: 'Time Frame',
      dataIndex: 'time',
    },
    {
      title: 'Owner',
      dataIndex: 'owner',
    },
    {
      title: 'Created Date',
      dataIndex: 'date',
    },
    {
      title: 'Actions',
      dataIndex: 'action',
    },
  ]

  const onCSVDownload = async (reportId) => {
    try {
      let tempCSVData = []
      // attachToken()
      const res = await publicAPI.get(`/campaign/get-campaign-report-ppt/${reportId}/all`)
      if (res?.data) {
        let reportPPTData = res?.data
        reportPPTData?.influencers?.map((inf) => {
          const payload = Object.assign(
            { Name: inf?.influencer?.name },
            reportPPTData?.infAudEnabled && {
              audience: NumberFormatter(inf?.influencer?.audience, 1),
            },
            reportPPTData?.infEngRateEnabled && {
              'Eng Rate': inf?.influencer?.instagram?.engagememtRate
                ? `${Number(inf?.influencer?.instagram?.engagememtRate)?.toFixed(2)}%`
                : '--',
            },
            reportPPTData?.infPostsEnabled && {
              // Posts: `${inf?.posts} / ${DeliverablesLength(inf?.deliverables)}`,
              Posts: `${inf?.posts} posts out of ${DeliverablesLength(inf?.deliverables)}`,
            },
            reportPPTData?.infEngEnabled && {
              Engagement: inf?.engagement ? NumberFormatter(inf?.engagement, 1) : 0,
            },
            reportPPTData?.socialComEnabled && {
              Comments: inf?.total_comments ? NumberFormatter(inf?.total_comments, 2) : 0,
            },
            reportPPTData?.socialLikesEnabled && {
              Likes: inf?.total_likes ? NumberFormatter(inf?.total_likes, 2) : 0,
            },
            reportPPTData?.socialVideoViewsEnabled && {
              'Video Views': inf?.videoView ? NumberFormatter(inf?.videoView, 1) : 0,
            },
            reportPPTData?.socialReachEnabled && {
              Reach: inf?.reach ? NumberFormatter(inf?.reach, 1) : 0,
            },
            reportPPTData?.socialEngRateEnabled && {
              'Social EngRate': inf?.engRate ? `${NumberFormatter(inf?.engRate, 1)}%` : 0,
            },
            reportPPTData?.engWithoutViewsEnabled && {
              'Eng Without View': inf?.engagement ? NumberFormatter(Number(inf?.engagement), 1) : 0,
            },
            reportPPTData?.engWithViewsEnabled && {
              'Eng Withe View': inf?.engagement
                ? NumberFormatter(Number(inf?.engagement + inf?.videoView), 1)
                : 0,
            },
            reportPPTData?.cpeWithoutViewsEnabled && {
              'CPE Without View': inf?.cpe ? `PKR${NumberFormatter(inf?.cpe, 2)}` : '--',
            },
            reportPPTData?.cpeWithViewsEnabled && {
              'CPE With View': inf?.cpeView ? `PKR${NumberFormatter(inf?.cpeView, 2)}` : '--',
            },
            reportPPTData?.CPREnabled && {
              CPR: inf?.cpr ? `PKR${NumberFormatter(inf?.cpr, 2)}` : '--',
            },
          )

          tempCSVData.push(payload)
        })

        setCSVData(tempCSVData)

        setTimeout(() => {
          csvRef?.current?.link?.click()
        }, 1000)
      }
    } catch (error) {
      console.log('err', error)
      notification.error({
        description: error || 'Error Occured',
        duration: 2,
      })
    }
  }

  const reportMenu = (reportID) => (
    <Menu>
      <Menu.Item
        icon={
          reportPptLoading == true ? (
            <LoadingOutlined
              style={{
                fontSize: 18,
              }}
              spin
            />
          ) : (
            <AiOutlineFilePpt className='dropdown-menu-icon' />
          )
        }
        // onClick={() => getCampaignReportPPT(reportID)}
        onClick={() => getChartReportPPT(reportID)}
        disabled={reportPptLoading}
      >
        Report PPT
      </Menu.Item>
      <Menu.Item icon={<GrDocumentCsv className='dropdown-menu-icon' />}>
        <span
          style={{ color: '#000000D9' }}
          onClick={() => {
            onCSVDownload(reportID)
          }}
        >
          Report CSV
        </span>
      </Menu.Item>
      <Menu.Item
        icon={<RiDeleteBinLine className='dropdown-menu-icon' />}
        onClick={() => deleteReport(reportID)}
      >
        Delete Report
      </Menu.Item>
      <Menu.Item
        icon={<AiOutlineShareAlt className='dropdown-menu-icon' />}
        onClick={() => copyToClipBoard(reportID)}
      >
        Share Report
      </Menu.Item>
    </Menu>
  )

  return (
    <Layout active={'reports'}>
      <CSVLink
        ref={csvRef}
        filename={`Campaign-Report-${moment().format('YYYY-MM-DD')}.csv`}
        data={CSVData}
        style={{ width: 'max-content', color: 'white' }}
      />
      <div className='campaigns-container'>
        <h2 className='mainHeading'>Reports</h2>
        {/* <p className='subHeading'>{getCampaigns?.length} Active Campaigns</p> */}

        {/* Table */}
        <div className='main'>
          <Table
            columns={columns}
            dataSource={data}
            // rowClassName="onHover"
            loader={false}
            pagination
            // onRow={(record) => {
            //   return {
            //     onClick: () => {
            //       navigate(`/campaign/report/${record?.id}`)
            //     },
            //   }
            // }}
          />
        </div>
      </div>
    </Layout>
  )
}

export default AllReports
