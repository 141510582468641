import { Col, Row, Tooltip } from "antd";
import { useState , useEffect} from "react";
import { FaInstagram, FaTiktok, FaYoutube } from "react-icons/fa";
import InfinityLoader from "../../../components/InfinityLoader";
import { attachToken, privateAPI } from "../../../config/constants";
import { NumberFormatter } from "../../../functions/NumberFormatter";

const FootPrint = ({ id }) => {
  const [data, setData] = useState(null);

  const getInfluencer = async () => {
    attachToken();
    const res = await privateAPI.get(
      `/influencer/get-influencer-footprint/${id}`
    );
    setData(res?.data?.data);
  };

  useEffect(() => {
    getInfluencer();
  }, []);

  return (
    <>
      {data ? (
        <div className="foot-print-container">
          <Row className="platform" gutter={[0, 0]}>
            <Col xl={5} xs={24}>
              <div className="info">
                <FaInstagram
                  color="white"
                  className="icon"
                  style={{ backgroundColor: "#E7008A" }}
                />
                <div>
                  <h2>Instagram</h2>
                  <p
                    className="link"
                    onClick={() => {
                      window.open(
                        `https://www.instagram.com/${data?.instagram?.username}`
                      );
                    }}
                  >
                    {data?.instagram?.username}
                  </p>
                </div>
              </div>
            </Col>
            <Col xl={18} xs={24}>
              <Row>
                <Col className="FootPrint_Col" md={4} sm={8} xs={12}>
                  <div className="Tooltip_Style">
                    <div className="heading">
                      {/* <Tooltip placement="right" title="Hi there"> */}
                      <h1>{NumberFormatter(data?.instagram?.followers, 2)}</h1>
                      {/* </Tooltip> */}
                      {/* <MdKeyboardArrowDown className="icon" /> */}
                    </div>
                    <p>Followers</p>
                  </div>

                  {/* <div className="Tooltip_Style">
                    <h1>{data?.instagram?.video_views_rate || '--'}</h1>
                    <p>VIDEO VIEWS RATE</p>
                  </div> */}
                  <div className="Tooltip_Style">
                    <h1>{NumberFormatter(data?.instagram?.avg_comments,1) || '--'}</h1>
                    <p>AVG COMMENTS</p>
                  </div>
                </Col>
                <Col className="FootPrint_Col" md={4} sm={8} xs={12}>
                  <div className="Tooltip_Style">
                    <h1>{NumberFormatter(data?.instagram?.follows, 1) || '--'}</h1>
                    <p>Following</p>
                  </div>
                  {/* <div className="Tooltip_Style">
                    <h1>{data?.instagram?.video_views_per_video_cost || '--'}</h1>
                    <p>VIDEO VIEWS PER VIDEO POST</p>
                  </div> */}
                </Col>
                <Col className="FootPrint_Col" md={4} sm={8} xs={12}>
                  <div className="Tooltip_Style">
                    <h1>{NumberFormatter(data?.instagram?.posts, 1) || '--'}</h1>
                    <p>POSTS</p>
                  </div>
                  {/* <div className="Tooltip_Style">
                    <h1>{NumberFormatter(data?.instagram?.avg_comments,1) || '--'}</h1>
                    <p>AVG COMMENTS</p>
                  </div> */}
                </Col>
                <Col className="FootPrint_Col" md={4} sm={8} xs={12}>
                  <div className="Tooltip_Style">
                    <h1>{data?.instagram?.posts_per_week || '--'}</h1>
                    <p>POSTS PER WEEK</p>
                  </div>
                </Col>
                <Col className="FootPrint_Col" md={4} sm={8} xs={12}>
                  <div className="Tooltip_Style">
                    {/* <Tooltip placement="right" title="Hi there helo"> */}
                    <h1>{data?.instagram?.engagement_rate || '--'}</h1>
                    {/* <h1>{mCampaign?.estEng || '--'}</h1> */}
                    {/* </Tooltip> */}
                    <p>ENGAGEMENT RATE</p>
                  </div>
                </Col>
                <Col className="FootPrint_Col" md={4} sm={8} xs={12}>
                  <div className="Tooltip_Style">
                    {/* <Tooltip placement="right" title="Hi there helo"> */}
                    <h1>{NumberFormatter(data?.instagram?.avg_likes,2) || '--'}</h1>
                    {/* </Tooltip> */}
                    <p>AVG LIKES</p>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>

          <Row className="platform disable" gutter={[0, 0]}>
            <Col xl={5} xs={24}>
              <div className="info">
                <FaTiktok className="icon" color="black" />
                <div>
                  <h2>TikTok</h2>
                  <p></p>
                </div>
              </div>
            </Col>
            <Col xl={18} xs={24}>
              <Row>
                <Col className="FootPrint_Col" md={4} sm={8} xs={12}>
                  <div className="Tooltip_Style">
                    <div className="heading">
                        <h1>0</h1>
                    </div>
                    <p>Followers</p>
                  </div>

                  <div className="Tooltip_Style">
                    <Tooltip placement="right" title="Hi there helo">
                      <h1>0</h1>
                    </Tooltip>
                    <p>AVG REACTIONS</p>
                  </div>
                </Col>
                <Col className="FootPrint_Col" md={4} sm={8} xs={12}>
                  <div className="Tooltip_Style">
                      <h1>0</h1>
                    <p>Following</p>
                  </div>
                  <div className="Tooltip_Style">
                      <h1>0</h1>
                    <p>AVG COMMENTS</p>
                  </div>
                </Col>
                <Col className="FootPrint_Col" md={4} sm={8} xs={12}>
                  <div className="Tooltip_Style">
                      <h1>0</h1>
                    <p>VIDEOS</p>
                  </div>
                  <div className="Tooltip_Style">
                    <Tooltip placement="right" title="Hi there helo">
                      <h1>0</h1>
                    </Tooltip>
                    <p>AVG SHARE</p>
                  </div>
                </Col>
                <Col className="FootPrint_Col" md={4} sm={8} xs={12}>
                  <div className="Tooltip_Style">
                      <h1>0</h1>
                    <p>LIKES</p>
                  </div>
                  <div className="Tooltip_Style">
                      <h1>0</h1>
                    <p>VIDEO VIEWS RATE</p>
                  </div>
                </Col>
                <Col className="FootPrint_Col" md={4} sm={8} xs={12}>
                  <div className="Tooltip_Style">
                      <h1>0</h1>
                    <p>POST PER WEEK</p>
                  </div>
                  <div className="Tooltip_Style">
                      <h1>0</h1>
                    <p>VIDEO VIEWS PER VIDEO POST</p>
                  </div>
                </Col>
                <Col className="FootPrint_Col" md={4} sm={8} xs={12}>
                  <div className="Tooltip_Style">
                      <h1>0</h1>
                    <p>ENGAGEMENT RATE</p>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>

          <Row className="platform disable" gutter={[0, 0]}>
            <Col xl={5} xs={24}>
              <div className="info">
                <FaYoutube
                  className="icon"
                  // style={{ backgroundColor: "#ff0000" }}
                />
                <div>
                  <h2>YouTube</h2>
                  <p></p>
                </div>
              </div>
            </Col>
            <Col xl={18} xs={24}>
              <Row>
                <Col className="FootPrint_Col" md={4} sm={8} xs={12}>
                  <div className="Tooltip_Style">
                    <div className="heading">
                        <h1>0</h1>
                    </div>
                    <p>Followers</p>
                  </div>

                  <div className="Tooltip_Style">
                      <h1>0</h1>
                    <p>AVG REACTIONS</p>
                  </div>
                </Col>
                <Col className="FootPrint_Col" md={4} sm={8} xs={12}>
                  <div className="Tooltip_Style">
                      <h1>0</h1>
                    <p>Following</p>
                  </div>
                  <div className="Tooltip_Style">
                      <h1>0</h1>
                    <p>AVG COMMENTS</p>
                  </div>
                </Col>
                <Col className="FootPrint_Col" md={4} sm={8} xs={12}>
                  <div className="Tooltip_Style">
                      <h1>0</h1>
                    <p>VIDEOS</p>
                  </div>
                  <div className="Tooltip_Style">
                      <h1>0</h1>
                    <p>AVG SHARE</p>
                  </div>
                </Col>
                <Col className="FootPrint_Col" md={4} sm={8} xs={12}>
                  <div className="Tooltip_Style">
                      <h1>0</h1>
                    <p>LIKES</p>
                  </div>
                  <div className="Tooltip_Style">
                      <h1>0</h1>
                    <p>VIDEO VIEWS RATE</p>
                  </div>
                </Col>
                <Col className="FootPrint_Col" md={4} sm={8} xs={12}>
                  <div className="Tooltip_Style">
                      <h1>0</h1>
                    <p>POST PER WEEK</p>
                  </div>
                  <div className="Tooltip_Style">
                      <h1>0</h1>
                    <p>VIDEO VIEWS PER VIDEO POST</p>
                  </div>
                </Col>
                <Col className="FootPrint_Col" md={4} sm={8} xs={12}>
                  <div className="Tooltip_Style">
                      <h1>0</h1>
                    <p>ENGAGEMENT RATE</p>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      ) : (
        <InfinityLoader height={"40vh"} />
      )}
    </>
  );
};
export default FootPrint;
