import React from 'react'
import { Layout } from 'antd'

// import { FiLogOut } from 'react-icons/fi'
import Logo from '../../assets/mainlogo.png'

const PublicMobileLayout = ({ children }) => {
  const { Header, Content } = Layout
  return (
    <Layout className='pub-m-layout'>
      <div className='main'>
        <Header className='mainHeader mobileHeader'>
          <div className='left'>
            <img src={Logo} style={{ height: '60px' }} />
          </div>
        </Header>
      </div>
      <Content className='m-children'>{children}</Content>
    </Layout>
  )
}

export default PublicMobileLayout
