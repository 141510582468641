import { useState } from 'react'
import { Modal, Button, Form, Input, notification, Select } from 'antd'

import { IoAddCircleOutline } from 'react-icons/io5'
import { attachToken, privateAPI } from '../../config/constants'
import { useDispatch, useSelector } from 'react-redux'
import { getAllInfluencerCateories } from '../../redux'

const AddInfluencer = ({ text, btn }) => {
  const { Option } = Select
  const [form] = Form.useForm()
  const dispatch = useDispatch()

  const [isModalVisible, setIsModalVisible] = useState(false)
  const [loading, setLoading] = useState(false)

  const userData = JSON.parse(localStorage.getItem('user'))
  const adminType = JSON.parse(localStorage.getItem('adminType'))

  const influencerCategories = useSelector((state) => state?.network?.categories)

  const showModal = () => {
    setIsModalVisible(true)
    dispatch(getAllInfluencerCateories())
  }

  const onFinish = async (values) => {
    setLoading(true)
    // Influencer handle and Url Query
    let handle = values?.influencerHandle
    let handleArray = handle?.split('')
    let handleSlashSplit = handle?.split('/')
    let influencerhandle = ''

    // Influencer handle and Url set
    if (handleArray[0] === '@') {
      influencerhandle = handleArray?.slice(1, handleArray.length).join('')
    } else if (handleSlashSplit?.length > 2) {
      if (
        handleSlashSplit[handleSlashSplit?.length - 1] === '' ||
        handleSlashSplit[handleSlashSplit?.length - 1] === '?'
      ) {
        influencerhandle = handleSlashSplit[handleSlashSplit?.length - 2]
      } else {
        influencerhandle = handleSlashSplit[handleSlashSplit?.length - 1]
      }
    } else {
      influencerhandle = handle
    }

    influencerhandle = influencerhandle?.replace(/\?.*/, '')

    let payload = {
      name: values?.name,
      influencerHandle: influencerhandle,
      adminId: adminType === 'moderator' ? userData?.adminId : userData?._id,
      gender: values?.gender,
      category: values?.category || null,
      reference: values?.reference || null,
    }
    try {
      attachToken()
      const res = await privateAPI.post(`/influencer/create`, payload)
      notification.success({
        description: res.data.message,
        duration: 2,
      })
      window.location.reload()
      setLoading(false)
      setIsModalVisible(false)
      form.resetFields()
    } catch (err) {
      notification.error({
        description: err?.response?.data?.message,
        duration: 4,
      })
      setLoading(false)
    }
  }

  const handleCancel = () => {
    setIsModalVisible(false)
  }

  return (
    <>
      {btn ? (
        text ? (
          <Button onClick={() => showModal()}>{text}</Button>
        ) : (
          <span onClick={() => showModal()}>{text}</span>
        )
      ) : (
        <IoAddCircleOutline className='icon' onClick={() => showModal()} />
      )}

      <Modal
        title={'Add Influencer'}
        visible={isModalVisible}
        footer={false}
        onCancel={handleCancel}
        width={'600px'}
      >
        <Form
          layout='vertical'
          form={form}
          onFinish={onFinish}
          initialValues={{
            gender: 'Other',
          }}
        >
          <Form.Item
            label='URL/Handle'
            name={'influencerHandle'}
            rules={[
              {
                required: true,
                message: '@handle is Required',
              },
            ]}
            requiredMark={'optional'}
          >
            <Input placeholder='Enter a URL of any Instagram or an @handle' />
          </Form.Item>
          <Form.Item
            label='Name'
            name={'name'}
            rules={[
              {
                required: true,
                message: 'Name is Required',
              },
            ]}
            requiredMark={'optional'}
          >
            <Input placeholder='Enter the influencer name' />
          </Form.Item>
          <Form.Item
            label='Gender'
            name={'gender'}
            rules={[
              {
                required: false,
              },
            ]}
          >
            <Select size='large' placeholder='Select a Gender' defaultValue={'other'}>
              <Option value='Male'>Male</Option>
              <Option value='Female'>Female</Option>
              <Option value='Other'>Other</Option>
            </Select>
          </Form.Item>
          <Form.Item label='Category (Optional)' name={'category'}>
            <Select
              size='large'
              // className='select'
              style={{ minWidth: '120px' }}
              placeholder='Select Category'
            >
              {influencerCategories?.map((cat) => (
                <Option key={cat?._id} value={cat?._id}>
                  {cat?.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item>
            <Form.Item
              label='Reference'
              name={'reference'}
              // rules={[
              //   {
              //     required: true,
              //     message: 'Name is Required',
              //   },
              // ]}
              requiredMark={'optional'}
            >
              <Input placeholder='' />
            </Form.Item>
            <Button htmlType='submit' loading={loading} type='primary'>
              Add
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </>
  )
}

export default AddInfluencer
