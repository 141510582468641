import React, { useState } from 'react'
import { Button, Form, Modal, Input, notification } from 'antd'

import { FaPlus } from 'react-icons/fa'

import { privateAPI, attachToken } from '../../config/constants'

const CreateCategory = ({ getCategories }) => {
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [loading, setLoading] = useState(false)
  const [form] = Form.useForm()

  const createCategory = async (payload) => {
    try {
      setLoading(true)
      attachToken()
      const res = await privateAPI.post('/category/create', payload)
      if (res) {
        notification.success({
          message: res?.data?.message,
          duration: 2,
        })
        getCategories()
        setLoading(false)
        setIsModalVisible(false)
      }
    } catch (error) {
      setLoading(false)
      console.log('error', error)
      notification.error({
        description: error,
        duration: 3,
      })
    }
  }

  const showModal = () => {
    setIsModalVisible(true)
  }

  const handleCancel = () => {
    setIsModalVisible(false)
  }

  const onFinish = (values) => {
    const payload = {
      ...values,
    }
    createCategory(payload)
  }
  return (
    <>
      <span style={{ cursor: 'pointer' }} onClick={showModal}>
        <FaPlus className='add-icon' />
      </span>
      <Modal
        title={'Create Category'}
        visible={isModalVisible}
        footer={false}
        onCancel={handleCancel}
        width={'600px'}
      >
        <Form layout='vertical' form={form} onFinish={onFinish}>
          <Form.Item
            label='Name'
            name={'name'}
            rules={[
              {
                required: true,
                message: 'Name is Required',
              },
            ]}
            requiredMark={'optional'}
          >
            <Input />
          </Form.Item>

          <Form.Item>
            <Button htmlType='submit' loading={loading} type='primary'>
              Create
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </>
  )
}

export default CreateCategory
