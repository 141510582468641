import DonutChart from '../../Charts/DonutChart'
import InfinityLoader from '../../InfinityLoader'

 // Get Top Gender
 const getTopGender = (data) => {
  const values = data?.map((e) => e?.percent)
  const max = Math.max(...values)
  const index = values.indexOf(max)
  const topGender = data?.[index]
  return topGender || {}
}


function Gender({ data, totalAudience }) {

  return (
    <div className='donut-chart'>
      {data ? (
        <div>
          <h3>Gender</h3>
          <div id='gender-div-id' style={{ backgroundColor: 'white' }}>
            <DonutChart data={data} totalAudience={totalAudience} top={getTopGender(data)} />
            <div>
              {data?.map((item, ind) => (
                <div key={ind} className='gender-div'>
                  <div className='name'>
                    <div
                      style={{
                        background:
                          item?.name === 'Female'
                            ? '#ef5a29'
                            : item?.name === 'Male'
                            ? '#fcd145'
                            : 'rgb(59, 59, 59)',
                        width: '10px',
                        height: '10px',
                        borderRadius: '50%',
                      }}
                    ></div>
                    <p>{item?.name || ''}</p>
                  </div>
                  <p>{item?.value || '-'}</p>
                  <p>{`${item?.percent}%`}</p>
                </div>
              ))}
            </div>
          </div>
        </div>
      ) : (
        <InfinityLoader height={'400px'} />
      )}
    </div>
  )
}

export default Gender
