import moment from 'moment'
import { Line } from '@ant-design/plots'

const DemoLine = ({ data, loader }) => {
  const config = {
    data: data || [],
    xField: 'date',
    yField: 'value',
    seriesField: 'keyword',
    smooth: true,
    color: [
      '#ef5a29',
      '#fcd145',
      'rgb(59, 59, 59)',
      '#F39C12',
      '#DC7633',
      '#F76028',
      '#A6ACAF',
      '#ECF0F1',
      '#34495E',
    ],
    tooltip: {
      title: (title) => {
        return moment(title).format('MMM DD') || title
      },
    },
    xAxis: {
      label: {
        formatter: (Data) => {
          return moment(Data).format('MMM DD')
        },
      },
    },
  }

  return (
    <div style={{ height: '500px', position: 'relative' }}>
      <Line {...config} loading={loader} />
    </div>
  )
}

export default DemoLine
