import { useEffect, useState } from 'react'
import { Bar } from '@ant-design/plots'
import { NumberFormatter } from '../../functions/NumberFormatter'

const StackedBarChart = ({ data, filter }) => {
  const [graphData, setGraphData] = useState([])

  useEffect(() => {
    let tempArr = []
    data?.map((i) => {
      tempArr.push(...i[filter])
    })
    setGraphData(tempArr)
  }, [data])

  const config = {
    data: graphData || [],
    isStack: true,
    xField: 'value',
    yField: 'keyword',
    barWidthRatio: 0.3,
    height: 300,
    xAxis: false,
    yAxis: {
      display: false,
      line: false,
    },
    seriesField: 'platform',
    color: ['#4dd7fe', '#4394fb', '#E7008A', '#212F3D', '#8C44F7', '#E74C3C'],
    legend: false,
    label: false,
    tooltip: {
      title: (title) => {
        return String(title).toUpperCase()
      },
      customContent: (title, data) => {
        return (
          <div
            style={{
              backgroundColor: 'white',
              borderRadius: '6px',
              padding: '10px 4px',
              paddingBottom: '0',
              width: '100%',
            }}
          >
            {data?.map((i, ind) => (
              <div
                key={ind}
                style={{
                  color: 'rgb(89, 89, 89)',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  gap: '20px',
                  width: '100%',
                }}
              >
                <p
                  style={{
                    color: '#444e5a',
                    fontSize: '14px',
                    fontWeight: 600,
                    display: 'flex',
                    alignItems: 'center',
                    gap: '10px',
                  }}
                >
                  <div
                    style={{
                      background: i?.color,
                      width: '8px',
                      height: '8px',
                      borderRadius: '50%',
                     
                    }}
                  ></div>
                  {i?.name}
                </p>

                <p
                  style={{
                    fontSize: '13px',
                    fontWeight: 600,
                    color: '#7E84A3',
                  }}
                >
                  {NumberFormatter(i?.value, 1)}
                </p>
              </div>
            ))}
          </div>
        )
      },
    },
  }
  return <Bar {...config} loading={graphData?.length > 0 ? false : true} />
}

export default StackedBarChart
