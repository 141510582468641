import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import { FiPlusCircle } from 'react-icons/fi'

import Layout from '../../layout/Layout'
import DataTable from '../../components/Table/DataTable'
import { privateAPI, attachToken } from '../../config/constants'
import CreateCategory from '../../components/modal/CreateCategory'
import UpdateInfCategory from '../../components/modal/UpdateInfCategory'

const InfluencerCategories = () => {
  const navigate = useNavigate()
  const [data, setData] = useState([])
  const [dataLoading, setDataLoading] = useState(false)

  const getAllCategories = async () => {
    try {
      setDataLoading(true)
      attachToken()
      const res = await privateAPI.get('/category/get-all')
      if (res) {
        setDataLoading(false)
        let tempData = res?.data

        tempData = tempData.sort((a, b) => {
          
          const nameA = a?.name?.toUpperCase(); // ignore upper and lowercase
          const nameB = b?.name?.toUpperCase(); // ignore upper and lowercase
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
        
          // names must be equal
          return 0;
        });

        setData(tempData)
      }
    } catch (error) {
      setDataLoading(false)
      console.log('error', error)
    }
  }

  useEffect(() => {
    getAllCategories()
  }, [])

  const columns = [
    {
      title: 'CATEGORY',
      dataIndex: 'name',
      render: (name, row) => (
        <p
          className='category-name-hover'
          onClick={() => navigate(`/view-category-influencers/${row?._id}`, { state: row })}
        >
          {name}
        </p>
      ),
    },
    {
      title: 'Actions',
      dataIndex: '',
      render: (a, row) => (
        <div style={{ display: 'flex', alignItems: 'center', gap: '1rem' }}>
          <UpdateInfCategory data={row} getCategories={getAllCategories} />
          <FiPlusCircle
            style={{ color: 'var(--primary)', cursor: 'pointer', width: '18px', height: '18px' }}
            onClick={() => navigate(`/category/${row?._id}`, { state: row })}
          />
        </div>
      ),
    },
  ]

  return (
    <Layout active={'influencerCategory'}>
      <div className='bulk_upload_influencers'>
        <h2 className='mainHeading' style={{ fontSize: '26px', fontWeight: 500 }}>
          Categories
        </h2>
        <div className='filter-bar'>
          <div className='left-side'></div>
          <div className='right-side'>
            <CreateCategory getCategories={getAllCategories} />
          </div>
        </div>

        <div style={{ marginTop: '2rem' }}>
          <DataTable
            columns={columns}
            data={data}
            loader={dataLoading}
            // width={1400}
          />
        </div>
      </div>
    </Layout>
  )
}

export default InfluencerCategories
