import React, { useState, useEffect } from 'react'
import { Row, Col, Input, Select, Form, Collapse, notification } from 'antd'
import { useSelector } from 'react-redux'

import { FiSearch } from 'react-icons/fi'

import { privateAPI, attachToken } from '../../../../config/constants'
import InfinityLoader from '../../../../components/InfinityLoader'
import InfluencerCard from '../../../../components/Cards/InfluencerCard'

const CategoryComp = ({
  state,
  selectedInfluencerCategories,
  setSelectedInfluencerCategories,
  categoriesInfluencers,
  setCategoriesInfluencers,
  overAllSelected,
  setOverAllSelected,
  overAllSelectedObj,
  setOverAllSelectedObj,
  apiLoading,
  setApiLoading,
}) => {
  const { Option } = Select
  const { Panel } = Collapse
  const influencerCategories = useSelector((state) => state?.network?.categories)
  const [search, setSearch] = useState({
    catId: null,
    searchString: '',
  })

  const getCategoriesInfluencers = async () => {
    try {
      attachToken()
      const res = await privateAPI.post('/category/get-categories-influencers', {
        categoryIds: selectedInfluencerCategories,
      })
      if (res?.data) {
        setCategoriesInfluencers(res?.data?.data)
      }
    } catch (error) {
      notification.error({
        description: error?.response?.data?.message || 'Server Error',
        duration: 2,
      })
    }
  }

  const getCategoryInfluencersOnSearch = async (categoryId) => {
    try {
      setApiLoading(true)
      attachToken()
      const res = await privateAPI.post('/category/get-category-influencers-search', {
        categoryId: categoryId,
        searchString: search?.searchString,
      })
      if (res?.data) {
        let tempCatsInfs = [...categoriesInfluencers]
        const index = tempCatsInfs?.findIndex((item) => item?.category?._id == categoryId)
        tempCatsInfs[index].influencers = res?.data?.data?.influencers
        setApiLoading(false)
        setCategoriesInfluencers(tempCatsInfs)
      }
    } catch (error) {
      setApiLoading(false)
      notification.error({
        description: error?.response?.data?.message || 'Server Error',
        duration: 2,
      })
    }
  }

  useEffect(() => {
    if (selectedInfluencerCategories?.length > 0) {
      getCategoriesInfluencers()
    }
  }, [selectedInfluencerCategories])

  return (
    <>
      <Col xs={24} md={24} lg={24}>
        <Form.Item
          label='Category'
          name={'category'}
          rules={[
            {
              required: false,
              message: '',
              // message: 'Category is Required',
            },
          ]}
          requiredMark={'optional'}
        >
          <Select
            mode='multiple'
            style={{ width: '100%' }}
            onChange={(e) => {
              setSelectedInfluencerCategories(e)
            }}
            size='large'
            placeholder='Select Categories'
          >
            {influencerCategories?.map((cat) => (
              <Option key={cat?._id} value={cat?._id}>
                {cat?.name}
              </Option>
            ))}
          </Select>
        </Form.Item>
      </Col>

      <Col xs={24}>
        <Collapse accordion className='category-influencers-collapse'>
          {categoriesInfluencers?.map((item, index) => (
            <Panel
              header={
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    gap: '1rem',
                    flexWrap: 'wrap',
                  }}
                >
                  <p>
                    {
                      influencerCategories?.filter((cat) => cat?._id == item?.category?._id)?.[0]
                        ?.name
                    }
                  </p>
                  <div>
                    <Input
                      placeholder='Search'
                      prefix={<FiSearch className='search-icon' />}
                      className='searchInput'
                      size='small'
                      style={{ maxWidth: '200px' }}
                      value={search?.catId === item?.category?._id ? search?.searchString : ''}
                      onClick={(e) => e.stopPropagation()}
                      onChange={(e) =>
                        setSearch({
                          catId: item?.category?._id,
                          searchString: e.target.value,
                        })
                      }
                      onKeyDown={(e) => {
                        console.log(e.key)
                        if (e.key === 'Enter') {
                          e.preventDefault()
                          getCategoryInfluencersOnSearch(item?.category?._id)
                        }
                      }}
                    />
                  </div>
                </div>
              }
              key={index}
            >
              <Row gutter={[10, 10]} style={{ minHeight: '200px' }}>
                {!apiLoading ? (
                  item?.influencers?.map((inf, ind) => (
                    <Col xxl={4} xl={6} md={8} sm={12} xs={24} key={ind}>
                      <InfluencerCard
                        data={{
                          id: inf?._id,
                          profileImage: inf?.arch_profile_picture_url || inf?.profile_picture_url,
                          name: inf?.name,
                          userName: inf?.instagram_username,
                        }}
                        // selectedInfluencers={selectedCategoriesInfluencers}
                        selectedInfluencers={overAllSelected}
                        // setSelectedInfluencers={setSelectedCategoriesInfluencers}
                        setSelectedInfluencers={setOverAllSelected}
                        object={inf}
                        // objectsArr={selectedCategoryInfluencers}
                        objectsArr={overAllSelectedObj}
                        // setObjects={setSelectedCategoryInfluencers}
                        setObjects={setOverAllSelectedObj}
                        state={state}
                      />
                    </Col>
                  ))
                ) : (
                  <InfinityLoader />
                )}
              </Row>
            </Panel>
          ))}
        </Collapse>
      </Col>
    </>
  )
}

export default CategoryComp
