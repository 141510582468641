import { useState , useEffect } from 'react'

import { AiOutlineInstagram } from 'react-icons/ai'

// import InfinityLoader from '../../../../components/InfinityLoader'
import InstagramAudience from './components/InstagramAudience'
import { publicAPI } from '../../../../config/constants'

const InfluencerAudience = ({ id, activeTab }) => {
  const [active, setActive] = useState('instagram')
  const [data, setData] = useState(null)

  const getInfluencerAudience = async () => {
    const res = await publicAPI.get(`/influencer/get-influencer-audience/${id}`)
    setData(res?.data?.data)
  }

  useEffect(() => {
    getInfluencerAudience()
  }, [])

  return (
    <>
      <div className='chart_parent'>
        <div className='Audience_div'>
          <h2>Audience insights for {data?.name} on instagram</h2>
          <div className='icon_div'>
            <AiOutlineInstagram
              className={`social_icon ${active === 'instagram' && 'active'}`}
              onClick={() => {
                setActive('instagram')
              }}
            />
            {/* <TbBrandTiktok
            className={`social_icon ${active === "tiktok" && "active"}`}
            onClick={() => {
              setActive("tiktok");
            }}
          />
          <AiOutlineYoutube
            className={`social_icon ${active === "youtube" && "active"}`}
            onClick={() => {
              setActive("youtube");
            }}
          /> */}
          </div>
        </div>
        <>
          <InstagramAudience id={id} activeTab={activeTab} data={data} />
        </>
      </div>
    </>
  )
}

export default InfluencerAudience
