import { useEffect } from 'react'
import { Navigate, Outlet,useLocation } from 'react-router-dom'
import IdleTimerContainer  from '../components/IdleTimerComponent'
const PrivateWrapper = () => {
  const {pathname} = useLocation()
  const isAuthenticated = localStorage.getItem('token')
  
  useEffect(() => {    
    window.scrollTo(0, 0)
  }, [pathname]) 

  if (isAuthenticated) {
      return(
      <>
       <Outlet />
       <IdleTimerContainer/>
      </>
       )
  } else {
    return <Navigate to='/login' />
  }
}

export default PrivateWrapper
