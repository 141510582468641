import React, { useState } from 'react'
import {
  Modal,
  Button,
  Select,
  Input,
  Form,
  Upload,
  Row,
  Col,
  notification,
  DatePicker,
} from 'antd'
import moment from 'moment'

import { InboxOutlined } from '@ant-design/icons'
import { AiFillDelete } from 'react-icons/ai'

import { GetReach } from '../../functions/GetReach'
import { privateAPI } from '../../config/constants'

const AddCustomCampaignContent = ({ id, influecersList, campaignDate, getCampaignContent }) => {
  const { Option } = Select
  const { Dragger } = Upload
  const [form] = Form.useForm()
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [fileState, setFileState] = useState(null)
  const [postType, setPostType] = useState('')
  const [loading, setLoading] = useState(false)
  const [tempImageState, setTempImageState] = useState(null)

  const addCustomContent = async (payload) => {
    try {
      setLoading(true)
      const res = await privateAPI.post('/custom-content/add-custom-content-campaign', payload)
      // let res;
      if (res) {
        setLoading(false)
        form.resetFields()
        setFileState(null)
        setIsModalVisible(false)
        getCampaignContent()
        notification.success({
          message: 'Content Added Successfully',
          duration: 3,
        })
      }
    } catch (err) {
      setLoading(false)
      notification.error({
        message: err,
        duration: 2,
      })
      console.log(err)
    }
  }

  function getVideoCover(file, seekTo = 0.0) {
    // console.log('getting video cover for file: ', file)
    return new Promise((resolve, reject) => {
      // load the file to a video player
      const videoPlayer = document.createElement('video')
      videoPlayer.setAttribute('src', URL.createObjectURL(file))
      videoPlayer.load()
      videoPlayer.addEventListener('error', (ex) => {
        reject('error when loading video file', ex)
      })
      // load metadata of the video to get video duration and dimensions
      videoPlayer.addEventListener('loadedmetadata', () => {
        // seek to user defined timestamp (in seconds) if possible
        if (videoPlayer.duration < seekTo) {
          reject('video is too short.')
          return
        }
        // delay seeking or else 'seeked' event won't fire on Safari
        setTimeout(() => {
          videoPlayer.currentTime = seekTo
        }, 200)
        // extract video thumbnail once seeking is complete
        videoPlayer.addEventListener('seeked', () => {
          // console.log('video is now paused at %ss.', seekTo)
          // define a canvas to have the same dimension as the video
          const canvas = document.createElement('canvas')
          canvas.width = videoPlayer.videoWidth
          canvas.height = videoPlayer.videoHeight
          // draw the video frame to canvas
          const ctx = canvas.getContext('2d')
          ctx.drawImage(videoPlayer, 0, 0, canvas.width, canvas.height)
          // return the canvas image as a blob
          // ctx.canvas.toDataURL("image/jpeg")
          // ctx.canvas.toDataURL((url) => resolve(url))
          var jpegFile = canvas.toDataURL('image/jpeg')
          resolve(jpegFile)
          // console.log('jpegFile',jpegFile)
          // return jpegFile
          // ctx.canvas.toBlob(
          //   async (blob) => {
          //     // const arrayBuffer = await blob.arrayBuffer()
          //     // console.log('arrayBuffer',arrayBuffer)
          //     // console.log('arrayBuffer dot buffer',arrayBuffer.buffer)
          //     // const temp8 = new Uint8Array(arrayBuffer)
          //     // const temp8 = new Uint8Array(arrayBuffer)
          //     // console.log('temp8',temp8)
          //     // const buffer = Buffer.from(arrayBuffer)
          //     resolve(blob)
          //   },
          //   'image/jpeg',
          //   0.75 /* quality */,
          // )
        })
      })
    })
  }

  const showModal = () => {
    setIsModalVisible(true)
  }

  const handleCancel = () => {
    setIsModalVisible(false)
    form.resetFields()
  }

  const LocalEngagmentCalculate = (interactions, followers) => {
    let eng = ((interactions / followers) * 100).toFixed(2)
    return Number(eng || 0)
  }

  const onFinish = async (values) => {
    // console.log(values)
    let tempInf = influecersList?.filter((inf) => inf?._id === values?.influencerId)

    let tempReach = values?.reach
      ? Number(values?.reach)
      : values?.type == 'STORY'
      ? GetReach({
          type: values?.type,
          engRate: LocalEngagmentCalculate(
            Number(values?.replies) +
              Number(values?.tapForward) +
              Number(values?.tapBackward) +
              Number(values?.exits),
            tempInf[0]?.instagram?.followers_count,
          ),
          videoViews: values?.videoViews || 0,
          followers: tempInf[0]?.instagram?.followers_count,
        })
      : GetReach({
          type: values?.type,
          engRate: LocalEngagmentCalculate(
            Number(values?.likes) + Number(values?.comments),
            tempInf[0]?.instagram?.followers_count,
          ),
          videoViews: values?.videoViews || 0,
          followers: tempInf[0]?.instagram?.followers_count,
        })

    let tempLocalEng =
      values?.type == 'STORY'
        ? LocalEngagmentCalculate(
            Number(values?.replies) +
              Number(values?.tapForward) +
              Number(values?.tapBackward) +
              Number(values?.exits),
            tempInf[0]?.instagram?.followers_count,
          )
        : LocalEngagmentCalculate(
            Number(values?.likes) + Number(values?.comments),
            tempInf[0]?.instagram?.followers_count,
          )

    // {
    //   fieldname: 'pictures',
    //   originalname: 'pexels-luis-quintero-18075634 (720p).mp4',
    //   encoding: '7bit',
    //   mimetype: 'video/mp4',
    //   buffer: <Buffer 00 00 00 1c 66 74 79 70 6d 70 34 32 00 00 00 00 6d 70 34 32 69 73 6f 6d 61 76 63 31 00 00 74 df 6d 6f 6f 76 00 00 00 78 6d 76 68 64 01 00 00 00 00 00 ... 11496155 more bytes>,
    //   size: 11496205
    // }
    console.log('fileState', fileState)
    const formData = new FormData()
    // console.log('fileState', fileState)
    formData.append('pictures', fileState)
    formData.append('thumbnail', tempImageState)
    formData.append('campaignId', id)
    formData.append('influencerId', values?.influencerId)
    formData.append('createdAt', values?.createdAt?._d)
    formData.append('type', values?.type)
    if (values?.impressions && values?.impressions?.length > 0) {
      formData.append('impressions', Number(values?.impressions))
    }
    formData.append('reach', tempReach)
    formData.append('localEng', tempLocalEng)
    formData.append('url', values?.url)

    if (values?.type === 'STORY') {
      formData.append('storyType', values?.storyType)
      formData.append('replies', values?.replies)
      formData.append('tapForward', values?.tapForward)
      formData.append('tapBackward', values?.tapBackward)
      formData.append('exits', values?.exits)
    }
    if (values?.type !== 'STORY') {
      formData.append('caption', values?.caption)
    }
    if (values?.videoViews) {
      formData.append('videoViews', Number(values?.videoViews))
      // payload = { ...payload, videoViews: Number(values?.videoViews) }
    }
    if (values?.likes) {
      formData.append('likes', Number(values?.likes))
      // payload = { ...payload, likes: Number(values?.likes) }
    }
    if (values?.comments) {
      formData.append('comments', Number(values?.comments))
      // payload = { ...payload, comments: Number(values?.comments) }
    }

    addCustomContent(formData)
  }

  const disabledDate = (current) => {
    // Can not select days before today and today
    return (
      (current && current < moment(campaignDate?.startOf).startOf('day')) ||
      current > moment(campaignDate?.endOf).endOf('day')
    )
    // return (
    //   d?.isBefore(moment(campaignDate?.startOf)) ||
    //   d?.isAfter(moment(campaignDate?.endOf))
    // )
  }

  const props = {
    name: 'file',
    customRequest: () => {},
    multiple: false,
    showUploadList: false,
    onChange(info) {
      const { originFileObj } = info.file
      // console.log('originFileObj', originFileObj)
      form.setFieldValue('content', originFileObj)
      setFileState(originFileObj)
      getVideoCover(originFileObj, 0.5)
        .then((blob) => {
          // console.log(blob)
          setTempImageState(blob)
        })
        .catch((err) => console.log(err))
    },
    onDrop(e) {
      form.setFieldValue('content', e?.dataTransfer?.files?.[0])
      setFileState(e?.dataTransfer?.files?.[0])
    },
  }

  return (
    <>
      <Button onClick={() => showModal()}>Add Content</Button>
      <Modal
        title={'Add Custom Campaign Content'}
        open={isModalVisible}
        footer={false}
        onCancel={handleCancel}
        width={'700px'}
      >
        <Form layout='vertical' form={form} onFinish={onFinish}>
          <Row gutter={[10, 0]}>
            <Col xl={8} lg={8} md={12} xs={24}>
              <Form.Item
                label='Influencer'
                name={'influencerId'}
                rules={[
                  {
                    required: true,
                    message: 'Influencer is Required',
                  },
                ]}
                requiredMark={'optional'}
              >
                <Select showSearch allowClear placeholder='Select Influencer'>
                  {influecersList?.map((inf, index) => (
                    <Option key={index} value={inf?._id}>
                      {inf?.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>

            <Col xl={8} lg={8} md={12} xs={24}>
              <Form.Item
                label='Post Type'
                name={'type'}
                rules={[
                  {
                    required: true,
                    message: 'Post Type is Required',
                  },
                ]}
                requiredMark={'optional'}
              >
                <Select allowClear placeholder='Select Post Type' onChange={(e) => setPostType(e)}>
                  <Option value={'IMAGE'}>Image</Option>
                  <Option value={'VIDEO'}>Video</Option>
                  <Option value={'STORY'}>Story</Option>
                </Select>
              </Form.Item>
            </Col>

            {postType === 'STORY' && (
              <Col xl={8} lg={8} md={12} xs={24}>
                <Form.Item
                  label='Story Type'
                  name={'storyType'}
                  rules={[
                    {
                      required: true,
                      message: 'Post Type is Required',
                    },
                  ]}
                  requiredMark={'optional'}
                >
                  <Select allowClear placeholder='Select Post Type'>
                    <Option value={'IMAGE'}>Image</Option>
                    <Option value={'VIDEO'}>Video</Option>
                  </Select>
                </Form.Item>
              </Col>
            )}

            <Col xs={24}>
              <Form.Item
                label='Create Date'
                name={'createdAt'}
                rules={[
                  {
                    required: true,
                    message: 'Select post create date',
                  },
                ]}
                requiredMark={'optional'}
              >
                <DatePicker
                  disabledDate={disabledDate}
                  style={{ width: '100%', borderRadius: '5px' }}
                />
              </Form.Item>
            </Col>

            <Col xs={24}>
              <Form.Item
                label='Content'
                name={'content'}
                valuePropName='file'
                // rules={[
                //   {
                //     required: true,
                //     message: 'Content is Required',
                //   },
                // ]}
                requiredMark={'optional'}
              >
                {fileState ? (
                  <>
                    <div className='uploaded-div'>
                      <AiFillDelete className='delete' onClick={() => setFileState(null)} />
                      <span>Uploaded Successfully</span>
                    </div>
                    {/* {tempImageState !== null && <img src={tempImageState} />} */}
                  </>
                ) : (
                  <Dragger
                    {...props}
                    style={{ display: 'flex', alignItems: 'center', minHeight: '200px' }}
                  >
                    <p className='ant-upload-drag-icon'>
                      <InboxOutlined />
                    </p>
                    <p className='ant-upload-text'>Drop Post Content Here</p>
                  </Dragger>
                )}
              </Form.Item>
            </Col>

            {postType !== 'STORY' && (
              <Col xs={24}>
                <Form.Item
                  label='Caption'
                  name={'caption'}
                  rules={[
                    {
                      required: true,
                      message: 'Enter Caption for content',
                    },
                  ]}
                  requiredMark={'optional'}
                >
                  <Input placeholder='Enter Caption' />
                </Form.Item>
              </Col>
            )}

            {postType !== 'STORY' && (
              <Col xs={24}>
                <Form.Item
                  label='URL'
                  name={'url'}
                  rules={[
                    {
                      required: false,
                      message: '',
                    },
                  ]}
                  requiredMark={'optional'}
                >
                  <Input placeholder='Enter URL' />
                </Form.Item>
              </Col>
            )}

            {postType !== 'STORY' && (
              <Col xl={8} lg={8} md={12} xs={24}>
                <Form.Item
                  label='Likes'
                  name={'likes'}
                  rules={[
                    {
                      required: true,
                      message: '',
                    },
                  ]}
                  requiredMark={'optional'}
                >
                  <Input type='number' />
                </Form.Item>
              </Col>
            )}

            {postType !== 'STORY' && (
              <Col xl={8} lg={8} md={12} xs={24}>
                <Form.Item
                  label='Comments'
                  name={'comments'}
                  rules={[
                    {
                      required: true,
                      message: '',
                    },
                  ]}
                  requiredMark={'optional'}
                >
                  <Input type='number' />
                </Form.Item>
              </Col>
            )}

            {postType == 'VIDEO' && (
              <Col xl={8} lg={8} md={12} xs={24}>
                <Form.Item
                  label='Video Views'
                  name={'videoViews'}
                  rules={[
                    {
                      required: true,
                      message: '',
                    },
                  ]}
                  requiredMark={'optional'}
                >
                  <Input type='number' />
                </Form.Item>
              </Col>
            )}

            {postType === 'STORY' && (
              <Col xl={8} lg={12} md={12} xs={24}>
                <Form.Item
                  label='Replies'
                  name={'replies'}
                  rules={[
                    {
                      required: true,
                      message: '',
                    },
                  ]}
                  requiredMark={'optional'}
                >
                  <Input type='number' />
                </Form.Item>
              </Col>
            )}

            {postType === 'STORY' && (
              <Col xl={8} lg={12} md={12} xs={24}>
                <Form.Item
                  label='Taps Forward'
                  name={'tapForward'}
                  rules={[
                    {
                      required: true,
                      message: '',
                    },
                  ]}
                  requiredMark={'optional'}
                >
                  <Input type='number' />
                </Form.Item>
              </Col>
            )}

            {postType === 'STORY' && (
              <Col xl={8} lg={12} md={12} xs={24}>
                <Form.Item
                  label='Taps Backward'
                  name={'tapBackward'}
                  rules={[
                    {
                      required: true,
                      message: '',
                    },
                  ]}
                  requiredMark={'optional'}
                >
                  <Input type='number' />
                </Form.Item>
              </Col>
            )}

            {postType === 'STORY' && (
              <Col xl={8} lg={12} md={12} xs={24}>
                <Form.Item
                  label='Exits'
                  name={'exits'}
                  rules={[
                    {
                      required: true,
                      message: '',
                    },
                  ]}
                  requiredMark={'optional'}
                >
                  <Input type='number' />
                </Form.Item>
              </Col>
            )}

            {postType === 'STORY' && <Col xl={16} lg={12} md={12} xs={24}></Col>}

            <Col xl={12} lg={12} md={12} xs={24}>
              <Form.Item
                label='Impressions'
                name={'impressions'}
                rules={[
                  {
                    required: false,
                    message: '',
                  },
                ]}
                requiredMark={'optional'}
              >
                <Input type='number' />
              </Form.Item>
            </Col>

            <Col xl={12} lg={12} md={12} xs={24}>
              <Form.Item
                label='Reach'
                name={'reach'}
                rules={[
                  {
                    required: false,
                    message: '',
                  },
                ]}
                requiredMark={'optional'}
              >
                <Input type='number' />
              </Form.Item>
            </Col>
          </Row>

          <Form.Item>
            <Button htmlType='submit' loading={loading} type='primary' style={{ width: '100px' }}>
              Add
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </>
  )
}

export default AddCustomCampaignContent
