import React, { useState, useEffect } from 'react'
import { notification, Input } from 'antd'

import { BsTrash, BsInfoCircle } from 'react-icons/bs'
// import { MdModeEditOutline } from 'react-icons/md'

import { privateAPI, attachToken } from '../../config/constants'
import Layout from '../../layout/Layout'
import UploadinfluencerModal from '../../components/modal/UploadInfluencerModal'
import DataTable from '../../components/Table/DataTable'
import UploadedInfEdit from '../../components/modal/UploadedInfEdit'
// import File from "../../assets/"

const BulkUpload = () => {
  const [data, setData] = useState([])
  const [dataLoading, setDataLoading] = useState(false)
  // const DemoCSV = '/DemoCSV.csv'
  // const DemoCSV = `${forntendUrl}/src/assets/DemoCSV.csv`
  // const DemoCSV = `/src/assets/offlineProfile.png`

  const getUploadedInfluencers = async (searchString) => {
    try {
      setDataLoading(true)
      attachToken()
      const res = await privateAPI.post('/influencer/get-uploaded-influencers', {
        searchString: searchString || '',
      })
      if (res) {
        setDataLoading(false)
        setData(res?.data?.data)
      }
    } catch (error) {
      setDataLoading(false)
      console.log('error', error)
    }
  }

  const deleteUploadInfluencer = async (handle) => {
    try {
      attachToken()
      const res = await privateAPI.post('/influencer/delete-upload-influencer', {
        handle: handle,
      })

      if (res) {
        notification.success({
          message: res?.data?.message,
          duration: 2,
        })
        getUploadedInfluencers()
      }
    } catch (error) {
      console.log('error', error)
      notification.error({
        description: error,
        duration: 3,
      })
    }
  }

  useEffect(() => {
    getUploadedInfluencers()
  }, [])

  const searchHandler = (e) => {
    getUploadedInfluencers(e?.target?.value)
  }

  const columns = [
    {
      title: 'NAME',
      dataIndex: 'name',
      render: (name) => <span style={{ textTransform: 'capitalize' }}>{name}</span>,
    },
    {
      title: 'HANDLE',
      dataIndex: 'handle',
    },
    {
      title: 'GENDER',
      dataIndex: 'gender',
    },
    {
      title: 'ACTION',
      dataIndex: '',
      render: (a, row) => (
        <div style={{ display: 'flex', alignItems: 'center', gap: '1rem' }}>
          <UploadedInfEdit data={row} getUploadedInfluencers={getUploadedInfluencers} />
          <span style={{ cursor: 'pointer' }} onClick={() => deleteUploadInfluencer(row?.handle)}>
            <BsTrash style={{ color: 'red' }} />
          </span>
        </div>
      ),
    },
  ]

  return (
    <Layout active={'bulkUpload'}>
      <div className='bulk_upload_influencers'>
        <h2 className='mainHeading' style={{ fontSize: '26px', fontWeight: 500 }}>
          Bulk Upload
        </h2>
        <div className='filter-bar'>
          <div className='left-side'>
            <Input placeholder='Search by name or handle' onPressEnter={searchHandler} />
          </div>
          <div className='right-side'>
            {/* <a className='link_btn_for_download' href={'../../assets/DownloadCSV.csv'} download='Demo-CSV'>
              Download Demo CSV
            </a> */}
            <div className='custom_tooltip'>
              <BsInfoCircle className='info_svg' style={{ color: 'var(--primary)' }} />
              <span>
                Donot use question mark or any additional text while adding instagram handle
              </span>
            </div>
            <UploadinfluencerModal
              tab={'bulk'}
              type={'button'}
              getUploadedInfs={getUploadedInfluencers}
            />
          </div>
        </div>

        <div style={{ marginTop: '2rem' }}>
          <DataTable
            columns={columns}
            data={data}
            loader={dataLoading}
            // width={1400}
          />
        </div>
      </div>
    </Layout>
  )
}

export default BulkUpload
