import React, { useEffect, useState } from "react";
import { Select, Table } from "antd";

import profile from "../../../assets/295116932_497088365417056_7090162834735970738_n.jpg";

function HeatMap() {
  const { Option } = Select;
  const [data, setData] = useState([]);

  useEffect(() => {
    let tempArr = [];
    [1, 2, 3, 4, 5, 6, 7, 8, 9].map((i) => {
      tempArr.push({
        alcoholDrugs: i * Math.floor(Math.random() * 6),
        covid19: i * Math.floor(Math.random() * 2),
        extremeLanguage: i * Math.floor(Math.random() * 8),
        politics: i * Math.floor(Math.random() * 7),
        potantialInsensitivity: i * Math.floor(Math.random() * 10),
        recism: i * Math.floor(Math.random() * 61),
        religious: i * Math.floor(Math.random() * 4),
        sustainability: i * Math.floor(Math.random() * 1),
      });
    });
    setData(tempArr);
  }, []);

  const changeColor = (num) => {
    if (num < 4) return "white";
    if (num < 10) return "#d8effe";
    if (num < 15) return "#b1defe";
    if (num < 25) return "#86cefe";
    if (num < 60) return "#55bdfc";
    if (num < 115) return "#92aad7";
    if (num < 300) return "#c397af";
    if (num > 500) return "#ff695e";
    if (num >= 300) return "#ed8185";
  };

  const columns = [
    {
      title: "INFUENCERS",
      render: () => (
        <div className="profile">
          <img src={profile} alt="profile" />
          <p>Tehzeeb Fatima</p>
        </div>
      ),
    },
    {
      title: "ALCOHOL/DRUGS",
      dataIndex: "alcoholDrugs",
      render(text) {
        return {
          props: {
            style: { background: changeColor(text) },
          },
          children: <span>{text}</span>,
        };
      },
    },
    {
      title: "COVID-19",
      dataIndex: "covid19",
      render(text) {
        return {
          props: {
            style: { background: changeColor(text) },
          },
          children: <span>{text}</span>,
        };
      },
    },
    {
      title: "EXTREME LANGUAGE",
      dataIndex: "extremeLanguage",
      render(text) {
        return {
          props: {
            style: { background: changeColor(text) },
          },
          children: <span>{text}</span>,
        };
      },
    },
    {
      title: "RECISM",
      dataIndex: "recism",
      render(text) {
        return {
          props: {
            style: { background: changeColor(text) },
          },
          children: <span>{text}</span>,
        };
      },
    },
    {
      title: "RELIGIOUS",
      dataIndex: "religious",
      render(text) {
        return {
          props: {
            style: { background: changeColor(text) },
          },
          children: <span>{text}</span>,
        };
      },
    },
    {
      title: "SUSTAINABILITY",
      dataIndex: "sustainability",
      render(text) {
        return {
          props: {
            style: { background: changeColor(text) },
          },
          children: <span>{text}</span>,
        };
      },
    },
    {
      title: "POLITICS",
      dataIndex: "politics",
      render(text) {
        return {
          props: {
            style: { background: changeColor(text) },
          },
          children: <span>{text}</span>,
        };
      },
    },
    {
      title: "POTANTIAL INSENSTIVITY",
      dataIndex: "potantialInsensitivity",
      render(text) {
        return {
          props: {
            style: { background: changeColor(text) },
          },
          children: <span>{text}</span>,
        };
      },
    },
    {
      title: "TOTAL",
      dataIndex: "politics",
      render(text, record) {
        console.log(text)
        let total = Object.values(record);
        let sum = total.reduce((accu, num) => accu + num, 0);
        return {
          props: {
            style: { background: changeColor(sum) },
          },
          children: <span>{sum}</span>,
        };
      },
    },
  ];
  return (
    <div className="heat-map-container">
      <div className="header">
        <h3>Heatmap of Posts by Keywords</h3>
        <div>
          <Select
            style={{
              minWidth: "150px",
            }}
            defaultValue={"posts"}
            onChange={() => {
              // if (selctedBar.length < 3) {
              // }
              // setSelctedBar(e);
            }}
          >
            <Option value="activatedInfluencers">Activated Influencers</Option>
            <Option value="posts">Posts</Option>
            <Option value="engagements">Engagements</Option>
            {/* <Option value="reach">reach</Option> */}
          </Select>
          <Select
            style={{
              minWidth: "150px",
            }}
            defaultValue={"keywords"}
            onChange={() => {}}
          >
            <Option value="keywords">Keywords</Option>
          </Select>
        </div>
      </div>
      <Table
        position={"topCenter"}
        width={200}
        size="large"
        scroll={{ x: "auto" }}
        bordered={true}
        dataSource={data}
        columns={columns}
        pagination={false}
        className="time-table-row-select"
        summary={(total) => {
          let alcoholDrugsNum = 0;
          let covid19Num = 0;
          let extremeLanguageNum = 0;
          let recismNum = 0;
          let religiousNum = 0;
          let sustainabilityNum = 0;
          let politicsNum = 0;
          let potantialInsensitivityNum = 0;
          total.forEach(
            ({
              alcoholDrugs,
              covid19,
              extremeLanguage,
              recism,
              religious,
              sustainability,
              politics,
              potantialInsensitivity,
            }) => {
              alcoholDrugsNum += alcoholDrugs;
              covid19Num += covid19;
              extremeLanguageNum += extremeLanguage;
              recismNum += recism;
              religiousNum += religious;
              sustainabilityNum += sustainability;
              politicsNum += politics;
              potantialInsensitivityNum += potantialInsensitivity;
            }
          );
          let totalAll =
            alcoholDrugsNum +
            covid19Num +
            extremeLanguageNum +
            recismNum +
            religiousNum +
            sustainabilityNum +
            politicsNum +
            potantialInsensitivityNum;
          return (
            <Table.Summary fixed>
              <Table.Summary.Row>
                <Table.Summary.Cell index={0}>Total</Table.Summary.Cell>
                <Table.Summary.Cell index={1}>
                  {alcoholDrugsNum}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={1}>{covid19Num}</Table.Summary.Cell>
                <Table.Summary.Cell index={1}>
                  {extremeLanguageNum}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={1}>{recismNum}</Table.Summary.Cell>
                <Table.Summary.Cell index={1}>
                  {religiousNum}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={1}>
                  {sustainabilityNum}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={1}>{politicsNum}</Table.Summary.Cell>
                <Table.Summary.Cell index={1}>
                  {potantialInsensitivityNum}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={1}>{totalAll}</Table.Summary.Cell>
              </Table.Summary.Row>
            </Table.Summary>
          );
        }}
      ></Table>
    </div>
  );
}
export default HeatMap;
