import { notification } from 'antd'
import { privateAPI, attachToken } from '../../config/constants'
// import * as type from "../types/generalTypes";
import {onLogout} from  "../../functions/RedirectingAuth"
export const campaignEmailSent = async (values, campaignName, hashTags) => {
  try {
    let adminId = JSON.parse(localStorage.getItem('user'))?._id
    let post = values?.deliverables?.filter((item) => item?.postType == 'Post')
    let video = values?.deliverables?.filter((item) => item?.postType == 'Video')
    let payload = {
      email: values?.influencer?.email,
      subject: 'Campaign Add Confirmation',
      name: values?.influencer?.name,
      postDesc: post.length > 0 ? post[0]: null,
      videoDesc: video.length > 0 ? video[0]: null,
      hashTags: hashTags,
      campaignName: campaignName,
      id: values?._id,
      adminId,
      deliverables: values?.deliverables,
    }
    attachToken()
    const res = await privateAPI.post(`/campaign/email-send`, payload)
    if (res?.data) {
      notification.success({
        message: res?.data?.message,
        duration: 2,
      })
    }
  } catch (error) {
    console.log('err', error)
    notification.error({
      message: error?.response?.data?.message,
      duration: 3,
    })
    onLogout();
  }
}

export const campaignEmailbulkSent = async (selectedInfluencers, campaignId, setEmailSendLoader) => {
  try {
    setEmailSendLoader(true)
    let adminId = JSON.parse(localStorage.getItem('user'))?._id
    let payload = {
      campInf: selectedInfluencers,
      subject: 'Campaign Add Confirmation',
      id: campaignId,
      adminId,
    }
    attachToken()
    const res = await privateAPI.post(`/campaign/email-send-multiple`, payload)
    if (res?.data) {
      setEmailSendLoader(false)
      notification.success({
        message: res?.data?.message,
        duration: 2,
      })
    }
  } catch (error) {
    setEmailSendLoader(false)
    notification.error({
      message: error?.response?.data?.message,
      duration: 3,
    })
    onLogout();
  }
}

export const campaignInfluencerDelete = async (campaignId, InfId, getAllCampaigns) => {
  let payload = {
    InfluencerIds: JSON.stringify([...InfId]),
    campaignId: campaignId,
  }
  try {
    attachToken()
    const res = await privateAPI.post(`/campaign/remove-influencer`, payload)
    notification.success({
      description: res.data.message,
      duration: 2,
    })
    getAllCampaigns()
  } catch (err) {
    notification.error({
      description: err?.response?.data?.message,
      duration: 4,
    })
    onLogout();
  }
}



export const campaignDelete = async (campaignId, dataCall, navigate) => {
  try {
    attachToken()
    const res = await privateAPI.delete(`/campaign/delete/${campaignId}`)
    if (res) {
      notification.success({
        description: res?.data?.message,
        duration: 2,
      })
      if (dataCall) {
        dataCall()
      }
      if (navigate) {
        navigate('/campaigns')
      }
    }
  } catch (err) {
    notification.error({
      description: err?.response?.data?.message,
      duration: 4,
    })
    onLogout();
  }
}

export const campaignReportDelete = async (id, dataCall, navigate, campaignId) => {
  try {
    attachToken()
    const res = await privateAPI.delete(`/campaign/report-delete/${id}`)
    if (res) {
      notification.success({
        description: res.data.message,
        duration: 2,
      })
      if (dataCall) {
        dataCall()
      }
      if (navigate) {
        navigate(`/campaign/${campaignId}?tab=report`)
      }
    }
  } catch (err) {
    notification.error({
      description: err?.response?.data?.message,
      duration: 4,
    })
    onLogout();
  }
}
