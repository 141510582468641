import { Menu } from 'antd'

import InstaAuthLinkModal from '../../../components/modal/InstaAuthLinkModal'
import { generatePpt } from '../../../functions/ExportPPT'

export const menu = (influencer, getPPTData, setPptDataLoading) => (
  <Menu>
    <Menu.Item key={0}>Email Send</Menu.Item>
    <Menu.Item key={1}>
      <InstaAuthLinkModal id={influencer?._id} />
    </Menu.Item>
    <Menu.Item
      key={2}
      onClick={async () => {
        setPptDataLoading(true)
        let PPTData = await getPPTData()
        await generatePpt(PPTData, setPptDataLoading)
        // if(res){
        //   setPptDataLoading(false)
        // }
      }}
    >
      Export PPT
    </Menu.Item>
    {/* <Menu.Item onClick={() => generatePdf()}>Export PDF</Menu.Item> */}
  </Menu>
)

// const generatePdf = async () => {
//   attachToken()
//   const res = await privateAPI.get(`/influencer/get-influencer-footprint/${id}`)
//   const posts = await privateAPI.get(`/influencer/get-latest-post/${id}`)
//   const genderChartImage = await ConvertToImage('gender-div-id')
//   const ageChartImage = await ConvertToImage('age-chart-id')
//   if (res && posts) {
//     let influencerDetails = {
//       name: influencer?.name,
//       username: influencer?.instagram?.username,
//       profile_picture_url: influencer?.profile_picture_url,
//       followers: NumberFormatter(influencer?.instagram?.followers_count, 2),
//       engagemnetRate: res?.data?.data?.instagram?.engagement_rate
//         ? `${res?.data?.data?.instagram?.engagement_rate}%`
//         : 'N/A',
//       posts: NumberFormatter(influencer?.instagram?.media_count, 2) || 'N/A',
//       postsPerWeek: res?.data?.data?.instagram?.posts_per_week || 'N/A',
//       authenticEngagement: res?.data?.data?.instagram?.avg_post_engagement || 'N/A',
//       avgLikes: res?.data?.data?.instagram?.avg_likes
//         ? NumberFormatter(res?.data?.data?.instagram?.avg_likes, 2)
//         : 'N/A',
//     }

//     let latestMedia = {
//       post1: posts?.data?.[0]?.media_url,
//       post2: posts?.data?.[1]?.media_url,
//       post3: posts?.data?.[2]?.media_url,
//       post4: posts?.data?.[3]?.media_url,
//       post5: posts?.data?.[4]?.media_url,
//     }
//     let charts={
//       gender:genderChartImage,
//       age:ageChartImage,
//     }

//     // console.log('ageChartImage',ageChartImage)
//     InfluencerInvoice(`${influencer?.name}.pdf`, influencerDetails, latestMedia, charts)
//   }
// }

// const ConvertToImage = (id) => {
//   return htmlToImage.toJpeg(document.getElementById(id)).then((dataUrl) => {
//     // var link = document.createElement('a')
//     // link.download = `abc.jpg`
//     // link.href = dataUrl
//     // link.click()
//     return dataUrl
//   })
// }
