import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Select, Row, Col, Dropdown, Menu } from 'antd'
import moment from 'moment'

import { LoadingOutlined, DownOutlined } from '@ant-design/icons'
import { BsFilePost, BsFillPlayFill } from 'react-icons/bs'

import Layout from '../../layout/Layout'
import { attachToken, privateAPI } from '../../config/constants'
import BrandBuzzCard from '../../components/Cards/BrandBuzzCard'
import BrandBuzzSkeleton from '../../components/Skeletons/BrandBuzzSkeleton'
import { getAllGroups } from '../../redux'

const BrandBuzz = () => {
  const dispatch = useDispatch()
  const { Option } = Select
  const { SubMenu } = Menu
  const selectedBrand = useSelector((state) => state?.brand?.selectedBrand)

  const [data, setData] = useState(null)
  const [limit, setLimit] = useState(24)
  const [loading, setloading] = useState(false)
  const [contentDateDesc, setContentDateDesc] = useState('Today')
  const [dateFilter, setDateFilter] = useState(null)
  const [getCampaigns, setgetCampaigns] = useState(null)
  const [groups, setGroups] = useState(null)
  const [generalFilter, setGeneralFilter] = useState({
    media_type: '',
    campaignId: '',
    group: '',
  })

  const onDateChange = (value) => {
    setData(null)
    if (value === 'today') {
      setContentDateDesc('Today')
      setDateFilter({
        startOf: moment()?.subtract('day', 2).format(),
        endOf: moment().add('day', 1).format(),
      })
    } else if (value === 'yesterday') {
      setContentDateDesc('Yesterday')
      setDateFilter({
        startOf: moment()?.subtract('day', 2).format(),
        endOf: moment()?.subtract('day', 1).format(),
      })
    } else if (value === 'last7Days') {
      setContentDateDesc('Last 7 Days')
      setDateFilter({ startOf: moment()?.subtract('day', 7).format(), endOf: moment().format() })
    } else if (value === 'last30Days') {
      setContentDateDesc('Last Month')
      setDateFilter({ startOf: moment()?.subtract('day', 30).format(), endOf: moment().format() })
    } else if (value === 'last60Days') {
      setContentDateDesc('Last 2 Months')
      setDateFilter({ startOf: moment()?.subtract('day', 60).format(), endOf: moment().format() })
    } else if (value === 'last90Days') {
      setContentDateDesc('Last 3 Months')
      setDateFilter({ startOf: moment()?.subtract('day', 90).format(), endOf: moment().format() })
    }
  }

  const getMedias = async () => {
    setloading(true)
    let payload = {
      limit,
      filter: generalFilter,
      dateFilter: dateFilter || {
        startOf: moment()?.subtract('day', 2).format(),
        endOf: moment().add('day', 1).format(),
      },
    }
    try {
      attachToken()
      const res = await privateAPI.post(`/brand-buzz/get-all`, payload)
      if (res) {
        setData(res?.data?.data)
        setloading(false)
      }
    } catch (error) {
      console.log(error)
      setloading(false)
    }
  }

  const getAllCampaigns = async () => {
    if (selectedBrand) {
      try {
        attachToken()
        const res = await privateAPI.get(`/campaign/get-campaigns/${selectedBrand}`)
        if (res?.data) {
          setgetCampaigns(res?.data?.slice(0, 3))
        }
      } catch (error) {
        console.log('err', error)
      }
    }
  }

  const getAllGroupsData = async () => {
    let payload = {
      brand: selectedBrand,
    }
    const res = await dispatch(getAllGroups(payload))
    setGroups(res)
  }

  useEffect(() => {
    if (selectedBrand) {
      getAllCampaigns()
      getAllGroupsData()
    }
  }, [selectedBrand])

  useEffect(() => {
    setData(null)
  }, [generalFilter])

  useEffect(() => {
    getMedias()
  }, [limit, dateFilter, generalFilter])

  const filterItems = (
    <Menu>
      <SubMenu title='Post Type' key='post-type'>
        <Menu.Item
          key='post'
          className={'sidebar-menu'}
          onClick={() =>
            setGeneralFilter({
              campaignId: '',
              group: '',
              media_type: 'post',
            })
          }
          icon={<BsFilePost className='drop-down-icon' />}
        >
          Post
        </Menu.Item>
        <Menu.Item
          key='video'
          className={'sidebar-menu'}
          onClick={() =>
            setGeneralFilter({
              campaignId: '',
              group: '',
              media_type: 'video',
            })
          }
          icon={<BsFillPlayFill className='drop-down-icon' />}
        >
          Video
        </Menu.Item>
      </SubMenu>
      <SubMenu title='Campaign' key='campaign'>
        {getCampaigns?.map((camp, ind) => (
          <Menu.Item
            key={ind}
            className={'sidebar-menu'}
            onClick={() =>
              setGeneralFilter({
                media_type: '',
                group: '',
                campaignId: camp?._id,
              })
            }
          >
            {camp?.name?.length > 20 ? `${camp?.name?.slice(0, 20)}...` : camp?.name}
          </Menu.Item>
        ))}
      </SubMenu>
      <SubMenu title='Group' key='group'>
        {groups?.map((group, ind) => (
          <Menu.Item
            key={ind}
            className={'sidebar-menu'}
            onClick={() =>
              setGeneralFilter({
                campaignId: '',
                media_type: '',
                group: group?._id,
              })
            }
          >
            {group?.name?.length > 20 ? `${group?.name?.slice(0, 20)}...` : group?.name}
          </Menu.Item>
        ))}
      </SubMenu>
    </Menu>
  )

  return (
    <Layout active={'brand-buzz'}>
      <div className='brandBuzz-container'>
        <h2 className='mainHeading'>Brand Buzz</h2>
        <p className='subHeading'>
          Welcome to Brand Buzz, a space to navigate the landscape of influencer marketing and PR
          campaigns. Gain unparalleled insights, view content and track your brand&apos;s
          performance across various influencers and media types with real time and accurate
          analytics. (Content Uploaded {contentDateDesc})
        </p>

        {/* Filter Bar */}
        <div className='filter-bar'>
          <div className='left-side'>
            <Select
              placeholder='Date'
              style={{ minWidth: 100 }}
              onChange={onDateChange}
              size='large'
            >
              <Option value='today'>Today</Option>
              <Option value='yesterday'>Yesterday</Option>
              <Option value='last7Days'>Last 7 Days</Option>
              <Option value='last30Days'>Last 30 Days</Option>
              <Option value='last60Days'>Last 60 Days</Option>
              <Option value='last90Days'>Last 90 Days</Option>
            </Select>
            <Dropdown
              className='filter-drop-down'
              overlay={filterItems}
              trigger={['click']}
              placement={'bottom'}
            >
              <div className='filter-main'>
                <span>Filter</span>
                <DownOutlined />
              </div>
            </Dropdown>
            {generalFilter?.media_type !== '' ? (
              <span
                className='clear-filter'
                onClick={() =>
                  setGeneralFilter({
                    media_type: '',
                    campaignId: '',
                    group: '',
                  })
                }
              >
                Clear Filter
              </span>
            ) : generalFilter?.campaignId !== '' ? (
              <span
                className='clear-filter'
                onClick={() =>
                  setGeneralFilter({
                    media_type: '',
                    campaignId: '',
                    group: '',
                  })
                }
              >
                Clear Filter
              </span>
            ) : generalFilter?.group !== '' ? (
              <span
                className='clear-filter'
                onClick={() =>
                  setGeneralFilter({
                    media_type: '',
                    campaignId: '',
                    group: '',
                  })
                }
              >
                Clear Filter
              </span>
            ) : (
              <></>
            )}
          </div>
          <div className='right-side'>
            {/* <Input
              placeholder='Search by name'
              prefix={<FiSearch className='search-icon' />}
              className='searchInput'
              size='small'
            /> */}
          </div>
        </div>
        <br />
        {data ? (
          <Row gutter={[20, 20]}>
            {data?.map((post, ind) => (
              <Col key={ind} xs={24} lg={12} xl={8} xxl={6}>
                <BrandBuzzCard
                  followers={post?.instagram?.followers_count}
                  media={post}
                  name={post?.instagram?.name}
                  profilePic={post?.instagram?.profile_picture_url}
                  influencerId={post?.instagram?.influencer}
                  inf={post?.influencer}
                />
              </Col>
            ))}
            <Col xs={24}>
              <div className='loadmore-div'>
                <h6
                  onClick={() => {
                    setLimit(limit + 24)
                  }}
                >
                  <span>Load More</span>
                  {loading && <LoadingOutlined className='icon' />}
                </h6>
              </div>
            </Col>
          </Row>
        ) : (
          <Row gutter={[20, 20]}>
            <Col xs={24} lg={12} xl={8} xxl={6}>
              <BrandBuzzSkeleton />
            </Col>
            <Col xs={24} lg={12} xl={8} xxl={6}>
              <BrandBuzzSkeleton />
            </Col>
            <Col xs={24} lg={12} xl={8} xxl={6}>
              <BrandBuzzSkeleton />
            </Col>
            <Col xs={24} lg={12} xl={8} xxl={6}>
              <BrandBuzzSkeleton />
            </Col>
            <Col xs={24} lg={12} xl={8} xxl={6}>
              <BrandBuzzSkeleton />
            </Col>
            <Col xs={24} lg={12} xl={8} xxl={6}>
              <BrandBuzzSkeleton />
            </Col>
            <Col xs={24} lg={12} xl={8} xxl={6}>
              <BrandBuzzSkeleton />
            </Col>
            <Col xs={24} lg={12} xl={8} xxl={6}>
              <BrandBuzzSkeleton />
            </Col>
          </Row>
        )}
      </div>
    </Layout>
  )
}

export default BrandBuzz
