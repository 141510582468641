import * as generalType from "../types/generalTypes";

const initialState = {
  brands: [],
  selectedBrand: localStorage.getItem("selectedBrand")
    ? localStorage.getItem("selectedBrand")
    : null,
};

const BrandReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case generalType.GET_ALL_BRANDS:
      localStorage.setItem("selectedBrand",payload?.[0]?._id)
      return {
        ...state,
        brands: payload,
        selectedBrand: state?.selectedBrand ? state?.selectedBrand : payload?.[0]?._id
      };

    case generalType.SELECT_BRAND:
      localStorage.setItem("selectedBrand",payload)
      return {
        ...state,
        selectedBrand: payload,
      };
    default:
      return state;
  }
};

export default BrandReducer;
