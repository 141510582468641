import { DatePicker, Checkbox } from "antd";

import Layout from "../layout/Layout";
import AddTask from "../components/modal/AddTask";
import { AiOutlineUser } from "react-icons/ai";
import { VscSettings } from "react-icons/vsc";

const Tasks = () => {
  const onDateChange = (date, dateString) => {
    console.log(date, dateString);
  };

  const onTaskCheck = (value) => {
    console.log("value", value);
  };

  return (
    <Layout active={"tasks"}>
      <div className="tasks-container">
        <h2 className="mainHeading">Tasks</h2>
        <p className="subHeading">1258 pending tasks</p>

        {/* Filter Bar */}
        <div className="filter-bar">
          <div className="left-side">
            <div className="filter-div">
              <VscSettings className="icon" />
              <span>Filter</span>
            </div>
            <DatePicker
              className="datePicker"
              placeholder="Due Date"
              onChange={onDateChange}
            />
          </div>
          <div className="right-side">
            <div className="right-side">
              <AddTask />
            </div>
          </div>
        </div>

        {/* Tasks */}
        <div className="task-list">
          <p className="heading">Today</p>
          {[1, 2, 4, 5, 6, 7, 8]?.map((task,ind) => (
            <div className="task" key={ind}>
              <div className="left">
                <Checkbox onChange={onTaskCheck} className="checkbox" />
                <div className="info">
                  <p className="todo">
                    connect your account with Fireup using auth code
                  </p>
                  <p className="influencer">
                    {" "}
                    <AiOutlineUser className="icon" /> Tehzeeb Fatima
                  </p>
                </div>
              </div>
              <div className="right">
                <p className="date">Dec 21</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </Layout>
  );
};

export default Tasks;
