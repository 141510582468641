import PptxGenJS from 'pptxgenjs'

import { NumberFormatter } from './NumberFormatter'

import InstaImage from '../assets/instaPicture.png'
import FireUp from '../assets/mainlogo.png'

export const generatePpt = (pptData, setLoading) => {
  // setLoading(true)
  // Create a new Presentation
  let presentation = new PptxGenJS()

  pptData?.map(async (data) => {
    // Add a Slide
    await addSlidePPT(presentation, data)
  })

  // Save the Presentation
  presentation.writeFile().then(()=>{
    setLoading(false)
  }).catch(err => {
    console.log(err)
    setLoading(false)
  })

  return true

  // setLoading(false)

}

const addSlidePPT = (presentation, data) => {

  let slide = presentation.addSlide()

  // const profileImage = data?.instagram?.influencer?.arch_profile_picture_url ? data?.instagram?.influencer?.arch_profile_picture_url : data?.instagram?.influencer?.profile_picture_url
  const profileImage = data?.instagram?.influencer?.arch_profile_picture_url ? data?.instagram?.influencer?.arch_profile_picture_url : data?.instagram?.influencer?.profile_picture_url
  const instaFollowers = data?.instagram?.followers_count ? NumberFormatter(data?.instagram?.followers_count) : '--'
  const instaEngRate = data?.instagram?.engagememtRate ? `${data?.instagram?.engagememtRate}%` : '--'
  // const infPosts = data?.media
  const infPosts = data?.media?.length > 6 ? data?.media?.slice(0, 6) : data?.media

  let infCountry = data?.country.length > 0 ? data?.country : []
  infCountry.sort((firstItem, secondItem) => secondItem?.percent - firstItem?.percent);

  let sortedFamily = data?.family.length > 0 ? data?.family : []
  sortedFamily.sort((a, b) => parseFloat(b.percent) - parseFloat(a.percent))

  let customPrimary = '#ef5a29'
  let textBlack = '000000'

  let ref = {
    x: 0,
    y: 0
  };
  // Profile Image Reference points
  let pImageRef = {
    x: ref.x + 0.1,
    y: ref.y + 0.1
  }
  // Profile Image Dimensions
  let pImageDimensions = {
    w: 2.1,
    h: 2.1
  }
  // Influencer name Reference points
  let nameRef = {
    x: pImageRef.x + pImageDimensions.w + 0.15,
    y: pImageRef.y + 0.13
  }

  // title1 Reference points
  let title1Ref = {
    x: 0,
    y: pImageRef.y + pImageDimensions.h + 0.2
  }

  // Social Site Images Reference
  let socialRef = {
    x: 0.1,
    y: title1Ref.y + 0.2
  }

  // Social Site Images Dimensions
  let socialDim = {
    w: 0.15,
    h: 0.15
  }

  // Post images
  var postImageRefX = pImageDimensions.w

  // Progress height
  let barHeight = 0.05

  // Audience Type Progress Container height
  let audTypeHeight = title1Ref.y + 0.2

  // Audience Type Progress Container height
  let audReachHeight = title1Ref.y + 0.2

  // Audience Gender Progress Container height
  let audGenderHeight = title1Ref.y + 1.7

  // Audience Family Progress Container height
  let audFamilyHeight = title1Ref.y + 2.45

  // Country Type Progress Container height
  let audCountryHeight = title1Ref.y + 1.7

  // Likes Type Progress Container height
  let audlikesHeight = title1Ref.y + 1.7

  // PIE CHART
  const dataChartPieStat = [
    {
      name: "Project Status",
      labels: ['', ''],
      values: [parseFloat(data?.audienceCredibility?.percent), 100 - parseFloat(data?.audienceCredibility?.percent)],
    },
  ];

  let optsChartPie1 = {
    x: nameRef.x + 0.6,
    y: title1Ref.y - 0.1,
    w: 0.9,
    h: 1.1,
    // chartArea: { fill: { color: "F1F1F1" } },
    chartArea: null,
    holeSize: 75,
    showLabel: false,
    showValue: false,
    showPercent: false,
    showLegend: false,
    legendPos: "b",

    chartColors: ['ef5a29', 'E8EDF3'],
    dataBorder: { pt: "1", color: "F1F1F1" },
    dataLabelColor: "FFFFFF",
    dataLabelFontSize: 14,

    showTitle: false,
  };

  // let formatBio = influencer?.biography?.replace('\n','')
  let formatBio = data?.instagram?.influencer?.biography?.slice(0, 175)

  // Profile Image
  slide.addImage({ x: pImageRef.x, y: pImageRef.y, w: pImageDimensions.w, h: pImageDimensions.h, path: `${profileImage}?r=${Math.floor(Math.random() * 100000)}` });

  // Influencer Name
  slide.addText(data?.instagram?.influencer?.name, { x: nameRef.x, y: nameRef.y, fontSize: 24, color: customPrimary })

  // Influencer Biography
  // influencer?.biography && slide.addText(influencer?.biography, {x: nameRef.x, y: 0.4 ,fontSize: 6.5, color: textBlack, autoFit: true, valign: 'top'})
  data?.instagram?.influencer?.biography && slide.addText(formatBio, { x: nameRef.x, y: 0.4, w: 5, fontSize: 6.5, color: textBlack, autoFit: true, valign: 'top' })

  // Heading or Title
  slide.addText('Audience & Eng Rate', { x: title1Ref.x, y: title1Ref.y, fontSize: 10, color: textBlack, bold: true })

  // Instagram row  
  slide.addImage({ x: socialRef.x, y: socialRef.y, w: socialDim.w, h: socialDim.h, path: InstaImage })
  slide.addText('Instagram', { x: socialRef.x + 0.2, y: socialRef.y + 0.06, fontSize: 7.5, color: textBlack })
  slide.addText(instaFollowers, { x: pImageDimensions.w - 0.7, y: socialRef.y + 0.06, fontSize: 7.5, color: textBlack })
  slide.addText(instaEngRate, { x: pImageDimensions.w - 0.3, y: socialRef.y + 0.06, fontSize: 7.5, color: textBlack })

  slide.addText('Audience insights for', { x: 0, y: socialRef.y + 0.5, fontSize: 7.5, color: textBlack })
  slide.addText(data?.instagram?.influencer?.instagram_username, { x: 0.93, y: socialRef.y + 0.5, fontSize: 7.5, color: textBlack, hyperlink: { url: `https://www.instagram.com/${data?.instagram?.username}` } })
  slide.addText('on Instagram', { x: 0, y: socialRef.y + 0.6, fontSize: 7.5, color: textBlack })

  // Fire Up logo
  slide.addImage({ path: FireUp, x: 8.6, y: 0.045, w: 1.2, h: 0.35 })

  // Posts Images
  // for (let i = 0; i < 6; i++) {
  for (let i = 0; i < infPosts?.length; i++) {
    slide.addImage({ x: postImageRefX + 0.35, y: pImageDimensions.h - 0.9, w: 1, h: 1, path: `${infPosts[i]?.media_url}?r=${Math.floor(Math.random() * 100000)}` })
    // slide.addImage({x: pImageDimensions.w + 0.2 ,y: pImageDimensions.h - 0.9 ,w: 1 ,h: 1 ,path: infPosts[i]?.media_url})
    postImageRefX = postImageRefX + 1.25
  }

  // Audience Credibility
  data?.audienceCredibility && slide.addText('Audience Credibility', { x: nameRef.x, y: title1Ref.y, fontSize: 10, color: textBlack, bold: true })

  // Audience Credibility > Credibility Pie Chart
  data?.audienceCredibility && slide.addChart(presentation.ChartType.doughnut, dataChartPieStat, optsChartPie1);
  data?.audienceCredibility && slide.addText(data?.audienceCredibility?.percent, { x: nameRef.x + 0.83, y: title1Ref.y + 0.46, fontSize: 10, color: textBlack })

  data?.audienceCredibility && slide.addText(data?.audienceCredibility?.data[0]?.name, { x: nameRef.x, y: title1Ref.y + 0.9, fontSize: 7.5, color: textBlack })
  data?.audienceCredibility && slide.addText(data?.audienceCredibility?.data[0]?.value, { x: nameRef.x + 1.75, y: title1Ref.y + 0.9, fontSize: 7.5, color: textBlack })

  data?.audienceCredibility && slide.addText(data?.audienceCredibility?.data[1]?.name, { x: nameRef.x, y: title1Ref.y + 1.1, fontSize: 7.5, color: textBlack })
  data?.audienceCredibility && slide.addText(data?.audienceCredibility?.data[1]?.value, { x: nameRef.x + 1.75, y: title1Ref.y + 1.1, fontSize: 7.5, color: textBlack })

  // Audience Type
  data?.audienceType?.length > 0 && slide.addText('Audience Type', { x: nameRef.x + 2.6, y: title1Ref.y, fontSize: 10, color: textBlack, bold: true })
  for (let i = 0; i < data?.audienceType?.length; i++) {
    let checkNeg = Math.sign(data?.audienceType[i]?.value) == -1 ? 0.01 : parseFloat(data?.audienceType[i]?.value) * (2 / 100)

    data?.audienceType?.length > 0 && slide.addText(data?.audienceType[i]?.name, { x: nameRef.x + 2.6, y: audTypeHeight, fontSize: 7.5, color: textBlack })
    data?.audienceType?.length > 0 && slide.addText(data?.audienceType[i]?.value < 1 ? '< 1%' : `${data?.audienceType[i]?.value}%`, { x: nameRef.x + 4.35, y: audTypeHeight, fontSize: 7.5, color: textBlack })

    data?.audienceType?.length > 0 && slide.addShape(presentation.ShapeType.roundRect, { x: nameRef.x + 2.7, y: audTypeHeight + 0.1, w: 2, h: barHeight, fill: 'E8EDF3', rectRadius: 0.5 });
    data?.audienceType?.length > 0 && slide.addShape(presentation.ShapeType.roundRect, { x: nameRef.x + 2.7, y: audTypeHeight + 0.1, w: checkNeg, h: barHeight, fill: customPrimary, rectRadius: checkNeg == 0.01 ? 0 : 0.5 });

    audTypeHeight = audTypeHeight + 0.25
  }

  // Audience Reach
  data?.audienceReach?.length > 0 && slide.addText('Audience Reach', { x: nameRef.x + 5.25, y: title1Ref.y, fontSize: 10, color: textBlack, bold: true })
  for (let i = 0; i < data?.audienceReach?.length; i++) {
    let checkNeg = Math.sign(data?.audienceReach[i]?.value) == -1 ? 0.01 : parseFloat(data?.audienceReach[i]?.value) * (2 / 100)

    data?.audienceReach?.length > 0 && slide.addText(data?.audienceReach[i]?.name, { x: nameRef.x + 5.25, y: audReachHeight, fontSize: 7.5, color: textBlack })
    data?.audienceReach?.length > 0 && slide.addText(data?.audienceReach[i]?.value < 1 ? '< 1%' : `${data?.audienceReach[i]?.value}%`, { x: nameRef.x + 7.0, y: audReachHeight, fontSize: 7.5, color: textBlack })

    data?.audienceReach?.length > 0 && slide.addShape(presentation.ShapeType.roundRect, { x: nameRef.x + 5.35, y: audReachHeight + 0.1, w: 2, h: barHeight, fill: 'E8EDF3', rectRadius: 0.5 });
    data?.audienceReach?.length > 0 && slide.addShape(presentation.ShapeType.roundRect, { x: nameRef.x + 5.35, y: audReachHeight + 0.1, w: checkNeg, h: barHeight, fill: customPrimary, rectRadius: checkNeg == 0.01 ? 0 : 0.5 });
    audReachHeight = audReachHeight + 0.25
  }

  // Gender
  data?.gender?.length > 0 && slide.addText('Gender', { x: nameRef.x, y: title1Ref.y + 1.5, fontSize: 10, color: textBlack, bold: true })
  for (let i = 0; i < data?.gender?.length; i++) {
    let checkNeg = Math.sign(data?.gender[i]?.percent) == -1 ? 0.01 : parseFloat(data?.gender[i]?.percent) * (2 / 100)

    data?.gender?.length > 0 && slide.addText(data?.gender[i]?.name, { x: nameRef.x, y: audGenderHeight, fontSize: 7.5, color: textBlack })
    data?.gender?.length > 0 && slide.addText(data?.gender[i]?.percent < 1 ? '< 1%' : `${data?.gender[i]?.percent}%`, { x: nameRef.x + 1.75, y: audGenderHeight, fontSize: 7.5, color: textBlack })

    data?.gender?.length > 0 && slide.addShape(presentation.ShapeType.roundRect, { x: nameRef.x + 0.1, y: audGenderHeight + 0.1, w: 2, h: barHeight, fill: 'E8EDF3', rectRadius: 0.5 });
    data?.gender?.length > 0 && slide.addShape(presentation.ShapeType.roundRect, { x: nameRef.x + 0.1, y: audGenderHeight + 0.1, w: checkNeg, h: barHeight, fill: customPrimary, rectRadius: checkNeg == 0.01 ? 0 : 0.5 });

    audGenderHeight = audGenderHeight + 0.25
  }

  // Family
  sortedFamily?.length > 0 && slide.addText('Family Status', { x: nameRef.x, y: title1Ref.y + 2.25, fontSize: 10, color: textBlack, bold: true })
  for (let i = 0; i < 2; i++) {
    let checkNeg = Math.sign(sortedFamily[i]?.percent) == -1 ? 0.01 : parseFloat(sortedFamily[i]?.percent) * (2 / 100)

    sortedFamily?.length > 0 && slide.addText(sortedFamily[i]?.name, { x: nameRef.x, y: audFamilyHeight, fontSize: 7.5, color: textBlack })
    sortedFamily?.length > 0 && slide.addText(sortedFamily[i]?.percent < 1 ? '< 1%' : `${sortedFamily[i]?.percent}%`, { x: nameRef.x + 1.75, y: audFamilyHeight, fontSize: 7.5, color: textBlack })

    sortedFamily?.length > 0 && slide.addShape(presentation.ShapeType.roundRect, { x: nameRef.x + 0.1, y: audFamilyHeight + 0.1, w: 2, h: barHeight, fill: 'E8EDF3', rectRadius: 0.5 });
    sortedFamily?.length > 0 && slide.addShape(presentation.ShapeType.roundRect, { x: nameRef.x + 0.1, y: audFamilyHeight + 0.1, w: checkNeg, h: barHeight, fill: customPrimary, rectRadius: checkNeg == 0.01 ? 0 : 0.5 });

    audFamilyHeight = audFamilyHeight + 0.25
  }

  // Country
  infCountry?.length > 0 && slide.addText('Country', { x: nameRef.x + 2.6, y: title1Ref.y + 1.5, fontSize: 10, color: textBlack, bold: true })
  let countryIterations = infCountry?.length < 5 ? infCountry?.length : 5

  for (let i = 0; i < countryIterations; i++) {
    let checkNeg = Math.sign(infCountry[i]?.percent) == -1 ? 0.01 : parseFloat(infCountry[i]?.percent) * (2 / 100)

    infCountry?.length > 0 && slide.addText(infCountry[i]?.name, { x: nameRef.x + 2.6, y: audCountryHeight, fontSize: 7.5, color: textBlack })
    infCountry?.length > 0 && slide.addText(infCountry[i]?.percent < 1 ? '< 1%' : `${infCountry[i]?.percent}%`, { x: nameRef.x + 4.35, y: audCountryHeight, fontSize: 7.5, color: textBlack })

    infCountry?.length > 0 && slide.addShape(presentation.ShapeType.roundRect, { x: nameRef.x + 2.7, y: audCountryHeight + 0.1, w: 2, h: barHeight, fill: 'E8EDF3', rectRadius: 0.5 });
    infCountry?.length > 0 && slide.addShape(presentation.ShapeType.roundRect, { x: nameRef.x + 2.7, y: audCountryHeight + 0.1, w: checkNeg, h: barHeight, fill: customPrimary, rectRadius: checkNeg == 0.01 ? 0 : 0.5 });

    audCountryHeight = audCountryHeight + 0.25
  }

  // Likes & interest
  data?.likesInterest?.length > 0 && slide.addText('Likes & Interests', { x: nameRef.x + 5.25, y: title1Ref.y + 1.5, fontSize: 10, color: textBlack, bold: true })
  let likeIterations = data?.likesInterest?.length < 5 ? data?.likesInterest?.length : 5

  for (let i = 0; i < likeIterations; i++) {
    let checkNeg = Math.sign(data?.likesInterest[i]?.percent) == -1 ? 0.01 : parseFloat(data?.likesInterest[i]?.percent) * (2 / 100)

    data?.likesInterest?.length > 0 && slide.addText(data?.likesInterest[i]?.name, { x: nameRef.x + 5.25, y: audlikesHeight, fontSize: 7.5, color: textBlack })
    data?.likesInterest?.length > 0 && slide.addText(data?.likesInterest[i]?.percent < 1 ? '< 1%' : `${data?.likesInterest[i]?.percent}%`, { x: nameRef.x + 7, y: audlikesHeight, fontSize: 7.5, color: textBlack })

    data?.likesInterest?.length > 0 && slide.addShape(presentation.ShapeType.roundRect, { x: nameRef.x + 5.35, y: audlikesHeight + 0.1, w: 2, h: barHeight, fill: 'E8EDF3', rectRadius: 0.5 });
    data?.likesInterest?.length > 0 && slide.addShape(presentation.ShapeType.roundRect, { x: nameRef.x + 5.35, y: audlikesHeight + 0.1, w: checkNeg, h: barHeight, fill: customPrimary, rectRadius: checkNeg == 0.01 ? 0 : 0.5 });

    audlikesHeight = audlikesHeight + 0.25
  }
}