import { useState, useEffect, useMemo } from 'react'
import { Col, Tabs, Row, Tooltip } from 'antd'
import { useNavigate, useParams, useLocation } from 'react-router-dom'

import { FaTiktok, FaInstagram, FaYoutube, FaRegEye, FaWifi } from 'react-icons/fa'
// import { BsThreeDots } from 'react-icons/bs'
import { MdOutlineKeyboardArrowLeft } from 'react-icons/md'
import { TiArrowRepeat } from 'react-icons/ti'
import { AiOutlineCheckCircle, AiOutlineExclamationCircle } from 'react-icons/ai'

import PublicLayout from '../../layout/PublicLayout/PublicLayout'
import { attachToken, privateAPI } from '../../config/constants'
// import InfluencerInfo from '../Influencer/components/InfluencerInfo'
// import InfluencerContent from './components/InfluencerContent'
import InfluencerContent from '../Influencer/components/InfluencerContent'
// import InfluencerCollaboration from '../Influencer/components/InfluencerCollaboration'
// import FootPrint from './components/FootPrint'
import FootPrint from '../Influencer/components/FootPrint'
// import InfluencerAudience from './components/InfluencerAudience'
import InfluencerAudience from '../Influencer/components/InfluencerAudience'
// import InfinityLoader from '../../components/InfinityLoader'
import { AudienceCalculator } from '../../functions/AudienceCalculation'
import { NumberFormatter } from '../../functions/NumberFormatter'
// import { menu } from "./utils/influencerUtil";
// import { menu } from '../Influencer/utils/influencerUtil'
// import OfflineProfileImage from '../../assets/offlineProfile.png'
// import { ExpireDateCheck } from '../../functions/ExpireDateCheck'

const useQuery = (search) => {
  return useMemo(() => new URLSearchParams(search), [search])
}

const PublicInfluencer = () => {
  const navigate = useNavigate()
  const { TabPane } = Tabs
  const { id } = useParams()
  const { search } = useLocation()
  const [influencer, setInfluencer] = useState(null)
  const [activeTab, setActiveTab] = useState('content')
  const query = useQuery(search)

  const onTabsChange = (key) => {
    const params = new URLSearchParams()
    if (key) {
      params.append('tab', key)
    } else {
      params.delete('tab')
    }
    navigate({ search: params.toString() })
  }

  const getInfluencer = async () => {
    attachToken()
    const res = await privateAPI.get(`/influencer/get/${id}`)
    setInfluencer(res?.data)
  }

  useEffect(() => {
    if (query.get('tab')) {
      setActiveTab(query.get('tab'))
    }
  }, [activeTab])

  useEffect(() => {
    getInfluencer()
  }, [])

  return (
    <PublicLayout>
      <div className='influencer-container'>
        <div className='back-icon-container' onClick={() => history.back()}>
          <MdOutlineKeyboardArrowLeft />
          Back
        </div>
        <Row className='network-row-card'>
          <Col xs={24} md={24} lg={6} xl={5} xxl={5} className='left-side'>
            <div className='image-div'>
              {/* <div className="options">
                <RiUserSettingsLine className="icon" />
                <BsThreeDots className="icon" />
                <BsArrowsAngleExpand className="icon" />
              </div> */}
              <img
                className='profile'
                src={
                  influencer?.influencer?.arch_profile_picture_url ||
                  influencer?.influencer?.profile_picture_url
                }
                // src={
                //   ExpireDateCheck(influencer?.influencer?.dataRefreshed) === 10
                //     ? influencer?.influencer?.arch_profile_picture_url
                //       ? influencer?.influencer?.arch_profile_picture_url
                //       : OfflineProfileImage
                //     : ExpireDateCheck(influencer?.influencer?.dataRefreshed) === 2
                //     ? influencer?.influencer?.profile_picture_url
                //     : OfflineProfileImage
                // }
                alt='profile'
              />
              <div className='aligned-div'>
                {influencer?.influencer?.valuesMRS === 'Aligned' ? (
                  <Tooltip placement='right' title={`Aligned`} className='tooltip'>
                    <AiOutlineCheckCircle className='icon aligned' />
                  </Tooltip>
                ) : influencer?.influencer?.valuesMRS === 'Not Aligned' ? (
                  <Tooltip placement='right' title={`Not Aligned`} className='tooltip'>
                    <AiOutlineExclamationCircle className='icon notaligned' />
                  </Tooltip>
                ) : null}
              </div>
            </div>
          </Col>
          <Col xs={24} md={24} lg={12} xl={13} xxl={15} className='middle'>
            <div className='info'>
              <div className='social'>
                <FaTiktok className='icon disable' />
                <FaYoutube className='icon disable' />
                <Tooltip
                  placement='right'
                  title={`https://www.instagram.com/${influencer?.influencer?.instagram?.username}`}
                >
                  <FaInstagram
                    className='icon'
                    onClick={() => {
                      window.open(
                        `https://www.instagram.com/${influencer?.influencer?.instagram?.username}`,
                        '_blank',
                      )
                    }}
                  />
                </Tooltip>
              </div>
              <h2 className='name'>{influencer?.influencer?.name}</h2>
              <p className='desc'>{influencer?.influencer?.biography}</p>
              {/* <div className="address">
                  <MdOutlineLocationOn className="icon" />
                  <span>Karachi, Pakistan.</span>
                </div> */}
              <div className='categories'>
                {influencer?.influencer?.category?.map((cat, ind) => (
                  <span key={ind}>{cat?.name}</span>
                ))}
              </div>

              {/* {influencer?.campaigns?.length > 0 &&
                influencer?.campaigns?.slice(0, 2)?.map((camp, ind) => (
                  <div
                    key={ind}
                    className='campaign'
                    onClick={() => navigate(`/campaign/${camp?._id}`)}
                  >
                    <FaRegFolder className='icon' />
                    <span> {camp?.name} </span>
                  </div>
                ))} */}
            </div>
            <h2 className='reach'>{AudienceCalculator(influencer?.influencer?.audience)}</h2>
          </Col>

          <Col xs={24} md={24} lg={6} xl={6} xxl={4} className='right-side'>
            <div className='header'>
              {/* <RiUserSettingsLine className="icon" /> */}

              {/* <Dropdown overlay={menu(influencer)} trigger={['click']} placement={'bottomRight'}>
                <BsThreeDots className='icon' />
              </Dropdown> */}
            </div>
            <div className='list'>
              <FaRegEye className='icon' />
              <span>Audience</span>
              <span className='count'>{NumberFormatter(influencer?.influencer?.audience, 1)}</span>
            </div>
            <div className='list'>
              <FaWifi className='icon' />
              <span>Reach</span>
              {/* <span className='count'>{influencer?.influencer?.reach}</span> */}
              <span className='count'>{influencer?.mCampaign?.avgReach}</span>
            </div>
            <div className='list'>
              <TiArrowRepeat className='icon' />
              <span>Resonance</span>
              <span className='count'>{influencer?.influencer?.resonance}</span>
            </div>
          </Col>
        </Row>

        <Tabs
          defaultActiveKey={activeTab}
          onChange={onTabsChange}
          className='tabs-container'
          size='large'
        >
          {/* <TabPane tab='INFO' key='info'>
            <InfluencerInfo id={id} getInfluencer={getInfluencer} data={influencer} />
          </TabPane> */}
          <TabPane tab='CONTENT' key='content'>
            <InfluencerContent id={id} />
          </TabPane>
          <TabPane tab='FOOTPRINT' key='footprint'>
            <FootPrint id={id} mCampaign={influencer?.mCampaign} />
          </TabPane>
          <TabPane tab='AUDIENCE' key='audience'>
            <InfluencerAudience id={id} />
          </TabPane>
          {/* <TabPane tab='COLLABORATION' key='collaboration'>
            <InfluencerCollaboration id={id} />
          </TabPane> */}
          {/* <TabPane tab="CONNECTIONS" key="connections"></TabPane> */}
        </Tabs>
      </div>
    </PublicLayout>
  )
}

export default PublicInfluencer
