import React, { useEffect, useState } from 'react'
import { Col, Row } from 'antd'

import { LoadingOutlined } from '@ant-design/icons'

// import { FiSearch } from "react-icons/fi";
// import { VscSettings } from "react-icons/vsc";
// import { MdOutlineDateRange } from "react-icons/md";
import BrandBuzzCard from '../../../components/Cards/BrandBuzzCard'
import { attachToken, privateAPI } from '../../../config/constants'
import BrandBuzzSkeleton from '../../../components/Skeletons/BrandBuzzSkeleton'

const InfluencerContent = ({ id }) => {
  // const { Option } = Select;
  const [data, setData] = useState(null)
  const [limit, setLimit] = useState(20)
  const [loading, setloading] = useState(false)

  const getInfluencer = async () => {
    try {
      let payload = {
        limit,
      }
      setloading(true)
      attachToken()
      const res = await privateAPI.post(`/influencer/get-influencer-profile/${id}`, payload)
      setData(res?.data)
      setloading(false)
    } catch (error) {
      console.log(error)
      setloading(false)
    }
  }

  useEffect(() => {
    getInfluencer()
  }, [id, limit])

  return (
    <div className='influencer-content'>
      {/* Filter Bar */}
      {/* <div className="filter-bar">
        <div className="left-side">
          <div className="filter-div">
            <VscSettings className="icon" />
            <span>Filter</span>
          </div>
          <Select
            placeholder="Date"
            style={{ minWidth: 100 }}
            onChange={() => {}}
            size="large"
            suffixIcon={<MdOutlineDateRange className="date-icon" />}
          >
            <Option value="today">Today</Option>
            <Option value="yesterday">Yesterday</Option>
          </Select>
        </div>
        <div className="right-side">
          <Input
            placeholder="Filter by keyword"
            prefix={<FiSearch className="search-icon" />}
            className="searchInput"
            size="small"
          />
        </div>
      </div> */}
      <br />

      {data ? (
        <Row gutter={[20, 30]}>
          {data?.media?.map((inf, index) =>
            // inf?.media_type === 'VIDEO' && !inf?.media_url ? null : (
            (
              <Col key={index} xs={24} lg={12} xl={8} xxl={6}>
                <BrandBuzzCard
                  followers={data?.instagram?.followers_count}
                  media={inf}
                  name={data?.instagram?.name}
                  profilePic={data?.instagram?.profile_picture_url}
                  influencerId={data?.instagram?.influencer}
                  inf={inf?.influencer}
                />
              </Col>
            ),
          )}
          {data?.mediaCount === data?.media?.length ? null : (
            <Col xs={24}>
              <div className='loadmore-div'>
                <h6
                  onClick={() => {
                    setLimit(limit + 20)
                  }}
                >
                  <span>Load More</span>
                  {loading && <LoadingOutlined className='icon' />}
                </h6>
              </div>
            </Col>
          )}
        </Row>
      ) : (
        <Row gutter={[20, 20]}>
          <Col xs={24} lg={12} xl={8} xxl={6}>
            <BrandBuzzSkeleton />
          </Col>
          <Col xs={24} lg={12} xl={8} xxl={6}>
            <BrandBuzzSkeleton />
          </Col>
          <Col xs={24} lg={12} xl={8} xxl={6}>
            <BrandBuzzSkeleton />
          </Col>
          <Col xs={24} lg={12} xl={8} xxl={6}>
            <BrandBuzzSkeleton />
          </Col>
          <Col xs={24} lg={12} xl={8} xxl={6}>
            <BrandBuzzSkeleton />
          </Col>
          <Col xs={24} lg={12} xl={8} xxl={6}>
            <BrandBuzzSkeleton />
          </Col>
          <Col xs={24} lg={12} xl={8} xxl={6}>
            <BrandBuzzSkeleton />
          </Col>
          <Col xs={24} lg={12} xl={8} xxl={6}>
            <BrandBuzzSkeleton />
          </Col>
        </Row>
      )}
    </div>
  )
}

export default InfluencerContent
