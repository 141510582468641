import { useState } from "react";
import { Select, Input, Tabs, Row, Col, notification } from "antd";

import Layout from "../layout/Layout";
import { BsSearch, BsThreeDots } from "react-icons/bs";
import { VscSettings } from "react-icons/vsc";
import { FaRegSave } from "react-icons/fa";
import SearchContent from "../components/Views/SearchContent";
import SearchPeople from "../components/Views/SearchPeople";

const Discovery = () => {
  const { Option } = Select;
  const { TabPane } = Tabs;
  const UserId = Number(localStorage.getItem("insta-id"));
  const [instaUser, setInstaUser] = useState("");

  const onTabsChange = () => {};

  const getInstaData = () => {
    if (UserId) {
      if (instaUser !== "") {
        window.FB.api(
          `/${UserId}?fields=business_discovery.username(${instaUser}){followers_count,media_count,media{media_url,caption}}`,
          (data) => {
            if (data?.business_discovery?.media) {
              console.log(data?.business_discovery?.media?.data);
            }
          }
        );
      } else {
        notification.error({
          message: "Field Null!!!",
          duration: 6,
        });
      }
    } else {
      notification.error({
        message: "Facebook Not Logged In!!!",
        duration: 6,
      });
    }
  };

  return (
    <Layout active={"search"}>
      <div className="discovery-container">
        <h2 className="mainHeading">Paid Partnerships</h2>
        <p className="subHeading">Find new influencers</p>

        <div className="main">
          <Row gutter={[10, 20]}>
            <Col xs={24} md={12} lg={7}>
              <span>Interest</span>
              <Select
                defaultValue={"software"}
                style={{ width: "100%" }}
                onChange={() => {}}
                className="select"
                size="large"
                placeholder="e.g: beauty or technology"
              >
                <Option value={"software"}>Computer: Software</Option>
              </Select>
            </Col>
            <Col xs={24} md={12} lg={8}>
              <span>Bio Keywords</span>
              <Input
                placeholder="e.g: makeup artist or developer"
                value={instaUser}
                onChange={(e) => setInstaUser(e.target.value)}
              />
            </Col>
            <Col xs={24} md={12} lg={7}>
              <span>Content Keywords</span>
              <Input placeholder="e.g: 'lipstick tutorial' or 'performance analysis'" />
            </Col>
            <Col xs={24} md={12} lg={2}>
              <BsSearch
                className="add-icon"
                style={{ width: "100%", marginTop: "1.3rem" }}
                onClick={() => getInstaData()}
              />
            </Col>
            <Col xs={24} md={12} lg={12}>
              <div className="filter-div">
                <VscSettings className="icon" />
                <span>Filter</span>
              </div>
            </Col>
            <Col xs={24} md={12} lg={12} className="right-side">
              <FaRegSave className="add-icon" style={{ width: "50px" }} />
              <BsThreeDots className="more-icon" />
            </Col>
          </Row>
        </div>
        <Tabs
          defaultActiveKey="people"
          onChange={onTabsChange}
          className="tabs-container"
        >
          <TabPane tab="PEOPLE" key="people">
            <SearchPeople />
          </TabPane>
          <TabPane tab="CONTENT" key="content">
            <SearchContent />
          </TabPane>
        </Tabs>
      </div>
    </Layout>
  );
};

export default Discovery;
