import React, { useState } from 'react'
import { Row, Col, Form, Select, Button } from 'antd'
import { useDispatch } from 'react-redux'

import { getAllInfluencersForCampaign, getAllInfluencerCateories } from '../../../redux'
import InfluencerCard from '../../../components/Cards/InfluencerCard'
import MinMaxComp from './components/MinMaxComp'
import GroupComp from './components/GroupComp'
import MultipleComp from './components/MultipleComp'
import CategoryComp from './components/CategoryComp'

const InfluencerForm = ({
  overAllSelected,
  setOverAllSelected,
  overAllSelectedObj,
  setOverAllSelectedObj,
  state,
  form,
  influencersData,
  setInfluencersData,
  groups,
  groupInfluencers,
  setGroupInfluencers,
  minFollower,
  setMinFollower,
  maxFollower,
  setMaxFollower,
  influncersByFollowers,
  setInfluncersByFollowers,
  influencerGetType,
  setinfluencerGetType,
  selectedInfluencerCategories,
  setSelectedInfluencerCategories,
  categoriesInfluencers,
  setCategoriesInfluencers,
  setStep,
  onFinishInfluencers,
  loading,
}) => {
  const dispatch = useDispatch()
  const { Option } = Select
  const [multipleSearchInfs, setMultipleSearchInfs] = useState('')
  const [apiLoading, setApiLoading] = useState(false)

  const getAllInfluencersData = async () => {
    setApiLoading(true)
    let payload = {
      searchString: multipleSearchInfs,
    }
    const res = await dispatch(getAllInfluencersForCampaign(payload))
    if (res) {
      setApiLoading(false)
      setInfluencersData(res)
    }
  }

  return (
    <Form layout='vertical' scrollToField form={form} onFinish={onFinishInfluencers}>
      <Row gutter={[30, 0]}>
        {/* {!state && (
          <Col xs={24}>
            <Form.Item
              label='Select Influencers Type'
              name={'creationType'}
              rules={[
                {
                  required: false,
                  // message: 'Influencers Type is Required',
                },
              ]}
              requiredMark={'optional'}
            >
              <Select
                // defaultValue={'min-max'}
                style={{ width: '100%' }}
                onChange={(e) => {
                  setinfluencerGetType(e)
                  if (e === 'multipleInfluencers') {
                    getAllInfluencersData()
                  } else if (e === 'category') {
                    dispatch(getAllInfluencerCateories())
                  }
                }}
                size='large'
                placeholder='Select Type'
                value={influencerGetType}
              >
                <Option value={'min-max'}>Minimum & Maximum Follower</Option>
                <Option value={'group'}>Group</Option>
                <Option value={'multipleInfluencers'}>Multiple Influencer</Option>
                <Option value={'category'}>Category</Option>
              </Select>
            </Form.Item>
          </Col>
        )} */}
        <Col xs={24}>
          <Form.Item
            label='Select Influencers Type'
            name={'creationType'}
            rules={[
              {
                required: false,
                // message: 'Influencers Type is Required',
              },
            ]}
            requiredMark={'optional'}
          >
            <Select
              // defaultValue={'min-max'}
              style={{ width: '100%' }}
              onChange={(e) => {
                setinfluencerGetType(e)
                if (e === 'multipleInfluencers') {
                  getAllInfluencersData()
                } else if (e === 'category') {
                  dispatch(getAllInfluencerCateories())
                }
              }}
              size='large'
              placeholder='Select Type'
              value={influencerGetType}
            >
              <Option value={'min-max'}>Minimum & Maximum Follower</Option>
              <Option value={'group'}>Group</Option>
              <Option value={'multipleInfluencers'}>Multiple Influencer</Option>
              <Option value={'category'}>Category</Option>
            </Select>
          </Form.Item>
        </Col>

        {/* {!state && influencerGetType === 'min-max' && ( */}
        {influencerGetType === 'min-max' && (
          <MinMaxComp
            state={state}
            apiLoading={apiLoading}
            setApiLoading={setApiLoading}
            minFollower={minFollower}
            setMinFollower={setMinFollower}
            maxFollower={maxFollower}
            setMaxFollower={setMaxFollower}
            influncersByFollowers={influncersByFollowers}
            setInfluncersByFollowers={setInfluncersByFollowers}
            overAllSelected={overAllSelected}
            setOverAllSelected={setOverAllSelected}
            overAllSelectedObj={overAllSelectedObj}
            setOverAllSelectedObj={setOverAllSelectedObj}
          />
        )}

        {/* {!state && influencerGetType === 'group' && ( */}
        {influencerGetType === 'group' && (
          <GroupComp
            state={state}
            apiLoading={apiLoading}
            setApiLoading={setApiLoading}
            groups={groups}
            groupInfluencers={groupInfluencers}
            setGroupInfluencers={setGroupInfluencers}
            overAllSelected={overAllSelected}
            setOverAllSelected={setOverAllSelected}
            overAllSelectedObj={overAllSelectedObj}
            setOverAllSelectedObj={setOverAllSelectedObj}
          />
        )}

        {/* {!state && influencerGetType === 'multipleInfluencers' && ( */}
        {influencerGetType === 'multipleInfluencers' && (
          <MultipleComp
            state={state}
            multipleSearchInfs={multipleSearchInfs}
            setMultipleSearchInfs={setMultipleSearchInfs}
            influencersData={influencersData}
            overAllSelected={overAllSelected}
            setOverAllSelected={setOverAllSelected}
            overAllSelectedObj={overAllSelectedObj}
            setOverAllSelectedObj={setOverAllSelectedObj}
            getAllInfluencersData={getAllInfluencersData}
            apiLoading={apiLoading}
          />
        )}

        {/* {!state && influencerGetType === 'category' && ( */}
        {influencerGetType === 'category' && (
          <CategoryComp
            state={state}
            selectedInfluencerCategories={selectedInfluencerCategories}
            setSelectedInfluencerCategories={setSelectedInfluencerCategories}
            categoriesInfluencers={categoriesInfluencers}
            setCategoriesInfluencers={setCategoriesInfluencers}
            overAllSelected={overAllSelected}
            setOverAllSelected={setOverAllSelected}
            overAllSelectedObj={overAllSelectedObj}
            setOverAllSelectedObj={setOverAllSelectedObj}
            apiLoading={apiLoading}
            setApiLoading={setApiLoading}
          />
        )}

        {overAllSelectedObj?.length > 0 && (
          <Col xs={24}>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                gap: '1rem',
                marginTop: '2rem',
              }}
            >
              <div
                style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}
              >
                <p style={{ fontSize: '18px' }}>Selected Influencers</p>
                <Button
                  disabled={state ? true : false}
                  onClick={() => {
                    setOverAllSelected([])
                    setOverAllSelectedObj([])
                  }}
                >
                  Clear All
                </Button>
              </div>
              <div
                style={{
                  background: '#f1f1f1',
                  padding: '10px',
                  borderRadius: '5px',
                  maxHeight: '550px',
                  overflowY: 'auto',
                }}
              >
                <Row gutter={[20, 20]}>
                  {overAllSelectedObj?.map((inf, i) => (
                    <Col xxl={4} xl={6} md={8} sm={12} xs={24} key={i}>
                      <InfluencerCard
                        data={{
                          id: inf?._id,
                          profileImage: inf?.arch_profile_picture_url || inf?.profile_picture_url,
                          name: inf?.name,
                          userName: inf?.instagram_username,
                        }}
                        selectedInfluencers={overAllSelected}
                        setSelectedInfluencers={setOverAllSelected}
                        object={inf}
                        objectsArr={overAllSelectedObj}
                        setObjects={setOverAllSelectedObj}
                        showClear={true}
                        state={state}
                      />
                    </Col>
                  ))}
                </Row>
              </div>
            </div>
          </Col>
        )}

        <Col
          xs={24}
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            gap: '1rem',
            marginTop: '1rem',
          }}
        >
          <Form.Item>
            <Button type='text' style={{ marginBottom: '-2rem' }} onClick={() => setStep(1)}>
              Previous
            </Button>
          </Form.Item>
          <Form.Item>
            <Button htmlType='submit' loading={loading} type='primary'>
              Next
            </Button>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  )
}

export default InfluencerForm
