import React from "react";

const SearchPeople = () => {
  return (
    <div className="search-people-container">
      <p className="recommended">Recomended influencers</p>
    </div>
  );
};

export default SearchPeople;
