import React from "react";

function SocialMedia() {
  return (
    <div className="circle">
      <div>
        <div style={{ backgroundColor: "#4dd7fe" }}></div>
        <p>Twitter</p>
      </div>
      <div>
        <div style={{ backgroundColor: "#4394fb" }}></div>
        <p>Facebook</p>
      </div>
      <div>
        <div style={{ backgroundColor: "#FA69CC" }}></div>
        <p>Instagram</p>
      </div>
      <div>
        <div style={{ backgroundColor: "#E74C3C" }}></div>
        <p>Youtube</p>
      </div>
      {/* <div>
        <div style={{ backgroundColor: "#9c8aec" }}></div>
        <p>Twitch</p>
      </div> */}
      <div>
        <div style={{ backgroundColor: "#212F3D" }}></div>
        <p>Tiktok</p>
      </div>
    </div>
  );
}

export default SocialMedia;
