import { useState } from 'react'
import { Modal, Button, Upload, notification } from 'antd'
import { InboxOutlined } from '@ant-design/icons'

import { attachToken, privateAPI } from '../../config/constants'
import { AiFillDelete } from 'react-icons/ai'

const UploadinfluencerModal = ({ id, tab, type, getUploadedInfs, apiCall }) => {
  const { Dragger } = Upload
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [file, setFile] = useState(null)

  const [loading, setLoading] = useState(false)

  const showModal = () => {
    setIsModalVisible(true)
  }

  const onFinish = async () => {
    setLoading(true)

    const formData = new FormData()

    if (tab !== 'bulk') {
      formData.append('id', id)
    }
    formData.append('storecsv', file)

    try {
      attachToken()
      let res
      if (tab == 'bulk') {
        res = await privateAPI.post('/influencer/upload-influencers-csv', formData)
      }
      notification.success({
        description: res?.data?.message,
        duration: 2,
      })
      setLoading(false)
      setIsModalVisible(false)
      setFile(null)
      if (res) {
        if (tab !== 'bulk') {
          apiCall()
        }
        if (tab === 'bulk' && type === 'button') {
          setTimeout(() => {
            getUploadedInfs()
          }, 2000)
        }
      }
    } catch (err) {
      notification.error({
        description: err?.response?.data?.message || 'Server Error',
        duration: 4,
      })
      setLoading(false)
    }
  }

  const handleCancel = () => {
    setIsModalVisible(false)
    setFile(null)
  }

  const props = {
    name: 'file',
    customRequest: () => {},
    multiple: false,
    onChange(info) {
      const { originFileObj } = info.file
      setFile(originFileObj)
    },
    onDrop(e) {
      setFile(e?.dataTransfer?.files?.[0])
    },
  }

  return (
    <>
      {tab == 'bulk' ? (
        type === 'button' ? (
          <Button onClick={() => showModal()}>Bulk Upload</Button>
        ) : (
          <span onClick={() => showModal()}>Bulk Upload</span>
        )
      ) : (
        <Button onClick={() => showModal()}>
          Upload{' '}
          {tab == 'profile'
            ? 'Influencer'
            : tab == 'celebrities'
            ? 'Celebrities'
            : tab == 'tiktok'
            ? 'Tiktokers'
            : tab == 'micro-blogger'
            ? 'Blogger'
            : tab == 'creator'
            ? 'Creators'
            : tab == 'publication'
            ? 'Publications'
            : tab == 'twitter'
            ? 'Twitter'
            : tab == 'seeders'
            ? 'Seeders'
            : tab == 'article'
            ? 'Articles'
            : null}
        </Button>
      )}

      <Modal
        title={`Upload ${
          tab == 'profile'
            ? 'Influencer'
            : tab == 'celebrities'
            ? 'Celebrities'
            : tab == 'tiktok'
            ? 'Tiktokers'
            : tab == 'micro-blogger'
            ? 'Blogger'
            : tab == 'creator'
            ? 'Creators'
            : tab == 'publication'
            ? 'Publications'
            : tab == 'twitter'
            ? 'Twitter'
            : tab == 'seeders'
            ? 'Seeders'
            : tab == 'article'
            ? 'Articles'
            : tab == 'bulk'
            ? 'Influencers in Bulk'
            : null
        }`}
        open={isModalVisible}
        footer={false}
        onCancel={handleCancel}
        width={'500px'}
      >
        {file ? (
          <div className='uploaded-div'>
            <AiFillDelete className='delete' onClick={() => setFile(null)} />
            <span>Uploaded Successfully</span>
          </div>
        ) : (
          <Dragger {...props}>
            <p className='ant-upload-drag-icon'>
              <InboxOutlined />
            </p>
            <p className='ant-upload-text'>Drop CSV here</p>
            <p className='ant-upload-hint'>Following Columns are required in Excel Sheet</p>
            <p className='ant-upload-hint'>
              {tab == 'bulk'
                ? 'Name,Handle,Gender'
                : 'Name,Category,SOW,ER,Avg Reach,Avg Estimated Views and UserName'}
            </p>
          </Dragger>
        )}
        <br />
        <Button onClick={onFinish} loading={loading} type='primary' style={{ width: '100%' }}>
          Upload
        </Button>
      </Modal>
    </>
  )
}

export default UploadinfluencerModal
