import React, { useState } from 'react'
import moment from 'moment'
import { Form, Row, Col, Input, Select, DatePicker, Button } from 'antd'
import { useNavigate } from 'react-router-dom'

import { TiDelete } from 'react-icons/ti'

const GeneralForm = ({ state, form, tagsState, setTagsState, loading, onFinishGeneral }) => {
  const navigate = useNavigate()
  const { Option } = Select
  const [startDateState, setStartDateState] = useState('')
  const [endDateState, setEndDateState] = useState('')
  const [tagInput, setTagInput] = useState('')

  return (
    <Form
      layout='vertical'
      scrollToField
      form={form}
      fields={
        state
          ? [
              {
                name: 'name',
                value: state?.name || '',
              },
              {
                name: 'visibility',
                value: state?.visibility || null,
              },
              {
                name: 'objective',
                value: state?.objective || null,
              },
              {
                name: 'campaignType',
                value: state?.campaignType || null,
              },
              {
                name: 'startDate',
                value: startDateState || moment(state?.startDate),
              },
              {
                name: 'endDate',
                value: endDateState || moment(state?.endDate),
              },
              {
                name: 'hashTags',
                value: state?.hashTags || null,
              },
              {
                name: 'currency',
                value: state?.currency || null,
              },
              {
                name: 'budget',
                value: state?.budget || '',
              },
            ]
          : [
              {
                name: 'visibility',
                value: 'all',
              },
            ]
      }
      onFinish={onFinishGeneral}
    >
      <Row gutter={[30, 0]}>
        <Col xs={24} md={12}>
          <Form.Item
            label='Name'
            name={'name'}
            rules={[
              {
                required: true,
                message: 'Name is Required',
              },
            ]}
            requiredMark={'optional'}
          >
            <Input placeholder='Campaign Name' />
          </Form.Item>
        </Col>
        <Col xs={24} md={12}>
          <Form.Item label='Budget' name={'budget'}>
            <Input placeholder='PKR' />
          </Form.Item>
        </Col>
        {/* <Col xs={24}>
                  <Form.Item
                    label='Visibility'
                    name='visibility'
                  >
                    <Select
                      style={{ width: '100%' }}
                      onChange={() => {
                        // console.log("e", e)
                      }}
                      size='large'
                      defaultValue={'all'}
                    >
                      <Option value='all'>
                        Organization: All members of organization can access
                      </Option>
                    </Select>
                  </Form.Item>
                </Col> */}
        <Col xs={24} md={12}>
          <Form.Item
            label='Type'
            name={'campaignType'}
            // rules={[
            //   {
            //     required: true,
            //     message: "Type is Required",
            //   },
            // ]}
            // requiredMark={"optional"}
          >
            <Select
              style={{ width: '100%' }}
              onChange={() => {
                // console.log("e", e)
              }}
              size='large'
              placeholder='Select a Colaboration type'
            >
              <Option value={'Content Creation'}>Content Creation</Option>
              <Option value={'Photoshot'}>Photoshot</Option>
              <Option value={'Trip'}>Trip</Option>
              <Option value={'Event'}>Event</Option>
              <Option value={'Product Collaboration'}>Product Collaboration</Option>
              <Option value={'Product Send'}>Product Send</Option>
              <Option value={'Other'}>Other</Option>
            </Select>
          </Form.Item>
        </Col>
        <Col xs={24} md={12}>
          <Form.Item
            label='Objective'
            name={'objective'}
            // rules={[
            //   {
            //     required: true,
            //     message: "Objective is Required",
            //   },
            // ]}
            // requiredMark={"optional"}
          >
            <Select style={{ width: '100%' }} size='large' placeholder='Select Objective'>
              <Option value={'None'}>None</Option>
              <Option value={'Awareness'}>Awarness</Option>
              <Option value={'Consideration'}>Consideration</Option>
              <Option value={'Conversion'}>Conversion</Option>
            </Select>
          </Form.Item>
        </Col>
        <Col xs={24} md={12}>
          <Form.Item
            label='Start Date'
            name={'startDate'}
            rules={[
              {
                required: true,
                message: 'Start Date is Required',
              },
            ]}
            requiredMark={'optional'}
          >
            <DatePicker
              disabledDate={(current) => {
                let customDate = moment(endDateState).format('YYYY-MM-DD')
                return current && current > moment(customDate, 'YYYY-MM-DD')
              }}
              onChange={(date) => {
                setStartDateState(moment(date))
              }}
              style={{ width: '100%', borderRadius: '0' }}
            />
          </Form.Item>
        </Col>
        <Col xs={24} md={12}>
          <Form.Item
            label='End Date'
            name={'endDate'}
            rules={[
              {
                required: true,
                message: 'End Date is Required',
              },
            ]}
            requiredMark={'optional'}
          >
            <DatePicker
              disabledDate={(current) => {
                let customDate = moment(startDateState).format('YYYY-MM-DD')
                return current && current < moment(customDate, 'YYYY-MM-DD')
              }}
              onChange={(date) => {
                setEndDateState(moment(date))
              }}
              style={{ width: '100%', borderRadius: '0' }}
            />
          </Form.Item>
        </Col>
        <Col xs={24}>
          {/* <Form.Item
            label='Hashtags, Mentions, Keywords'
            name={'hashTags'}
            // rules={[
            //   {
            //     required: true,
            //     message: "Hashtags is Required",
            //   },
            // ]}
            // requiredMark={"optional"}
          > */}
          {/* <Select
              mode='tags'
              className='multipleSelect'
              style={{
                width: '100%',
              }}
              placeholder='Enter Campaign hashrags'
            ></Select> */}
          <div>
            <p
              style={{ fontSize: '14px', paddingBottom: '8px', color: 'var(--text)' }}
              className='custom_label_tag'
            >
              Hashtags, Mentions, Keywords
            </p>
            <Input
              placeholder='Press enter for adding hastag'
              value={tagInput}
              onChange={(e) => {
                setTagInput(e?.target?.value)
              }}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  if (tagsState?.indexOf(e?.target?.value) === -1) {
                    console.log('inside not found')
                    setTagInput('')
                    let tempArr = [...tagsState]
                    let splitArr = e?.target?.value?.split(' ')
                    setTagsState([...tempArr, ...splitArr])
                  } else {
                    setTagInput('')
                  }

                  e.preventDefault()
                }
              }}
            />
          </div>
          {/* </Form.Item> */}
        </Col>
        <Col xs={24}>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              flexWrap: 'wrap',
              gap: '0.3rem',
              marginTop: '1rem',
            }}
          >
            {tagsState?.length > 0 &&
              tagsState?.map((tag, index) => (
                <div className='hash_tag' key={index}>
                  {tag}
                  <TiDelete
                    onClick={() => {
                      let tempTags = tagsState?.filter((item) => item !== tag)
                      setTagsState(tempTags)
                    }}
                  />
                </div>
              ))}
          </div>
        </Col>
        {/* <Col xs={24} md={12} lg={8}>
                  <Form.Item
                    label='CPME & Currency'
                    name={'currency'}
                    // rules={[
                    //   {
                    //     required: true,
                    //     message: "CPME & Currency is Required",
                    //   },
                    // ]}
                    // requiredMark={"optional"}
                  >
                    <Select
                      style={{ width: '100%' }}
                      onChange={() => {
                        // console.log("e", e)
                      }}
                      size='large'
                      placeholder='Select Currency'
                    >
                      <Option value={'PKR'}>PKR CPME VALUE - PKR(PKR)</Option>
                    </Select>
                  </Form.Item>
                </Col> */}

        <Col
          xs={24}
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            gap: '1rem',
          }}
        >
          <Form.Item>
            <Button type='text' style={{ marginBottom: '-2rem' }} onClick={() => navigate(-1)}>
              Cancel
            </Button>
          </Form.Item>
          <Form.Item>
            <Button
              htmlType='submit'
              loading={loading}
              type='primary'
              disabled={tagInput?.length > 0 ? true : false}
              title={tagInput?.length > 0 && 'Press Enter to add hash tag'}
            >
              Next
            </Button>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  )
}

export default GeneralForm
