import React from 'react'
import { NumberFormatter } from '../../../functions/NumberFormatter'

import Profile from './BrandSummaryProfile'

function Influencers({ title, icon, data, value  }) {
  return (
    <div className='influencers-container'>
      <div className='header'>
        <p>{title}</p>
      </div>
      {data?.map((inf, ind) => (
        <Profile
          key={ind}
          name={inf?.name}
          progress={inf?.[value] > 0 ? ((inf?.[value] / data[0]?.[value]) * 100).toFixed() : 0}
          icon={icon}
          profileImage={inf?.profileImage}
          value={inf?.[value] ? NumberFormatter(inf?.[value],1) : 0}
          percent={value === 'engRate' ? '%' : ''}
          inf={inf}
        />
      ))}
    </div>
  )
}

export default Influencers
