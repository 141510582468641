import { useState } from "react";
import { Table } from "antd";
import { LoadingOutlined } from "@ant-design/icons";

const DataTable = ({
  data,
  columns,
  pagination,
  selection,
  width,
  // height,
  loader,
}) => {
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  // console.log("DataTable", data);

  const onSelectChange = (selectedRowKeys) => {
    setSelectedRowKeys([...selectedRowKeys]);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const tableLoading = {
    spinning: loader,
    indicator: <LoadingOutlined />,
  };

  return (
    <>
      <Table
        className="data-table"
        columns={columns}
        dataSource={data}
        rowSelection={selection ? rowSelection : null}
        pagination={pagination}
        // pagination={pagination}
        // scroll={{ x: width ? width : "auto" }}
        scroll={{ x: width ? width : "auto" }}
        // loading={true}
        loading={tableLoading}
        // rowClassName={(record, index) => {
        //   // console.log('record',record)
        //   // console.log('index',index)

        // }}
        // rowClassName={(record, index) => record.machineStatus === 'OFF' ? 'MachineOffRed' :  ''}
      />
    </>
  );
};

export default DataTable;
