import React from 'react'
import { Progress } from 'antd'

// import { ExpireDateCheck } from '../../../functions/ExpireDateCheck'
// import OfflineProfileImage from '../../../assets/offlineProfile.png'

function BrandSummaryProfile({ progress, icon, name, value, percent, inf }) {
  return (
    <div className='profile'>
      <img
        alt='profile'
        src={inf?.arch_profile_picture_url}
        // src={
        //   ExpireDateCheck(inf?.dataRefreshed) === 10
        //     ? inf?.arch_profile_picture_url
        //       ? inf?.arch_profile_picture_url
        //       : OfflineProfileImage
        //     : ExpireDateCheck(inf?.dataRefreshed) === 2
        //     ? inf?.arch_profile_picture_url
        //     : OfflineProfileImage
        // }
      />
      <div className='details'>
        <div className='info'>
          <h3>{name || '--'}</h3>
          <div>
            {icon}
            <p>
              {value || '0'}
              {percent}
            </p>
          </div>
        </div>
        <Progress percent={progress} showInfo={false} strokeColor={'#ef5b29bd'} />
      </div>
    </div>
  )
}

export default BrandSummaryProfile
