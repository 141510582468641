import { useState, useEffect } from "react";
import { Col, DatePicker, Input, Row, Table, Typography } from "antd";

import { FiSearch } from "react-icons/fi";
import { FaRegSave } from "react-icons/fa";
import { BsThreeDots } from "react-icons/bs";
import { VscSettings } from "react-icons/vsc";

const Partners = () => {
  const { Text } = Typography;
  const { RangePicker } = DatePicker;
  const [data, setData] = useState(null);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const onDateChange = (date, dateString) => {
    console.log(date, dateString);
  };

  useEffect(() => {
    let tempArr = [];
    [1, 2, 3, 4, 5, 6]?.map(() => {
      tempArr.push({
        name: "Afaque Ahmed",
        brands: "L'Oreal",
        spend: "PKR64M",
        cpp: "PKR64M",
        cpvid: "PKR64M",
        cpe: "PKR 417k",
        cpv: "PKR 224k",
        cpsv: "--",
        action: <BsThreeDots className="dot-icon" />,
      });
    });
    setData(tempArr);
  }, []);

  const columns = [
    {
      key: "1",
      title: "NAME",
      dataIndex: "name",
    },
    {
      key: "2",

      title: "BRANDS",
      dataIndex: "brands",
    },
    {
      key: "3",
      title: "SPEND",
      dataIndex: "spend",
    },
    {
      key: "4",
      title: "CPP",
      dataIndex: "cpp",
    },
    {
      key: "5",
      title: "CPVID",
      dataIndex: "cpvid",
    },
    {
      key: "6",
      title: "CPE",
      dataIndex: "cpe",
    },
    {
      key: "7",
      title: "CPV",
      dataIndex: "cpv",
    },
    {
      key: "8",
      title: "CPSV",
      dataIndex: "cpsv",
    },
    {
      key: "9",
      title: "ACTION",
      dataIndex: "action",
    },
  ];

  const onSelectChange = (selectedRowKeys) => {
    setSelectedRowKeys([...selectedRowKeys]);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  //   const tableLoading = {
  //     spinning: loader,
  //     indicator: <LoadingOutlined />,
  //   };

  return (
    <div className="partner-container">
      {/* Filter Bar */}
      <div className="filter-bar">
        <div className="left-side">
          <div className="filter-div">
            <VscSettings className="icon" />
            <span>Filter</span>
          </div>
          <RangePicker
            className="datePicker"
            style={{ width: "220px" }}
            onChange={onDateChange}
          />
        </div>
        <div className="right-side">
          <Input
            placeholder="Search by name"
            prefix={<FiSearch className="search-icon" />}
            className="searchInput"
            size="small"
          />
          <FaRegSave className="add-icon" style={{ width: "50px" }} />
        </div>
      </div>

      {/* Analytic's Box */}
      <Row className="analtics-boxes" gutter={[5, 5]}>
        <Col xs={12} md={8} lg={4}>
          <div className="box">
            <span>244</span>
            <p>PARTNERS</p>
          </div>
        </Col>
        <Col xs={12} md={8} lg={4}>
          <div className="box">
            <span>PKR75M</span>
            <p>TOTAL SPEND</p>
          </div>
        </Col>
        <Col xs={12} md={8} lg={4}>
          <div className="box">
            <span>PKR80.7K</span>
            <p>COST PER POST</p>
          </div>
        </Col>
        <Col xs={12} md={8} lg={4}>
          <div className="box">
            <span>PKR101K</span>
            <p>COST PER VIDEO</p>
          </div>
        </Col>
        <Col xs={12} md={8} lg={4}>
          <div className="box">
            <span>PKR 38.92</span>
            <p>CPE</p>
          </div>
        </Col>
        <Col xs={12} md={8} lg={4}>
          <div className="box">
            <span>PKR 2.53</span>
            <p>CPV</p>
          </div>
        </Col>
      </Row>

      {/* Table */}
      <Table
        className="data-table"
        columns={columns}
        dataSource={data}
        rowSelection={rowSelection}
        pagination
        // scroll={{ x: "auto" }}
        // loading={}
        summary={() => {
          // let totalBorrow = 0;
          // let totalRepayment = 0;
          // pageData.forEach(({ borrow, repayment }) => {
          //   totalBorrow += borrow;
          //   totalRepayment += repayment;
          // });
          return (
            <>
              <Table.Summary.Row>
                <Table.Summary.Cell index={1}></Table.Summary.Cell>
                <Table.Summary.Cell index={2} colSpan={2}>
                  <span style={{ color: "var(--primary)", fontWeight: "800" }}>
                    Total
                  </span>
                </Table.Summary.Cell>
                <Table.Summary.Cell index={3}>
                  <Text>PKR177M</Text>
                </Table.Summary.Cell>
                <Table.Summary.Cell index={4}>
                  <Text>PKR 64M</Text>
                </Table.Summary.Cell>
                <Table.Summary.Cell index={5}>
                  <Text>PKR 64M</Text>
                </Table.Summary.Cell>
                <Table.Summary.Cell index={6}>
                  <Text>PKR 64M</Text>
                </Table.Summary.Cell>
                <Table.Summary.Cell index={7}>
                  <Text>PKR 64M</Text>
                </Table.Summary.Cell>
                <Table.Summary.Cell index={8}>
                  <Text>--</Text>
                </Table.Summary.Cell>
              </Table.Summary.Row>
              {/* <Table.Summary.Row>
                  <Table.Summary.Cell index={0}>Balance</Table.Summary.Cell>
                  <Table.Summary.Cell index={1} colSpan={2}>
                    <Text type="danger">{totalBorrow - totalRepayment}</Text>
                  </Table.Summary.Cell>
                </Table.Summary.Row> */}
            </>
          );
        }}
      />
    </div>
  );
};

export default Partners;
