import { useState, useEffect } from "react";

import Layout from "../layout/Layout";
import DataTable from "../components/Table/DataTable";
import { getModerators } from "../redux";
import { useDispatch } from "react-redux";
import AddModerator from "../components/modal/AddModerator";
import moment from "moment";

const Moderators = () => {
  const dispatch = useDispatch();
  const [data, setData] = useState(null);
  const [moderatorData, setModeratorData] = useState(null);

  const getData = async () => {
    let res = await dispatch(getModerators());
    setModeratorData(res);
  };

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    let tempArr = [];
    moderatorData?.map((i) => {
      tempArr.push({
        name: `${i?.firstName} ${i?.lastName}`,
        email: i?.email,
        date: moment(i?.createdAt).format("MMM DD, YYYY"),
      });
    });
    setData(tempArr);
  }, [moderatorData]);

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
    },
    {
      title: "Email",
      dataIndex: "email",
    },
    {
      title: "Created Date",
      dataIndex: "date",
    },
  ];

  return (
    <Layout active={"moderators"}>
      <div className="moderators-container">
        <div className="header">
          <h2 className="heading">Moderators</h2>
          <AddModerator getData={getData} />
        </div>
        {/* <p className="subHeading">0 people want to join your studio</p> */}
        <div className="main">
          <DataTable columns={columns} data={data} loader={false} />
        </div>
      </div>
    </Layout>
  );
};

export default Moderators;
