import { Skeleton } from 'antd'
import React from 'react'

const BrandBuzzSkeleton = () => {
  return (
    <div style={{ backgroundColor: 'white', borderRadius: '8px', padding: '10px 20px' }}>
      <Skeleton active avatar paragraph={{ rows: 0 }} />
      <Skeleton.Image active style={{ height: '300px', width: '300px', marginBottom: '10px' }} />
      <Skeleton active paragraph={{ rows: 2 }} />
    </div>
  )
}

export default BrandBuzzSkeleton
