import RingChart from '../../Charts/RingChart'

const AudienceCredibility = ({ data }) => {
  return (
    <>
      {data && (
        <div className='ringChart_div'>
          <h3>Audience Credibility</h3>
          <RingChart percent={data?.percent / 100} />
          {data?.data?.map((item, ind) => (
            <div key={ind} className='states_Div'>
              <p>{item?.name}</p>
              <p>{item?.value}</p>
            </div>
          ))}
        </div>
      )}
    </>
  )
}

export default AudienceCredibility
