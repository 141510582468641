import { Collapse, Input, Button } from "antd";

import Layout from "../layout/Layout";
import { AiOutlineCalendar } from "react-icons/ai";
import { FiSearch } from "react-icons/fi";
import AddNotes from "../components/modal/AddNotes";
import { VscSettings } from "react-icons/vsc";

const Notes = () => {
  const { Panel } = Collapse;

  const CollapesHeader = () => (
    <div className="note">
      <div className="left">
        <div className="avatar">HI</div>
        <div className="info">
          <p className="todo">
            <span>Afaque Ahmed</span> completed a task | ENIGINE
          </p>
          <p className="influencer">
            {" "}
            <span>@AnumFatima</span> Approved{" "}
          </p>
        </div>
      </div>
      <div className="right">
        <p className="date">
          Dec 21 <AiOutlineCalendar className="icon" />{" "}
        </p>
      </div>
    </div>
  );

  return (
    <Layout active={"notes"}>
      <div className="notes-container">
        <h2 className="mainHeading">Notes</h2>
        <p className="subHeading">Recent Notes</p>

        {/* Filter Bar */}
        <div className="filter-bar">
          <div className="left-side">
            <div className="filter-div">
              <VscSettings className="icon" />
              <span>Filter</span>
            </div>

            <Input
              placeholder="Search by Keywords"
              prefix={<FiSearch className="search-icon" />}
              className="searchInput"
              size="small"
              style={{ minWidth: "100px" }}
            />
          </div>
          <div className="right-side">
            <AddNotes />
          </div>
        </div>

        {/* Notes */}
        <div className="notes-collapse">
          <Collapse
            // defaultActiveKey={["1"]}
            onChange={(e) => console.log("e", e)}
            accordion
          >
            {[1, 2, 3, 4, 5, 6, 7, 8]?.map((not) => (
              <Panel
                showArrow={false}
                header={CollapesHeader("hello")}
                key={not}
              >
                <div className="note-panel">
                  <Button type="default" size="large">
                    Edit
                  </Button>
                  <Button type="default" size="large">
                    Delete
                  </Button>
                </div>
              </Panel>
            ))}
          </Collapse>
        </div>
      </div>
    </Layout>
  );
};

export default Notes;
