import React from "react";
import { Col, Row } from "antd";

import BrandBuzzCard from "../Cards/BrandBuzzCard";

const SearchContent = () => {
  return (
    <div>
      <Row gutter={[20, 30]}>
        <Col xs={24} lg={12} xl={8} xxl={6}>
          <BrandBuzzCard />
        </Col>
        <Col xs={24} lg={12} xl={8} xxl={6}>
          <BrandBuzzCard />
        </Col>
        <Col xs={24} lg={12} xl={8} xxl={6}>
          <BrandBuzzCard />
        </Col>
        <Col xs={24} lg={12} xl={8} xxl={6}>
          <BrandBuzzCard />
        </Col>
        <Col xs={24} lg={12} xl={8} xxl={6}>
          <BrandBuzzCard />
        </Col>
        <Col xs={24} lg={12} xl={8} xxl={6}>
          <BrandBuzzCard />
        </Col>
        <Col xs={24} lg={12} xl={8} xxl={6}>
          <BrandBuzzCard />
        </Col>
        <Col xs={24} lg={12} xl={8} xxl={6}>
          <BrandBuzzCard />
        </Col>
      </Row>
    </div>
  );
};

export default SearchContent;
