import { useState, useEffect } from "react";
import { DatePicker } from "antd";

import { FaRegSave } from "react-icons/fa";
import { VscSettings } from "react-icons/vsc";
import DataTable from "../Table/DataTable";

const Brands = () => {
  const { RangePicker } = DatePicker;
  const [data, setData] = useState(null);

  const onDateChange = (date, dateString) => {
    console.log(date, dateString);
  };

  useEffect(() => {
    let tempArr = [];
    [1, 2, 3, 4, 5, 6, 7, 8]?.map(() => {
      tempArr.push({
        brands: "L'Oreal",
        spend: "PKR64M",
        cpp: "PKR64M",
        cpvid: "PKR64M",
        cpe: "PKR 417k",
        cpv: "PKR 224k",
        cpsv: "--",
      });
    });
    setData(tempArr);
  }, []);

  const columns = [
    {
      title: "BRANDS",
      dataIndex: "brands",
    },
    {
      title: "SPEND",
      dataIndex: "spend",
    },
    {
      title: "CPP",
      dataIndex: "cpp",
    },
    {
      title: "CPVID",
      dataIndex: "cpvid",
    },
    {
      title: "CPE",
      dataIndex: "cpe",
    },
    {
      title: "CPV",
      dataIndex: "cpv",
    },
    {
      title: "CPSV",
      dataIndex: "cpsv",
    },
  ];

  return (
    <div className="partner-container">
      {/* Filter Bar */}
      <div className="filter-bar">
        <div className="left-side">
          <div className="filter-div">
            <VscSettings className="icon" />
            <span>Filter</span>
          </div>
          <RangePicker
            className="datePicker"
            style={{ width: "220px" }}
            onChange={onDateChange}
          />
        </div>
        <div className="right-side">
          <FaRegSave className="add-icon" style={{ width: "50px" }} />
        </div>
      </div>
      <br />
      {/* Table */}
      <DataTable data={data} columns={columns} loader={false} />
    </div>
  );
};

export default Brands;
