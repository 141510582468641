import React from "react";
import loader from "../assets/loader.svg";

const InfinityLoader = ({ height }) => {
  return (
    <div
      className="infinityLoader"
      style={{ height: height ? height : "auto" }}
    >
      <img src={loader} alt="loader..." />
    </div>
  );
};

export default InfinityLoader;
