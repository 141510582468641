import * as generalType from "../types/generalTypes";

const initialState = {
  categories: [],
};

const NetworkReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case generalType.GET_ALL_INFLUENCER_CATEGORIES:
      return {
        ...state,
        categories: payload,
      };
    default:
      return state;
  }
};

export default NetworkReducer;
