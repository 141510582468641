import { useState, useEffect } from "react";
import { Row, Col } from "antd";
import { useDispatch, useSelector } from "react-redux";

import Layout from "../../layout/Layout";
import {attachToken} from '../../config/constants'
import { getAllGroups, getAllInfluencersForCampaign } from "../../redux/index";
import AddGroup from "../../components/modal/AddGroup";
import GroupsCard from "../../components/Cards/GroupsCard";
import InfinityLoader from "../../components/InfinityLoader";

const Groups = () => {
  const dispatch = useDispatch();
  const [groups, setGroups] = useState(null);
  const [influencers, setInfluencers] = useState(null)
  const selectedBrand = useSelector((state) => state?.brand?.selectedBrand);

  const getInfluencers = async () => {
    try {
      let payload = {
        searchString: '',
      }
      attachToken()
      const res = await dispatch(getAllInfluencersForCampaign(payload))
      if (res) {
        setInfluencers(res)
      }
    } catch (error) {
      console.log('error', error)
    }
  }

  const getAllGroupsData = async () => {
    let payload = {
      brand: selectedBrand,
    };
    const res = await dispatch(getAllGroups(payload));
    setGroups(res);
  };

  useEffect( () => {
    if(selectedBrand){
      getAllGroupsData();
    }
    if(!influencers || influencers?.length == 0){
      getInfluencers()
    }
  }, [selectedBrand]);
  
  return (
    <Layout active={"Groups"}>
      {groups ? (
        <div className="group-container">
          <h2 className="mainHeading">Group</h2>
          <p className="subHeading">
            {groups?.length} groups are in your network.
          </p>

          {/* Filter Bar */}
          <div className="filter-bar">
            <div className="left-side">
              {/* <div className="filter-div">
              <VscSettings className="icon" />
              <span>Filter</span>
            </div>
            <Input
              placeholder="Search by name"
              prefix={<FiSearch className="search-icon" />}
              className="searchInput"
              size="small"
            /> */}
            </div>
            <div className="right-side">
              <AddGroup getAllGroupsData={getAllGroupsData} influencers={influencers}/>
            </div>
          </div>

          {/* Groups Render */}

          <Row gutter={[30, 30]} className="cards-container">
            {groups?.map((group,ind) => (
              <Col sm={24} lg={12} key={ind}>
                <GroupsCard data={group} getAllGroupsData={getAllGroupsData} />
              </Col>
            ))}
          </Row>
        </div>
      ) : (
        <InfinityLoader height={"50vh"} />
      )}
    </Layout>
  );
};

export default Groups;
