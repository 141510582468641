import React, { useState, useEffect } from 'react'
import { Button, Col, Input, notification, Row, Select } from 'antd'
import {
  // AiOutlinePlus,
  // AiOutlineCalendar,
  // AiOutlineFolder,
  // AiOutlineSearch,
  // AiOutlineBell,
  AiOutlineEdit,
} from 'react-icons/ai'
// import { MdPersonAddAlt } from "react-icons/md";
// import { VscSettings } from "react-icons/vsc";
// import profile from "../../assets/295116932_497088365417056_7090162834735970738_n.jpg";
import { attachToken, privateAPI } from '../../../config/constants'
import { useDispatch, useSelector } from 'react-redux'
import { getAllInfluencerCateories } from '../../../redux'

const InfluencerInfo = ({ id, getInfluencer, data }) => {
  const { Option } = Select
  const dispatch = useDispatch()
  const [editState, setEditState] = useState('')
  const [name, setName] = useState(null)
  const [email, setEmail] = useState(null)
  const [gender, setGender] = useState(null)
  const [phone, setPhone] = useState(null)
  const [address, setAddress] = useState(null)
  const [birthDay, setBirthDay] = useState(null)
  const [valuesMRS, setValuesMRS] = useState(null)
  const [valuesMPBVM, setValuesMPBVM] = useState(null)
  const [validityPeriod, setValidityPeriod] = useState(null)
  const [alignedReason, setAlignedReason] = useState(null)
  const [category, setCategory] = useState(null)
  const [reference, setReference] = useState(null)
  const [loader, setLoader] = useState(false)
  const influencerCategories = useSelector((state) => state?.network?.categories)

  console.log('inside inf info tab',data)

  useEffect(() => {
    setName(data?.influencer?.name)
    setEmail(data?.influencer?.email)
    setGender(data?.influencer?.gender)
    setPhone(data?.influencer?.phone)
    setBirthDay(data?.influencer?.birthDay)
    setAddress(data?.influencer?.address)
    setValuesMRS(data?.influencer?.valuesMRS)
    setValuesMPBVM(data?.influencer?.valuesMPBVM)
    setValidityPeriod(data?.influencer?.validityPeriod)
    setAlignedReason(data?.influencer?.alignedReason)
    let catArr = data?.influencer?.category?.map((cat) => cat?._id) || []
    setCategory(catArr)
    setReference(data?.influencer?.reference)
  }, [data])

  useEffect(() => {
    dispatch(getAllInfluencerCateories())
  }, [])

  const UpdateDetails = async (values) => {
    setLoader(true)
    try {
      let payload = {
        ...values,
        id,
      }
      attachToken()
      const res = await privateAPI.post(`/influencer/update`, payload)
      if (res?.data) {
        setEditState(null)
        getInfluencer()
        notification.success({
          message: res?.data?.message,
          duration: 2,
        })
        setLoader(false)
      }
    } catch (err) {
      notification.error({
        message: err?.response?.data?.message,
        duration: 3,
      })
      setLoader(false)
    }
  }

  return (
    <div className='influencer-info'>
      <Row gutter={[20, 20]}>
        <Col className='MainInfoParenDiv' xs={24} lg={24}>
          <div className='InfoDetailsDiv'>
            <p>Personal Details</p>
          </div>
          <div className='InfoDetailsDiv'>
            <p>Name</p>
            {editState === 'name' ? (
              <div className='edit-div'>
                <Input
                  className='input'
                  placeholder='Enter Name'
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
                <div className='action-div'>
                  <Button
                    type='primary'
                    size='large'
                    loading={loader}
                    onClick={() => UpdateDetails({ name: name })}
                  >
                    Save
                  </Button>
                  <Button
                    type='default'
                    size='large'
                    onClick={() => {
                      setName(null)
                      setEditState('')
                    }}
                  >
                    Cancel
                  </Button>
                </div>
              </div>
            ) : (
              <p>{name}</p>
            )}
            {editState !== 'name' && (
              <AiOutlineEdit className='icon' onClick={() => setEditState('name')} />
            )}
          </div>
          <div className='InfoDetailsDiv'>
            <p>Email</p>
            {editState === 'email' ? (
              <div className='edit-div'>
                <Input
                  className='input'
                  placeholder='Enter Email'
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
                <div className='action-div'>
                  <Button
                    type='primary'
                    size='large'
                    loading={loader}
                    onClick={() => UpdateDetails({ email: email })}
                  >
                    Save
                  </Button>
                  <Button
                    type='default'
                    size='large'
                    onClick={() => {
                      setEmail(null)
                      setEditState('')
                    }}
                  >
                    Cancel
                  </Button>
                </div>
              </div>
            ) : (
              <p>{email}</p>
            )}
            {editState !== 'email' && (
              <AiOutlineEdit className='icon' onClick={() => setEditState('email')} />
            )}
          </div>
          <div className='InfoDetailsDiv'>
            <p>Gender</p>
            {editState === 'gender' ? (
              <div className='edit-div'>
                <Select
                  placeholder='Select Gender'
                  style={{ minWidth: '200px' }}
                  onChange={(e) => {
                    setGender(e)
                  }}
                  size='large'
                >
                  <Option value='Male'>Male</Option>
                  <Option value='Female'>Female</Option>
                </Select>
                <div className="action-div">
                  <Button
                    type="primary"
                    size="large"
                    loading={loader}
                    onClick={() => UpdateDetails({ gender: gender })}
                  >
                    Save
                  </Button>
                  <Button
                    type="default"
                    size="large"
                    onClick={() => {
                      setGender(null);
                      setEditState("");
                    }}
                  >
                    Cancel
                  </Button>
                </div>
                {/* <Input
                  className="input"
                  placeholder="Select Gender"
                  value={gender}
                  onChange={(e) => setGender(e.target.value)}
                />
                <div className="action-div">
                  <Button
                    type="primary"
                    size="large"
                    loading={loader}
                    onClick={() => UpdateDetails({ gender: gender })}
                  >
                    Save
                  </Button>
                  <Button
                    type="default"
                    size="large"
                    onClick={() => {
                      setGender(null);
                      setEditState("");
                    }}
                  >
                    Cancel
                  </Button>
                </div> */}
              </div>
            ) : (
              <p>{gender}</p>
            )}
            {editState !== 'gender' && (
              <AiOutlineEdit className='icon' onClick={() => setEditState('gender')} />
            )}
          </div>
          <div className='InfoDetailsDiv'>
            <p>Categories</p>
            {editState === 'category' ? (
              <div className='edit-div'>
                <div className='action-div'>
                  <Select
                    mode='multiple'
                    style={{ minWidth: '200px' }}
                    defaultValue={category}
                    value={category}
                    onChange={(e) => setCategory(e)}
                    placeholder='Select Category'
                  >
                    {influencerCategories?.map((cat, ind) => (
                      <Option key={ind} value={cat?._id}>
                        {cat?.name}
                      </Option>
                    ))}
                  </Select>
                  <Button
                    type='primary'
                    size='large'
                    loading={loader}
                    onClick={() => UpdateDetails({ category: category })}
                  >
                    Save
                  </Button>
                  <Button
                    type='default'
                    size='large'
                    onClick={() => {
                      setCategory(null)
                      setEditState('')
                    }}
                  >
                    Cancel
                  </Button>
                </div>
              </div>
            ) : (
              <div className='categories'>
                {data?.influencer?.category?.map((cat, ind) => (
                  <p key={ind}>{cat?.name}</p>
                ))}
              </div>
            )}
            {editState !== 'category' && (
              <AiOutlineEdit className='icon' onClick={() => setEditState('category')} />
            )}
          </div>

          <div className='InfoDetailsDiv'>
            <p>Reference</p>
            {editState === 'reference' ? (
              <div className='edit-div'>
                <Input
                  className='input'
                  placeholder='Enter Reference'
                  value={reference}
                  onChange={(e) => setReference(e.target.value)}
                />
                <div className='action-div'>
                  <Button
                    type='primary'
                    size='large'
                    loading={loader}
                    onClick={() => UpdateDetails({ reference: reference })}
                  >
                    Save
                  </Button>
                  <Button
                    type='default'
                    size='large'
                    onClick={() => {
                      setReference(null)
                      setEditState('')
                    }}
                  >
                    Cancel
                  </Button>
                </div>
              </div>
            ) : (
              <p>{reference}</p>
            )}
            {editState !== 'reference' && (
              <AiOutlineEdit className='icon' onClick={() => setEditState('reference')} />
            )}
          </div>

          <div className='InfoDetailsDiv'>
            <p>Phone</p>
            {editState === 'phone' ? (
              <div className='edit-div'>
                <Input
                  className='input'
                  placeholder='Enter Phone'
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                />
                <div className='action-div'>
                  <Button
                    type='primary'
                    size='large'
                    loading={loader}
                    onClick={() => UpdateDetails({ phone: phone })}
                  >
                    Save
                  </Button>
                  <Button
                    type='default'
                    size='large'
                    onClick={() => {
                      setPhone(null)
                      setEditState('')
                    }}
                  >
                    Cancel
                  </Button>
                </div>
              </div>
            ) : (
              <p>{phone}</p>
            )}
            {editState !== 'phone' && (
              <AiOutlineEdit className='icon' onClick={() => setEditState('phone')} />
            )}
          </div>
          <div className='InfoDetailsDiv'>
            <p>Address</p>
            {editState === 'address' ? (
              <div className='edit-div'>
                <Input
                  className='input'
                  placeholder='Enter Address'
                  value={address}
                  onChange={(e) => setAddress(e.target.value)}
                />
                <div className='action-div'>
                  <Button
                    type='primary'
                    size='large'
                    loading={loader}
                    onClick={() => UpdateDetails({ address: address })}
                  >
                    Save
                  </Button>
                  <Button
                    type='default'
                    size='large'
                    onClick={() => {
                      setAddress(null)
                      setEditState('')
                    }}
                  >
                    Cancel
                  </Button>
                </div>
              </div>
            ) : (
              <p>{address}</p>
            )}
            {editState !== 'address' && (
              <AiOutlineEdit className='icon' onClick={() => setEditState('address')} />
            )}
          </div>
          <div className='InfoDetailsDiv'>
            <p>Birtday</p>
            {editState === 'birthDay' ? (
              <div className='edit-div'>
                <Input
                  className='input'
                  placeholder='Enter birthDay'
                  value={birthDay}
                  onChange={(e) => setBirthDay(e.target.value)}
                />
                <div className='action-div'>
                  <Button
                    type='primary'
                    size='large'
                    loading={loader}
                    onClick={() => UpdateDetails({ birthDay: birthDay })}
                  >
                    Save
                  </Button>
                  <Button
                    type='default'
                    size='large'
                    onClick={() => {
                      setBirthDay(null)
                      setEditState('')
                    }}
                  >
                    Cancel
                  </Button>
                </div>
              </div>
            ) : (
              <p>{birthDay}</p>
            )}
            {editState !== 'birthDay' && (
              <AiOutlineEdit className='icon' onClick={() => setEditState('birthDay')} />
            )}
          </div>
          <div className='InfoDetailsDiv'>
            <p>Values Match Review Status</p>
            {editState === 'valuesMRS' ? (
              <div className='edit-div'>
                <Select
                  style={{ minWidth: '200px' }}
                  value={valuesMRS}
                  onChange={(e) => setValuesMRS(e)}
                  placeholder='Select'
                >
                  <Option value={'Aligned'}>Aligned</Option>
                  <Option value={'Not Aligned'}>Not Aligned</Option>
                  <Option value={'Not Reviewed'}>Not Reviewed</Option>
                </Select>
                <div className='action-div'>
                  <Button
                    type='primary'
                    size='large'
                    loading={loader}
                    onClick={() => UpdateDetails({ valuesMRS: valuesMRS })}
                  >
                    Save
                  </Button>
                  <Button
                    type='default'
                    size='large'
                    onClick={() => {
                      setValuesMRS(null)
                      setEditState('')
                    }}
                  >
                    Cancel
                  </Button>
                </div>
              </div>
            ) : (
              <p>{valuesMRS}</p>
            )}
            {editState !== 'valuesMRS' && (
              <AiOutlineEdit className='icon' onClick={() => setEditState('valuesMRS')} />
            )}
          </div>
          <div className='InfoDetailsDiv'>
            <p>Values Match Performed By Value Match</p>
            <p></p>
            {editState === 'valuesMPBVM' ? (
              <div className='edit-div'>
                <Input
                  className='input'
                  placeholder='Enter values'
                  value={valuesMPBVM}
                  onChange={(e) => setValuesMPBVM(e.target.value)}
                />
                <div className='action-div'>
                  <Button
                    type='primary'
                    size='large'
                    loading={loader}
                    onClick={() => UpdateDetails({ valuesMPBVM: valuesMPBVM })}
                  >
                    Save
                  </Button>
                  <Button
                    type='default'
                    size='large'
                    onClick={() => {
                      setValuesMPBVM(null)
                      setEditState('')
                    }}
                  >
                    Cancel
                  </Button>
                </div>
              </div>
            ) : (
              <p>{valuesMPBVM}</p>
            )}
            {editState !== 'valuesMPBVM' && (
              <AiOutlineEdit className='icon' onClick={() => setEditState('valuesMPBVM')} />
            )}
          </div>
          <div className='InfoDetailsDiv'>
            <p>Validity Period (DD MMM YYYY - DD MMMM YYYYY)</p>
            {editState === 'validityPeriod' ? (
              <div className='edit-div'>
                <Input
                  className='input'
                  placeholder='Enter Validity Period'
                  value={validityPeriod}
                  onChange={(e) => setValidityPeriod(e.target.value)}
                />
                <div className='action-div'>
                  <Button
                    type='primary'
                    size='large'
                    loading={loader}
                    onClick={() => UpdateDetails({ validityPeriod: validityPeriod })}
                  >
                    Save
                  </Button>
                  <Button
                    type='default'
                    size='large'
                    onClick={() => {
                      setValidityPeriod(null)
                      setEditState('')
                    }}
                  >
                    Cancel
                  </Button>
                </div>
              </div>
            ) : (
              <p>{validityPeriod}</p>
            )}
            {editState !== 'validityPeriod' && (
              <AiOutlineEdit className='icon' onClick={() => setEditState('validityPeriod')} />
            )}
          </div>
          <div className='InfoDetailsDiv'>
            <p>Reason For Not Aligned</p>
            {editState === 'alignedReason' ? (
              <div className='edit-div'>
                <Input
                  className='input'
                  placeholder='Enter Reason'
                  value={alignedReason}
                  onChange={(e) => setAlignedReason(e.target.value)}
                />
                <div className='action-div'>
                  <Button
                    type='primary'
                    size='large'
                    loading={loader}
                    onClick={() => UpdateDetails({ alignedReason: alignedReason })}
                  >
                    Save
                  </Button>
                  <Button
                    type='default'
                    size='large'
                    onClick={() => {
                      setAlignedReason(null)
                      setEditState('')
                    }}
                  >
                    Cancel
                  </Button>
                </div>
              </div>
            ) : (
              <p>{alignedReason}</p>
            )}
            {editState !== 'alignedReason' && (
              <AiOutlineEdit className='icon' onClick={() => setEditState('alignedReason')} />
            )}
          </div>
        </Col>
        {/* <Col md={24} lg={12}>
          <div className="taskParenDiv">
            <p>Task</p>
            <div className="Info_Button_Div">
              <div className="Info_Icon_Text_Div">
                <AiOutlinePlus className="add" />
                <p>Create New Task</p>
              </div>
              <div className="Info_Icon_Div">
                <AiOutlineCalendar />
                <MdPersonAddAlt />
                <AiOutlineFolder />
              </div>
            </div>
            <div style={{ textAlign: "center" }}>
              <h3>Nothing to do here. Have a super day!</h3>
              <p>Create new task by clicking on the plus icon</p>
            </div>
          </div>
          <div className="NotesParenDiv">
            <div className="Notes_Text_Icon_Div">
              <p>Notes</p>
              <div className="Notes_Icon_Div">
                <Select
                  placeholder="Date"
                  style={{ minWidth: 100 }}
                  onChange={() => {}}
                  size="large"
                >
                  <Option value="today">Today</Option>
                  <Option value="yesterday">Yesterday</Option>
                </Select>
                <div className="filter-div">
                  <AiOutlineSearch className="icon" />
                  Keyword
                </div>
                <div className="filter-div">
                  <VscSettings className="icon" />
                  <span>Filter</span>
                </div>
              </div>
            </div>
            <div>
              <div className="Notes_TextArea_Main_Div">
                <p className="Notes_Circle_Div">MI</p>
                <Input.TextArea rows={2} placeholder="Write a note..." />
              </div>
              <div className="Notes_Comments_div">
                <img src={profile} alt="profile" />
                <p>Umais naveed moved Sami Malik to micro (10k - 50k)</p>
                <p>
                  Aug 26 <AiOutlineBell />
                </p>
              </div>
              <div className="Notes_Comments_div">
                <img src={profile} alt="profile" />
                <p>Umais naveed moved Sami Malik to micro (10k - 50k)</p>
                <p>
                  Aug 26 <AiOutlineBell />
                </p>
              </div>
              <div className="Notes_Comments_div">
                <img src={profile} alt="profile" />
                <p>Umais naveed moved Sami Malik to micro (10k - 50k)</p>
                <p>
                  Aug 26 <AiOutlineBell />
                </p>
              </div>
              <div className="Notes_Comments_div">
                <img src={profile} alt="profile" />
                <p>Umais naveed moved Sami Malik to micro (10k - 50k)</p>
                <p>
                  Aug 26 <AiOutlineBell />
                </p>
              </div>
            </div>
          </div>
        </Col> */}
      </Row>
    </div>
  )
}

export default InfluencerInfo
