import { useState, useEffect } from 'react'
import { Input, Table, Select, Dropdown, Menu, Checkbox, Button } from 'antd'
import { useNavigate } from 'react-router-dom'
import { CSVLink } from 'react-csv'
import moment from 'moment'

import { LoadingOutlined } from '@ant-design/icons'
import { CiCircleInfo } from 'react-icons/ci'
import { FiSearch } from 'react-icons/fi'
import { GrDocumentCsv } from 'react-icons/gr'
import { BsThreeDots } from 'react-icons/bs'
// import { VscSettings } from "react-icons/vsc";
import {
  AiOutlineDelete,
  AiOutlineMinusCircle,
  // AiOutlineUserAdd,
  AiOutlineFilePpt,
} from 'react-icons/ai'
import { RiDeleteBinLine } from 'react-icons/ri'
import {
  // MdOutlineAssignment,
  MdOutlineMail,
} from 'react-icons/md'

import { NumberFormatter } from '../../../functions/NumberFormatter'
import { attachToken, privateAPI } from '../../../config/constants'
import CampaignInfluncerAssignDrawer from '../../../components/Drawer/CampaignInfluncerAssignDrawer'
import CampaginInfluencerAdd from '../../../components/modal/CampaignAddInfluencer'
import InfinityLoader from '../../../components/InfinityLoader'
import {
  campaignProfileColumns,
  onManyStatusChanges,
  onStatusChange,
} from '../../../Utilities/CampaignProfile'
import { SelectAll } from '../../../functions/CampaigCalculations'
import { campaignEmailSent, campaignInfluencerDelete, campaignEmailbulkSent } from '../../../redux'
import { DeliverablesLength } from '../utils/campaignUtil'
// import OfflineProfileImage from '../../../assets/offlineProfile.png'
// import { ExpireDateCheck } from '../../../functions/ExpireDateCheck'
import { generatePpt } from '../../../functions/ExportPPT'
import CampaignInfluencersEmailCheck from '../../../components/modal/CampaignInfluencersEmailCheck'

const CampaignProfile = ({ id }) => {
  const { Option } = Select
  const navigate = useNavigate()
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [data, setData] = useState(null)
  const [CSVData, setCSVData] = useState([])
  const [campaignPPTData, setCampaignPPTData] = useState([])
  const [campaignPPTLoading, setCampaignPPTLoading] = useState(false)
  const [selectedInfluencer, setSelectedInfluencer] = useState([])
  const [steps, setSteps] = useState('all')
  const [perPage, setPerPage] = useState(10)
  const [campaignData, setCampaignData] = useState(null)
  const [campaignTableData, setCampaignTableData] = useState(null)
  const [searchString, setSearchString] = useState('')
  const [UpdateStatusMany, setUpdateStatusMany] = useState(null)
  const [emailSendLoader, setEmailSendLoader] = useState(false)

  const getAllProfiles = async () => {
    let payload = {
      status: steps,
      searchString: searchString,
    }
    try {
      attachToken()
      const res = await privateAPI.post(`/campaign/get-campaigns-profile/${id}`, payload)
      if (res?.data) {
        setCampaignData(res?.data)
        setCampaignTableData(res?.data)
      }
    } catch (error) {
      console.log('err', error)
    }
  }

  const getCampaignPPTData = async () => {
    try {
      attachToken()
      const res = await privateAPI.post(`/influencer/get-campaign-ppt-data/${id}`)
      setCampaignPPTData(res?.data?.data)
    } catch (err) {
      console.log(err)
    }
  }

  useEffect(() => {
    setCampaignTableData(null)
    getAllProfiles()
    getCampaignPPTData()
  }, [id, steps])

  useEffect(() => {
    let tempCSVData = []
    let tempArr = []
    const campaignTableDataTemp = campaignTableData?.data
    campaignTableDataTemp?.sort((a, b) => {
      if (a?.influencer?.name?.toLowerCase() > b?.influencer?.name?.toLowerCase()) {
        return 1
      }
      if (a?.influencer?.name?.toLowerCase() < b?.influencer?.name?.toLowerCase()) {
        return -1
      }
      return 0
    })
    // campaignTableData?.data?.map((i, index) => {
    campaignTableDataTemp?.map((i, index) => {
      tempArr.push({
        key: index,
        addAfter: i?.addAfter,
        influencer: i?.influencer?._id,
        campaignId: i?._id,
        deliverables: i?.deliverables,
        name: (
          <div
            style={{ zIndex: '10' }}
            className='profile-info'
            onClick={() => navigate(`/influencer/${i?.influencer?._id}`)}
          >
            <img
              src={i?.influencer?.arch_profile_picture_url || i?.influencer?.profile_picture_url}
              // src={
              //   ExpireDateCheck(i?.influencer?.dataRefreshed) === 10
              //     ? i?.influencer?.arch_profile_picture_url
              //       ? i?.influencer?.arch_profile_picture_url
              //       : OfflineProfileImage
              //     : ExpireDateCheck(i?.influencer?.dataRefreshed) === 2
              //     ? i?.influencer?.profile_picture_url
              //     : OfflineProfileImage
              // }
              alt='profile'
            />
            <span className='link'>{i?.influencer?.name}</span>
          </div>
        ),
        audience: NumberFormatter(i?.influencer?.audience, 1),
        rate: i?.influencer?.instagram?.engagememtRate
          ? `${Number(i?.influencer?.instagram?.engagememtRate)?.toFixed(2)}%`
          : '--',
        posts: `${i?.posts}/${DeliverablesLength(i?.deliverables)}`,
        value: `PKR${NumberFormatter(i?.value, 1)}`,
        emailStatus: i?.emailStatus,
        confirmationStatus: i?.confirmationStatus || 'Not Available',
        availability: i?.availabilityStatus,
        status: (
          <div>
            <Select
              style={{ minWidth: 150 }}
              onChange={(e) => onStatusChange(e, i?._id, getAllProfiles)}
              size='large'
              className='tableSelect'
              value={i?.status}
            >
              <Option value={'inReview'}>In Review</Option>
              <Option value='budgeting'>Budgeting</Option>
              <Option value='outReach'>Outreach</Option>
              <Option value='productSend'>Product Send</Option>
              <Option value='fulfillment'>Fulfillment</Option>
              <Option value='payment'>Payment</Option>
              <Option value='done'>Done</Option>
            </Select>
          </div>
        ),
        action: (
          <Dropdown
            overlay={InfluencerMenu(i?.influencer?._id, i, campaignTableData?.campaignHashTags)}
            trigger={['click']}
            placement={'bottomRight'}
          >
            <BsThreeDots className='dot-icon' />
          </Dropdown>
        ),
      })
    })
    setData(tempArr)
    campaignTableData?.data?.map((i, index) => {
      tempCSVData.push({
        'S.No': index,
        // campaignId: i?._id,
        Name: i?.influencer?.name,
        Profile: `https://www.instagram.com/${i?.influencer?.instagram?.username}`,
        Audience: NumberFormatter(i?.influencer?.audience, 1),
        // Followers: i?.influencer?.instagram?.followers_count,
        'Eng Rate': i?.influencer?.instagram?.engagememtRate
          ? `${Number(i?.influencer?.instagram?.engagememtRate)?.toFixed(2)}%`
          : '--',
        Posts: i?.posts,
        Value: `PKR${NumberFormatter(i?.value, 1)}`,
        // 'Email Status': i?.emailStatus,
        // Status: i?.status,
      })
    })
    if (tempCSVData.length > 0) {
      setCSVData(tempCSVData)
    }
  }, [campaignTableData, steps])

  const UploadMenu = (
    <Menu>
      {/* <Menu.Item key={0} icon={<AiOutlineUserAdd className='dropdown-menu-icon' />}>
        <CampaginInfluencerAdd id={id} getAllCampaigns={getAllProfiles} />
      </Menu.Item> */}
      <Menu.Item key={0} icon={<GrDocumentCsv className='dropdown-menu-icon' />}>
        <CSVLink
          filename={`Campaign-profiles-${moment().format('YYYY-MM-DD')}.csv`}
          data={CSVData}
          style={{ width: 'max-content', color: 'white' }}
        >
          <span style={{ color: 'rgba(0, 0, 0, 0.85)' }}>Export CSV</span>
          {/* <Button className='export-csv-btn'>Export CSV</Button> */}
        </CSVLink>
      </Menu.Item>
      <Menu.Item
        key={1}
        icon={
          campaignPPTLoading == true ? (
            <LoadingOutlined
              style={{
                fontSize: 18,
              }}
              spin
            />
          ) : (
            <AiOutlineFilePpt className='dropdown-menu-icon' />
          )
        }
        onClick={() => {
          setCampaignPPTLoading(true)
          generatePpt(campaignPPTData, setCampaignPPTLoading)
        }}
        disabled={campaignPPTLoading}
      >
        Export PPT
      </Menu.Item>
    </Menu>
  )

  const InfluencerMenu = (influencerId, campData, hashTags) => (
    <Menu>
      {/* <Menu.Item icon={<MdOutlineAssignment className='dropdown-menu-icon' />} onClick={() => {}}> */}
      <CampaignInfluncerAssignDrawer
        data={campData}
        campaignId={id}
        influencerId={influencerId}
        getAllCampaigns={getAllProfiles}
      />
      {/* </Menu.Item> */}
      <Menu.Item
        icon={<MdOutlineMail className='dropdown-menu-icon' />}
        onClick={() => campaignEmailSent(campData, campaignData?.campaignName, hashTags)}
        disabled={campData?.value > 0 && campData?.influencer?.email ? false : true}
      >
        Email Send
      </Menu.Item>
      <Menu.Item
        icon={<RiDeleteBinLine className='dropdown-menu-icon' />}
        onClick={
          () => campaignInfluencerDelete(id, [campData?.influencer?._id], getAllProfiles)
          // campaignInfluencerDelete(id, [campData?._id], getAllProfiles)
        }
      >
        Remove
      </Menu.Item>
    </Menu>
  )

  const onDelete = async () => {
    const InfluencersIds = []
    let count = 0
    selectedInfluencer?.map((ind) => {
      count += 1
      InfluencersIds.push(data[ind]?.influencer)
      // CampaignInfluencersId.push(data[ind]?.campaignId)
      if (count === selectedInfluencer?.length) {
        campaignInfluencerDelete(id, InfluencersIds, getAllProfiles)
        setSelectedInfluencer([])
      }
    })
  }

  const onManyStatusChange = (e) => {
    setUpdateStatusMany(e)
    const CampaignInfluencersId = []
    let count = 0
    selectedInfluencer?.map((ind) => {
      count += 1
      CampaignInfluencersId.push(data[ind]?.campaignId)
      if (count === selectedInfluencer?.length) {
        setCampaignTableData(null)
        onManyStatusChanges(e, CampaignInfluencersId, getAllProfiles)
        setUpdateStatusMany(null)
        setSelectedInfluencer([])
      }
    })
  }

  const bulkEmailSent = () => {
    // const CampaignInfluencersId = []
    let mailData = []
    let count = 0
    selectedInfluencer?.map((ind) => {
      count += 1
      // CampaignInfluencersId.push(data[ind]?.campaignId)
      mailData.push(campaignTableData?.data[ind])
      if (count === selectedInfluencer?.length) {
        // console.log('mailData', mailData)
        const missingEmailVal = mailData?.filter(
          (item) => !item?.influencer?.email || item?.value == 0,
        )
        if (missingEmailVal?.length > 0) {
          setIsModalVisible(true)
        } else {
          campaignEmailbulkSent(mailData, id, setEmailSendLoader)
          setSelectedInfluencer([])
        }
      }
    })
  }

  return (
    <>
      <CampaignInfluencersEmailCheck
        isModalVisible={isModalVisible}
        setIsModalVisible={setIsModalVisible}
        campInfData={campaignTableData}
      />
      {!campaignData ? (
        <InfinityLoader height={'60vh'} />
      ) : (
        <div className='campaign-profile-container'>
          {/* Steps */}
          <div className='steps'>
            <div
              className={`${steps === 'all' && 'active'} filter-card__item`}
              onClick={() => setSteps('all')}
            >
              <span className='filter-card__item-num'>{campaignData?.total}</span>
              <span className='filter-card__item-label'>INFLUENCERS</span>
            </div>
            <div
              className={`${steps === 'inReview' && 'active'} filter-card__item`}
              onClick={() => setSteps('inReview')}
            >
              <span className='filter-card__item-num'>{campaignData?.inReview}</span>
              <span className='filter-card__item-label'>IN REVIEW</span>
            </div>
            <div
              className={`${steps === 'budgeting' && 'active'} filter-card__item`}
              onClick={() => setSteps('budgeting')}
            >
              <span className='filter-card__item-num'>{campaignData?.budgeting}</span>
              <span className='filter-card__item-label'>BUDGETING</span>
            </div>
            <div
              className={`${steps === 'outReach' && 'active'} filter-card__item`}
              onClick={() => setSteps('outReach')}
            >
              <span className='filter-card__item-num'>{campaignData?.outReach}</span>
              <span className='filter-card__item-label'>OUTREACH</span>
            </div>
            <div
              className={`${steps === 'productSend' && 'active'} filter-card__item`}
              onClick={() => setSteps('productSend')}
            >
              <span className='filter-card__item-num'>{campaignData?.productSend}</span>
              <span className='filter-card__item-label'>PRODUCT SEND</span>
            </div>
            <div
              className={`${steps === 'fulfillment' && 'active'} filter-card__item`}
              onClick={() => setSteps('fulfillment')}
            >
              <span className='filter-card__item-num'>{campaignData?.fulfillment}</span>
              <span className='filter-card__item-label'>FULLFILLMINT</span>
            </div>
            <div
              className={`${steps === 'payment' && 'active'} filter-card__item`}
              onClick={() => setSteps('payment')}
            >
              <span className='filter-card__item-num'>{campaignData?.payment}</span>
              <span className='filter-card__item-label'>PAYMENT</span>
            </div>
            <div
              className={`${steps === 'done' && 'active'} filter-card__item11`}
              onClick={() => setSteps('done')}
            >
              <span className='filter-card__item-num'>{campaignData?.done}</span>
              <span className='filter-card__item-label'>DONE</span>
            </div>
          </div>

          {/* Filter Bar */}
          {selectedInfluencer?.length > 0 ? (
            <div className='filter-bar'>
              <div className='left-side'>
                <div className='selected-row'>
                  <span>
                    {selectedInfluencer?.length} / {campaignTableData?.data?.length}
                  </span>
                  <AiOutlineMinusCircle
                    className='icon'
                    onClick={() => setSelectedInfluencer([])}
                  />
                </div>
                {selectedInfluencer?.length !== campaignTableData?.data?.length && (
                  <div className='selected-row'>
                    <Checkbox
                      onChange={(e) =>
                        SelectAll(e.target.checked, setSelectedInfluencer, selectedInfluencer, data)
                      }
                    >
                      Select All
                    </Checkbox>
                  </div>
                )}
              </div>
              <div className='right-side'>
                <Button
                  className='export-csv-btn'
                  onClick={bulkEmailSent}
                  style={{ fontSize: '16px' }}
                  disabled={emailSendLoader}
                >
                  Send Email
                </Button>
                <Select
                  // style={{ minWidth: 150 }}
                  onChange={(e) => onManyStatusChange(e)}
                  size='large'
                  placeholder='Update Status'
                  className='tableSelect'
                  value={UpdateStatusMany}
                >
                  <Option value='inReview'>In Review</Option>
                  <Option value='budgeting'>Budgeting</Option>
                  <Option value='outReach'>Outreach</Option>
                  <Option value='productSend'>Product Send</Option>
                  <Option value='fulfillment'>Fulfillment</Option>
                  <Option value='payment'>Payment</Option>
                  <Option value='done'>Done</Option>
                </Select>
                <AiOutlineDelete className='action-icon delete-icon' onClick={() => onDelete()} />
              </div>
            </div>
          ) : (
            <div className='filter-bar'>
              <div className='left-side'>
                {/* <div className="filter-div">
                <VscSettings className="icon" />
                <span>Filter</span>
              </div> */}
                {/* <Select
                defaultValue="audience"
                style={{ minWidth: 150 }}
                onChange={onfilter2Change}
                size="large"
                className="select"
              >
                <Option value="user">User</Option>
                <Option value="audience">Total Audience</Option>
              </Select> */}

                <Input
                  placeholder='Search by name'
                  prefix={<FiSearch className='search-icon' />}
                  className='searchInput'
                  size='small'
                  value={searchString}
                  onChange={(e) => {
                    setSearchString(e.target.value)
                  }}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      setCampaignTableData(null)
                      getAllProfiles()
                    }
                  }}
                />
                <Select
                  onChange={(e) => setPerPage(e)}
                  className='select'
                  size='large'
                  value={perPage}
                  style={{ minWidth: '120px' }}
                >
                  <Option value={10}>Show 10</Option>
                  <Option value={20}>Show 20</Option>
                  <Option value={35}>Show 35</Option>
                  <Option value={50}>Show 50</Option>
                </Select>
                {/* <div className="select-all-filter">
            Select all
            <Checkbox onChange={onUserSelect} />
          </div> */}
              </div>
              <div className='right-side'>
                {/* <Select
            defaultValue="audience"
            style={{ minWidth: 150 }}
            onChange={onfilter2Change}
            size="large"
            className="select"
          >
            <Option value="user">User</Option>
            <Option value="audience">Total Audience</Option>
          </Select> */}

                <CampaginInfluencerAdd id={id} getAllCampaigns={getAllProfiles} button />
                <Dropdown
                  overlay={UploadMenu}
                  trigger={['click']}
                  placement={'bottomRight'}
                  disabled={campaignPPTLoading}
                >
                  {campaignPPTLoading == true ? (
                    <LoadingOutlined
                      style={{
                        fontSize: 18,
                      }}
                      spin
                    />
                  ) : (
                    <BsThreeDots className='dot-icon' />
                  )}
                </Dropdown>
              </div>
            </div>
          )}
          <br />
          {/* Table */}
          {campaignTableData ? (
            <Table
              className='data-table'
              columns={campaignProfileColumns}
              dataSource={data}
              scroll={{ x: 'auto' }}
              rowClassName={(row) => row?.addAfter && 'background-row'}
              rowSelection={{
                selectedRowKeys: selectedInfluencer,
                onChange: (selected) => setSelectedInfluencer(selected),
              }}
              pagination={{
                pageSize: perPage,
              }}
              // summary={(pageData) => {
              //   let totalAudience = 0;
              //   // pageData.forEach(({ audience }) => {
              //   //   totalAudience += audience;
              //   // });
              //   console.log("pageData", pageData);
              //   return (
              //     <>
              //       <Table.Summary.Row>
              //         <Table.Summary.Cell index={1}>
              //           <span
              //             style={{ color: "var(--primary)", fontWeight: "600" }}
              //           >
              //             Total
              //           </span>
              //         </Table.Summary.Cell>
              //         <Table.Summary.Cell index={2}>
              //           <span>{totalAudience}</span>
              //         </Table.Summary.Cell>
              //         <Table.Summary.Cell index={5}>
              //           <span>100M</span>
              //         </Table.Summary.Cell>
              //       </Table.Summary.Row>
              //     </>
              //   );
              // }}
            />
          ) : (
            <InfinityLoader height={'40vh'} />
          )}

          <br />

          <div
            style={{
              width: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              gap: '0.5rem',
              background: '#f6f6f8',
              padding: '10px',
              borderRadius: '25px',
            }}
          >
            <CiCircleInfo style={{ color: 'var(--primary)' }} />
            <p>influencers without any hashtags found in their last 25 posts are highlighted.</p>
          </div>
        </div>
      )}
    </>
  )
}

export default CampaignProfile
