import React, { useEffect, useState } from 'react'
import {
  //  Select,
  Table,
} from 'antd'
import { LoadingOutlined } from '@ant-design/icons'

import { attachToken, privateAPI } from '../../../../config/constants'
// import { ExpireDateCheck } from '../../../../functions/ExpireDateCheck'
// import OfflineProfileImage from '../../../../assets/offlineProfile.png'

function ReportHeatMap({ activeTab, id }) {
  //   const { Option } = Select
  const [data, setData] = useState([])
  const [reportData, setReportData] = useState(null)
  const [columnData, setColumnData] = useState([])

  const getReportData = async () => {
    if (id) {
      try {
        attachToken()
        const res = await privateAPI.post(`/campaign/get-campaign-heatmap-report/${id}`)
        if (res?.data) {
          setReportData(res?.data)
          setColumnData(res?.data?.columns)
        }
      } catch (error) {
        console.log('err', error)
      }
    }
  }

  const getColumnData = (col, colData) => {
    const ind = colData?.findIndex((e) => e.keyword === col?.dataIndex)
    if (ind !== -1) {
      return colData[ind]?.value
    } else {
      return 0
    }
  }

  useEffect(() => {
    getReportData()
  }, [activeTab, id])

  useEffect(() => {
    let tempArr = []
    reportData?.influencers?.map((i) => {
      let cols = {
        inf: (
          <div className='profile'>
            <img
              src={i?.arch_profile_picture_url || i?.profile_picture_url}
              // src={
              //   ExpireDateCheck(i?.dataRefreshed) === 10
              //     ? i?.arch_profile_picture_url
              //       ? i?.arch_profile_picture_url
              //       : OfflineProfileImage
              //     : ExpireDateCheck(i?.dataRefreshed) === 2
              //     ? i?.profile_picture_url
              //     : OfflineProfileImage
              // }
              alt='profile'
            />
            <p>{i?.name || '--'}</p>
          </div>
        ),
      }
      columnData?.map((col) => {
        cols = {
          ...cols,
          [col?.dataIndex]: getColumnData(col, i?.data),
        }
      })
      tempArr.push(cols)
    })
    setData(tempArr)
  }, [reportData])

  const changeColor = (num) => {
    if (num < 4) return 'white'
    if (num < 10) return '#d8effe'
    if (num < 15) return '#b1defe'
    if (num < 25) return '#86cefe'
    if (num < 60) return '#55bdfc'
    if (num < 115) return '#92aad7'
    if (num < 300) return '#c397af'
    if (num > 500) return '#ff695e'
    if (num >= 300) return '#ed8185'
  }

  const columns = [
    {
      title: 'INFUENCERS',
      dataIndex: 'inf',
      width: 300,
    },
  ]
  let colCount = 0
  columnData?.map((col) => {
    colCount += 1
    columns.push({
      title: col?.title || '-',
      dataIndex: col?.dataIndex || '-',
      render(text) {
        return {
          props: {
            style: { background: changeColor(text) },
          },
          children: <span>{text}</span>,
        }
      },
    })
    if (colCount === columnData?.length) {
      columns.push({
        title: 'TOTAL',
        dataIndex: 'total',
        render(text, record) {
          let total = Object.values(record)
          let sum = total?.slice(1, total?.length).reduce((accu, num) => accu + num, 0)
          return {
            props: {
              style: { background: changeColor(sum) },
            },
            children: <span>{sum}</span>,
          }
        },
      })
    }
  })

  const tableLoading = {
    spinning: reportData ? false : true,
    indicator: <LoadingOutlined />,
  }

  return (
    <div className='heat-map-container'>
      <div className='header'>
        <h3>Heatmap of Posts by Hash Tags</h3>
        <div>
          {/* <Select
            style={{
              minWidth: '150px',
            }}
            defaultValue={'posts'}
            onChange={() => {
              // if (selctedBar.length < 3) {
              // }
              // setSelctedBar(e);
            }}
          >
            <Option value='activatedInfluencers'>Activated Influencers</Option>
            <Option value='posts'>Posts</Option>
            <Option value='engagements'>Engagements</Option>
            <Option value="reach">reach</Option>
          </Select> */}
          {/* <Select
            style={{
              minWidth: '150px',
            }}
            defaultValue={'keywords'}
            onChange={() => {}}
          >
            <Option value='keywords'>Keywords</Option>
          </Select> */}
        </div>
      </div>
      <Table
        position={'topCenter'}
        width={200}
        size='large'
        scroll={{ x: 'auto' }}
        loading={tableLoading}
        bordered={true}
        dataSource={data}
        columns={columns}
        pagination={false}
        className='time-table-row-select'
        summary={() => {
          return (
            <Table.Summary fixed>
              <Table.Summary.Row>
                <Table.Summary.Cell index={0}>Total</Table.Summary.Cell>
                {reportData?.keywordTotal?.map((col, ind) => (
                  <Table.Summary.Cell key={ind} index={ind}>
                    {col?.total}
                  </Table.Summary.Cell>
                ))}
              </Table.Summary.Row>
            </Table.Summary>
          )
        }}
      ></Table>
    </div>
  )
}
export default ReportHeatMap
