import React, { useEffect, useState, useMemo } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { Dropdown, Menu, Tabs, Spin, notification } from 'antd'
import { CSVLink } from 'react-csv'
import moment from 'moment'

// Icons Import
import { LoadingOutlined } from '@ant-design/icons'
import { RiDeleteBinLine } from 'react-icons/ri'
import { GrDocumentCsv } from 'react-icons/gr'
import { AiOutlineSetting, AiOutlineFilePpt, AiOutlineShareAlt } from 'react-icons/ai'

// Local Imports
import { DeliverablesLength } from '../../../pages/Campaign/utils/campaignUtil'
import { generateReportPpt } from '../../../functions/ExportReportPPT'
import { NumberFormatter } from '../../../functions/NumberFormatter'
import { generatePpt } from '../../../functions/ExportPPT'
import Layout from '../../../layout/Layout'
// import { campaignReportDelete } from '../../../redux'
import { attachToken, privateAPI, publicAPI, forntendUrl } from '../../../config/constants'
import ReportSummary from './ReportComponent/ReportSummary'
// import ReportPerformance from './ReportComponent/ReportPerformance'
import Timeline from '../../../components/Views/Reports/Timeline'
// import ReportLeaderboard from './ReportComponent/ReportLeaderboard'
// import ReportPlatform from './ReportComponent/ReportPlatform'
import ReportHeatMap from './ReportComponent/ReportHeatMap'

const useQuery = (search) => {
  return useMemo(() => new URLSearchParams(search), [search])
}

const Report = () => {
  const { TabPane } = Tabs
  const { id } = useParams()
  const { search } = useLocation()
  const navigate = useNavigate()
  const [reportData, setReportData] = useState(null)
  const [reportPPTData, setReportPPTData] = useState(null)
  const [reportPPTLoading, setReportPPTLoading] = useState(false)
  const [pptInfLoading, setPptInfLoading] = useState(false)
  const [CSVData, setCSVData] = useState(null)
  const [activeTab, setActiveTab] = useState('summary')
  const [filterContentState, setFilterContentState] = useState('all')
  const [barChartData, setBarChartData] = useState(null)
  const query = useQuery(search)

  const onTabsChange = (key) => {
    const params = new URLSearchParams()
    if (key) {
      params.append('tab', key)
    } else {
      params.delete('tab')
    }
    navigate({ search: params.toString() })
  }

  const getCampaignReport = async () => {
    if (id) {
      try {
        attachToken()
        const res = await privateAPI.get(`/campaign/get-campaign-report/${id}`)
        if (res?.data) {
          setReportData(res?.data)
        }
      } catch (error) {
        console.log('err', error)
      }
    }
  }

  const getCampaignReportPPT = async () => {
    if (id) {
      try {
        // attachToken()
        const res = await publicAPI.get(
          `/campaign/get-campaign-report-ppt/${id}/${filterContentState}`,
        )
        if (res?.data) {
          setReportPPTData(res?.data)
        }
      } catch (error) {
        console.log('err', error)
      }
    }
  }

  const getChart1ReportPPT = async () => {
    if (id) {
      try {
        // attachToken()
        const res = await publicAPI.post(`/campaign/chart-1-data`, { reportId: id })
        if (res?.data) {
          setBarChartData(res?.data)
          // setReportPPTData(res?.data)
        }
      } catch (error) {
        console.log('err', error)
      }
    }
  }

  const getReportInfluencersPPT = async () => {
    if (id) {
      try {
        setPptInfLoading(true)
        // attachToken()
        const res = await publicAPI.get(`/campaign/get-report-influencers-ppt-data/${id}`)
        if (res?.data) {
          generatePpt(res?.data?.data, setPptInfLoading)
        }
      } catch (error) {
        console.log('err', error)
      }
    }
  }

  const deleteReport = async () => {
    try {
      attachToken()
      const res = await privateAPI.delete(`/campaign/report-delete/${id}`)
      if (res) {
        console.log(res)
        navigate(-1)
        notification.success({
          message: 'Report Deleted',
          duration: 3,
        })
      }
    } catch (err) {
      console.log(err)
    }
  }

  const copyToClipBoard = () => {
    navigator.clipboard.writeText(`${forntendUrl}/public-campaign-report-summary/${id}`)
    notification.success({
      message: 'Copied To Clipboard',
      duration: 2,
    })
  }

  useEffect(() => {
    getCampaignReport()
    getCampaignReportPPT()
    getChart1ReportPPT()
  }, [id])

  useEffect(() => {
    getCampaignReportPPT()
  }, [filterContentState])

  const antIconSpin = (
    <LoadingOutlined
      style={{
        fontSize: 18,
      }}
      spin
    />
  )

  useEffect(() => {
    // console.log('reportData --->', reportPPTData)

    let tempCSVData = []

    // console.log('reportPPTData',reportPPTData)

    reportPPTData?.influencers?.map((inf) => {
      const payload = Object.assign(
        { Name: inf?.influencer?.name },
        reportPPTData?.infAudEnabled && { audience: NumberFormatter(inf?.influencer?.audience, 1) },
        reportPPTData?.infEngRateEnabled && {
          'Eng Rate': inf?.influencer?.instagram?.engagememtRate
            ? `${Number(inf?.influencer?.instagram?.engagememtRate)?.toFixed(2)}%`
            : '--',
        },
        reportPPTData?.infPostsEnabled && {
          // Posts: `${inf?.posts} / ${DeliverablesLength(inf?.deliverables)}`,
          Posts: `${inf?.posts} posts out of ${DeliverablesLength(inf?.deliverables)}`,
        },
        reportPPTData?.infEngEnabled && {
          Engagement: inf?.engagement ? NumberFormatter(inf?.engagement, 1) : 0,
        },
        reportPPTData?.socialComEnabled && {
          Comments: inf?.total_comments ? NumberFormatter(inf?.total_comments, 2) : 0,
        },
        reportPPTData?.socialLikesEnabled && {
          Likes: inf?.total_likes ? NumberFormatter(inf?.total_likes, 2) : 0,
        },
        reportPPTData?.socialVideoViewsEnabled && {
          'Video Views': inf?.videoView ? NumberFormatter(inf?.videoView, 1) : 0,
        },
        reportPPTData?.socialReachEnabled && {
          Reach: inf?.reach ? NumberFormatter(inf?.reach, 1) : 0,
        },
        reportPPTData?.socialEngRateEnabled && {
          'Social EngRate': inf?.engRate ? `${NumberFormatter(inf?.engRate, 1)}%` : 0,
        },
        reportPPTData?.engWithoutViewsEnabled && {
          'Eng Without View': inf?.engagement ? NumberFormatter(Number(inf?.engagement), 1) : 0,
        },
        reportPPTData?.engWithViewsEnabled && {
          'Eng Withe View': inf?.engagement
            ? NumberFormatter(Number(inf?.engagement + inf?.videoView), 1)
            : 0,
        },
        reportPPTData?.cpeWithoutViewsEnabled && {
          'CPE Without View': inf?.cpe ? `PKR${NumberFormatter(inf?.cpe, 2)}` : '--',
        },
        reportPPTData?.cpeWithViewsEnabled && {
          'CPE With View': inf?.cpeView ? `PKR${NumberFormatter(inf?.cpeView, 2)}` : '--',
        },
        reportPPTData?.CPREnabled && {
          CPR: inf?.cpr ? `PKR${NumberFormatter(inf?.cpr, 2)}` : '--',
        },
      )

      // console.log('payload',payload)

      tempCSVData.push(payload)

      // tempCSVData.push({
      //   ...audience,
      //   name: inf?.influencer?.name,
      //   engRate: inf?.influencer?.instagram?.engagememtRate
      //     ? `${Number(inf?.influencer?.instagram?.engagememtRate)?.toFixed(2)}%`
      //     : '--',
      //   posts: `${inf?.posts} / ${DeliverablesLength(inf?.deliverables)}`,
      //   engagement: inf?.engagement ? NumberFormatter(inf?.engagement, 1) : 0,
      // })
    })

    setCSVData(tempCSVData)
  }, [reportPPTData])

  // console.log('CSVData', CSVData)

  // const generateReportPptFn = async () => {
  //   setReportPPTLoading(true)
  //   const res = await generateReportPpt(reportPPTData, setReportPPTLoading)
  //   if (res) {
  //     setReportPPTLoading(false)
  //   }
  // }

  const SettingsMenu = (reportData, navigate) => (
    <Menu>
      <Menu.Item
        icon={<AiOutlineSetting className='dropdown-menu-icon' />}
        onClick={() =>
          navigate(`/campaign/${reportData?.campaign?._id}/edit-report`, { state: reportData })
        }
      >
        Edit Report
      </Menu.Item>
      <Menu.Item
        icon={<RiDeleteBinLine className='dropdown-menu-icon' />}
        onClick={() => {
          // campaignReportDelete(reportData?._id, null, navigate, reportData?.campaign?._id)
          deleteReport()
        }}
      >
        Delete Report
      </Menu.Item>
      <Menu.Item
        icon={
          pptInfLoading == true ? (
            <Spin indicator={antIconSpin} style={{ marginRight: '8px' }} />
          ) : (
            <AiOutlineFilePpt className='dropdown-menu-icon' />
          )
        }
        onClick={() => getReportInfluencersPPT()}
        disabled={pptInfLoading}
      >
        Report Influencers PPT
      </Menu.Item>
      <Menu.Item
        icon={
          reportPPTLoading == true ? (
            <Spin indicator={antIconSpin} style={{ marginRight: '8px' }} />
          ) : (
            <AiOutlineFilePpt className='dropdown-menu-icon' />
          )
        }
        // onClick={() => generateReportPptFn()}
        onClick={() => {
          setReportPPTLoading(true)
          generateReportPpt(
            {
              ...reportPPTData,
              chart1: barChartData?.chartData,
              interval: barChartData?.interval,
              duration: barChartData?.duration,
              chart2: [
                [barChartData?.budget, barChartData?.budget, barChartData?.budget],
                [barChartData?.totalViews, barChartData?.totalEng, barChartData?.totalReach],
              ],
              lineData: barChartData?.socialPerformance
            },
            setReportPPTLoading,
          )
        }}
        disabled={!reportPPTLoading && barChartData !== null ? false : true}
      >
        Report PPT
      </Menu.Item>
      {CSVData && (
        <Menu.Item icon={<GrDocumentCsv className='dropdown-menu-icon' />}>
          <CSVLink
            filename={`Campaign-Report-${moment().format('YYYY-MM-DD')}.csv`}
            data={CSVData}
            style={{ width: 'max-content', color: 'white' }}
          >
            {/* <Button className='export-csv-btn'>Report CSV</Button> */}
            <span style={{ color: '#000000D9' }}>Report CSV</span>
          </CSVLink>
        </Menu.Item>
      )}
      <Menu.Item
        icon={<AiOutlineShareAlt className='dropdown-menu-icon' />}
        onClick={copyToClipBoard}
      >
        Share Report
      </Menu.Item>
    </Menu>
  )

  useEffect(() => {
    if (query.get('tab')) {
      setActiveTab(query.get('tab'))
    }
  }, [activeTab])

  return (
    <Layout>
      <div className='campaign-container'>
        <div className='header'>
          <div className='left'>
            <h2>{reportData?.name || '--'}</h2>
            <p className='subHeading'>
              {reportData?.influencers?.length} Influencers | Last Refreshed{' '}
              {moment(reportData?.updatedAt).format('h:mm a')} PM
            </p>
          </div>
          <div className='right'>
            <Dropdown
              overlay={SettingsMenu(reportData, navigate)}
              trigger={['click']}
              placement={'bottomLeft'}
            >
              {reportPPTLoading == true || pptInfLoading == true ? (
                <Spin indicator={antIconSpin} className='icon' />
              ) : (
                <AiOutlineSetting className='icon' />
              )}
            </Dropdown>

            {/* <AiOutlineSave className="icon" /> */}
            {/* <MdRefresh className="icon" /> */}
          </div>
        </div>
        <Tabs
          defaultActiveKey={activeTab ? activeTab : 'summary'}
          onChange={(e) => onTabsChange(e)}
          className='tabs-container'
        >
          <TabPane tab='SUMMARY' key='summary'>
            <ReportSummary
              id={id}
              activeTab={activeTab}
              filterContentState={filterContentState}
              setFilterContentState={setFilterContentState}
            />
          </TabPane>
          {/* <TabPane tab="LEADERBOARD" key="leaderboard">
            <ReportLeaderboard id={id} activeTab={activeTab} />
          </TabPane> */}
          {/* <TabPane tab='PERFORMANCE' key='performance'>
            <ReportPerformance id={id} activeTab={activeTab} />
          </TabPane> */}
          <TabPane tab='TIMELINE' key='timeline'>
            <Timeline
              id={id}
              activeTab={activeTab}
              reportDate={{
                startOf: reportData?.startDate,
                endOf: reportData?.endDate,
              }}
            />
          </TabPane>
          {/* <TabPane tab="PLATFORMS" key="platforms">
            <ReportPlatform id={id} activeTab={activeTab} />
          </TabPane> */}
          {/* <TabPane tab="POST TYPES" key="posttypes">
            <PostType />
          </TabPane> */}
          <TabPane tab='HEATMAP' key='heatmap'>
            <ReportHeatMap id={id} activeTab={activeTab} />
          </TabPane>
        </Tabs>
      </div>
    </Layout>
  )
}

export default Report
