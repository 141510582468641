import Layout from "../layout/Layout";

import { FaSnapchatGhost, FaTiktok, FaTwitter } from "react-icons/fa";
import FacebookConnectModal from "../components/modal/FacebookConnectModal";

const Profile = () => {
  // const ConnectTiktok = async () => {
  //   // let payload = {
  //   //   redirect_url: "http://localhost:3000/profile",
  //   // };
  //   // const res = await publicAPI.post(`/admin/tiktok-oauth`, payload);
  //   let redirect_uri = "https://fireup-dashboard.web.app";
  //   const csrfState = Math.random().toString(36).substring(2);
  //   let url = `https://www.tiktok.com/auth/authorize?client_key=aw1q9thfh1r3c59w&scope=user.info.basic,video.list&response_type=code&redirect_uri=https://fireup-dashboard.web.app&state=${csrfState}`;
  //   window.open(url);
  // };

  return (
    <Layout active={"profile"}>
      <div className="Profile-container">
        {/* <h2 className="mainHeading">Profile</h2> */}

        <div className="main">
          <h2>Select a Platform to Connect to Your Account</h2>
          <p>
            Select the social network for our feature usage, Select upto two
          </p>
          <div className="social-container">
            <FacebookConnectModal />
            <div className="account">
              <FaTiktok className="tiktok icon" />
              <p>TikTok</p>
            </div>
            <div className="account">
              <FaTwitter className="twitter icon" />
              <p>Twitter</p>
            </div>
            <div className="account">
              <FaSnapchatGhost className="snapchat icon" />
              <p>Snapchat</p>
            </div>
          </div>

          {/* <button
            onClick={ConnectTiktok}
            type="button"
            data-toggle="modal"
            className="tiktonButton btn"
          >
            <FaTiktok className="fbicon" /> Continue with TikTok
          </button> */}
        </div>
      </div>
    </Layout>
  );
};

export default Profile;
