import React, { useEffect, useState } from 'react'
import { Row, Col, Input, Button, notification } from 'antd'
import { useLocation } from 'react-router-dom'
import { useDispatch } from 'react-redux'

import { LoadingOutlined } from '@ant-design/icons'
import { FiSearch } from 'react-icons/fi'

import Layout from '../../layout/Layout'
import InfluencerCard from '../../components/Cards/InfluencerCard'
import { getAllInfluencersCategory } from '../../redux'
import { privateAPI, attachToken } from '../../config/constants'
import InfinityLoader from '../../components/InfinityLoader'

const AddInfluencerInCategory = () => {
  const { state } = useLocation()
  const [limit, setLimit] = useState(20)
  const [loading, setloading] = useState(false)
  const [search, setSearch] = useState('')
  const [influencers, setInfluencers] = useState(null)
  const [selectedInfluencers, setSelectedInfluencers] = useState([])
  const [totalInfluencers, setTotalInfluencers] = useState(0)
  const dispatch = useDispatch()

  const getInfluencers = async () => {
    let payload = {
      perPage: limit,
      searchString: search,
      categoryId: state?._id,
    }
    let res = await dispatch(getAllInfluencersCategory(payload, setloading))
    setInfluencers(res?.data)
    setTotalInfluencers(res?.count)
  }

  const addCategoryMultipleInf = async () => {
    try {
      attachToken()
      const res = await privateAPI.post(`/category/add-category-to-multiple`, {
        categoryId: state?._id,
        influencers: selectedInfluencers,
      })
      if (res) {
        notification.success({
          description: 'Influencers Added to Category',
          duration: 2,
        })

        getInfluencers()
        return res?.data?.data
      }
    } catch (err) {
      notification.error({
        description: err?.response?.data?.message || 'Server Error',
        duration: 2,
      })
    }
  }

  const selectAllHandler = () => {
    let ids = []
    influencers?.map((item) => {
      ids.push(item?._id)
    })
    setSelectedInfluencers(ids)
  }

  useEffect(() => {
    getInfluencers()
  }, [limit])

  return (
    <Layout active={'influencerCategory'}>
      <div className='bulk_upload_influencers'>
        <h2 className='mainHeading' style={{ fontSize: '26px', fontWeight: 500 }}>
          {state?.name}
        </h2>
        <div className='filter-bar'>
          <div className='left-side'>
            <Input
              placeholder='Search by name'
              prefix={<FiSearch className='search-icon' />}
              className='searchInput'
              size='small'
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  getInfluencers()
                }
              }}
            />
          </div>
          <div className='right-side' style={{ flexWrap: 'wrap' }}>
            <Button onClick={selectAllHandler}>Select All</Button>
            <Button
              disabled={selectedInfluencers?.length === 0 ? true : false}
              onClick={() => setSelectedInfluencers([])}
            >
              Clear All
            </Button>
            <Button
              disabled={selectedInfluencers?.length === 0 ? true : false}
              onClick={() => addCategoryMultipleInf()}
              style={{
                background: selectedInfluencers?.length === 0 ? '#f48763' : 'var(--primary)',
                border: 'none',
                color: 'white',
              }}
            >
              Save
            </Button>
          </div>
        </div>
        {influencers !== null ? (
          <>
            {' '}
            <div style={{ marginTop: '2rem' }}>
              <Row gutter={[20, 20]}>
                {influencers?.map((item, index) => (
                  <Col key={index} xxl={4} lg={6} md={8} xs={24}>
                    <InfluencerCard
                      data={{
                        id: item?._id,
                        profileImage: item?.arch_profile_picture_url || item?.profile_picture_url,
                        name: item?.name,
                        userName: item?.instagram_username,
                      }}
                      selectedInfluencers={selectedInfluencers}
                      setSelectedInfluencers={setSelectedInfluencers}
                    />
                  </Col>
                ))}
              </Row>
            </div>
            <div className='page-footer'>
              <div className='loadmore-div'>
                <Button
                  disabled={totalInfluencers == influencers?.length ? true : false}
                  onClick={() => {
                    setLimit(limit + 20)
                  }}
                >
                  <span>Load More</span>
                  {loading && <LoadingOutlined className='icon' />}
                </Button>
              </div>
            </div>
          </>
        ) : (
          <InfinityLoader height={'400px'} />
        )}
      </div>
    </Layout>
  )
}

export default AddInfluencerInCategory
