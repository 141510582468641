import { FaFacebook , FaTwitter , FaInstagram , FaTwitch, FaYoutube , FaTiktok} from "react-icons/fa";

function SocialMedia({ name }) {
  return (
    <div>
      <h3 >{name}</h3>
      <div className="icons-header">
        <div>
          <FaTwitter className="icon" />
          <p>Twitter</p>
        </div>
        <div>
          <FaFacebook className="icon" />
          <p>Facebook</p>
        </div>
        <div>
          <FaInstagram className="icon" />
          <p>Instagram</p>
        </div>
        <div>
          <FaYoutube className="icon" />
          <p>Youtube</p>
        </div>
        <div>
          <FaTwitch className="icon" />
          <p>Twitch</p>
        </div>
        <div>
          <FaTiktok className="icon" />
          <p>Tiktok</p>
        </div>
      </div>
    </div>
  );
}

export default SocialMedia;
