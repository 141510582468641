import { Bar } from "@ant-design/plots";

const BasicStackbar = () => {
  const data = [
    {
      content: "Feed Content",
      value: 50,
    },
    {
      content: "Story Content",
      value: 30,
    },
    {
      content: "Stream Content",
      value: 0,
    },
  ];
  const config = {
    data,
    xAxis: false,
    color: ["#55d5ff", "#2b0f5d"],
    yAxis: {
      display: false,
      line: false,
    },
    height: 200,
    xField: "value",
    yField: "content",
    seriesField: "content",
    legend: false,
  };
  return <Bar {...config} />;
};

export default BasicStackbar;
