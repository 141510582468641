import React, { useState, useEffect } from 'react'
import { notification, Row, Col, Button } from 'antd'
import { useLocation } from 'react-router-dom'

import Layout from '../../layout/Layout'
import InfluencerCard from '../../components/Cards/InfluencerCard'
import { privateAPI, attachToken } from '../../config/constants'
import InfinityLoader from '../../components/InfinityLoader'

const ViewCategoryInfluencers = () => {
  const { state } = useLocation()
  const [influencers, setInfluencers] = useState(null)
  const [selectedInfluencers, setSelectedInfluencers] = useState([])

  const getCategoryInfluencers = async () => {
    try {
      attachToken()
      const res = await privateAPI.post(`/category/get-category-influencers`, {
        categoryId: state?._id,
      })
      if (res) {
        setInfluencers(res?.data?.data)
      }
    } catch (err) {
      notification.error({
        description: err?.response?.data?.message || 'Server Error',
        duration: 2,
      })
    }
  }

  const removeInfluencersFromCategory = async () => {
    try {
      attachToken()
      const res = await privateAPI.post(`/category/remove-category-influencers`, {
        categoryId: state?._id,
        influencers: selectedInfluencers,
      })
      if (res) {
        notification.success({
          description: 'Removed Influencers',
          duration: 2,
        })

        getCategoryInfluencers()
      }
    } catch (err) {
      notification.error({
        description: err?.response?.data?.message || 'Server Error',
        duration: 2,
      })
    }
  }

  const selectAllHandler = () => {
    let ids = []
    influencers?.map((item) => {
      ids.push(item?._id)
    })
    setSelectedInfluencers(ids)
  }

  useEffect(() => {
    getCategoryInfluencers()
  }, [])

  return (
    <Layout active={'influencerCategory'}>
      <div className='bulk_upload_influencers'>
        <h2 className='mainHeading' style={{ fontSize: '26px', fontWeight: 500 }}>
          {state?.name}
        </h2>
        <div className='filter-bar'>
          <div className='left-side'></div>
          <div className='right-side' style={{ flexWrap: 'wrap' }}>
            <Button onClick={selectAllHandler}>Select All</Button>
            <Button
              disabled={selectedInfluencers?.length === 0 ? true : false}
              onClick={() => setSelectedInfluencers([])}
            >
              Clear All
            </Button>
            <Button
              disabled={selectedInfluencers?.length === 0 ? true : false}
              onClick={() => removeInfluencersFromCategory()}
              style={{
                background: selectedInfluencers?.length === 0 ? '#f48763' : 'var(--primary)',
                border: 'none',
                color: 'white',
              }}
            >
              Remove
            </Button>
          </div>
        </div>
        {influencers !== null ? (
          <>
            <div style={{ marginTop: '2rem' }}>
              <Row gutter={[20, 20]}>
                {influencers?.map((item, index) => (
                  <Col key={index} xxl={4} lg={6} md={8} xs={24}>
                    <InfluencerCard
                      data={{
                        id: item?._id,
                        profileImage: item?.arch_profile_picture_url || item?.profile_picture_url,
                        name: item?.name,
                        userName: item?.instagram_username,
                      }}
                      selectedInfluencers={selectedInfluencers}
                      setSelectedInfluencers={setSelectedInfluencers}
                    />
                  </Col>
                ))}
              </Row>
            </div>
          </>
        ) : (
          <InfinityLoader height={'400px'} />
        )}
      </div>
    </Layout>
  )
}

export default ViewCategoryInfluencers
