export const AudienceCalculator = (num) => {
  let aud = Number(num);
  if (aud > 0 && aud < 10000) {
    return `Nano (< 10k)`;
  } else if (aud > 10000 && aud < 50000) {
    return `Micro (10K - 50K)`;
  } else if (aud > 50000 && aud < 250000) {
    return `Mid (50K - 250K)`;
  } else if (aud > 250000 && aud < 1000000) {
    return `Macro (250K - 1M)`;
  } else if (aud > 1000000 && aud < 5000000) {
    return `Top (1M - 5M)`;
  } else if (aud > 5000000) {
    return `Vip (> 5M)`;
  }
};
