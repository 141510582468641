import React, { useEffect, useState, useRef } from "react";
import IdleJs from "idle-js";
import { Modal } from "antd";
import {onLogout} from  "../functions/RedirectingAuth"

const IdleTimerContainer = () => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [countdown, setCountdown] = useState(10);
  const countdownRef = useRef(null);

  useEffect(() => {
    const idle = new IdleJs({
      idle: 3600000, // 1 hour for idle
      onIdle: () => {
        setModalIsOpen(true);
        startCountdown();
      },
      onActive: () => {
        clearCountdown();
      },
      onHide: () => {
        console.log("User is hiding the window");
      },
      onShow: () => {
        console.log("User is showing the window");
      },
      keepTracking: true,
    });

    idle.start();

    return () => {
      idle.stop();
      clearCountdown();
    };
  }, []);

  const startCountdown = () => {
    setCountdown(10);
    countdownRef.current = setInterval(() => {
      setCountdown((prev) => {
        if (prev <= 1) {
          handleLogout();
          return 0;
        }
        return prev - 1;
      });
    }, 1000);
  };

  const clearCountdown = () => {
    clearInterval(countdownRef.current);
    setCountdown(9);
  };

  const handleLogout = () => {
    clearCountdown();
    setModalIsOpen(false);
    onLogout();
  };

  const handleStayLoggedIn = () => {
    clearCountdown();
    setModalIsOpen(false);
  };

  return (
    <div>
      <Modal
        title="Need More Time?"
        visible={modalIsOpen}
        onCancel={handleStayLoggedIn}
        footer={[
          // <button key="logout" onClick={handleLogout}>
          //   Log me out
          // </button>,
          <button key="stay" onClick={handleStayLoggedIn}
          style={{
            cursor: 'pointer',
            background: 'var(--primary)',
            color: 'white',
            width: '160px',
            height: '36px',
            fontSize:"16px",
            border:"none"
          }}>
            Keep me signed in
          </button>,
        ]}
      >
        <p>Your session is about to expire. You will be automatically signed out in</p>
        <h1 > 00:0{countdown} </h1>
      </Modal>
    </div>
  );
};

export default IdleTimerContainer;
