import { useState, useCallback } from "react";

import { Modal, Button, Select, notification } from "antd";
import axios from "axios";
import moment from "moment";

import { FaFacebookF } from "react-icons/fa";
import { BsFacebook } from "react-icons/bs";
import { publicAPI } from "../../config/constants";
import { useInitFbSDK } from "../../functions/FacebookHook";

const FacebookConnectModal = ({inf}) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const isFbSDKInitialized = useInitFbSDK();
  const [fbUserAccessToken, setFbUserAccessToken] = useState(false);
  const admin = JSON.parse(localStorage.getItem("user"));
  const alreadyFbToken = localStorage.getItem("fb-access-token");
  const client_secret = '8bb098cddb3da0725a4569defcbc916d';
  const client_id = '734541667638429';
  const [Pages, setPages] = useState(null);
  const [pageId, setPageId] = useState(null);
  const [loading, setLoading] = useState(false);

  console.log(isFbSDKInitialized)



  // Logs in a Facebook user
  const logInToFB = useCallback(() => {
    window.FB.login(
      (response) => {
        if (response?.authResponse?.accessToken) {
          getAccount(response?.authResponse?.accessToken);
        }else{
          notification.error({
            message:'Unable to Login Facebook',
            duration:3
          })
        }
      },
      {
        scope:
          "pages_show_list,instagram_manage_insights, instagram_basic",
      }
    );
  }, []);

  const getAccount = async (accessToken) => {
    const getLongLivedToken = await axios.get(
      `https://graph.facebook.com/v14.0/oauth/access_token?grant_type=fb_exchange_token&client_id=${client_id}&client_secret=${client_secret}&fb_exchange_token=${accessToken}`
    );
    if (getLongLivedToken) {
      setFbUserAccessToken(getLongLivedToken?.data?.access_token);
      const getAccount = await axios.get(
        `https://graph.facebook.com/v14.0/me/accounts?limit=3&access_token=${getLongLivedToken?.data?.access_token}`
      );
      const getAccountData = getAccount?.data?.data
      if (getAccount?.data?.paging?.next) {
        const getAccount2= await axios.get(
          `${getAccount?.data?.paging?.next}`
        );
        const getAccount2Next  = getAccount2?.data?.paging?.next
        const getAccount2Data = getAccount2?.data?.data
        if (getAccount2Next) {
          const getAccount3 = await axios.get(
            `${getAccount2?.data?.paging?.next}`
          );
        const getAccount3Data = getAccount3?.data?.data
          if (getAccount3.data?.paging?.next) {
            const getAccount4 = await axios.get(
              `${getAccount3?.data?.paging?.next}`
            );
        const getAccount4Data = getAccount4?.data?.data

            if (getAccount4.data) {
              setPages([
                ...getAccountData,
                ...getAccount2Data,
                ...getAccount3Data,
                ...getAccount4Data,
              ]);
            }
          } else {
            setPages([
              ...getAccountData,
              ...getAccount2Data,
              ...getAccount3Data,
            ]);
          }
        } else {
          setPages([...getAccountData, ...getAccount2Data]);
        }
      } else {
        setPages(getAccountData);
      }
    }
  };

  const onSavePage = async () => {
    setLoading(true);
    let payload = {
      fbtoken: fbUserAccessToken,
      page_id: pageId,
      id: inf ? inf : admin?._id,
      tokenExpire:moment().add(59,'day').format('DD-MM-YYYY')
    };
    let res=null;
    if(inf){
      res = await publicAPI.post(`/influencer/save-access-token`, payload);
    }else{
      res = await publicAPI.post(`/admin/save-access-token`, payload);
    }
    if (res) {
      localStorage.setItem("fb-access-token", fbUserAccessToken);
      setIsModalVisible(false);
      setLoading(false);
      notification.success({
        message: res.data?.message,
        duration: 2,
      });
    } else {
      setLoading(false);
    }
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <>
     {inf ? <div className="account" onClick={() => showModal()}>
        <p>Connect to Facebook</p>
      </div> : <div className="account" onClick={() => showModal()}>
        <FaFacebookF className="facebook icon" />
        <p>Facebook</p>
      </div>}
      <Modal
        title={"Connect You Facebook Account"}
        visible={isModalVisible}
        footer={false}
        onCancel={handleCancel}
        width={"400px"}
      >
        <div className="facebook-modal">
          {!alreadyFbToken ? (
            <>
              {!Pages ? (
                <button
                  onClick={() => logInToFB()}
                  type="button"
                  data-toggle="modal"
                  className="faceBookButton btn"
                >
                  <BsFacebook className="fbicon" /> Continue with Facebook
                </button>
              ) : (
                <div className="pages-div">
                  <Select
                    size="large"
                    style={{ width: "240px" }}
                    placeholder="Select Page"
                    onChange={(e) => setPageId(e)}
                  >
                    {Pages?.map((pg,ind) => (
                      <Select.Option key={ind} value={pg?.id}>{pg?.name}</Select.Option>
                    ))}
                  </Select>
                  <Button
                    type="primary"
                    loading={loading}
                    onClick={() => onSavePage()}
                  >
                    Save
                  </Button>
                </div>
              )}
            </>
          ) : (
            <h2 className="connected">Already Connected</h2>
          )}
        </div>
      </Modal>
    </>
  );
};

export default FacebookConnectModal;
