import { useState } from "react";
import {
  Modal,
  Button,
  Form,
  Input,
  notification,
  Row,
  Col,
} from "antd";
import { FaPlus } from "react-icons/fa";
import { attachToken, privateAPI } from "../../config/constants";

const AddModerator = ({ getData, edit }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const adminData = JSON.parse(localStorage.getItem("user"));

  const showModal = () => {
    setIsModalVisible(true);
  };

  const onFinish = async (values) => {
    setLoading(true);
    let payload = {
      ...values,
      admin: adminData?._id,
    };
    try {
      attachToken();
      const res = await privateAPI.post(`/admin/create-moderator`, payload);
      if (res.data) {
        notification.success({
          message: res?.data?.message,
          duration: 3,
        });
        form.resetFields();
        setLoading(false);
        setIsModalVisible(false);
        getData();
      }
    } catch (err) {
      notification.error({
        description: err?.response?.data?.message,
        duration: 4,
      });
      setLoading(false);
    }
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <>
      {edit ? (
        <span onClick={() => showModal()}>Edit</span>
      ) : (
        <FaPlus className="add-icon" onClick={() => showModal()} />
      )}
      <Modal
        title={"Create Group"}
        visible={isModalVisible}
        footer={false}
        onCancel={handleCancel}
        width={"600px"}
      >
        <Form
          layout="vertical"
          form={form}
          fields={edit ? [] : null}
          onFinish={onFinish}
        >
          <Row gutter={[20, 0]}>
            <Col xs={24} md={12}>
              <Form.Item
                label="First Name"
                name={"firstName"}
                rules={[
                  {
                    required: true,
                    message: "First Name is Required",
                  },
                ]}
                requiredMark={"optional"}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col xs={24} md={12}>
              <Form.Item
                label="Last Name"
                name={"lastName"}
                rules={[
                  {
                    required: true,
                    message: "Last Name is Required",
                  },
                ]}
                requiredMark={"optional"}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col xs={24} md={12}>
              <Form.Item
                label="Email"
                name={"email"}
                rules={[
                  {
                    required: true,
                    message: "Email is Required",
                  },
                ]}
                requiredMark={"optional"}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col xs={24} md={12}>
              <Form.Item
                label="Password"
                name={"password"}
                rules={[
                  {
                    required: true,
                    message: "Password is Required",
                  },
                ]}
                requiredMark={"optional"}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>

          <Form.Item>
            <Button htmlType="submit" loading={loading} type="primary">
              {edit ? "Update" : "Create"}
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default AddModerator;
