import { useState, useEffect, useCallback } from 'react'
import { Button, Typography, notification } from 'antd'
import { useParams } from 'react-router-dom'
import axios from 'axios'
import moment from 'moment'

import { FaInstagram } from 'react-icons/fa'

import { privateAPI, publicAPI } from '../config/constants'
import InfinityLoader from '../components/InfinityLoader'
import { NumberFormatter } from '../functions/NumberFormatter'
import { useInitFbSDK } from '../functions/FacebookHook'
import Logo from '../assets/mainlogo.png'
// import OfflineProfileImage from '../assets/offlineProfile.png'
// import { ExpireDateCheck } from '../functions/ExpireDateCheck'

const InfluencerConnect = () => {
  // const navigate = useNavigate();
  const { id } = useParams()
  const isFbSDKInitialized = useInitFbSDK()
  const client_secret = '8bb098cddb3da0725a4569defcbc916d'
  const client_id = '734541667638429'
  const [influencer, setInfluencer] = useState(null)
  const [loading, setLoading] = useState(false)

  console.log(isFbSDKInitialized)

  const getInfluencer = async () => {
    const res = await privateAPI.get(`/influencer/get/${id}`)
    setInfluencer(res?.data?.influencer)
  }

  useEffect(() => {
    getInfluencer()
  }, [])

  // Logs in a Facebook user
  const logInToFB = useCallback(() => {
    window.FB.login(
      (response) => {
        // console.log('response', response)
        if (response?.authResponse?.accessToken) {
          getAccount(response?.authResponse?.accessToken)
        } else {
          notification.error({
            message: 'Unable to Connect. Try Again',
            duration: 10,
          })
        }
      },
      { scope: 'pages_show_list,instagram_manage_insights, instagram_basic' },
    )
  }, [])

  const getAccount = async (accessToken) => {
    const getLongLivedToken = await axios.get(
      `https://graph.facebook.com/v14.0/oauth/access_token?grant_type=fb_exchange_token&client_id=${client_id}&client_secret=${client_secret}&fb_exchange_token=${accessToken}`,
    )
    if (getLongLivedToken) {
      onSaveToken(getLongLivedToken?.data?.access_token)
    }
  }

  const onSaveToken = async (fbToken) => {
    setLoading(true)
    let payload = {
      fbtoken: fbToken,
      id: id,
      tokenExpire: moment().add(59, 'day').format('DD-MM-YYYY'),
    }
    const res = await publicAPI.post(`/influencer/save-access-token`, payload)
    if (res) {
      setLoading(false)
      notification.success({
        message: res.data?.message,
        duration: 2,
      })
    } else {
      setLoading(false)
    }
  }

  return (
    <>
      {!influencer ? (
        <InfinityLoader />
      ) : (
        <>
          <div className='inf-connect-container'>
            <div className='header'>
              <img src={Logo} alt='logo' />
            </div>
            <div className='main'>
              <div className='profile-image'>
                <img
                  alt='logo'
                  src={influencer?.arch_profile_picture_url || influencer?.profile_picture_url}
                  // src={
                  //   ExpireDateCheck(influencer?.dataRefreshed) === 10
                  //     ? influencer?.arch_profile_picture_url
                  //       ? influencer?.arch_profile_picture_url
                  //       : OfflineProfileImage
                  //     : ExpireDateCheck(influencer?.dataRefreshed) === 2
                  //     ? influencer?.profile_picture_url
                  //     : OfflineProfileImage
                  // }
                />
              </div>
              <h1>Hi, {influencer?.name}</h1>
              <br />
              <br />
              <p>
                You&apos;ve been invited to connect your Instagram profile to Fireup, a marketing
                software used by some of the world&apos;s most well-known brands and agencies to
                manage collaborations with content creators and partners like you.
                <br />
                <br />
                By connecting your account, you will enable brands utilizing the Fireup software
                read-only access to your Instagram Stories, and your Instagram Stories engagement
                metrics (e.g. views, reach). You may choose to remove this connection at any time.
                <br />
                <br />
                At Fireup, we take privacy and security very seriously. To learn more, please review
                our{' '}
                <a
                  // href='https://www.traackr.com/infl-privacy-notice'
                  href='#'
                  rel='noreferrer'
                  // target={'_blank'}
                >
                  Influencer Privacy Policy.
                </a>
                <br />
                <br />
                Please note, this functionality is only available to you if you have an Instagram
                business or creator account connected to a Facebook page. You can learn more about
                Business accounts{' '}
                <a
                  href='https://help.instagram.com/502981923235522'
                  rel='noreferrer'
                  target={'_blank'}
                >
                  here.
                </a>
              </p>
            </div>
          </div>
          <div className='connect-div'>
            <div className='inner-div'>
              <div className='left'>
                <div className='circle'>
                  <FaInstagram />
                </div>
                <div>
                  <h2>@{influencer?.instagram_username}</h2>
                  <Typography.Text type='secondary'>
                    {NumberFormatter(influencer?.audience, 2)} FOLLOWERS
                  </Typography.Text>
                </div>
              </div>
              <div className='connect-section'>
                {influencer?.facebookLogined ? (
                  <span>Connected</span>
                ) : (
                  <Button loading={loading} size='large' onClick={() => logInToFB()}>
                    Connect
                  </Button>
                )}
              </div>
            </div>
          </div>
        </>
      )}
    </>
  )
}

export default InfluencerConnect
