import React from 'react'
import { Avatar, Checkbox } from 'antd'

// import { TiDelete } from 'react-icons/ti'
import { TiDeleteOutline } from "react-icons/ti";

const InfluencerCard = ({
  state,
  data,
  selectedInfluencers,
  setSelectedInfluencers,
  object,
  objectsArr,
  setObjects,
  showClear,
}) => {
  const checkHandler = (id) => {
    let ids
    if (selectedInfluencers?.includes(id)) {
      if(!state){
        ids = selectedInfluencers?.filter((item) => item !== id)
        setSelectedInfluencers(ids)
      }
    } else {
      ids = [...selectedInfluencers, id]
      setSelectedInfluencers(ids)
    }

    if (object) {
      if (selectedInfluencers?.includes(object?._id)) {
        if(!state){
          let tempArr = objectsArr?.filter((item) => item?._id !== object?._id)
          setObjects(tempArr)
        }
      } else {
        setObjects([...objectsArr, object])
      }
    }
  }

  return (
    <div
      className={
        !showClear ? selectedInfluencers?.includes(data?.id)
          ? 'influencer-card influencer-card-active'
          : 'influencer-card' : 'influencer-card'
      }
      style={{ cursor: !showClear ? 'pointer' : 'default' }}
      onClick={() => {
        if (!showClear) {
          checkHandler(data?.id)
        }
      }}
    >
      <div className='top'>
        {!showClear && (
          <Checkbox
            className='inf_cat_check'
            checked={selectedInfluencers?.includes(data?.id)}
            onChange={() => checkHandler(data?.id)}
          />
        )}
        {!state && showClear && (
          <TiDeleteOutline
            style={{
              cursor: 'pointer',
              // color: 'var(--primary)',
              color: 'gray',
              position: 'absolute',
              top: '5px',
              right: '8px',
              width: '20px',
              height: '20px'
            }}
            onClick={() => {
              let ids = selectedInfluencers?.filter((item) => item !== data?.id)

              setSelectedInfluencers(ids)

              if (object) {
                let tempArr = objectsArr?.filter((item) => item?._id !== object?._id)
                setObjects(tempArr)
              }
            }}
          />
        )}
        <Avatar src={data?.profileImage} style={{ width: '80px', height: '80px' }} />
      </div>
      <div className='bottom'>
        <p>{data?.name}</p>
        <p>@{data?.userName}</p>
      </div>
    </div>
  )
}

export default InfluencerCard
