import { Menu } from 'antd'

import { AiOutlineSetting } from 'react-icons/ai'
import { RiDeleteBinLine } from 'react-icons/ri'

import { campaignDelete } from '../../../redux'

export const SettingsMenu = (campaignData, navigate) => (
  <Menu>
    <Menu.Item
      icon={<AiOutlineSetting className='dropdown-menu-icon' />}
      onClick={() => navigate('/edit-campaign', { state: campaignData?.campaign })}
    >
      Edit Campaign
    </Menu.Item>
    <Menu.Item
      icon={<RiDeleteBinLine className='dropdown-menu-icon' />}
      onClick={() => {
        campaignDelete(campaignData?.campaign?._id, null,navigate)
      }}
    >
      Delete Campaign
    </Menu.Item>
  </Menu>
)


export const DeliverablesLength = (deliverables) => {
  let delLength = 0
  deliverables?.map(del => {
    delLength += del?.howMany
  })
  return delLength
}