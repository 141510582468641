import React, { useEffect, useState } from 'react'
import { Progress, Col, Row, Table } from 'antd'

import { MdPeopleOutline } from 'react-icons/md'
import { CgFileDocument } from 'react-icons/cg'
import { TbMessageCircle2 } from 'react-icons/tb'
import { BsPlay } from 'react-icons/bs'
import { BiTrendingUp, BiWifi2 } from 'react-icons/bi'
import { AiOutlineEye } from 'react-icons/ai'
import { LoadingOutlined } from '@ant-design/icons'

import SocialMedia from './SocialMedia'
import Expand from './Expand'
import { NumberFormatter } from '../../../functions/NumberFormatter'

function ExpandableTable({ data }) {
  const [tableData, setTableData] = useState([])
console.log("Potential Reach : ", data)
  useEffect(() => {
    let tempArr = []
    data?.keywords?.map((d, ind) => {
      tempArr.push({
        key: ind + 1,
        ...d,
      })
    })
    setTableData(tempArr)
  }, [data])

  // Table Columns
  const columns = [
    {
      title: '#',
      dataIndex: 'key',
      render: (text) => {
        return <span>{text}</span>
      },
    },
    {
      title: 'NAME',
      key: 'keyword',
      dataIndex: 'keyword',
      width: 200,
      render: (text, inf) => {
        return (
          <div>
            <h3>{text}</h3>
            <Progress
              percent={
                inf?.Posts?.value > 0
                  ? ((inf?.Posts?.value / tableData[0]?.Posts?.value) * 100).toFixed()
                  : 0
              }
              strokeColor={'#ef5b29bd'}
              showInfo={false}
            />
          </div>
        )
      },
    },
    {
      title: "INF'S",
      key: 'key',
      dataIndex: 'activatedInfluencers',
      render: ({ value }) => {
        return (
          <div className='dataStyle'>
            <MdPeopleOutline />
            <p>{value || 0}</p>
          </div>
        )
      },
    },
    {
      title: 'POSTS',
      key: 'key',
      dataIndex: 'Posts',
      render: ({ value }) => {
        return (
          <div className='dataStyle'>
            <CgFileDocument />
            <p>{value || 0}</p>
          </div>
        )
      },
    },
    {
      title: "ENG'S",
      key: 'Engagements',
      dataIndex: 'Engagements',
      render: ({ value }) => {
        return (
          <div className='dataStyle'>
            <TbMessageCircle2 />
            <p>{NumberFormatter(value, 1) || 0}</p>
          </div>
        )
      },
    },
    {
      title: 'VIDEO VIEWS',
      key: 'videoView',
      dataIndex: 'videoView',
      render: ({ value }) => {
        return (
          <div className='dataStyle'>
            <BsPlay />
            <p>{NumberFormatter(value, 1) || 0}</p>
          </div>
        )
      },
    },
    {
      title: 'REACH',
      key: 'Reach',
      dataIndex: 'Reach',
      render: ({ value }) => {
        return (
          <div className='dataStyle'>
            <BiWifi2 />
            <p>{NumberFormatter(value, 1) || 0}</p>
          </div>
        )
      },
    },
    {
      title: 'IMPRESSIONS',
      key: 'Impressions',
      dataIndex: 'Impressions',
      render: ({ value }) => {
        return (
          <div className='dataStyle'>
            <AiOutlineEye />
            <p>{NumberFormatter(value, 1) || 0}</p>
          </div>
        )
      },
    },
    {
      title: "EST IMP'S'",
      key: 'EstImpressions',
      dataIndex: 'EstImpressions',
      render: ({ value }) => {
        return (
          <div className='dataStyle'>
            <BiWifi2 />
            <p>{NumberFormatter(value, 1) || 0}</p>
          </div>
        )
      },
    },
    {
      title: "POT'L Reach",
      key: 'potentialReach',
      dataIndex: 'potentialReach',
      render: ({ value }) => {
        return (
          <div className='dataStyle'>
            <BiWifi2 />
            <p>{NumberFormatter(value, 1) || 0}</p>
          </div>
        )
      },
    },
    {
      title: 'ENG RATE',
      key: 'engRate',
      dataIndex: 'engRate',
      render: ({ value }) => {
        return (
          <div className='dataStyle'>
            <BiTrendingUp />
            <p>{value ? `${value?.toFixed(2)}%` : '0'}</p>
          </div>
        )
      },
    },
  ]

  // Table Custom Loader
  const tableLoading = {
    spinning: tableData?.length > 0 ? false : true,
    indicator: (
      <div
        style={{
          backgroundColor: 'white',
          width: '100%',
          height: '100%',
          position: 'unset',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          margin: '0',
        }}
      >
        <LoadingOutlined size={150} />
      </div>
    ),
  }

  return (
    <Table
      scroll={{ x: 'auto' }}
      loading={tableLoading}
      expandable={{
        expandIconColumnIndex: -1,
        expandedRowRender: (expData) => {
          return (
            <div className='expand_container'>
              <Row justify='space-between' gutter={[20, 10]}>
                <Col xl={5} lg={5} md={5} sm={5} xs={5}>
                  <SocialMedia name={expData?.keyword} />
                </Col>
                <Col xl={2} lg={2} md={2} sm={2} xs={2}>
                  <Expand
                    value={expData?.activatedInfluencers?.value}
                    valueShow={expData?.activatedInfluencers?.value || '0'}
                    total={expData?.activatedInfluencers?.total}
                    name="INF'S"
                  />
                </Col>
                <Col xl={2} lg={2} md={2} sm={2} xs={2}>
                  <Expand
                    value={expData?.Posts?.value}
                    valueShow={expData?.Posts?.value || '0'}
                    total={expData?.Posts?.total}
                    name='POSTS'
                  />
                </Col>
                <Col xl={2} lg={2} md={2} sm={2} xs={2}>
                  <Expand
                    value={expData?.Engagements?.value}
                    valueShow={NumberFormatter(expData?.Engagements?.value, 1) || '0'}
                    total={expData?.Engagements?.total}
                    name="ENG'S"
                  />
                </Col>
                <Col xl={2} lg={2} md={2} sm={2} xs={2}>
                  <Expand
                    value={expData?.videoView?.value}
                    valueShow={NumberFormatter(expData?.videoView?.value, 1) || '0'}
                    total={expData?.videoView?.total}
                    name='VIDEO VIEWS'
                  />
                </Col>
                <Col xl={2} lg={2} md={2} sm={2} xs={2}>
                  <Expand
                    value={expData?.Reach?.value}
                    valueShow={NumberFormatter(expData?.Reach?.value, 1) || '0'}
                    total={expData?.Reach?.total}
                    name='REACH'
                  />
                </Col>
                <Col xl={2} lg={2} md={2} sm={2} xs={2}>
                  <Expand
                    value={expData?.Impressions?.value}
                    valueShow={NumberFormatter(expData?.Impressions?.value, 1) || '0'}
                    total={expData?.Impressions?.total}
                    name='IMPRESSION'
                  />
                </Col>
                <Col xl={2} lg={2} md={2} sm={2} xs={2}>
                  <Expand
                    value={expData?.EstImpressions?.value}
                    valueShow={NumberFormatter(expData?.EstImpressions?.value, 1) || '0'}
                    total={expData?.EstImpressions?.total}
                    name="EST IMP'S"
                  />
                </Col>
                <Col xl={2} lg={2} md={2} sm={2} xs={2}>
                  <Expand
                    value={expData?.potentialReach?.value}
                    valueShow={NumberFormatter(expData?.potentialReach?.value, 1) || '0'}
                    total={expData?.potentialReach?.total}
                    name="POT'L Reach"
                  />
                </Col>
                <Col xl={2} lg={2} md={2} sm={2} xs={2}>
                  <Expand
                    value={expData?.engRate?.value?.toFixed(2)}
                    valueShow={
                      expData?.engRate?.value ? `${expData?.engRate?.value?.toFixed(2)}%` : '0'
                    }
                    total={expData?.engRate?.total}
                    name='ENG RATE'
                  />
                </Col>
              </Row>
            </div>
          )
        },
      }}
      dataSource={tableData}
      rowClassName='onHover'
      columns={columns}
      expandRowByClick={true}
      pagination={false}
      summary={() => {
        return (
          <Table.Summary fixed>
            <Table.Summary.Row>
              <Table.Summary.Cell index={0}></Table.Summary.Cell>
              <Table.Summary.Cell index={1}>
                <p style={{ color: '#55bdfb' }}>Total</p>
              </Table.Summary.Cell>
              <Table.Summary.Cell index={1}>
                <div className='dataStyle'>
                  <MdPeopleOutline />
                  <p style={{ color: '#5ea3d0' }}>{data?.uniqueInf || '0'}</p>
                </div>
              </Table.Summary.Cell>
              <Table.Summary.Cell index={2}>
                <div className='dataStyle'>
                  <CgFileDocument />
                  <p style={{ color: '#5ea3d0' }}>{data?.uniquePost || '0'}</p>
                </div>
              </Table.Summary.Cell>
              <Table.Summary.Cell index={3}>
                <div className='dataStyle'>
                  <TbMessageCircle2 />
                  <p style={{ color: '#5ea3d0' }}>{NumberFormatter(data?.uniqueEng, 1) || '0'}</p>
                </div>
              </Table.Summary.Cell>
              <Table.Summary.Cell index={4}>
                <div className='dataStyle'>
                  <BsPlay />
                  <p style={{ color: '#5ea3d0' }}>
                    {NumberFormatter(data?.uniqueVideoView, 1) || '0'}
                  </p>
                </div>
              </Table.Summary.Cell>
              <Table.Summary.Cell index={5}>
                <div className='dataStyle'>
                  <BiWifi2 />
                  <p style={{ color: '#5ea3d0' }}>{NumberFormatter(data?.uniqueReach, 1) || '0'}</p>
                </div>
              </Table.Summary.Cell>
              <Table.Summary.Cell index={6}>
                <div className='dataStyle'>
                  <AiOutlineEye />
                  <p style={{ color: '#5ea3d0' }}>
                    {NumberFormatter(data?.uniqueImpression, 1) || '0'}
                  </p>
                </div>
              </Table.Summary.Cell>
              <Table.Summary.Cell index={7}>
                <div className='dataStyle'>
                  <BiWifi2 />
                  <p style={{ color: '#5ea3d0' }}>
                    {NumberFormatter(data?.uniqueEstImpression, 1) || '0'}
                  </p>
                </div>
              </Table.Summary.Cell>
              <Table.Summary.Cell index={8}>
                <div className='dataStyle'>
                  <BiWifi2 />
                  <p style={{ color: '#5ea3d0' }}>
                    {NumberFormatter(data?.uniqueEstImpression, 1) || '0'}
                  </p>
                </div>
              </Table.Summary.Cell>
              <Table.Summary.Cell index={9}>
                <div className='dataStyle'>
                  <BiTrendingUp />
                  <p style={{ color: '#5ea3d0' }}>{data?.uniqueEngRate?.toFixed(2) || 0}%</p>
                </div>
              </Table.Summary.Cell>
            </Table.Summary.Row>
          </Table.Summary>
        )
      }}
    />
  )
}

export default ExpandableTable
