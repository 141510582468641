import React from 'react'

import VerticalLayout from './PublicVerticalLayout'
import MobileLayout from './PublicMobileLayout'

const PublicLayout = ({ children, active }) => {
  return (
    <>
      <VerticalLayout children={children} active={active} />
      <MobileLayout>{children}</MobileLayout>
    </>
  )
}

export default PublicLayout
