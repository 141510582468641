import { useState, useEffect } from 'react'
import { notification, Button } from 'antd'
import { useParams } from 'react-router-dom'

import { BiErrorAlt } from 'react-icons/bi'
import { BsCheckLg } from 'react-icons/bs'
import { RxCross2 } from 'react-icons/rx'

import { privateAPI } from '../config/constants'
import Profile from '../assets/mainlogo.png'
import ConnectArrows from '../assets/connect-arrow-bg.png'

const CampaignEmailConfirmation = () => {
  const [data, setData] = useState(null)
  const { id, ad } = useParams()
  const [loading, setloading] = useState(false)

  const checkValidity = async () => {
    console.log('run...')
    try {
      let payload = {
        id: id,
        adminId: ad,
      }
      const res = await privateAPI.post(`/campaign/email-confirmation-check`, payload)
      if (res?.data) {
        setData(res?.data)
      }
    } catch (error) {
      notification.error({
        message: 'Invalid URL',
        duration: 3,
      })
      setData('invalid')
      console.log('err', error)
    }
  }

  useEffect(() => {
    checkValidity()
  }, [])

  const onConfirm = async () => {
    setloading(true)
    try {
      let payload = {
        id: id,
        confirmed: true,
      }

      const res = await privateAPI.post(`/campaign/email-confirmation-check-update`, payload)
      if (res?.data) {
        setloading(false)
        checkValidity()
      }
    } catch (err) {
      notification.error({
        message: 'SERVER ERROR',
        duration: 3,
      })
      setloading(false)
    }
  }

  const onReject = async () => {
    try {
      setloading(true)
      let payload = {
        id: id,
        reject: true,
      }

      const res = await privateAPI.post(`/campaign/email-confirmation-check-update`, payload)
      if (res?.data) {
        setloading(false)
        checkValidity()
      }
    } catch (err) {
      notification.error({
        message: 'SERVER ERROR',
        duration: 3,
      })
      setloading(false)
    }
  }

  return (
    <>
      {data && (
        <div className='campaign-email-confirmation-container'>
          <img className='company-logo' src={Profile} alt='profile' />

          {data === 'invalid' ? (
            <div className='status-div invalid'>
              <BiErrorAlt className='icon' />
              <p>Invalid URL</p>
            </div>
          ) : (
            <div className='main'>
              <h2>Campaign Confirmation</h2>
              <div className='flow-container'>
                <img src={data?.admin?.profile_picture} className='admin-logo' />
                <img src={ConnectArrows} />
                <img src={data?.influencer?.arch_profile_picture_url} />
              </div>
              <h3>Hello {data?.influencer?.name},</h3>
              <p>
                You have been added to {data?.campaign?.name?.replace('Campaign', '')} campaign on
                Fireup by {data?.admin?.name}, if you want to be part of this campaign then kindly
                click The &apos;confirm&apos; button.
              </p>

              <div className='table-container'>
                <p className='title'>Content</p>
                <table className='content-table'>
                  <tr className='table-header'>
                    <th>Type</th>
                    <th>Platform</th>
                    <th>No. Of</th>
                    <th>Fee</th>
                  </tr>
                  {data?.deliverables?.map((content, index) => (
                    <tr className='data-row' key={index}>
                      <td>{content?.postType}</td>
                      <td>{content?.platform}</td>
                      <td>{content?.howMany}</td>
                      <td>{content?.contentFee}</td>
                    </tr>
                  ))}
                </table>
              </div>

              <div className='hash-tag-container'>
                <p className='title'>Hash Tags</p>
                <div className='hash-tags'>
                  {data?.campaign?.hashTags?.map((tag, index) => (
                    <p className='tag' key={index}>
                      {tag}
                    </p>
                  ))}
                </div>
              </div>
              
              {data?.availabilityStatus === 'Not Available' ? (
                <div className='btns-container'>
                  <Button className='reject-btn' onClick={() => onReject()}>
                    Reject
                  </Button>
                  <Button type='primary' loading={loading} onClick={() => onConfirm()}>
                    Confirm
                  </Button>
                </div>
              ) : data?.availabilityStatus == 'Available' ? (
                <div className='confirmed-box'>
                  <BsCheckLg />
                  <p>Confirmed</p>
                </div>
              ) : (
                <div className='reject-box'>
                  <RxCross2 className="cross-icon"/>
                  <p>Rejected</p>
                </div>
              )}
            </div>
          )}
        </div>
      )}
    </>
  )
}

export default CampaignEmailConfirmation
