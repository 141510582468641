import React, { useState } from 'react'
import { Col, Row, Select } from 'antd'

import PerformersActivated from './PerformersActivated'

function TopPerformer({ data }) {
  const { Option } = Select
  const [selctedBar, setSelctedBar] = useState(['activatedInfluencers', 'Posts', 'Engagements'])

  const tagRender = (props) => {
    const { label } = props;
   
    return (
      <span
        style={{ marginRight: 3 }}
      >
        {label},
      </span>
    );
  };
  
  return (
    <div className='top_performer_Container'>
      <div className='header'>
        <h3>Top Performers</h3>
        <Select
          mode='multiple'
          style={{
            minWidth: '250px',
          }}
          maxTagCount={3}
          tagRender={tagRender}
          value={selctedBar}
          onChange={(e) => {
            // if (selctedBar.length < 3) {
            // }
            setSelctedBar(e)
          }}
        >
          <Option value='activatedInfluencers'>Activated Influencers</Option>
          <Option value='Posts'>Posts</Option>
          <Option value='Engagements'>Engagements</Option>
          <Option value='Reach'>Reach</Option>
          <Option value='Impressions'>Impressions</Option>
          <Option value='EstImpressions'>Est Impressions</Option>
          <Option value='potentialReach'>Potential Reach</Option>
          <Option value='engRate'>Eng Rate</Option>
        </Select>
      </div>
      <div className='active-container'>
        <Row className='main'>
          {selctedBar?.map((per, ind) => (
            <Col key={ind}>
              <PerformersActivated
                title={
                  per === 'activatedInfluencers'
                    ? 'ACTIVATED INFLUENCERS'
                    : per === 'EstImpressions'
                    ? 'Est Impressions'
                    : per === 'potentialReach'
                    ? 'Potential Reach'
                    : per
                }
                data={data}
                value={per}
              />
            </Col>
          ))}
        </Row>
      </div>
    </div>
  )
}

export default TopPerformer
