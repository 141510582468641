import { useState, useEffect } from 'react'
import { Col, Row } from 'antd'

// Components
import AudienceCredibility from '../../../../../components/Views/influencerAudience/AudienceCredibility'
import AudienceType from '../../../../../components/Views/influencerAudience/AudienceType'
import AudienceReachability from '../../../../../components/Views/influencerAudience/AudienceReachability'
// import AgeChart from '../../../../../components/Charts/AgeChart'
// import IncomeChart from "../Charts/IncomeChart";
// import AudienceGrowth from '../../../../../components/Views/influencerAudience/AudienceGrowth'
// import EngagementGrowth from '../../../../../components/Views/influencerAudience/EngagementGrowth'
import Gender from '../../../../../components/Views/influencerAudience/Gender'
import FamilyStatus from '../../../../../components/Views/influencerAudience/FamilyStatus'
import Country from '../../../../../components/Views/influencerAudience/Country'
import City from '../../../../../components/Views/influencerAudience/City'
import Occupation from "../../../../../components/Views/influencerAudience/Occupation";
import LikesInterest from "../../../../../components/Views/influencerAudience/LikesInterest";
// import BrandAffinity from "./influencerAudience/BrandAffinity";
import { attachToken, privateAPI } from '../../../../../config/constants'

const InstagramAudience = ({ id, data }) => {
  // const [InstaAudienceGrowth, setInstaAudienceGrowth] = useState(null)
  // const [InstaEngGrowth, setInstaEngGrowth] = useState(null)
  const [audienceCredibilityData, setAudienceCredibilityData] = useState(null)
  const [audienceTypeData, setAudienceTypeData] = useState(null)
  const [audienceReachabilityData, setAudienceReachabilityData] = useState(null)
  const [audienceGenderData, setAudienceGenderData] = useState(null)
  const [audienceFamilyStatusData, setAudienceFamilyStatusData] = useState(null)
  const [audienceCountryData, setAudienceCountryData] = useState(null)
  const [audienceCityData, setAudienceCityData] = useState(null)
  const [audienceOccupationData, setAudienceOccupationData] = useState(null)
  const [audienceLikeAndInterestData, setAudienceLikeAndInterestData] = useState(null)

  // const getInfluencerAudiencerowth = async () => {
  //   attachToken()
  //   const res = await privateAPI.get(`/instagram/get-influencer-audience-growth/${id}`)
  //   setInstaAudienceGrowth(res?.data?.data)
  // }

  // const getInfluencerInstaEngGrowth = async () => {
  //   attachToken()
  //   const res = await privateAPI.get(`/instagram/get-influencer-engagement-growth/${id}`)
  //   setInstaEngGrowth(res?.data?.data)
  // }

  const getAudienceCredibility = async () => {
    attachToken()
    const res = await privateAPI.get(`/instagram/get-influencer-audience-credibility/${id}`)
    if (res?.data?.data) {
      setAudienceCredibilityData(res?.data?.data)
    }
  }

  const getAudienceType = async () => {
    attachToken()
    const res = await privateAPI.get(`/instagram/get-influencer-audience-type/${id}`)
    if (res?.data?.data) {
      setAudienceTypeData(res?.data?.data)
    }
  }

  const getAudienceReachability = async () => {
    attachToken()
    const res = await privateAPI.get(`/instagram/get-influencer-audience-reachability/${id}`)
    if (res?.data?.data) {
      setAudienceReachabilityData(res?.data?.data)
    }
  }

  const getAudienceGender = async () => {
    attachToken()
    const res = await privateAPI.get(`/instagram/get-influencer-audience-gender/${id}`)
    if (res?.data?.data) {
      setAudienceGenderData(res?.data?.data)
    }
  }

  const getAudienceFamilyStatus = async () => {
    attachToken()
    const res = await privateAPI.get(`/instagram/get-influencer-audience-family-status/${id}`)
    if (res?.data?.data) {
      setAudienceFamilyStatusData(res?.data?.data)
    }
  }

  const getAudienceCountry = async () => {
    attachToken()
    const res = await privateAPI.get(`/instagram/get-influencer-audience-country/${id}`)
    if (res?.data?.data) {
      setAudienceCountryData(res?.data?.data)
    }
  }

  const getAudienceCity = async () => {
    attachToken()
    const res = await privateAPI.get(`/instagram/get-influencer-audience-city/${id}`)
    if (res?.data?.data) {
      setAudienceCityData(res?.data?.data)
    }
  }

  const getAudienceOccupation = async () => {
    attachToken()
    const res = await privateAPI.get(`/instagram/get-influencer-audience-occupation/${id}`)
    if (res?.data?.data) {
      setAudienceOccupationData(res?.data?.data)
    }
  }

  const getAudienceLikeAndInterest = async () => {
    attachToken()
    const res = await privateAPI.get(`/instagram/get-influencer-audience-like-interest/${id}`)
    if (res?.data?.data) {
      setAudienceLikeAndInterestData(res?.data?.data)
    }
  }

  useEffect(() => {
    // getInfluencerAudiencerowth()
    // getInfluencerInstaEngGrowth()
    getAudienceCredibility()
    getAudienceType()
    getAudienceReachability()
    getAudienceGender()
    getAudienceFamilyStatus()
    getAudienceCountry()
    getAudienceCity()
    getAudienceOccupation()
    getAudienceLikeAndInterest()
  }, [])

  return (
    <div>
      <Row gutter={[15, 15]}>
        {audienceCredibilityData && (
          <Col xl={7} lg={24} md={24} sm={24} xs={24}>
            <AudienceCredibility data={audienceCredibilityData} />
          </Col>
        )}
        {audienceTypeData && (
          <Col xl={10} lg={12} md={12} sm={24} xs={24}>
            <AudienceType data={audienceTypeData} />
          </Col>
        )}
        {audienceReachabilityData && (
          <Col xl={7} lg={12} md={12} sm={24} xs={24}>
            <AudienceReachability data={audienceReachabilityData} />
          </Col>
        )}
        {/* {InstaAudienceGrowth?.length > 5 && (
          <Col xl={12} lg={12} md={24} sm={24} xs={24}>
            <AudienceGrowth
              data={InstaAudienceGrowth}
                totalAudience={data?.totalAudience}
            />
          </Col>
        )}

        {InstaEngGrowth?.length > 5 && (
          <Col xl={12} lg={12} md={24} sm={24} xs={24}>
            <EngagementGrowth
              data={InstaEngGrowth}
                engagementRate={data?.engagement_rate}
            />
          </Col>
        )} */}
        {/* {data?.age && (
            <Col xl={12} lg={24} md={24} sm={24} xs={24}>
              <div className='age-chart'>
                <h3>Age</h3>
                <AgeChart data={data?.age} />
              </div>
            </Col>
          )} */}
        {audienceGenderData && (
          <Col xl={6} lg={12} md={12} sm={24} xs={24}>
            <Gender data={audienceGenderData} totalAudience={data?.totalAudience} />
          </Col>
        )}
        {audienceFamilyStatusData && (
          <Col xl={6} lg={12} md={12} sm={24} xs={24}>
            <FamilyStatus data={audienceFamilyStatusData} />
          </Col>
        )}
        {audienceCountryData && (
          <Col xl={6} lg={12} md={12} sm={24} xs={24}>
            <Country data={audienceCountryData} />
          </Col>
        )}
        {audienceCityData && (
          <Col xl={6} lg={12} md={12} sm={24} xs={24}>
            <City data={audienceCityData} />
          </Col>
        )}
      {audienceOccupationData &&  <Col xl={12} lg={24} md={24} sm={24} xs={24}>
           <Occupation data={audienceOccupationData} />
         </Col>}
        {/* <Col xl={12} lg={12} md={24} sm={24} xs={24}>
           <div className="age_chart">
             <h3>Income</h3>
             <IncomeChart />
           </div>
         </Col> */}
        {audienceLikeAndInterestData && <Col xl={12} lg={12} md={24} sm={24} xs={24}>
           <LikesInterest data={audienceLikeAndInterestData} />
         </Col>}
        {/* <Col xl={12} lg={12} md={24} sm={24} xs={24}>
           <BrandAffinity />
         </Col> */}
      </Row>
      {/* ) : (
        <div className='no-found '>
          <span>{data?.name} is not connected with Fireup</span>
        </div>
      )} */}
    </div>
  )
}

export default InstagramAudience
