import React, { useEffect, useState, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
// import { Row, Col } from 'antd'
import { Dropdown, Menu, Table, notification } from 'antd'
import { CSVLink } from 'react-csv'

import { LoadingOutlined } from '@ant-design/icons'
import { FaPlus } from 'react-icons/fa'
import { BsThreeDots } from 'react-icons/bs'
import { AiOutlineFilePpt, AiOutlineShareAlt } from 'react-icons/ai'
import { RiDeleteBinLine } from 'react-icons/ri'
import { GrDocumentCsv } from 'react-icons/gr'

import { attachToken, privateAPI, publicAPI, forntendUrl } from '../../../config/constants'
import { NumberFormatter } from '../../../functions/NumberFormatter'
import { DeliverablesLength } from '../../../pages/Campaign/utils/campaignUtil'
import { generateReportPpt } from '../../../functions/ExportReportPPT'
// import InfinityLoader from '../../../components/InfinityLoader'
import reportPng from '../../../assets/report-influence.png'
import moment from 'moment'

const CampaignReport = ({ id }) => {
  const navigate = useNavigate()
  const [data, setData] = useState(null)
  const [campaignData, setCampaignData] = useState(null)
  const [reportPPTLoading, setReportPPTLoading] = useState(false)
  // const [reportPPTData, setReportPPTData] = useState(null)
  const [CSVData, setCSVData] = useState([])
  const csvRef = useRef()

  const getCampaignReports = async () => {
    if (id) {
      try {
        attachToken()
        const res = await privateAPI.get(`/campaign/get-campaign-reports/${id}`)
        if (res?.data) {
          setCampaignData(res?.data)
        }
      } catch (error) {
        console.log('err', error)
      }
    }
  }

  const getCampaignReportPPT = async (campReportID , chartData) => {
    if (campReportID) {
      try {
        setReportPPTLoading(true)
        // attachToken()
        const res = await publicAPI.get(`/campaign/get-campaign-report-ppt/${campReportID}/all`)
        if (res?.data) {
          // generateReportPpt(res?.data, setReportPPTLoading)
          generateReportPpt( {
            ...res?.data,
            chart1: chartData?.chartData,
            interval: chartData?.interval,
            duration: chartData?.duration,
            chart2: [
              [chartData?.budget, chartData?.budget, chartData?.budget],
              [chartData?.totalViews, chartData?.totalEng, chartData?.totalReach],
            ],
          }, setReportPPTLoading)
        }
      } catch (error) {
        console.log('err', error)
      }
    }
  }

  const getChartReportPPT = async (campReportID) => {
    if (campReportID) {
      try {
        // attachToken()
        const res = await publicAPI.post(`/campaign/chart-1-data`, { reportId: campReportID })
        if (res?.data) {
          
          getCampaignReportPPT(campReportID, res?.data)
        }
      } catch (error) {
        console.log('err', error)
      }
    }
  }

  const deleteReport = async (id) => {
    try {
      attachToken()
      const res = await privateAPI.delete(`/campaign/report-delete/${id}`)
      if (res) {
        // navigate(-1)
        getCampaignReports()
        notification.success({
          message: 'Report Deleted',
          duration: 3,
        })
      }
    } catch (err) {
      console.log(err)
    }
  }

  const copyToClipBoard = (id) => {
    navigator.clipboard.writeText(`${forntendUrl}/public-campaign-report-summary/${id}`)
    notification.success({
      message: 'Copied To Clipboard',
      duration: 2,
    })
  }

  useEffect(() => {
    getCampaignReports()
  }, [id])

  useEffect(() => {
    let tempArr = []
    let tempReports = campaignData
    tempReports?.sort((a, b) => {
      if (a?.name?.toLowerCase() > b?.name?.toLowerCase()) {
        return 1
      }
      if (a?.name?.toLowerCase() < b?.name?.toLowerCase()) {
        return -1
      }
      return 0
    })
    tempReports?.map((i) => {
      tempArr.push({
        id: i?._id,
        profile: (
          <div className='profile'>
            <img src={reportPng} alt='profile' />
          </div>
        ),
        name:
          (
            <span
              style={{ cursor: 'pointer' }}
              onClick={() => navigate(`/campaign/report/${i?._id}`)}
            >
              {i?.name}
            </span>
          ) || '--',
        time: `${moment(i?.startDate).format('MMM DD, YYYY')} - ${moment(i?.endDate).format(
          'MMM DD, YYYY',
        )}`,
        owner: 'Admin',
        date: moment(i?.createdAt).format('MMM DD, YYYY'),
        action: (
          <>
            {reportPPTLoading == true ? (
              <LoadingOutlined
                style={{
                  fontSize: 18,
                }}
                spin
              />
            ) : (
              <Dropdown
                overlay={reportMenu(i?._id)}
                trigger={['click']}
                placement={'bottomRight'}
                disabled={reportPPTLoading}
              >
                <BsThreeDots className='dot-icon' />
              </Dropdown>
            )}
          </>
        ),
      })
    })
    setData(tempArr)
  }, [campaignData])

  const columns = [
    {
      title: ' ',
      dataIndex: 'profile',
      width: '50px',
    },
    {
      title: 'Name',
      dataIndex: 'name',
    },
    {
      title: 'Time Frame',
      dataIndex: 'time',
    },
    {
      title: 'Owner',
      dataIndex: 'owner',
    },
    {
      title: 'Created Date',
      dataIndex: 'date',
    },
    {
      title: 'Actions',
      dataIndex: 'action',
    },
  ]

  const onCSVDownload = async (reportId) => {
    try {
      let tempCSVData = []
      setReportPPTLoading(true)
      // attachToken()
      const res = await publicAPI.get(`/campaign/get-campaign-report-ppt/${reportId}/all`)
      if (res?.data) {
        let reportPPTData = res?.data
        reportPPTData?.influencers?.map((inf) => {
          const payload = Object.assign(
            { Name: inf?.influencer?.name },
            reportPPTData?.infAudEnabled && {
              audience: NumberFormatter(inf?.influencer?.audience, 1),
            },
            reportPPTData?.infEngRateEnabled && {
              'Eng Rate': inf?.influencer?.instagram?.engagememtRate
                ? `${Number(inf?.influencer?.instagram?.engagememtRate)?.toFixed(2)}%`
                : '--',
            },
            reportPPTData?.infPostsEnabled && {
              // Posts: `${inf?.posts} / ${DeliverablesLength(inf?.deliverables)}`,
              Posts: `${inf?.posts} posts out of ${DeliverablesLength(inf?.deliverables)}`,
            },
            reportPPTData?.infEngEnabled && {
              Engagement: inf?.engagement ? NumberFormatter(inf?.engagement, 1) : 0,
            },
            reportPPTData?.socialComEnabled && {
              Comments: inf?.total_comments ? NumberFormatter(inf?.total_comments, 2) : 0,
            },
            reportPPTData?.socialLikesEnabled && {
              Likes: inf?.total_likes ? NumberFormatter(inf?.total_likes, 2) : 0,
            },
            reportPPTData?.socialVideoViewsEnabled && {
              'Video Views': inf?.videoView ? NumberFormatter(inf?.videoView, 1) : 0,
            },
            reportPPTData?.socialReachEnabled && {
              Reach: inf?.reach ? NumberFormatter(inf?.reach, 1) : 0,
            },
            reportPPTData?.socialEngRateEnabled && {
              'Social EngRate': inf?.engRate ? `${NumberFormatter(inf?.engRate, 1)}%` : 0,
            },
            reportPPTData?.engWithoutViewsEnabled && {
              'Eng Without View': inf?.engagement ? NumberFormatter(Number(inf?.engagement), 1) : 0,
            },
            reportPPTData?.engWithViewsEnabled && {
              'Eng Withe View': inf?.engagement
                ? NumberFormatter(Number(inf?.engagement + inf?.videoView), 1)
                : 0,
            },
            reportPPTData?.cpeWithoutViewsEnabled && {
              'CPE Without View': inf?.cpe ? `PKR${NumberFormatter(inf?.cpe, 2)}` : '--',
            },
            reportPPTData?.cpeWithViewsEnabled && {
              'CPE With View': inf?.cpeView ? `PKR${NumberFormatter(inf?.cpeView, 2)}` : '--',
            },
            reportPPTData?.CPREnabled && {
              CPR: inf?.cpr ? `PKR${NumberFormatter(inf?.cpr, 2)}` : '--',
            },
          )


          tempCSVData.push(payload)
        })

        setCSVData(tempCSVData)

        setTimeout(() => {
          csvRef?.current?.link?.click()
        }, 1000)
      }
    } catch (error) {
      console.log('err', error)
      notification.error({
        description: error || "Error Occured",
        duration: 2,
      });
    }
  }

  const reportMenu = (campReportID) => (
    <Menu>
      <Menu.Item
        icon={
          reportPPTLoading == true ? (
            <LoadingOutlined
              style={{
                fontSize: 18,
              }}
              spin
            />
          ) : (
            <AiOutlineFilePpt className='dropdown-menu-icon' />
          )
        }
        // onClick={() => getCampaignReportPPT(campReportID)}
        onClick={() => getChartReportPPT(campReportID)}
        disabled={reportPPTLoading}
      >
        Report PPT
      </Menu.Item>

      <Menu.Item icon={<GrDocumentCsv className='dropdown-menu-icon' />}>
        {/* <CSVLink
          ref={csvRef}
          filename={`Campaign-Report-${moment().format('YYYY-MM-DD')}.csv`}
          data={CSVData}
          style={{ width: 'max-content', color: 'white' }}
        /> */}
        {/* <Button className='export-csv-btn'>Report CSV</Button> */}
        <span
          style={{ color: '#000000D9' }}
          onClick={() => {
            onCSVDownload(campReportID)
          }}
        >
          Report CSV
        </span>
      </Menu.Item>

      <Menu.Item
        icon={<RiDeleteBinLine className='dropdown-menu-icon' />}
        onClick={() => deleteReport(campReportID)}
      >
        Delete Report
      </Menu.Item>
      <Menu.Item
        icon={<AiOutlineShareAlt className='dropdown-menu-icon' />}
        onClick={() => copyToClipBoard(campReportID)}
      >
        Share Report
      </Menu.Item>
    </Menu>
  )

  return (
    <div className='program-analytics-container'>
      <CSVLink
        ref={csvRef}
        filename={`Campaign-Report-${moment().format('YYYY-MM-DD')}.csv`}
        data={CSVData}
        style={{ width: 'max-content', color: 'white' }}
      />
      {/* Filter Bar */}
      <div className='filter-bar'>
        <div className='left-side'>
          {/* <Select
            defaultValue='recent'
            style={{ width: 100 }}
            onChange={onfilter2Change}
            size='large'
            className='select'
          >
            <Option value='recent'>Recent</Option>
            <Option value='name'>Name</Option>
            <Option value='owner'>Owner</Option>
          </Select> */}
        </div>
        <div className='right-side'>
          <FaPlus className='add-icon' onClick={() => navigate(`/campaign/${id}/create-report`)} />
        </div>
      </div>
      <div className='main'>
        <Table
          columns={columns}
          dataSource={data}
          // rowClassName="onHover"
          loader={false}
          pagination
          // onRow={(record) => {
          //   return {
          //     onClick: () => {
          //       navigate(`/campaign/report/${record?.id}`)
          //     },
          //   }
          // }}
        />
      </div>
    </div>
  )
}

export default CampaignReport
