export const getPotentialReach = (data) => {
    return data?.instagram?.media?.data?.slice(0, 6) || [];
  };
  
  export const calculateTotalLikes = (potentialReach) => {
    let totalLikes = 0;
    potentialReach?.forEach((post) => {
      if (post.like_count) {
        totalLikes += post.like_count;
      }
    });
    return totalLikes;
  };

  // Function to get potential reach from multiple profiles
export const getPotentialReachFromProfiles = (profiles) => {
  let potentialReach = [];

  profiles.forEach(profile => {
    const profilePotentialReach = getPotentialReach(profile);
    potentialReach = potentialReach.concat(profilePotentialReach);
  });

  return potentialReach;
};

// Function to calculate total likes from multiple profiles
export const calculateTotalLikesFromProfiles = (profiles) => {
  const potentialReach = getPotentialReachFromProfiles(profiles);
  return calculateTotalLikes(potentialReach);
};