export const light = {
  background: "#f1f1f1",
  primary: "#ef5a29",
  primaryHover: "#ef5b29dc",
  secondary: "#fcd145",
  sidebar: "#FFFFFF",
  text: "#1C2833",
  borderLight: "rgb(231, 231, 231)",
  cardColor: "#FFFFFF",
  tableHeader: "#F8F9F9",
  lightBlack: "rgb(59, 59, 59)",
  danger:'#E74C3C'
};

export const dark = {
  background: "#212528",
  primary: "red",
  secondary: "#fcd145",
  sidebar: "#282c31",
  text: "#c9d1d9",
  borderLight: "#555",
  cardColor: "#282c31",
  tableHeader: "#282c31",
  lightBlack: "rgb(59, 59, 59)",
  danger:'#E74C3C'
};
