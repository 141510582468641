import { Skeleton } from 'antd'
import React from 'react'

const InfluencerSkeleton = () => {
  return (
    <div style={{ backgroundColor: 'white', borderRadius: '8px', padding: '10px 20px' }}>
      <Skeleton active avatar paragraph={{ rows: 1 }} />
      <Skeleton active avatar paragraph={{ rows: 1 }} />
      <Skeleton active avatar paragraph={{ rows: 1 }} />
    </div>
  )
}

export default InfluencerSkeleton
