import { useState, useEffect } from 'react'
import { Select, Input, Form, Button, Row, Col, notification, Typography, Steps } from 'antd'
import { useLocation, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import { RiDeleteBin2Line } from 'react-icons/ri'
import { AiOutlinePlusCircle } from 'react-icons/ai'

import DataTable from '../../components/Table/DataTable'
import Layout from '../../layout/Layout'
import { attachToken, privateAPI } from '../../config/constants'
import { getAllGroups } from '../../redux'
import { randomCode } from '../../functions/randomCode'
import { columns } from './utils/utils'
import GeneralForm from './forms/GeneralForm'
import InfluencerForm from './forms/InfluencerForm'

const AddCampaigns = () => {
  const { Option } = Select
  const dispatch = useDispatch()
  const [form] = Form.useForm()
  const navigate = useNavigate()
  const selectedBrand = useSelector((state) => state?.brand?.selectedBrand)
  const { state } = useLocation()
  const [step, setStep] = useState(1)
  const [formData, setFormData] = useState({})
  const [loading, setLoading] = useState(false)
  const [tagsState, setTagsState] = useState([])
  const merchant = JSON.parse(localStorage.getItem('user'))
  const [minFollower, setMinFollower] = useState(0)
  const [maxFollower, setMaxFollower] = useState(0)
  const [influencerGetType, setinfluencerGetType] = useState('')
  const [groups, setGroups] = useState(null)
  const [platform, setPlatform] = useState(null)
  const [postType, setPostType] = useState(null)
  const [howMany, setHowMany] = useState('')
  const [contentArr, setContentArr] = useState(state?.content || [])
  const [contentData, setContentData] = useState([])
  const [groupInfluencers, setGroupInfluencers] = useState()
  const [influncersByFollowers, setInfluncersByFollowers] = useState([])
  const [influencersData, setInfluencersData] = useState([])
  const [selectedInfluencerCategories, setSelectedInfluencerCategories] = useState([])
  const [categoriesInfluencers, setCategoriesInfluencers] = useState([])
  const [overAllSelected, setOverAllSelected] = useState([])
  const [overAllSelectedObj, setOverAllSelectedObj] = useState([])

  useEffect(() => {
    if (state) {
      setTagsState(state?.hashTags)

      let ids = []
      state?.influencers?.map((item) => {
        ids?.push(item?._id)
      })
      setOverAllSelected([...ids])
      setOverAllSelectedObj(state?.influencers)
    }
  }, [])

  const onFinishGeneral = (values) => {
    setFormData({
      ...formData,
      name: values?.name,
      brand: selectedBrand,
      visibility: values?.visibility || 'all',
      campaignType: values?.campaignType,
      objective: values?.objective,
      startDate: values?.startDate,
      endDate: values?.endDate,
      // hashTags: JSON.stringify(values?.hashTags || []),
      hashTags: JSON.stringify(tagsState || []),
      currency: values?.currency || 'PKR',
      budget: Number(values?.budget),
    })

    // if (!state) {
    //   setStep(2)
    // } else {
    //   setStep(3)
    // }
    setStep(2)
  }

  const onFinishInfluencers = (values) => {
    setFormData({
      ...formData,
      creationType: values?.creationType,
      min: Number(values?.min),
      max: Number(values?.max),
      // selectInfFollowers: selectedInfByFollowers,
      selectInfFollowers: overAllSelected,
      merchant: merchant?._id,
      group: values?.group,
      // selectGroupIngfluencers: selectedGroupInfluecers,
      selectGroupIngfluencers: overAllSelected,
      // selectInfluencers: JSON.stringify(selectInfluencers || []),
      selectInfluencers: JSON.stringify(overAllSelected || []),
      selectCategories: values?.category,
      selectedCategoriesInfluencers: overAllSelected,
    })

    setStep(3)
  }

  const onFinish = async (values) => {
    console.log('values', values)
    // setLoading(false)
    setLoading(true)
    setFormData({
      ...formData,
      content: JSON.stringify(contentArr),
    })
    // let payload = {
    //   name: values?.name,
    //   brand: selectedBrand,
    //   visibility: values?.visibility,
    //   campaignType: values?.campaignType,
    //   objective: values?.objective,
    //   startDate: values?.startDate,
    //   endDate: values?.endDate,
    //   hashTags: JSON.stringify(values?.hashTags || []),
    //   currency: values?.currency,
    //   budget: Number(values?.budget),
    //   creationType: values?.creationType,
    //   min: Number(values?.min),
    //   max: Number(values?.max),
    //   merchant: merchant?._id,
    //   group: values?.group,
    //   selectInfluencers: JSON.stringify(selectInfluencers || []),
    //   selectCategories: values?.category,
    //   selectedCategoriesInfluencers,
    //   content: JSON.stringify(contentArr),
    // }
    let payload = {
      ...formData,
      content: JSON.stringify(contentArr),
    }
    try {
      if (state) {
        attachToken()
        const res = await privateAPI.post(`/campaign/edit/${state?._id}`, {...payload, selectedInfluencers: overAllSelected})
        if (res?.data) {
          notification.success({
            message: res?.data?.message,
            duration: 3,
          })
          setLoading(false)
          navigate(`/campaign/${state?._id}`)
        }
      } else {
        attachToken()
        const res = await privateAPI.post(`/campaign/create`, payload)
        if (res?.data) {
          notification.success({
            message: res?.data?.message,
            duration: 3,
          })
          setLoading(false)
          navigate('/campaigns')
        }
      }
    } catch (error) {
      setLoading(false)
      notification.error({
        description: error?.response?.data?.message || 'Server Error',
        duration: 2,
      })
    }
  }

  const getOptionalData = async () => {
    let payload = {
      brand: selectedBrand,
    }
    const res = await dispatch(getAllGroups(payload))
    setGroups(res)
  }

  useEffect(() => {
    getOptionalData()
  }, [])

  const AddConetnt = () => {
    if (postType && platform && howMany !== '') {
      let payload = {
        postType: postType,
        platform: platform,
        howMany: howMany,
        id: randomCode(),
      }
      setContentArr([...contentArr, payload])
      setPlatform(null)
      setPostType(null)
      setHowMany('')
    } else {
      notification.error({
        message: 'Fill All Content Fields',
        duration: 2,
      })
    }
  }

  useEffect(() => {
    let tempArr = []
    contentArr?.map((i) => {
      tempArr.push({
        platform: i?.platform,
        postType: i?.postType,
        howMany: i?.howMany,
        action: (
          <RiDeleteBin2Line
            className='remove-icon'
            onClick={() => {
              let indexFind = contentArr.findIndex((e) => e.id === i.id)
              contentArr.splice(indexFind, 1)
              setContentArr([...contentArr])
            }}
          />
        ),
      })
    })
    setContentData(tempArr)
  }, [contentArr])

  const items = [
    {
      title: 'GENERAL',
    },
    {
      title: 'INFLUENCERS',
    },
    {
      title: 'CONTENT',
    },
  ]

  return (
    <Layout active={'campaigns'}>
      <div className='create-campaigns-container'>
        <div className='main'>
        <h2 className='mainHeading'>{state ? 'Update' : 'Create'} Campaign</h2>
        <div className='steps-div' style={{margin: '2rem auto', width: '60%'}}>
            <Steps current={step - 1} labelPlacement='vertical' items={items} />
          </div>
          {step === 1 ? (
            <GeneralForm
              state={state}
              form={form}
              tagsState={tagsState}
              setTagsState={setTagsState}
              loading={loading}
              onFinishGeneral={onFinishGeneral}
            />
          ) : step === 2 ? (
            <InfluencerForm
              overAllSelected={overAllSelected}
              setOverAllSelected={setOverAllSelected}
              overAllSelectedObj={overAllSelectedObj}
              setOverAllSelectedObj={setOverAllSelectedObj}
              state={state}
              form={form}
              groups={groups}
              influencersData={influencersData}
              setInfluencersData={setInfluencersData}
              groupInfluencers={groupInfluencers}
              setGroupInfluencers={setGroupInfluencers}
              minFollower={minFollower}
              setMinFollower={setMinFollower}
              maxFollower={maxFollower}
              setMaxFollower={setMaxFollower}
              influncersByFollowers={influncersByFollowers}
              setInfluncersByFollowers={setInfluncersByFollowers}
              influencerGetType={influencerGetType}
              setinfluencerGetType={setinfluencerGetType}
              selectedInfluencerCategories={selectedInfluencerCategories}
              setSelectedInfluencerCategories={setSelectedInfluencerCategories}
              categoriesInfluencers={categoriesInfluencers}
              setCategoriesInfluencers={setCategoriesInfluencers}
              setStep={setStep}
              onFinishInfluencers={onFinishInfluencers}
              loading={loading}
            />
          ) : (
            <Form layout='vertical' scrollToField form={form} onFinish={onFinish}>
              <Row gutter={[30, 0]}>
                <Col span={24}>
                  <Row gutter={[30, 0]} className='add-content-container'>
                    <Col span={24}>
                      <Typography.Title level={4}>Content</Typography.Title>
                    </Col>
                    <Col xs={24} md={12} lg={8}>
                      <Form.Item label='Platform'>
                        <Select
                          style={{ width: '100%' }}
                          onChange={(e) => setPlatform(e)}
                          value={platform}
                          size='large'
                          placeholder='Select Platform'
                        >
                          <Option value={'Instagram'}>Instagram</Option>
                          <Option disabled value={'Facebook'}>
                            Facebook
                          </Option>
                          <Option disabled value={'Tiktok'}>
                            Tiktok
                          </Option>
                          <Option disabled value={'Youtube'}>
                            Youtube
                          </Option>
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col xs={24} md={12} lg={7}>
                      <Form.Item label='Post Type'>
                        <Select
                          style={{ width: '100%' }}
                          onChange={(e) => setPostType(e)}
                          value={postType}
                          size='large'
                          placeholder='Select Post Type'
                        >
                          <Option value={'Story'}>Story</Option>
                          <Option value={'Video'}>Video</Option>
                          <Option value={'Post'}>Post</Option>
                          <Option disabled value={'Message'}>
                            Message
                          </Option>
                          <Option disabled value={'Text'}>
                            Text
                          </Option>
                          <Option disabled value={'Stream'}>
                            Stream
                          </Option>
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col xs={24} md={12} lg={7}>
                      <Form.Item label='How many'>
                        <Input
                          placeholder='5'
                          value={howMany}
                          onChange={(e) => setHowMany(e.target.value)}
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={24} md={12} lg={1}>
                      <Form.Item label=' '>
                        <AiOutlinePlusCircle className='bin' onClick={() => AddConetnt()} />
                      </Form.Item>
                    </Col>
                    {contentArr?.length > 0 && (
                      <Col span={24}>
                        <DataTable
                          columns={columns}
                          data={contentData}
                          loader={false}
                          pagination={false}
                        />
                      </Col>
                    )}
                  </Row>
                </Col>

                <Col
                  xs={24}
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    gap: '1rem',
                    marginTop: '1rem',
                  }}
                >
                  <Form.Item>
                    <Button
                      type='text'
                      style={{ marginBottom: '-2rem' }}
                      onClick={() => navigate(-1)}
                    >
                      Cancel
                    </Button>
                  </Form.Item>
                  <Form.Item>
                    <Button
                      type='text'
                      style={{ marginBottom: '-2rem' }}
                      onClick={() => {
                        // if (!state) {
                        //   setStep(2)
                        // } else {
                        //   setStep(1)
                        // }
                        setStep(2)
                      }}
                    >
                      Previous
                    </Button>
                  </Form.Item>
                  <Form.Item>
                    <Button htmlType='submit' loading={loading} type='primary'>
                      {state ? 'Update' : 'Create'}
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          )}
        </div>
      </div>
    </Layout>
  )
}

export default AddCampaigns
