export const HashtagText = (text, extra) => {
  let tempArr = []
  const parts = text?.split(/((?:#|@)[a-zA-Z]+)/).filter((s) => s?.length)
  parts?.map((part, ind) => {
    if (part?.startsWith('@')) {
      // if part starts with `@` return `Link` in your case
      tempArr.push(
        <span
          key={ind}
          className='hashtag-text'
          onClick={() => {
            window.open(`https://www.instagram.com/${part?.slice(1, part?.length)}/`, '_blank')
          }}
        >
          {part}
        </span>,
      )
    } else if (part?.startsWith('#')) {
      // if part starts with `#` return other `Link`
      tempArr.push(
        <span
          key={ind}
          className='hashtag-text'
          onClick={() => {
            window.open(
              `https://www.instagram.com/explore/tags/${part?.slice(1, part?.length)}`,
              '_blank',
            )
          }}
        >
          {part}
        </span>,
      )
    } else {
      tempArr.push(part)
    }
  })
  tempArr.push(extra)
  return tempArr
}
