import React, { useState } from 'react'
import { Modal, Form, Input, Button, Select, notification } from 'antd'

import { MdModeEditOutline } from 'react-icons/md'

import { privateAPI, attachToken } from '../../config/constants'

const UploadedInfEdit = ({ data, getUploadedInfluencers }) => {
  const { Option } = Select
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [loading, setLoading] = useState(false)
  const [form] = Form.useForm()

  const updateUploadedInfluencer = async (payload) => {
    try {
      setLoading(true)
      attachToken()
      const res = await privateAPI.post('/influencer/update-upload-influencer', { payload })
      if (res) {
        notification.success({
          message: res?.data?.message,
          duration: 2,
        })
        getUploadedInfluencers()
        setLoading(false)
        setIsModalVisible(false)
      }
    } catch (error) {
      setLoading(false)
      console.log('error', error)
      notification.error({
        description: error,
        duration: 3,
      })
    }
  }

  const showModal = () => {
    setIsModalVisible(true)
  }

  const handleCancel = () => {
    setIsModalVisible(false)
  }

  const onFinish = (values) => {
    const payload = {
      ...values,
      id: data?._id,
    }
    updateUploadedInfluencer(payload)
  }

  return (
    <>
      <span style={{ cursor: 'pointer' }} onClick={showModal}>
        <MdModeEditOutline />
      </span>
      <Modal
        title={'Edit Influencer'}
        visible={isModalVisible}
        footer={false}
        onCancel={handleCancel}
        width={'600px'}
      >
        <Form
          layout='vertical'
          form={form}
          fields={[
            {
              name: 'name',
              value: data?.name,
            },
            {
              name: 'handle',
              value: data?.handle,
            },
            {
              name: 'gender',
              value: data?.gender?.toLowerCase(),
            },
          ]}
          onFinish={onFinish}
        >
          <Form.Item
            label='Name'
            name={'name'}
            rules={[
              {
                required: true,
                message: 'Name is Required',
              },
            ]}
            requiredMark={'optional'}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label='Handle'
            name={'handle'}
            rules={[
              {
                required: true,
                message: 'Handle is Required',
              },
            ]}
            requiredMark={'optional'}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label='Gender'
            name={'gender'}
            rules={[
              {
                required: true,
                message: 'Gender is Required',
              },
            ]}
            requiredMark={'optional'}
          >
            <Select>
              <Option value='male'>male</Option>
              <Option value='female'>female</Option>
              <Option value='other'>other</Option>
            </Select>
          </Form.Item>
          <Form.Item>
            <Button htmlType='submit' loading={loading} type='primary'>
              Edit
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </>
  )
}

export default UploadedInfEdit
